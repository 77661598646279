import React from 'react';
import { FormattedMessage } from 'react-intl';
import BaseComponent from '../BaseComponent';
import Constants from '../../shared/constants';
import validator from 'validator';
import Input from '../Input';
import { Control, ValidationRule } from '../../shared/Control';
import ResellerService from '../../services/ResellerService';
import Config from '../../config';
import { withRouter } from 'react-router';

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import { charLimit } from '../../shared/stringOperations';

class ConnectExistingCustomer extends BaseComponent {
  resellerService = new ResellerService(Config.apiBase);

  state = {
    selectedOption: null,
    isDisabled: true,
    showUserDetailsForm: false,
    isSubmitted: false,
    resellers: [],
    value: '',
    reseller: null,
    fetchingResellers: false,
  };

  controls = {
    userDetails: [
      new Control({
        name: 'name',
        type: 'string',
        label: <FormattedMessage id="all_reseller_contact_person" />,
        maxLength: '25',
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_contact_person" />,
        }),
        id: 'contactName',
      }),
      new Control({
        name: 'phone',
        type: 'phone',
        label: <FormattedMessage id="ph_phone" />,
        rule: new ValidationRule({
          method: validator.isLength,
          validWhen: true,
          args: [{ min: 8, max: 8 }],
          message: <FormattedMessage id="warning_phone_number" />,
        }),
        id: 'contactPhone',
      }),
      new Control({
        name: 'email',
        type: 'email',
        label: <FormattedMessage id="ph_email" />,
        rule: new ValidationRule({
          method: validator.isEmail,
          validWhen: true,
          message: <FormattedMessage id="warning_email" />,
        }),
        id: 'contactEmail',
      }),
    ],
  };

  handleSubmit = async event => {
    event.preventDefault();
    //this.setState({ isSubmitted: true });
    this.setState({ loading: true });
    let payload = this.controls.userDetails.map(obj => {
      return { name: obj.name, value: obj.value };
    });
    Array.prototype.push.apply(payload, [{ name: 'vismaId', value: this.props.reseller.id }]);

    try {
      const response = await this.resellerService.connectExistingReseller(payload);
      if (response.status === 200) {
        this.props.onSubmitted();
      } else {
        this.setState({ error: response.data.message });
      }
    } catch (error) {
      this.setState({ error: 'Unexpected error' });
    }
    this.setState({ loading: false });
  };

  handleUserDetailsChange = (name, value) => {
    this.setState({
      isDisabled: !(this.controls.userDetails.find(obj => obj.rule && !obj.isValid) === undefined),
    });
  };

  componentWillMount() {}

  renderResellerDetails() {
    const { error, loading, isDisabled } = this.state;
    const { name, id } = this.props.reseller;
    return (
      <LoadingOverlay
        active={loading}
        spinner={
          <BounceLoader
            size={Constants.LOADER_PROPS.SIZE}
            sizeUnit={Constants.LOADER_PROPS.SIZE_UNIT}
            loading={loading}
          />
        }
        classNamePrefix={'light_modal_'}
        text={<FormattedMessage id="common_loading_overlay" />}
      >
        <form onSubmit={this.handleSubmit} method="post">
          <div className="cardAccordion">
            <div className="row">
              <div className="col text-left mb-4 border-bottom border-dark mt-2 mx-3 pl-0 pb-2">
                <img
                  className="mr-0 pb-1"
                  src={Constants.icons.credentialsDone}
                  width="18"
                  height="18"
                  alt="trash-icon"
                />
                <span className="pt-1" title={name + ', ' + id}>
                  {[charLimit.charLimit(name, 25), id].join(',')}
                </span>
                <div className="d-inline-block">
                  <span className="btn bg-white pr-4 pt-0 pb-1 ml-3" onClick={this.props.onRemoveSelection}>
                    <img
                      className="ml-2  cursor-pointer"
                      src={Constants.icons.trash}
                      width="32"
                      height="18"
                      alt="trash-icon"
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-7 text-left mt-2">
                <h4 className="font-weight-bold my-0">
                  <FormattedMessage id="new_reseller_user_details" />
                </h4>
                <div className="my-4">
                  {this.controls.userDetails.map(control => (
                    <Input key={control.name} options={control} onChange={this.handleUserDetailsChange} />
                  ))}
                </div>
              </div>
              <div className="col text-center mt-2 pt-3 ">
                <div className="mt-10 mb-5">
                  <div className="invalid-feedback pb-2 d-block">{error || <span>&nbsp;</span>}</div>
                  <button
                    type="submit"
                    className={'btn btn-sm ' + (isDisabled ? 'btn-dark' : 'bg-dark-aquamarine text-white')}
                    disabled={isDisabled}
                  >
                    <FormattedMessage id="new_reseller_create_user" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </LoadingOverlay>
    );
  }

  render() {
    return this.renderResellerDetails();
  }
}

export default withRouter(ConnectExistingCustomer);
