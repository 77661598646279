import React from 'react';
import { FormattedMessage } from 'react-intl';
import Constants from '../../../../shared/constants';

const ListViewHeader = ({ onSort, pathName, invoiceType, orderType, isActivityLog, isSortAscending, sortedBy }) => {
  const renderOrderElements = () => (
    <div className="mt-3 px-4 pb-2 row ">
      <div className={'col-3 cursor-pointer'} onClick={onSort.bind(this, Constants.sortingOptions.orderNo)}>
        <u className={sortedBy === Constants.sortingOptions.orderNo ? 'font-weight-semibold' : ''}>
          <FormattedMessage id="common_order_ID" />
        </u>
        <img
          src={Constants.icons.arrowBack}
          width="18px"
          height="18px"
          alt="icon"
          className={
            sortedBy === Constants.sortingOptions.orderNo
              ? isSortAscending
                ? 'arrow-up-sort'
                : 'arrow-down-sort'
              : 'd-none'
          }
        />
      </div>
      <div className={'col-3 cursor-pointer'} onClick={onSort.bind(Constants.sortingOptions.type)}>
        <u className={sortedBy === Constants.sortingOptions.type ? 'font-weight-semibold' : ''}>
          <FormattedMessage id="all_orders_type" />
        </u>
        <img
          src={Constants.icons.arrowBack}
          width="18px"
          height="18px"
          alt="icon"
          className={
            sortedBy === Constants.sortingOptions.type
              ? isSortAscending
                ? 'arrow-up-sort'
                : 'arrow-down-sort'
              : 'd-none'
          }
        />
      </div>
      <div className={'col-3 cursor-pointer'} onClick={onSort.bind(this, Constants.sortingOptions.date)}>
        <u className={sortedBy === Constants.sortingOptions.date ? 'font-weight-semibold' : ''}>
          <FormattedMessage id="all_orders_order_date" />
        </u>
        <img
          src={Constants.icons.arrowBack}
          width="18px"
          height="18px"
          alt="icon"
          className={
            sortedBy === Constants.sortingOptions.date
              ? isSortAscending
                ? 'arrow-up-sort'
                : 'arrow-down-sort'
              : 'd-none'
          }
        />
      </div>
      <div className={'col-3 cursor-pointer'} onClick={onSort.bind(this, Constants.sortingOptions.status)}>
        <u className={sortedBy === Constants.sortingOptions.status ? 'font-weight-semibold' : ''}>
          <FormattedMessage id="all_orders_order_status" />
        </u>
        <img
          src={Constants.icons.arrowBack}
          width="18px"
          height="18px"
          alt="icon"
          className={
            sortedBy === Constants.sortingOptions.status
              ? isSortAscending
                ? 'arrow-up-sort'
                : 'arrow-down-sort'
              : 'd-none'
          }
        />
      </div>
    </div>
  );
  const renderInvoiceElements = () => (
    <div className="mt-3 px-4 pb-2 row">
      <div className={'col-3 cursor-pointer'} onClick={onSort.bind(this, Constants.sortingOptions.referenceNumber)}>
        <u className={sortedBy === Constants.sortingOptions.referenceNumber ? 'font-weight-semibold' : ''}>
          <FormattedMessage id="all_orders_invoice_id" />
        </u>
        <img
          src={Constants.icons.arrowBack}
          width="18px"
          height="18px"
          alt="icon"
          className={
            sortedBy === Constants.sortingOptions.referenceNumber
              ? isSortAscending
                ? 'arrow-up-sort'
                : 'arrow-down-sort'
              : 'd-none'
          }
        />
      </div>
      <div className={'col-2 cursor-pointer'} onClick={onSort.bind(this, Constants.sortingOptions.invoiceDate)}>
        <u className={sortedBy === Constants.sortingOptions.invoiceDate ? 'font-weight-semibold' : ''}>
          <FormattedMessage id="all_orders_invoice_date" />
        </u>
        <img
          src={Constants.icons.arrowBack}
          width="18px"
          height="18px"
          alt="icon"
          className={
            sortedBy === Constants.sortingOptions.invoiceDate
              ? isSortAscending
                ? 'arrow-up-sort'
                : 'arrow-down-sort'
              : 'd-none'
          }
        />
      </div>
      <div className={'col-3 cursor-pointer'} onClick={onSort.bind(this, Constants.sortingOptions.dueDate)}>
        <u className={sortedBy === Constants.sortingOptions.dueDate ? 'font-weight-semibold' : ''}>
          <FormattedMessage id="all_orders_invoice_due_date" />
        </u>
        <img
          src={Constants.icons.arrowBack}
          width="18px"
          height="18px"
          alt="icon"
          className={
            sortedBy === Constants.sortingOptions.dueDate
              ? isSortAscending
                ? 'arrow-up-sort'
                : 'arrow-down-sort'
              : 'd-none'
          }
        />
      </div>
      <div className={'col-2 cursor-pointer'} onClick={onSort.bind(this, Constants.sortingOptions.status)}>
        <u className={sortedBy === Constants.sortingOptions.status ? 'font-weight-semibold' : ''}>
          <FormattedMessage id="all_orders_order_status" />
        </u>
        <img
          src={Constants.icons.arrowBack}
          width="18px"
          height="18px"
          alt="icon"
          className={
            sortedBy === Constants.sortingOptions.status
              ? isSortAscending
                ? 'arrow-up-sort'
                : 'arrow-down-sort'
              : 'd-none'
          }
        />
      </div>
      <div className={'col-2 cursor-pointer'} onClick={onSort.bind(this, Constants.sortingOptions.amount)}>
        <u className={sortedBy === Constants.sortingOptions.amount ? 'font-weight-semibold' : ''}>
          <FormattedMessage id="common_total" />
        </u>
        <img
          src={Constants.icons.arrowBack}
          width="18px"
          height="18px"
          alt="icon"
          className={
            sortedBy === Constants.sortingOptions.amount
              ? isSortAscending
                ? 'arrow-up-sort'
                : 'arrow-down-sort'
              : 'd-none'
          }
        />
      </div>
    </div>
  );
  const renderActivityLog = () => (
    <div className="mt-3 px-4 pb-2 row">
      <div className={'col-2 cursor-pointer'} onClick={onSort.bind(this, Constants.sortingOptions.changeType)}>
        <u className={sortedBy === Constants.sortingOptions.changeType ? 'font-weight-semibold' : ''}>
          <FormattedMessage id="all_orders_type" />
        </u>
        <img
          src={Constants.icons.arrowBack}
          width="18px"
          height="18px"
          alt="icon"
          className={
            sortedBy === Constants.sortingOptions.changeType
              ? isSortAscending
                ? 'arrow-up-sort'
                : 'arrow-down-sort'
              : 'd-none'
          }
        />
      </div>
      <div className={'col-2 cursor-pointer'} onClick={onSort.bind(this, 'customerName')}>
        <u className={sortedBy === 'customerName' ? 'font-weight-semibold' : ''}>
          <FormattedMessage id="common_activity_user" />
        </u>
        <img
          src={Constants.icons.arrowBack}
          width="18px"
          height="18px"
          alt="icon"
          className={sortedBy === 'customerName' ? (isSortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'}
        />
      </div>
      <div className={'col-2 cursor-pointer'} onClick={onSort.bind(this, Constants.sortingOptions.logTime)}>
        <u className={sortedBy === Constants.sortingOptions.logTime ? 'font-weight-semibold' : ''}>
          <FormattedMessage id="common_date" />
        </u>
        <img
          src={Constants.icons.arrowBack}
          width="18px"
          height="18px"
          alt="icon"
          className={
            sortedBy === Constants.sortingOptions.logTime
              ? isSortAscending
                ? 'arrow-up-sort'
                : 'arrow-down-sort'
              : 'd-none'
          }
        />
      </div>
      <div className={'col-6 cursor-pointer'} onClick={onSort.bind(this, Constants.sortingOptions.description)}>
        <u className={sortedBy === Constants.sortingOptions.description ? 'font-weight-semibold' : ''}>
          <FormattedMessage id="common_description" />
        </u>
        <img
          src={Constants.icons.arrowBack}
          width="18px"
          height="18px"
          alt="icon"
          className={
            sortedBy === Constants.sortingOptions.description
              ? isSortAscending
                ? 'arrow-up-sort'
                : 'arrow-down-sort'
              : 'd-none'
          }
        />
      </div>
    </div>
  );

  return (
    <>
      {orderType && renderOrderElements()}
      {invoiceType && renderInvoiceElements()}
      {isActivityLog && renderActivityLog()}
    </>
  );
};
export default ListViewHeader;
