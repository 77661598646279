import React, { Component } from 'react';
import Nav from './Nav';
import SideMenu from './SideMenu';
import BreadcrumbComponent from '../components/Breadcrumbs/index';
import Constants from '../shared/constants';
import { withRouter } from 'react-router';
import AuthService from '../services/AuthService';
import Routes from '../shared/Routes';
import { Link, Redirect } from 'react-router-dom';

class Container extends Component {
  authService = new AuthService();

  render() {
    const { location, activePage, children } = this.props;
    const darkBackground = ['/'];
    const { pathname } = location;
    document.body.className = darkBackground.indexOf(pathname) >= 0 ? 'bg-dark' : '';

    const routes = {
      home: {
        url: '/',
        title: 'Sign In',
        nav: [],
      },
      dashboard: { url: '/dashboard', title: 'Dashboard', nav: [] },
      createNewReseller: {
        url: '/new-reseller/create-new-reseller',
        title: 'Create new Reseller',
        nav: [[location, 'Create new Reseller']],
      },

      connectExistingReseller: {
        url: '/connect-existing-reseller',
        title: 'Connect Reseller',
        nav: [
          [Constants.routes.home.url, 'Home'],
          [location, 'Connect Reseller'],
        ],
      },
      products: {
        url: '/products',
        title: 'All Products',
        nav: [],
      },
      services: {
        url: '/services',
        title: 'All Services',
        nav: [],
      },
      allResellers: {
        url: '/resellers',
        title: 'All Resellers',
        nav: [],
      },

      allCustomers: {
        url: '/customers',
        title: 'All Customers',
        nav: [],
      },

      users: {
        url: '/users',
        title: 'users',
        nav: [],
      },

      allOrders: {
        url: '/orders',
        title: 'All Orders',
        nav: [],
      },
      myOrders: {
        url: '/my-orders',
        title: 'My Orders',
        nav: [],
      },
      newOrderChooseCustomerService: {
        url: '/new-order/choose-customer/service',
        title: 'New Order - Choose your customer',
        nav: [[location, 'New Subscription']],
      },
      newOrderChooseCustomerProduct: {
        url: '/new-order/choose-customer/product',
        title: 'New Order - Choose your customer',
        nav: [[location, 'New Order']],
      },
      newProposalChooseCustomerProduct:{
        url: '/new-proposal/choose-customer/product',
        title: 'New Proposal - Choose your custmer',
        nav: [[location, 'New Proposal (Product)']],
      },
      newProposalChooseCustomerService:{
        url: '/new-proposal/choose-customer/service',
        title: 'New Proposal - Choose your customer',
        nav: [[location, 'New Proposal (Subscription)']]
      }, 
      newOrderChooseLocation: {
        url: '/new-order/choose-location',
        title: 'New Order - Choose Location',
        nav: [
          [Constants.routes.newOrderChooseCustomer, 'New Order'],
          [location, 'Choose Location'],
        ],
      },
      newProductPrepareOrder: {
        url: '/new-order/select-service',
        title: 'New Order - Select products',
        nav: [
          [Constants.routes.newOrderChooseCustomer, 'New Order'],
          [location, 'Select products'],
        ],
      },
      newProductPrepareProposal: {
        url: '/new-proposal/select-product',
        title: 'New Proposal - Select products',
        nav: [
          [Constants.routes.newProposalChooseCustomerProduct, 'New Proposal (Product)'],
          [location, 'Select products'],
        ],
      },
      newServicePrepareOrder: {
        url: '/new-order/select-product',
        title: 'New Order - Select products',
        nav: [
          [Constants.routes.newOrderChooseCustomer, 'New Order'],
          [location, 'Select Services'],
        ],
      },
      newServicePrepareProposal: {
        url: '/new-proposal/select-service',
        title: 'New Proposal - Select subscriptions',
        nav: [
          [Constants.routes.newProposalChooseCustomerService, 'New Proposal (Subscription)'],
          [location, 'Select Services']
        ]
      },
      forgotPassword: {
        url: '/forgot-password',
        title: 'Forgot Password',
        nav: [],
      },
      resetPassword: {
        url: '/reset-password',
        title: 'Reset Password',
        nav: [],
      },
      newPassword: {
        url: '/new-password',
        title: '',
        nav: [
          [Constants.routes.signin.url, 'New User'],
          [location, 'New Password'],
        ],
      },
      newReseller: {
        url: '/new-reseller',
        title: '',
        nav: [],
      },
      thankYouOrder: {
        url: '/new-order/thank-you-order',
        title: 'Thank you',
        nav: [[location, 'New Reseller']],
      },
      addressOrder: {
        url: '/new-order/confirm-order',
        title: 'Confirm Order',
        nav: [
          [location, 'New Order'],
          [location, 'Confirm Order'],
        ],
      },
      newOrderServices: {
        url: '/new-order/choose-customer',
        title: 'New Order Services - Choose your customer',
        nav: [[location, 'New Order Services']],
      },
      // Contracts breadcrumb object should be last
      // since it taking url dynamic it clashes with other breadcrumb also.
      // Keep the contracts object in last
      contracts: {
        url: '/contracts',
        title: 'Contracts',
        nav: [],
      },
      contractsID: {
        url: pathname,
        title: 'Contract Detail',
        nav: [
          [Constants.routes.contracts, 'Contracts'],
          [location, pathname.slice(11)],
        ],
      },
    };

    // if (this.authService.isResellerUser()) {
    //   delete routes['dashboard'];
    // }

    const routesList = Object.entries(routes).map(obj => obj[1]);
    const route = routesList.find(obj => obj.url === pathname);
    if (route) {
      document.title = Constants.ApplicationName + Constants.TitleDivder + route.title;
    }

    const isAuthenticated = this.authService.isAuthenticated() === true;
    // const isReseller = this.authService.isResellerUser();

    // if (isAuthenticated && (route.url === '/dashboard' || route.url === '/') && isReseller) {
    //   return <Redirect to={Constants.routes.myOrders} />;
    // }

    return (
      <div className={(isAuthenticated ? 'row ' : 'w-100') + ' h-100  m-0'} id="main-wrapper">
        {isAuthenticated && (
          <>
            <div className={isAuthenticated ? 'col sideMenu-panel p-0' : ''}>
              <SideMenu route={route} />
            </div>

            {/* <header>
              <Nav activePage={activePage} />
            </header> */}
          </>
        )}
        <div className={isAuthenticated ? 'col main-panel  mh-100 h-100 p-0' : 'h-100'}>
          {isAuthenticated && !(route.url === Routes.newOrderThankyouOrder || route.url === Routes.users) && (
            <>
              {route && route.nav.length > 0 && (
                <div className="container-fluid px-0">
                  {/* <div className="row  mb-0 pb-0"> */}
                  <BreadcrumbComponent route={route} />
                  {/* </div> */}
                </div>
              )}
            </>
          )}
          {children}
        </div>
      </div>
    );
  }
}

export default withRouter(Container);
