import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Constants from '../../shared/constants';
const numberFormat = new Intl.NumberFormat('sv-SE');

function DashboardItem({ icon, route, title, isValueCurrency, data = {}, proposal = false, order = false }) {
  const { value = 0, changeIn30Days = 0 } = data;
  return (
    <>
      <h6 className="mb-0 font-weight-normal fs-14">
        <img src={icon} className="mr-2 mb-1" style={proposal ? {background: "black",  height: "19px"} : null} />
        {route ? (
          <Link to={route} className="black fs-14 text-underline">
            <FormattedMessage id={title} />
          </Link>
        ) : (
          <FormattedMessage id={title} />
        )}
      </h6>
      <h1 className="mb-0 mt-2 color-474747">
        {isValueCurrency ? numberFormat.format(Math.round(value)) : Math.round(value)}
        {isValueCurrency && Constants.currency.norway}
      </h1>
      {typeof changeIn30Days !== 'undefined' && (
        <h6 className="mb-0 mt-2 product-sub-text">
          
          <span className={changeIn30Days > 0 ? 'color-59aa46' : 'color-ff5252'}>
            {changeIn30Days > 0 ? '+' : ''}
            {isValueCurrency ? numberFormat.format(Math.round(changeIn30Days)) : Math.round(changeIn30Days)}
            {isValueCurrency && Constants.currency.norway}
          </span>{' '}
          <FormattedMessage id={order ? "next_30_days" :"dashboard_from_30_days"} />
        </h6>
      )}
    </>
  );
}

export default DashboardItem;
