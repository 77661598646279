import React from 'react';
import { FormattedMessage } from 'react-intl';
import Constants from '../../shared/constants';
import Routes from '../../shared/Routes';

const ThankyouOrder = ({ history }) => {
  const {
    location: { pathname },
  } = history;
  const isProposal = pathname === Constants.routes.newProposalThankyou;
  const onMyOrders = () => {
    history.push(Routes.myOrders);
  };
  const onDefaultRoute = () => {
    if (isProposal) {
      history.replace({
        pathname: Constants.routes.proposals,
      });
    } else {
      history.replace({
        pathname: Constants.routes.orders,
      });
    }
  };
  return (
    <div className="container-fluid bg-green h-100">
      <div className="d-flex flex-column pt-9 h-100">
        <section id="selected-products" className="pt-3">
          <div className="row  justify-content-center text-center">
            <h1 className="mb-3 font-weight-light text-black fs-59 line-height-normal">
              {isProposal ? (
                <FormattedMessage id="new_proposal_success_title" />
              ) : (
                <FormattedMessage id="new_order_success_title" />
              )}
            </h1>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <p className="mb-0 font-weight-normal fs-16 text-black line-height-normal">
                {isProposal ? (
                  <FormattedMessage id="new_proposal_success_mesage" />
                ) : (
                  <FormattedMessage id="new_order_success_message" />
                )}
              </p>
            </div>
            <div className="col-12 text-center">
              <p className="mb-0 font-weight-normal fs-16 text-black">
                {!isProposal && <FormattedMessage id="new_order_success_message_3" />}
              </p>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-4 text-center">
              <button className="btn btn-primary btn-sm w-180-min" onClick={onDefaultRoute}>
                {isProposal ? (
                  <FormattedMessage id="back_to_proposals" />
                ) : pathname === '/new-order/thankyou' || pathname === '/new-order/thankyou/' ? (
                  <FormattedMessage id="common_back_t_orders" />
                ) : (
                  <FormattedMessage id="common_back_t_orders" />
                )}
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ThankyouOrder;
