import React from 'react';
import Chart from 'react-apexcharts';

function BarChart({
  type = 'bar',
  series,
  xaxis,
  height,
  color = '#f2f2f2',
  tooltip = { enabled: false },
  bottomPadding = 0,
}) {
  const options = {
    chart: {
      height: height,
      type: type === 'combo' ? 'line' : 'bar',
      zoom: {
        enabled: false,
      },
      foreColor: 'rgba(0, 0, 0, 0.6)',
    },
    legend: {
      horizontalAlign: 'center',
      labels: {
        colors: 'rgba(0, 0, 0, 0.6)',
      },
      show: true,
      markers: {
        fillColors: [color, '#ff5252'],
      },
      fontSize: 14,
      fontFamily: 'OpanSans, Helvetica, Arial, sans-serif',
    },
    markers: {
      colors: [color, '#ff5252'],
    },
    tooltip: tooltip,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: [2, 1],
      colors: [color, '#ff5252'],
    },
    title: {
      text: '',
      align: 'left',
    },
    grid: {
      borderColor: '#f3f3f3',
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        bottom: bottomPadding,
      },
    },
    xaxis: {
      categories: xaxis,
      labels: {
        show: true,
        style: {
          colors: 'rgba(0, 0, 0, 0.6)',
          fontSize: 14,
          fontFamily: 'OpanSans, Helvetica, Arial, sans-serif',
        },
      },
      crosshairs: {
        show: type === 'combo' ? true : false,
        opacity: 1,
        position: 'front',
        stroke: {
          width: 1,
          color: '#000000',
          dashArray: 0,
        },
      },
      tooltip: { enabled: false },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: 'rgba(0, 0, 0, 0.6)',
          fontSize: 14,
          fontFamily: 'OpanSans, Helvetica, Arial, sans-serif',
        },
      },
    },
    fill: {
      opacity: 1,
      colors: [color, '#ff5252'],
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
  };

  return <Chart options={options} series={series} type={type === 'combo' ? 'line' : 'bar'} height={height} />;
}

export default BarChart;
