import React, { Component } from "react";
import Input from "../Input";
import { Control, ValidationRule } from "../../shared/Control";
import PostalCodeService from "../../services/PostalCodeService";
import Config from "../../config";
import validator from "validator";
import { FormattedMessage } from "react-intl";

class PostalInput extends Component {
  postalCodeService = new PostalCodeService(Config.apiBase);
  state = {
    postalCode: { isValid: false },
    city: ""
  };

  postalCodeInput = {};

  constructor(props) {
    super(props);
    this.postalCodeInput = new Control({
      name: "postalCode",
      type: "text",
      label: <FormattedMessage id="ph_postal_code" />,
      id: "postal",
      minLength: "4",
      maxLength: "4",
      rule: new ValidationRule({
        method: validator.isLength,
        validWhen: true,
        args: [{ min: 4, max: 4 }],
        message: <FormattedMessage id="warning_postal_code" />
      }),
      onBlur: this.handleBlur,
      onFocus: this.props.options.onFocus,
      value: this.props.options.value,
      onChange: this.onChange
    });
  }

  /**
   * The Below componentWillMount is added by KarthikeyanU
   * Don't exactly know how the city is filling automatically without any triggering
   * so I added componentWillMount to autoFill city and it's working fine
   */
  async componentWillMount() {
    const value = this.props.options.value;
    if (value) {
      await this.fetchPostalCode(value);
    }
  }

  handleBlur = async (event, control) => {
    const { onBlur } = this.props.options;
    if (onBlur) {
      onBlur(event, this.props.options);
    }
    await this.fetchPostalCode(control.value);
    this.setState({
      postalCode: {
        isValid: this.postalCodeInput.isValid
      }
    });
  };

  async fetchPostalCode(value) {
    const { onChange, options } = this.props;
    try {
      let response = await this.postalCodeService.getCityDetails(value);
      if (response.status === 200 && response.data.valid) {
        this.props.options.city = response.data.result;
        this.props.options.isValid = true;
        this.setState({ city: response.data.result });
        this.postalCodeInput.isValid = true;
        onChange(options.name, options);
      } else {
        this.setState({ city: "" });
        this.props.options.city = "";
        this.props.options.isValid = false;
        onChange(options.name, options);
        this.postalCodeInput.isValid = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleCity = async (name, value) => {
    this.setState({
      city: {
        isValid: this.validate(this.controls.city)
      }
    });
  };

  handleChange = (name, control) => {
    const { onChange, options } = this.props;
    if (onChange) {
      this.props.options.isValid = false;
      this.props.options.value = control.value;
      onChange(name, options);
    }
    if (String(control.value).length === 4) {
      this.fetchPostalCode(control.value);
      this.setState({
        postalCode: {
          isValid: this.postalCodeInput.isValid
        }
      });
    }
  };

  render() {
    const { city } = this.state;
    if (this.postalCodeInput.value !== this.props.options.value) {
      this.postalCodeInput.value = this.props.options.value;
      this.fetchPostalCode(this.postalCodeInput.value);
    }
    return (
      <div>
        <div className="row">
          <div className="col-6 postal-input-width">
            <Input
              options={this.postalCodeInput}
              onChange={this.handleChange}
              type="number"
              key={"postal-input"}
            />
          </div>
          <div className="col-4 postal-input-width input-city-disabled">
            <Input
              options={{
                name: "city",
                type: "text",
                label: <FormattedMessage id="ph_city" />,
                id: "postal" + +new Date(),
                disabled: true,
                value: city
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PostalInput;
