import React, { Component } from 'react';

class Accordion extends Component {
  handleToggle = () => {
    const { onToggle, item } = this.props;
    onToggle(item);
  };

  renderChildren() {
    const {
      children,
      isExpanded,
      item,
      isSuperAdmin,
      isAdminBackOffice,
      isAdminSales,
      invoiceType,
      orderType,
      isActivityLog,
      isDetails,
      isContracts,
      forBasket,
      isProposal,
    } = this.props;
    return React.Children.map(children, child => {
      return React.cloneElement(child, {
        isExpanded: isExpanded,
        handleToggle: this.handleToggle,
        item: item,
        isSuperAdmin: isSuperAdmin,
        isAdminBackOffice: isAdminBackOffice,
        isAdminSales: isAdminSales,
        invoiceType: invoiceType,
        orderType: orderType,
        isActivityLog: isActivityLog,
        isDetails: isDetails,
        isContracts: isContracts,
        forBasket,
        isProposal: isProposal,
        key: Math.random() * 100,
      });
    });
  }

  render() {
    return (
      <div className={'row mx-0'}>
        <div className={'col '}>{this.renderChildren()}</div>
      </div>
    );
  }
}

export default Accordion;
