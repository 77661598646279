import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ListViewComponent from '../../../ListView/ListView';
import AuthService from '../../../../services/AuthService';
import CustomerListViewHeader from './CustomerListViewHeader';
import Constants from '../../../../shared/constants';

class CustomerListView extends ListViewComponent {
  authService = new AuthService();
  isResellerLoggedIn = this.authService.isResellerUser();
  state = {};
  render() {
    const { users, onSort, sortAscending, sortedBy } = this.props;

    const { loggedResellerId } = this.state;
    const roles = {
      1: Constants.language.role_privat,
      4: Constants.language.role_bedrift,
      5: Constants.language.all_contracts_reseller,
    };
    return (
      <React.Fragment>
        <CustomerListViewHeader onSort={onSort} sortAscending={sortAscending} sortedBy={sortedBy} />

        {users.map((user, index) => {
          return (
            <div
              className={
                'row py-2 align-items-center table-row fs-14 ' +
                (index + 1 === users.length && ' border-bottom-979797 ')
              }
              key={Math.random() * 100}
            >
              <div className="col-3 font-weight-semibold rounded-color pl-2 text-truncate" title={user.name}>
                <>
                  {user.customerClass && Number(user.customerClass.id) === 5 ? (
                    <Link to={{ pathname: `/resellers/${user.number}`, query: { name: user.name } }}>
                      <u className="text-truncate">{user.name}</u>
                    </Link>
                  ) : (
                    user.customerClass.id !== 5 && (
                      <Link to={{ pathname: `/customers/${user.number}`, query: { name: user.name } }}>
                        <u className="text-truncate">{user.name}</u>
                      </Link>
                    )
                  )}
                </>
              </div>
              <div className="col-2">{user.number}</div>
              <div className="col-3 text-truncate" title={user.reseller && user.reseller.name}>
                {user.reseller && user.reseller.name}
              </div>
              <div className="col-2 pl-0 " title={user.customerClass && roles[user.customerClass.id]}>
                {user.customerClass && roles[user.customerClass.id]}
              </div>
              <div className="col-2 ">{moment(user.createdDateTime, 'YYYY-MM-DD').format('DD/MM/YYYY')}</div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

CustomerListView.propTypes = {
  users: PropTypes.array.isRequired,
  onSort: PropTypes.func,
  sortAscending: PropTypes.bool,
};

export default CustomerListView;
