import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

class NoMatch extends Component {
  componentDidMount() {
    document.title = "Rackit";
  }
  render() {
    return (
      <div className="container">
        <h1 className="text-center">
          <FormattedMessage id="no_match_page_not_found" />
        </h1>
      </div>
    );
  }
}

export default NoMatch;
