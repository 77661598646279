import ApiService from './ApiService';
import AuthService from './AuthService';
import Config from '../config';

class ProposalService extends ApiService {
  authService = new AuthService(Config.apiBase);
  endPoint = {
    proposals: 'proposals',
    decline: '/decline',
    details: '/details',
  };

  async getAll(queryParam, id) {
    let response;
    if (this.authService.isResellerUser()) {
      response = await this.get(this.baseURL + this.endPoint.proposals + '/' + id + this.queryParamString(queryParam));
    } else {
      response = await this.get(this.baseURL + this.endPoint.proposals + this.queryParamString(queryParam));
    }
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        proposals: response.data.data.map(obj => obj),
        meta: response.data.meta,
      };
    }
  }

  async declineProposal(id) {
    let response = await this.put(this.baseURL + this.endPoint.proposals + '/' + id + this.endPoint.decline);
    const { status, statusText, data } = response;
    if (status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status,
        data,
      };
    }
  }

  async getProposalDetails(id) {
    let response = await this.get(this.baseURL + this.endPoint.proposals + '/' + id + this.endPoint.details);
    const { status, data } = response;
    if (status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status,
        data,
      };
    }
  }
}

export default ProposalService;
