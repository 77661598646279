import Constants from '../../shared/constants';

const Period = {
  LastTwelveWeeks: 0,
  ThisQuarter: 1,
  LastQuarter: 2,
  LastTwelveMonths: 3,
  ThisYear: 4,
  LastYear: 5,
};

const Periods = [
  {
    id: Period.LastTwelveWeeks,
    label: 'dashboard_period_last_twelve_weeks',
  },
  {
    id: Period.ThisQuarter,
    label: 'dashboard_period_this_quarter',
  },
  {
    id: Period.LastQuarter,
    label: 'dashboard_period_last_quarter',
  },
  {
    id: Period.LastTwelveMonths,
    label: 'dashboard_period_last_twelve_month',
  },
  {
    id: Period.ThisYear,
    label: 'dashboard_period_this_year',
  },
  {
    id: Period.LastYear,
    label: 'dashboard_period_last_year',
  },
];

const ShortMonthNames = [
  Constants.language.month_short_name_jan,
  Constants.language.month_short_name_feb,
  Constants.language.month_short_name_mar,
  Constants.language.month_short_name_apr,
  Constants.language.month_short_name_may,
  Constants.language.month_short_name_jun,
  Constants.language.month_short_name_jul,
  Constants.language.month_short_name_aug,
  Constants.language.month_short_name_sep,
  Constants.language.month_short_name_oct,
  Constants.language.month_short_name_nov,
  Constants.language.month_short_name_dec,
];

const FullMonthNames = [
  Constants.language.month_full_name_jan,
  Constants.language.month_full_name_feb,
  Constants.language.month_full_name_mar,
  Constants.language.month_full_name_apr,
  Constants.language.month_full_name_may,
  Constants.language.month_full_name_jun,
  Constants.language.month_full_name_jul,
  Constants.language.month_full_name_aug,
  Constants.language.month_full_name_sep,
  Constants.language.month_full_name_oct,
  Constants.language.month_full_name_nov,
  Constants.language.month_full_name_dec,
];

export { Period, Periods, ShortMonthNames, FullMonthNames };
