import ApiService from './ApiService';

class UserService extends ApiService {
  endPointUser = 'all-users';

  async getAllUsers(queryParam) {
    let response = await this.get(this.baseURL + this.endPointUser + this.queryParamString(queryParam));
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      const { data, meta } = response.data;
      return {
        status: response.status,
        customers: data,
        data: data,
        meta: meta,
      };
    }
  }
}

export default UserService;
