import React from 'react';
import { FormattedMessage } from 'react-intl';
import Constants from '../../../../shared/constants';
import PropTypes from 'prop-types';

const UserListViewHeader = ({ onSort, sortedBy, sortAscending }) => {
  return (
    <>
      <div className="row pb-2 table-header fs-14">
        <div className="col-3 cursor-pointer" onClick={onSort.bind(this, 'name')}>
          <u className={sortedBy === 'name' ? 'font-weight-semibold' : 'font-weight-normal'}>
            <FormattedMessage id="common_name" />
          </u>
          <img
            src={Constants.icons.arrowUp}
            alt="sort"
            className={
              sortedBy === 'name'
                ? sortAscending
                  ? 'users-arrow-up-sort ml-3 mb-1'
                  : 'users-arrow-down-sort ml-3 mb-1'
                : 'd-none'
            }
          />
        </div>
        <div className="col-3 cursor-pointer" onClick={onSort.bind(this, 'email')}>
          <u className={sortedBy === 'email' ? 'font-weight-semibold' : 'font-weight-normal'}>
            <FormattedMessage id="ph_email" />
          </u>
          <img
            src={Constants.icons.arrowUp}
            alt="sort"
            className={
              sortedBy === 'email'
                ? sortAscending
                  ? 'users-arrow-up-sort ml-3 mb-1'
                  : 'users-arrow-down-sort ml-3 mb-1'
                : 'd-none'
            }
          />
        </div>
        <div className="col-2 cursor-pointer" onClick={onSort.bind(this, 'role')}>
          <u className={sortedBy === 'role' ? 'font-weight-semibold' : 'font-weight-normal'}>
            <FormattedMessage id="all_orders_type" />
          </u>
          <img
            src={Constants.icons.arrowUp}
            alt="sort"
            className={
              sortedBy === 'role'
                ? sortAscending
                  ? 'users-arrow-up-sort ml-3 mb-1'
                  : 'users-arrow-down-sort ml-3 mb-1'
                : 'd-none'
            }
          />
        </div>
        <div className="col-2 cursor-pointer" onClick={onSort.bind(this, 'reseller')}>
          <u className={sortedBy === 'reseller' ? 'font-weight-semibold' : 'font-weight-normal'}>
            <FormattedMessage id="all_contracts_reseller" />
          </u>
          <img
            src={Constants.icons.arrowUp}
            alt="sort"
            className={
              sortedBy === 'reseller'
                ? sortAscending
                  ? 'users-arrow-up-sort ml-3 mb-1'
                  : 'users-arrow-down-sort ml-3 mb-1'
                : 'd-none'
            }
          />
        </div>
        <div className="col-2 cursor-pointer" onClick={onSort.bind(this, 'status')}>
          <u className={sortedBy === 'status' ? 'font-weight-semibold' : 'font-weight-normal'}>
            <FormattedMessage id="all_orders_order_status" />
          </u>
          <img
            src={Constants.icons.arrowUp}
            alt="sort"
            className={
              sortedBy === 'status'
                ? sortAscending
                  ? 'users-arrow-up-sort ml-3 mb-1'
                  : 'users-arrow-down-sort ml-3 mb-1'
                : 'd-none'
            }
          />
        </div>
      </div>
    </>
  );
};

UserListViewHeader.propTypes = {
  onSort: PropTypes.func,
};

export default UserListViewHeader;
