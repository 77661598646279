import React from 'react';
import { FormattedMessage } from 'react-intl';
import ProductService from '../services/ProductService';
import AuthService from '../services/AuthService';
import Config from '../config';
import Constants from '../shared/constants';
import Routes from '../shared/Routes';
import LoadingOverlay from 'react-loading-overlay';
import BaseComponent from '../components/BaseComponent';
import BounceLoader from 'react-spinners/BounceLoader';
import ServiceCards from '../components/ServiceCards';
import Toast from '../components/Toast/index';
import ServiceModal from '../components/Services/ServiceModal';
class Services extends BaseComponent {
  productService = new ProductService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  state = {
    loading: false,
    parentServiceData: [],
    services: [],
    meta: {
      cursor: {
        previous: null,
        current: 0,
        next: 1,
        count: null,
        pageNumber: null,
      },
    },
    sortedBy: 'name',
    searchQuery: '',
    sortAscending: true,
    showToast: false,
    toastData: {},
    isFilterOpen: false,
    serviceModalShow: false,
    editService: [],
    edit: false,
    isSubmitted: false,
  };
  constructor(props) {
    super(props);
    const isReseller = this.authService.isResellerUser();
  }

  async componentDidMount() {
    this.fetchServices();
  }
  async fetchServices() {
    this.setState({ loading: true });
    let response;

    try {
      response = await this.productService.getServices({ queryParam: '' });
      let services = response.services;

      const childServices = services.filter(obj => obj.parent);
      var parentServices = services.filter(obj => !obj.parent);
      parentServices = parentServices.map(parent => {
        parent.children = childServices.filter(obj => obj.parent.id === parent.inventoryId);

        return parent;
      });

      this.setState({
        services: services,
        parentServiceData: parentServices,
      });
    } catch (error) {
      console.log(error);
    }

    this.setState({ loading: false });
  }

  handleReadMore = service => {
    const { services } = this.state;
    services.filter(obj => obj.inventoryId === service.inventoryId).map(obj => (obj.readMore = !obj.readMore));
    this.setState({ services });
  };
  handleOnPopUpClick = ({ service }) => {
    const { parentServiceData } = this.state;
    const findedService = parentServiceData.find(data => data.inventoryId === service.inventoryId);
    if (!findedService) {
      parentServiceData.map(obj => {
        if (obj.children && obj.children.length > 0) {
          obj.children.map(item => {
            if (item.inventoryId === service.inventoryId) {
              item.isInfoOpened = !item.isInfoOpened;
            }
            return item;
          });
        }
        return obj;
      });
    }

    if (findedService) {
      findedService.isInfoOpened = !findedService.isInfoOpened;
    }
    this.setState({ parentServiceData });
  };
  handleToast = data => {
    this.setState({ showToast: true, toastData: data });
    setTimeout(() => {
      this.setState({
        showToast: false,
      });
    }, data.delay);
    if (data.type === Constants.toast.SUCCESS) {
      this.fetchServices();
    } else {
      this.fetchServices();
    }
  };
  handleServieToggle = ({ serviceData }) => {
    const { services } = this.state;
    if (serviceData) {
      let chosenService = services.find(obj => obj.inventoryId === serviceData.inventoryId);
      if (chosenService && chosenService.isExpanded) {
        chosenService.isExpanded = false;
      } else if (chosenService) {
        chosenService.isExpanded = true;
      }
      this.setState({ services });
    }
  };
  handleServiceOrder = event => {
    const { history } = this.props;
    event.preventDefault();
    history.push(Routes.newOrderChooseCustomerService);
  };

  handleToUpdateProductService = async () => {
    this.setState({ loading: true });
    // this.fetchServices();
  };
  editServiceModal = editService => {
    const { serviceModalShow } = this.state;
    this.openCreateservicemodal();
    this.setState({ serviceModalShow: true, editService, edit: true });
  };
  editServiceModalClose = editService => {
    this.setState({ serviceModalShow: false, editService: [], edit: false });
  };
  openCreateservicemodal = () => {
    this.setState({ serviceModalShow: true }, () => {
      this.openElement.click();
    });
  };
  createServiceModalClose = data => {
    this.setState({ serviceModalShow: false });
  };
  render() {
    const isReseller = this.authService.isResellerUser();
    const isSuperAdmin = this.authService.isSuperAdminUser();
    const isAdminSales = this.authService.isAdminSalesUser();
    const isAdminBackOffice = this.authService.isAdminBackOfficeUser();
    const {
      parentServiceData,
      showToast,
      toastData,
      serviceModalShow,
      editService,
      edit,
      data,
      isSubmitted,
      loading,
    } = this.state;
    return (
      <React.Fragment>
        {showToast && (
          <Toast type={toastData.type} message={toastData.message} showToast={showToast} delay={toastData.delay} />
        )}

        <section id="page-header">
          <div className="container-fluid px-0">
            <div className=" mt-5">
              <div className="row mb-3">
                <div className="col-12">
                  <h1 className="mb-2 pb-1 ls-0-25">
                    <FormattedMessage id="sidemenu_subscription" />
                  </h1>
                  <p className="mb-0 line-height-22 mb-1 w-600-max">
                    <FormattedMessage id="all_services_description" />
                  </p>
                </div>
              </div>
              <div className="row mt-3 mb-2 pb-2">
                <div className="col">
                  <>
                    <button
                      className="new-product-btn  btn btn-primary fs-14 mr-4 text-align-center w-237 py-1 line-height-16 ls-1-27"
                      onClick={this.handleServiceOrder}
                    >
                      <img src={Constants.icons.iconAddWhite} width="24" height="24" className="mr-1" alt="icon" />
                      <span className="va-middle font-weight-normal">
                        <FormattedMessage id="create_new_service_order" />
                      </span>
                    </button>
                    {(isSuperAdmin || isAdminBackOffice) && (
                      <>
                        <button
                          className="new-product-btn btn bg-white fs-14 py-1 line-height-16 ls-1-27 row px-3 ml-0"
                          onClick={this.openCreateservicemodal}
                        >
                          <img src={Constants.icons.iconAddBlack} width="24" height="24" className="mr-1" alt="icon" />
                          <span>
                            <span className="va-middle font-weight-normal">
                              <FormattedMessage id="create_add_service" />
                            </span>
                          </span>
                        </button>
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#servicemodal"
                          ref={openModal => (this.openElement = openModal)}
                        />
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>{' '}
          </div>
        </section>
        <section id="services" className="bg-green mb-4">
          <div id="services-content" className="collapse show">
            <div className="container-fluid mt-3 px-0">
              <div className="">
                <LoadingOverlay
                  active={loading}
                  spinner={<BounceLoader />}
                  className="loading__overlay_white_wrapper loading-all-products"
                  text={<FormattedMessage id="common_loading_overlay" />}
                >
                  {parentServiceData.length > 0 ? (
                    <ServiceCards
                      parentServiceData={parentServiceData}
                      onReadMore={this.handleReadMore}
                      forProducts={true}
                      onPopUpClick={this.handleOnPopUpClick}
                      onServiceToggle={this.handleServieToggle}
                      edit={edit}
                      isSuperAdminUser={isSuperAdmin}
                      isAdminBackOffice={isAdminBackOffice}
                      editServiceModal={this.editServiceModal}
                      editServiceModalClose={this.editServiceModalClose}
                    />
                  ) : (
                    <>
                      <div className=" pt-3 ri-table pb-5">
                        <React.Fragment>
                          {!loading && parentServiceData.length === 0 && (
                            <div className="row justify-content-center mb-4">
                              <p className="text-center">
                                ! &nbsp;
                                <FormattedMessage id="all_subscriptions_no_services" />
                              </p>
                            </div>
                          )}
                        </React.Fragment>
                      </div>
                    </>
                  )}
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </section>

        {serviceModalShow && (
          <ServiceModal
            toUpdateProductService={this.handleToUpdateProductService}
            editService={editService}
            editServiceModal={this.editServiceModal}
            editServiceModalClose={this.editServiceModalClose}
            serviceModalShow={serviceModalShow}
            createServiceModalClose={this.createServiceModalClose}
            handleToast={this.handleToast}
            edit={edit}
            data={data}
            isSubmitted={isSubmitted}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Services;
