import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import {FormattedMessage} from 'react-intl';
import Pagination from '../../Pagination/index';
import ListViewCard from '../Components/ListView/ListViewCard';
import ContractService from '../../../services/ContractService';
import Config from '../../../config';
import BaseComponent from '../../BaseComponent';
import Constants from '../../../shared/constants';

export default class ServiceActivityLog extends BaseComponent {
  contractService = new ContractService (Config.apiBase);

  constructor (props) {
    super (props);
    this.state = {
      meta: {
        cursor: {
          previous: null,
          current: 0,
          next: 1,
          count: null,
        },
      },
      searchQuery: '',
      showNext: true,
      message: false,
      sortedBy: Constants.sortingOptions.logTime,
      sortAscending: false,
      activityLogsData: [],
      loading: true,
    };
    this.fetchOrders ();
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      prevState.searchQuery !== this.state.searchQuery ||
      prevState.meta.cursor.pageNumber !== this.state.meta.cursor.pageNumber ||
      prevState.sortedBy !== this.state.sortedBy ||
      prevState.sortAscending !== this.state.sortAscending
    ) {
      this.fetchOrders ();
    }
  }

  gotoPage = pageNumber => {
    this.setState ({
      meta: {
        cursor: {
          pageNumber: pageNumber,
        },
      },
    });
  };

  async fetchOrders () {
    const {searchQuery, sortedBy, sortAscending} = this.state;
    this.setState ({loading: true});
    const queryParams = [
      ['name', searchQuery],
      ['pageNumber', this.state.meta.cursor.pageNumber],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
    ];
    let response = await this.contractService.getActivityLogs (
      this.props.contractID,
      queryParams
    );

    let {meta, status} = response;
    try {
      if (status === 200) {
        let activityLogsData;
        activityLogsData = response.data.data;
        const showNext = this.canShowNext (
          this.state.activityLogsData,
          activityLogsData,
          meta.cursor
        );
        if (activityLogsData.length > 0) {
          if (this.state.searchQuery) {
            this.setState ({message: false});
          }
          this.setState ({
            activityLogsData,
            meta,
            showNext,
            //message: false
          });
        } else {
          if (this.state.searchQuery) {
            this.setState ({
              searchQuery: undefined,
              message: true,
              unknown: true,
            });
          }
        }
      }
    } catch (e) {
      console.error (e);
    }
    this.setState ({loading: false});
  }

  handleSorting = name => {
    if (this.state.sortedBy === name) {
      this.setState ({sortAscending: !this.state.sortAscending});
    } else {
      this.setState ({sortedBy: name, sortAscending: true});
    }
  };

  render () {
    const {
      loading,
      activityLogsData,
      meta,
      sortedBy,
      sortAscending,
    } = this.state;
    return (
      <div>
        <section id="items" className="pb-3">
          {loading && <div className="pt-2 mt-5" />}

          <div className=" ri-table mb-5">
            <div className="card card-rounded">
              <div className="card-body service-order-card-body">
                <LoadingOverlay
                  active={loading}
                  spinner={<BounceLoader />}
                  text={<FormattedMessage id="common_loading_overlay" />}
                >
                  {!loading &&
                    (activityLogsData.length > 0
                      ? <React.Fragment>
                          <div className="fs-14">
                            <ListViewCard
                              activityLogsData={activityLogsData}
                              isActivityLog
                              onSort={this.handleSorting}
                              sortAscending={sortAscending}
                              sortedBy={sortedBy}
                            />
                            {!loading &&
                              activityLogsData.length > 0 &&
                              <Pagination
                                cursor={meta.cursor}
                                gotoPage={this.gotoPage}
                              />}
                          </div>
                        </React.Fragment>
                      : <p className="text-center fs-14 mt-4 pt-1">
                          ! &nbsp;
                          <FormattedMessage id="common_no_log_found" />
                        </p>)}
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
