import React from 'react';
import { FormattedMessage } from 'react-intl';
import Constants from '../shared/constants';
import { withRouter } from 'react-router';
import { charLimit } from '../shared/stringOperations';
import Autosuggest from 'react-autosuggest';
import ResellerService from '../services/ResellerService';
import Config from '../config';
import ConnectExistingCustomer from '../components/NewReseller/ConnectExistingCustomer';
import BaseComponent from '../components/BaseComponent';
import Thankyou from '../components/NewReseller/Thankyou';

class NewReseller extends BaseComponent {
  state = {
    customerId: '',
    resellers: [],
    loading: false,
    value: '',
    fetchingResellers: false,
    showForm: false,
    isSubmitted: false,
  };
  resellerService = new ResellerService(Config.apiBase);

  handleExistingCustomer = () => {
    this.props.history.push(Constants.routes.connectExistingReseller);
  };

  handleNewReseller = () => {
    this.props.history.push(Constants.routes.createNewReseller);
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = async ({ value }) => {
    if (value.length > 3 && !this.state.fetchingResellers) {
      this.setState({ fetchingResellers: true });
      const response = await this.resellerService.getAllUsers([['filter', { name: [value] }]]);
      const { resellers } = response;
      this.setState({ resellers, fetchingResellers: false });
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      resellers: [],
    });
  };

  handleSuggestionSelected = (event, obj) => {
    this.setState({ selectedCustomer: obj.suggestion });
  };

  onRemoveSelection = () => {
    this.setState({
      selectedCustomer: undefined,
      value: '',
      showForm: false,
    });
  };

  handleSubmitted = () => {
    this.setState({ isSubmitted: true });
  };

  render() {
    const { value, resellers, selectedCustomer, showForm, isSubmitted } = this.state;
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: Constants.language.common_write_name_or_id,
      value,
      onChange: this.onChange,
    };
    const options = resellers.slice(0, 20).map(reseller => {
      return { id: reseller.id, name: reseller.name };
    });

    return (
      <div className="container">
        <section id="welcome-section" className="mt-5">
          {!isSubmitted && (
            <div className="row mb-0 align-items-center">
              <div className="col-2" />
              <div className="col text-center">
                <h1>
                  <FormattedMessage id="new_reseller_page_heading" />
                </h1>
                <br />
                <p>
                  <FormattedMessage id="new_reseller_description" />
                </p>
              </div>
              <div className="col-2 text-right">
                <a href="#back" className="font-weight-normal fs-14" onClick={this.goBack}>
                  <img className="mr-2" src={Constants.icons.arrowBack} width="18" height="18" alt="back-icon" />
                  <FormattedMessage id="common_back" />
                </a>
              </div>
            </div>
          )}
        </section>
        <section id="admin-section" className="mt-3">
          {!isSubmitted && (
            <div className="cardAccordion">
              <div className="row justify-content-md-center">
                <div className="col-8 ml-2">
                  <div id="chooseReseller" className="card">
                    <div className="cardBody p-5">
                      <div className="row">
                        <div className="col-12">
                          <h4 className="font-weight-bold my-0">
                            <FormattedMessage id="new_users_add_new_user" />
                          </h4>
                        </div>

                        <div className="col-12 mt-2 mb-3">
                          <p>
                            <FormattedMessage id="new_reseller_add_new_user_subtext" />
                          </p>
                        </div>

                        {!showForm && (
                          <React.Fragment>
                            <div className="col-7  mb-3">
                              {!selectedCustomer && (
                                <div id="search-box" className="input-group border border-dark rounded pl-4">
                                  <div className="form-control border-0 pt-2 pl-0">
                                    <Autosuggest
                                      suggestions={options}
                                      onSuggestionSelected={this.handleSuggestionSelected}
                                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                      getSuggestionValue={option => option.name}
                                      renderSuggestion={suggestion => (
                                        <div>
                                          {suggestion.name} - #{suggestion.id}
                                        </div>
                                      )}
                                      inputProps={inputProps}
                                    />
                                  </div>
                                  <div className="input-group-append search-icon z-index-4">
                                    <button className="btn bg-white py-2 mr-1 z-index-4" type="button">
                                      {this.state.fetchingResellers && (
                                        <div className="spinner-border spinner-border-sm" role="status">
                                          <span className="sr-only">
                                            <FormattedMessage id="common_loading_overlay" />
                                          </span>
                                        </div>
                                      )}
                                      {!this.state.fetchingResellers ? (
                                        value.length > 0 ? (
                                          <span className="float-right cursor-pointer" onClick={this.onRemoveSelection}>
                                            <img
                                              className="mr-2 cursor-pointer"
                                              src={Constants.icons.crossNew1}
                                              width="14"
                                              height="14"
                                              alt="trash-icon"
                                            />
                                          </span>
                                        ) : (
                                          <img src={Constants.icons.search} width="18" height="18" alt="search-icon" />
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </button>
                                  </div>
                                </div>
                              )}
                              {selectedCustomer && (
                                <div className="input-group mb-0">
                                  <div
                                    className="form-control form-control-lg pl-2 pr-0 border border-dark customer-details"
                                    title={selectedCustomer.name + ', ' + selectedCustomer.id}
                                  >
                                    <img
                                      className="ml-3 mr-2 pb-0"
                                      src={Constants.icons.credentialsDone}
                                      width="17"
                                      height="13"
                                      alt="trash-icon"
                                    />
                                    {[charLimit.charLimit(selectedCustomer.name, 12), selectedCustomer.id].join(',  ')}
                                    <span className="float-right cursor-pointer" onClick={this.onRemoveSelection}>
                                      <img
                                        className="mr-2"
                                        src={Constants.icons.trash}
                                        width="16"
                                        height="20"
                                        alt="trash-icon"
                                      />
                                    </span>
                                  </div>
                                  <div className="input-group-append">
                                    <span
                                      className="bg-grey border br-4 btn pr-3"
                                      onClick={event => {
                                        event.preventDefault();
                                        this.setState({ showForm: true });
                                      }}
                                    >
                                      <FormattedMessage id="common_next" />
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="col-5  mb-3">
                              <button
                                className="btn btn-dark btn-sm w-75"
                                onClick={event => {
                                  event.preventDefault();
                                  this.handleNewReseller();
                                }}
                              >
                                <span className="mr-2">+</span> <FormattedMessage id="bc_new_reseller" />
                              </button>
                            </div>
                          </React.Fragment>
                        )}
                      </div>

                      {showForm && (
                        <ConnectExistingCustomer
                          onRemoveSelection={this.onRemoveSelection}
                          reseller={selectedCustomer}
                          onSubmitted={this.handleSubmitted}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isSubmitted && (
            <Thankyou
              title={<FormattedMessage id="new_reseller_registration_success" />}
              message={<FormattedMessage id="new_reseller_success_description" />}
              url={Constants.routes.orders}
              btnText={<FormattedMessage id="common_back_to_dashboard" />}
            />
          )}
        </section>
      </div>
    );
  }
}

export default withRouter(NewReseller);
