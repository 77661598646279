import axios from 'axios';
import { GET_ALL_PRODUCTS, PRODUCTS_ARE_LOADING } from './types';
import Config from '../../config';
import ProductService from '../../services/ProductService';
import { store } from '..//store';
import { CustomerType } from '../../shared/CustomerType';
let productService = new ProductService(Config.apiBase);

export function productsHaveError(bool) {
  return {
    type: PRODUCTS_ARE_LOADING,
    hasError: bool,
  };
}

export function productsAreLoading(bool) {
  return {
    type: PRODUCTS_ARE_LOADING,
    isLoading: bool,
  };
}

export function productsFetchDataSuccess(products) {
  return {
    type: GET_ALL_PRODUCTS,
    payLoad: products,
  };
}

export function productsFetchData(url) {
  return dispatch => {
    dispatch(productsAreLoading(true));

    axios
      .get(url)
      .then(response => {
        if (response.status !== 200) {
          throw Error(response.statusText);
        }

        dispatch(productsAreLoading(false));
        return response;
      })
      .then(response => dispatch(productsFetchDataSuccess(response.data.products)))
      .catch(() => dispatch(productsHaveError(true)));
  };
}

export function getAll(url, pageNumber) {
  return (dispatch, getState) => {
    dispatch(productsAreLoading(true));
    let productsRedux = getState().Products.totalProducts;

    if (productsRedux[pageNumber] ? true : false) {
      let state = getState().Products;
      state.products = productsRedux[pageNumber];
      state.meta.cursor.pageNumber = pageNumber;
      dispatch(productsAreLoading(false));
      dispatch(productsFetchDataSuccess(state));
      return;
    }
    productService
      .getAll(url)
      .then(response => {
        productsRedux[response.meta.cursor.pageNumber] = response.products;
        response.totalProducts = productsRedux;
        dispatch(productsFetchDataSuccess(response));
        dispatch(productsAreLoading(false));
      })
      .catch(() => {
        dispatch(productsHaveError(true));
        dispatch(productsAreLoading(false));
      });
  };
}
