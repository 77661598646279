import React from 'react';
import { FormattedMessage } from 'react-intl';
import BaseComponent from '../BaseComponent';
import Constants from '../../shared/constants';
import validator from 'validator';
import Input from '../Input';
import { Control, ValidationRule } from '../../shared/Control';
import Card from '../Card';
import Thankyou from './Thankyou';
import ResellerService from '../../services/ResellerService';
import Config from '../../config';
import { withRouter } from 'react-router';
import LoadingOverlay from 'react-loading-overlay';
import RadioButtonComponent from '../RadioButton';
import PostalInput from '../PostalInput';
import BounceLoader from 'react-spinners/BounceLoader';

class CreateNewReseller extends BaseComponent {
  resellerService = new ResellerService(Config.apiBase);
  state = {
    customer: {
      isValid: false,
      isFocus: false,
    },
    billing: {
      isValid: false,
      isFocus: false,
    },
    shipping: {
      isValid: false,
      isFocus: false,
    },
    isDisabled: true,
    billingIsSameAsMain: false,
    shippingIsSameAsMain: false,
    sendInvoice: true,
    isSubmitted: false,
    showErrors: false,
    cardId: 0,
    checked: false,
  };
  controls = {};

  constructor(props) {
    super(props);

    const customer = [
      new Control({
        name: 'name',
        type: 'string',
        label: <FormattedMessage id="ph_company_name" />,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_company_name" />,
        }),
        group: 'mainContact',
        id: 'customerName',
        formGroup: 0,
        onBlur: this.handleCustomerDetailsBlur,
        onFocus: this.handleCustomerDetailsFocus,
        onChange: this.handleCustomerDetailsChange,
      }),
      new Control({
        name: 'corporateId',
        type: 'phone',
        label: <FormattedMessage id="ph_organization_number" />,
        rule: new ValidationRule({
          method: validator.isLength,
          validWhen: true,
          args: [{ min: 9, max: 9 }],
          message: <FormattedMessage id="warning_organization_number" />,
        }),
        id: 'customerCorporateId',
        formGroup: 0,
        isValid: true,
        onBlur: this.handleCustomerDetailsBlur,
        onFocus: this.handleCustomerDetailsFocus,
        onChange: this.handleCustomerDetailsChange,
      }),

      new Control({
        name: 'attention',
        type: 'string',
        label: <FormattedMessage id="all_reseller_contact_person" />,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_contact_person" />,
        }),
        group: 'mainContact',
        id: 'customerAttention',
        formGroup: 1,
        onBlur: this.handleCustomerDetailsBlur,
        onFocus: this.handleCustomerDetailsFocus,
        onChange: this.handleCustomerDetailsChange,
      }),
      new Control({
        name: 'phone1',
        type: 'phone',
        label: <FormattedMessage id="ph_phone" />,
        rule: new ValidationRule({
          method: validator.isLength,
          validWhen: true,
          args: [{ min: 8, max: 8 }],
          message: <FormattedMessage id="warning_phone_number" />,
        }),
        group: 'mainContact',
        id: 'customerPhone1',
        formGroup: 1,
        onBlur: this.handleCustomerDetailsBlur,
        onFocus: this.handleCustomerDetailsFocus,
        onChange: this.handleCustomerDetailsChange,
      }),
      new Control({
        name: 'email',
        type: 'email',
        label: <FormattedMessage id="ph_email" />,
        rule: new ValidationRule({
          method: validator.isEmail,
          validWhen: true,
          message: <FormattedMessage id="warning_email" />,
        }),
        group: 'mainContact',
        id: 'customerEmail',
        formGroup: 1,
        onBlur: this.handleCustomerDetailsBlur,
        onFocus: this.handleCustomerDetailsFocus,
        onChange: this.handleCustomerDetailsChange,
      }),
      new Control({
        name: 'addressLine1',
        type: 'string',
        label: <FormattedMessage id="ph_address_info" />,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_address" />,
        }),
        group: 'mainAddress',
        id: 'customerAddressLine1',
        formGroup: 2,
        onBlur: this.handleCustomerDetailsBlur,
        onFocus: this.handleCustomerDetailsFocus,
        onChange: this.handleCustomerDetailsChange,
      }),
      new Control({
        name: 'postalCode',
        type: 'number',
        label: <FormattedMessage id="ph_postal_code" />,
        rule: new ValidationRule({
          method: validator.isLength,
          validWhen: true,
          args: [{ min: 0, max: 4 }],
          message: <FormattedMessage id="warning_postal_code" />,
        }),
        group: 'mainAddress',
        id: 'customerPostalCode',
        formGroup: 2,
        onBlur: this.handleCustomerDetailsBlur,
        onFocus: this.handleCustomerDetailsFocus,
        onChange: this.handleCustomerDetailsChange,
      }),
    ];

    const billing = [
      new Control({
        name: 'email',
        type: 'email',
        label: <FormattedMessage id="ph_billing_email" />,
        rule: new ValidationRule({
          method: validator.isEmail,
          validWhen: true,
          message: <FormattedMessage id="warning_email" />,
        }),
        group: 'invoiceContact',
        id: 'billingEmail',
        formGroup: 0,
        onBlur: this.handleBillingDetailsBlur,
        onFocus: this.handleBillingDetailsFocus,
        onChange: this.handleBillingAddressChange,
      }),
      new Control({
        name: 'billingContact',
        type: 'string',
        label: <FormattedMessage id="ph_billing_contact" />,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_contact_person" />,
        }),
        group: 'invoiceAddress',
        id: 'billingContact',
        formGroup: 1,
        onBlur: this.handleBillingDetailsBlur,
        onFocus: this.handleBillingDetailsFocus,
        onChange: this.handleBillingAddressChange,
      }),
      new Control({
        name: 'phone1',
        type: 'phone',
        label: <FormattedMessage id="ph_phone" />,
        rule: new ValidationRule({
          method: validator.isLength,
          validWhen: true,
          args: [{ min: 8, max: 8 }],
          message: <FormattedMessage id="warning_phone_number" />,
        }),
        group: 'mainContact',
        id: 'billingPhone',
        formGroup: 1,
        onBlur: this.handleBillingDetailsBlur,
        onFocus: this.handleBillingDetailsFocus,
        onChange: this.handleBillingAddressChange,
      }),
    ];

    const shipping = [
      new Control({
        name: 'attention',
        type: 'string',
        label: <FormattedMessage id="all_reseller_contact_person" />,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_contact_person" />,
        }),
        group: 'deliveryContact',
        id: 'shippingAttention',
        formGroup: 0,
        onBlur: this.handleShippingDetailsBlur,
        onFocus: this.handleShippingDetailsFocus,
        onChange: this.handleShippingAddressChange,
      }),
      new Control({
        name: 'phone1',
        type: 'phone',
        label: <FormattedMessage id="ph_phone" />,
        rule: new ValidationRule({
          method: validator.isLength,
          validWhen: true,
          args: [{ min: 8, max: 8 }],
          message: <FormattedMessage id="warning_phone_number" />,
        }),
        group: 'deliveryContact',
        id: 'shippingPhone',
        formGroup: 0,
        onBlur: this.handleShippingDetailsBlur,
        onFocus: this.handleShippingDetailsFocus,
        onChange: this.handleShippingAddressChange,
      }),
      new Control({
        name: 'email',
        type: 'email',
        label: <FormattedMessage id="ph_email" />,
        rule: new ValidationRule({
          method: validator.isEmail,
          validWhen: true,
          message: <FormattedMessage id="warning_email" />,
        }),
        group: 'deliveryContact',
        id: 'shippingEmail',
        formGroup: 0,
        onBlur: this.handleShippingDetailsBlur,
        onFocus: this.handleShippingDetailsFocus,
        onChange: this.handleShippingAddressChange,
      }),
      new Control({
        name: 'addressLine1',
        type: 'string',
        label: <FormattedMessage id="ph_address_info" />,
        rule: new ValidationRule({
          method: validator.isEmpty,
          validWhen: false,
          message: <FormattedMessage id="warning_address" />,
        }),
        group: 'deliveryAddress',
        id: 'shippingAddressLine1',
        formGroup: 1,
        onBlur: this.handleShippingDetailsBlur,
        onFocus: this.handleShippingDetailsFocus,
        onChange: this.handleShippingAddressChange,
      }),
      new Control({
        name: 'postalCode',
        type: 'number',
        label: <FormattedMessage id="ph_postal_code" />,
        rule: new ValidationRule({
          method: validator.isLength,
          validWhen: true,
          args: [{ min: 0, max: 4 }],
          message: <FormattedMessage id="warning_postal_code" />,
        }),
        group: 'deliveryAddress',
        id: 'shippingPostalCode',
        formGroup: 1,
        onBlur: this.handleShippingDetailsBlur,
        onFocus: this.handleShippingDetailsFocus,
        onChange: this.handleShippingAddressChange,
      }),
    ];

    this.controls = {
      customer: customer,
      billing: billing,
      shipping: shipping,
    };
  }

  componentWillMount() {}

  validate(controls) {
    return controls.find(obj => obj.rule && !obj.isValid) === undefined;
  }

  handleCustomerDetailsFocus = () => {
    let { customer } = this.state;
    customer.isFocus = true;
    customer.isValid = this.validate(this.controls.customer);
  };
  handleCustomerDetailsBlur = (event, control) => {
    let { customer } = this.state;
    customer.isFocus = false;
    this.setState({ customer });
  };

  handleShippingDetailsFocus = () => {
    let { shipping } = this.state;
    shipping.isFocus = true;
    shipping.isValid = this.validate(this.controls.shipping);
  };
  handleShippingDetailsBlur = (event, control) => {
    let { shipping } = this.state;
    shipping.isFocus = false;
    this.setState({ shipping });
  };

  handleBillingDetailsFocus = () => {
    let { billing } = this.state;
    billing.isFocus = true;
    billing.isValid = this.validate(this.controls.billing);
  };
  handleBillingDetailsBlur = (event, control) => {
    let { billing } = this.state;
    billing.isFocus = false;

    this.setState({ billing });
  };

  handleCustomerDetailsChange = async (name, control) => {
    let { customer } = this.state;
    customer.isValid = this.validate(this.controls.customer);
    this.setState({
      customer,
      billingIsSameAsMain: false,
      shippingIsSameAsMain: false,
    });
  };

  handleBillingAddressChange = async (name, value) => {
    let { billing } = this.state;
    billing.isValid = this.validate(this.controls.billing);
    this.setState({ billing, billingIsSameAsMain: false });
  };

  handleShippingAddressChange = async (name, value) => {
    let { shipping } = this.state;
    shipping.isValid = this.validate(this.controls.shipping);
    this.setState({ shipping, shippingIsSameAsMain: false });
  };

  handleBillingSameAsMain = checked => {
    if (checked) {
      const { billing, customer } = this.controls;
      billing[0].setValue(customer[4].value);
      billing[1].setValue(customer[2].value);
      billing[2].setValue(customer[3].value);

      this.controls.billing[0].ref.focus();
      this.setState({
        checked: !this.state.checked,
      });
    } else {
      this.setState({
        checked: false,
        billing: {
          isValid: this.validate(this.controls.billing),
        },
      });
    }
  };

  handleShippingSameAsMain = checked => {
    if (checked) {
      const { shipping, customer } = this.controls;
      shipping[0].setValue(customer[2].value);
      shipping[1].setValue(customer[3].value);
      shipping[2].setValue(customer[4].value);
      shipping[3].setValue(customer[5].value);
      shipping[4].setValue(customer[6].value);
      this.controls.shipping[0].ref.focus();
      this.setState({
        checked: !this.state.checked,
      });
    } else {
      this.setState({
        checked: false,
        shipping: {
          isValid: this.validate(this.controls.shipping),
        },
      });
    }
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { customer, billing, shipping, billingIsSameAsMain, shippingIsSameAsMain } = this.state;
    this.setState({ showErrors: true });
    if (customer.isValid && billing.isValid && shipping.isValid) {
      this.setState({ loading: true });
      try {
        const response = await this.resellerService.createNewReseller(
          this.controls,
          billingIsSameAsMain,
          shippingIsSameAsMain
        );
        if (response.status === 200) {
          this.setState({ isSubmitted: true, error: undefined });
        } else {
          this.setState({ error: response.data.message });
        }
      } catch (error) {
        this.setState({ error: error });
      }
      this.setState({ loading: false });
    }
  };

  renderGroup(controls, formGroup) {
    return controls
      .filter(control => control.formGroup === formGroup)
      .map(control =>
        control.name === 'postalCode' ? (
          <PostalInput
            key={control.name}
            options={control}
            onChange={control.onChange}
            ref={input => {
              control.ref = input;
            }}
          />
        ) : (
          <Input
            key={control.name}
            options={control}
            onChange={control.onChange}
            ref={input => {
              control.ref = input;
            }}
          />
        )
      );
  }

  renderForm(
    group,
    controls,
    index,
    showNext,
    groupCount,
    cardLabelIndex,
    cardLabel,
    bodyTarget,
    showBillingAsCustomer,
    checked,
    onBillingSameAsCustomer
  ) {
    const { isValid } = group;
    const { cardId } = this.state;
    return (
      <div className="row justify-content-md-center">
        <div className="col-8">
          <Card
            index={cardLabelIndex}
            label={cardLabel}
            valid={isValid && cardId !== index}
            bodyTarget={bodyTarget}
            showBody={true}
            editable={cardId !== index && index < cardId}
            onEdit={() => {
              this.setState({
                previousCardId: this.state.cardId,
                cardId: index,
              });
            }}
          >
            {cardId === index && (
              <div className={'col-11 text-left' + (cardId === index ? '' : ' d-none ')}>
                {showBillingAsCustomer && (
                  <div className="form-check mb-5 pl-0">
                    <label className="form-check-label pt-3 text-left">
                      <RadioButtonComponent
                        checked={checked}
                        onChange={onBillingSameAsCustomer}
                        label={<FormattedMessage id="common_same_as_customer_details" />}
                      />
                    </label>
                  </div>
                )}

                <div className="mt-5 mb-1">
                  {Array.from(Array(groupCount).keys()).map(index => (
                    <div key={index} className="mb-5">
                      {this.renderGroup(controls, index)}
                    </div>
                  ))}
                </div>

                {showNext && (
                  <div className="row justify-content-end">
                    <div className="col-6">
                      {!this.state.previousCardId && (
                        <button
                          className={'btn btn-sm ' + (isValid ? 'bg-dark-aquamarine text-white' : 'btn-dark')}
                          disabled={!isValid}
                          onClick={event => {
                            event.preventDefault();
                            this.setState({
                              cardId: index + 1,
                              checked: false,
                            });
                          }}
                        >
                          <FormattedMessage id="common_next" />
                        </button>
                      )}

                      {this.state.previousCardId && (
                        <button
                          className={'btn btn-sm ' + (isValid ? 'bg-dark-aquamarine text-white' : 'btn-dark')}
                          disabled={!isValid}
                          onClick={event => {
                            event.preventDefault();
                            this.setState({
                              cardId: this.state.previousCardId,
                              previousCardId: undefined,
                              checked: false,
                            });
                          }}
                        >
                          <FormattedMessage id="common_done" />
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {cardId !== index && (
              <React.Fragment>
                {/* Card Filled */}
                {/* Customer Details */}
                {index === 0 && (
                  <div className="filled-form col-11 mt-3 mb-1">
                    <div className="row">
                      <div className="col">
                        <p className="font-weight-bold mb-0"> {controls[0].value} </p>
                        <p className="mb-0"> {controls[1].value} </p>
                      </div>
                      <div className="col">
                        <p className="mb-0"> {controls[2].value} </p>
                        <p className="mb-0"> {controls[3].value} </p>
                        <p className="mb-0"> {controls[4].value} </p>
                      </div>

                      <div className="col">
                        <p className="mb-0"> {controls[5].value} </p>
                        <p className="mb-0"> {controls[6].value} </p>
                        <p className="mb-0"> {controls[6].city} </p>
                      </div>
                    </div>
                  </div>
                )}

                {/* Customer Details */}
                {index === 1 && (
                  <div className="filled-form col-11 mt-3 mb-1">
                    <p className="mb-0"> {controls[0].value} </p>
                    <p className="mb-0"> {controls[1].value} </p>
                    <p className="mb-0"> {controls[2].value} </p>
                  </div>
                )}

                {/* Customer Details */}
                {index === 2 && (
                  <div className="filled-form col-11 mt-3 mb-1">
                    <div className="row">
                      <div className="col">
                        <p className="mb-0"> {controls[0].value} </p>
                        <p className="mb-0"> {controls[1].value} </p>
                        <p className="mb-0"> {controls[2].value} </p>
                      </div>
                      <div className="col">&nbsp;</div>
                      <div className="col">
                        <p className="mb-0"> {controls[3].value} </p>
                        <p className="mb-0"> {controls[4].value} </p>
                        <p className="mb-0"> {controls[4].city} </p>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </Card>
        </div>
      </div>
    );
  }

  render() {
    const { isSubmitted, error, loading, customer, billing, shipping, cardId } = this.state;
    let mainElements;
    if (!isSubmitted) {
      mainElements = (
        <LoadingOverlay
          active={loading}
          spinner={
            <BounceLoader
              size={Constants.LOADER_PROPS.SIZE}
              sizeUnit={Constants.LOADER_PROPS.SIZE_UNIT}
              loading={loading}
            />
          }
          classNamePrefix={'confirmOrder_'}
          text={<FormattedMessage id="common_loading_overlay" />}
        >
          <div className="container">
            <div className="row mb-4 align-items-center">
              <div className="col-2" />
              <div className="col text-center">
                <h1>
                  <FormattedMessage id="new_reseller_create_new" />
                </h1>
              </div>
              <div className="col-2 text-right">
                <a href="#back" className="font-weight-normal fs-14" onClick={this.goBack}>
                  <img className="mr-2" src={Constants.icons.arrowBack} width="18" height="18" alt="back-icon" />
                  <u>
                    <FormattedMessage id="common_back" />
                  </u>
                </a>
              </div>
            </div>
            <div className="row mb-6">
              <div className="col text-center">
                <p>
                  <FormattedMessage id="common_fill_form" />
                </p>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} method="post">
              <div className="cardAccordion">
                {this.renderForm(
                  this.state.customer,
                  this.controls.customer,
                  0,
                  true,
                  3,
                  '1',
                  <FormattedMessage id="common_customer_details" />,
                  'customerDetails'
                )}
                {this.renderForm(
                  this.state.billing,
                  this.controls.billing,
                  1,
                  true,
                  2,
                  '2',
                  <FormattedMessage id="common_billing_address" />,
                  'billingAddress',
                  true,
                  this.state.checked,
                  this.handleBillingSameAsMain
                )}
                {this.renderForm(
                  this.state.shipping,
                  this.controls.shipping,
                  2,
                  true,
                  2,
                  '3',
                  <FormattedMessage id="common_shipping_address" />,
                  'shippingAddress',
                  true,
                  this.state.checked,
                  this.handleShippingSameAsMain
                )}
              </div>
              <div className="row justify-content-center mb-7">
                <div className="col-4">
                  <div className="text-center mt-5">
                    {error && <div className="invalid-feedback pl-4 pb-2 d-block">{error}</div>}
                    <button
                      type="submit"
                      className="btn btn-dark btn-sm w-75"
                      disabled={!(customer.isValid && billing.isValid && shipping.isValid && cardId > 2)}
                    >
                      <FormattedMessage id="new_reseller_create_reseller" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </LoadingOverlay>
      );
    } else {
      mainElements = (
        <Thankyou
          title={<FormattedMessage id="new_reseller_registration_success" />}
          message={<FormattedMessage id="new_reseller_success_description" />}
          url={Constants.routes.orders}
          btnText={<FormattedMessage id="common_back_to_dashboard" />}
        />
      );
    }

    return mainElements;
  }
}

export default withRouter(CreateNewReseller);
