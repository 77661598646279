import React from 'react';
import Constants from '../../shared/constants';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const Card = ({ label, children, valid, bodyTarget, showBody, editable, onEdit, cardLabelIndex, isFilled }) => {
  const cardClassName =
    'card mb-3 card-rounded mr-2' +
    (valid === true ? ' card-details-filled ' : showBody ? ' ' : ' ') +
    (showBody ? ' ' : ' card-collapsed ');
  return (
    <div className={cardClassName}>
      <div className={(isFilled ? 'p-4' : 'mb-2 mx-2 pb-4 pt-4 px-4') + (showBody ? ' pt-4 pb-4 mx-2' : ' p-4')}>
        <div className={'row justify-content-center '}>
          <div className="col">
            <div className={'row align-items-center ' + (showBody ? '' : 'collapsed')} data-target={'#' + bodyTarget}>
              <div className="col-1">
                <span className={'badge badge-dark card-badge badge-color-custom' + (showBody ? '' : 'bg-grey-3 ')}>
                  {isFilled ? (
                    <img src={Constants.icons.tickWhite} className="" width="15" height="15" alt="tick-icon" />
                  ) : (
                    cardLabelIndex
                  )}
                </span>
              </div>
              <div className="col-11 pl-1">
                <h3 className={'my-0 ls-0-3 ' + (showBody ? 'color-1d1d1d' : 'grey')}>
                  {label}
                  {editable && (
                    <span className="p-0 cursor-pointer float-right" onClick={onEdit}>
                      <u className="mr-2 color-1d1d1d fs-14 font-weight-normal">
                        <FormattedMessage id="contract_button_edit_service" />
                      </u>
                      <img
                        className="mr-2 mt-1"
                        src={Constants.icons.materialEdit}
                        width="16"
                        height="16"
                        alt="back-icon"
                      />
                    </span>
                  )}
                </h3>
              </div>
            </div>
            <div
              id={bodyTarget}
              className={'row collapse justify-content-center ' + (showBody ? 'show' : '')}
              data-parent=".cardAccordion"
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node,
  valid: PropTypes.bool,
  bodyTarget: PropTypes.string,
  showBody: PropTypes.bool,
  editable: PropTypes.bool,
  onEdit: PropTypes.func,
  cardLabelIndex: PropTypes.string,
  isFilled: PropTypes.bool,
};

export default Card;
