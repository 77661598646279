import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomerAutoSuggest from './CustomerAutoSuggest';
import { CustomerType } from '../../../../shared/CustomerType';
import Constants from '../../../../shared/constants';

export default class SelectCustomerCard extends Component {
  render() {
    const {
      customerService,
      onExistingCustomer,
      onNewCustomer,
      onSuggestionSelected,
      selectedSuggestion,
      reseller,
    } = this.props;
    return (
      <div className="row">
        <div className="min-width-45-per pl-3 pr-4 mr-2">
          <div className="card mb-4 card-rounded select-customer-min-ht">
            <div className="px-4 py-4 my-0 ">
              <h4 className="mb-4 font-weight-semibold">
                <FormattedMessage id="ph_create_new_customer" />
              </h4>
              <div className="row mb-2 ml-0">
                <div className="mr-4">
                  <button
                    className="new-product-btn h-48 btn btn-primary w-180-min py-1 line-height-16 ls-1-27"
                    onClick={onNewCustomer.bind(this, {
                      customerType: CustomerType.BUSINESS_CUSTOMER,
                    })}
                  >
                    <img src={Constants.icons.iconAddWhite} width="24" height="24" className="mr-1" alt="icon" />
                    <span className="va-middle font-weight-normal">
                      <FormattedMessage id="common_business_customer" />
                    </span>
                  </button>
                </div>
                <div>
                  <button
                    className="new-product-btn h-48 btn btn-primary w-180-min py-1 line-height-16 ls-1-27"
                    onClick={onNewCustomer.bind(this, {
                      customerType: CustomerType.PRIVATE_CUSTOMER,
                    })}
                  >
                    <img src={Constants.icons.iconAddWhite} width="24" height="24" className="mr-1" alt="icon" />
                    <span className="va-middle font-weight-normal">
                      <FormattedMessage id="common_private_customer" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col pl-0 pr-0 ml-0">
          <div className="min-width-50-per">
            <div className="card mb-4 card-rounded select-customer-min-ht">
              <div className="px-4 py-4 m-0">
                <h4 className="mb-4 font-weight-semibold">
                  <FormattedMessage id="ph_search_existing_customer" />
                </h4>
                <div className="row mb-2 pl-3">
                  <div className="col-8 mt-1 search-input-container-customer p-0 select-cust-search-width-new-order h-44 mr-3 br-4">
                    <CustomerAutoSuggest
                      lists={customerService}
                      onSuggestionSelected={onSuggestionSelected}
                      reseller={reseller}
                    />
                  </div>
                  <div className="pl-0">
                    <button
                      className="btn btn-next px-5 py-0-78 new-sub-next"
                      onClick={onExistingCustomer}
                      disabled={!selectedSuggestion}
                    >
                      <FormattedMessage id="common_next" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
