import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import Constants from '../../../../shared/constants';
import { charLimit } from '../../../../shared/stringOperations';
import { FormattedMessage } from 'react-intl';

class CustomerAutoSuggest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      lists: [],
      value: '',
    };
  }

  componentDidMount() {
    /**
     * before component mount the selectedSuggestion will be set if the isContractDetails prop is true
     */
    const { isContractDetails } = this.props;
    this.setState({
      selectedSuggestion: isContractDetails,
      suggestionCopy: isContractDetails,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isContractDetails) {
      this.setState({
        selectedSuggestion: nextProps.isContractDetails,
        value: '',
      });
    }
  }

  /**
   * suggestionCopy is newly added to store the suggestionCopy value to reStore when onBlur event
   * @param {suggestionCopy}
   */
  handleSuggestionSelected = (event, obj) => {
    const { onNext, isContractDetails, onSuggestionSelected, vendor } = this.props;

    const { listsCopy } = this.state;
    if (isContractDetails || vendor) {
      let filtered = listsCopy.filter(item => item.number === obj.suggestion.id);

      onNext && onNext(filtered);
    }
    if (onSuggestionSelected) {
      let filtered = listsCopy.filter(item => item.number === obj.suggestion.id);
      onSuggestionSelected(obj.suggestion, { customerDetails: filtered });
    }
    this.setState({
      selectedSuggestion: obj.suggestion,
      suggestionCopy: obj.suggestion,
    });
  };

  handleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  handleSuggestionsFetchRequested = async ({ value }) => {
    const { reseller, lists, vendor } = this.props;
    if (value.length > 3 && !this.state.fetching) {
      this.setState({ fetching: true });
      const $query = [['filter', { name: [value] }]];
      if (reseller) {
        $query.push(['reseller', reseller.id]);
      }

      const response = await lists.getAll($query);
      const { resellers } = response;
      let data;

      if (resellers) {
        data = response.resellers;
      } else {
        data = response.data;
      }

      if (data && data.length > 0) {
        this.setState({ notFound: false });
      } else {
        this.setState({ notFound: true });
      }

      this.setState({
        lists: data,
        // Since the resellers state value is conflicting with other methods I have created a listsCopy variable
        // so that can be used in other places
        listsCopy: response.data || data,
        fetching: false,
      });
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  handleSuggestionsClearRequested = () => {
    this.setState({
      lists: [],
    });
  };

  handleRemoveSelection = () => {
    const { onSuggestionSelected, onNext } = this.props;
    this.setState({
      selectedSuggestion: undefined,
      value: '',
    });
    if (onSuggestionSelected) {
      onSuggestionSelected(undefined);
    }
    if (onNext) {
      onNext(undefined);
    }
  };

  handleNext = () => {
    /**
     * @author {Karthikeyan U}
     * added isContractDetails props to get seperate value for multiple uses
     * filtered and returned the whole objects value instead of returning name and ID
     */
    const { onNext, isContractDetails } = this.props;
    const { listsCopy, selectedSuggestion } = this.state;
    if (onNext) {
      if (isContractDetails) {
        let filtered = listsCopy.filter(obj => obj.number === selectedSuggestion.id);

        onNext(filtered);
      } else {
        onNext(selectedSuggestion);
      }
    }
  };

  render() {
    const { value, lists, selectedSuggestion, fetching, notFound, suggestionCopy } = this.state;
    const { isContractDetails, isConfirmOrder, placeholder } = this.props;
    const inputProps = {
      placeholder: placeholder ? placeholder : Constants.language.common_write_name_or_id,
      value,
      onChange: this.handleChange,
      onBlur: () => {
        isContractDetails && this.setState({ selectedSuggestion: suggestionCopy });
      },
      autoFocus: true,
    };
    const options = lists.slice(0, 20).map(obj => {
      return { id: obj.number, name: obj.name };
    });
    return (
      <React.Fragment>
        {!selectedSuggestion && (
          <div
            id="search-box"
            className={
              'input-group ' +
              (isContractDetails && !isConfirmOrder
                ? 'mb-5 contract-detail-suggest edit-details-modal'
                : selectedSuggestion
                ? ' '
                : 'border border-dark')
            }
          >
            <div className={'form-control ' + (isContractDetails && !isConfirmOrder ? 'input-search-container' : '')}>
              <Autosuggest
                suggestions={options}
                onSuggestionSelected={this.handleSuggestionSelected}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                getSuggestionValue={option => option.name}
                renderSuggestion={suggestion => (
                  <div>
                    {suggestion.name} - #{suggestion.id}
                  </div>
                )}
                inputProps={inputProps}
              />
            </div>
            <div className="input-group-append search-icon">
              <button className={'btn pr-4 py-0'} type="button">
                {fetching && (
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">
                      <FormattedMessage id="common_loading_overlay" />
                    </span>
                  </div>
                )}
                {!fetching ? (
                  value.length > 0 ? (
                    <span className="float-right cursor-pointer" onClick={this.handleRemoveSelection}>
                      <img
                        className="mr-2 cursor-pointer"
                        src={Constants.icons.crossNew1}
                        width="14"
                        height="14"
                        alt="trash-icon"
                      />
                    </span>
                  ) : (
                    <img src={Constants.icons.search} width="18" height="18" alt="search-icon" />
                  )
                ) : (
                  ''
                )}

                {/* {!fetching && (
                  <img
                    src={options.length > 0 ? Constants.icons.searchDark : Constants.icons.search}
                    width="18"
                    height="18"
                    alt="search-icon"
                  />
                )} */}
              </button>
            </div>
            {/* message will be shown if the customer not found */}
            {notFound && (
              <div className="text-danger error-message small customer-suggest-error ml-2">
                <span>
                  <FormattedMessage id="edit_contract_customer_search_error" />
                </span>
              </div>
            )}
          </div>
        )}
        {selectedSuggestion && (
          <div className="input-group br-4" title={selectedSuggestion.name}>
            <div
              className={
                'col align-self-center ' +
                (isContractDetails && !isConfirmOrder ? 'mb-4 contract-details-field edit-details-modal' : ' ')
              }
              onClick={() => isContractDetails && this.setState({ selectedSuggestion: false })}
            >
              {!isContractDetails && (
                <img className="mr-0 pb-1" src={Constants.icons.tick} width="9.8px" height="7px" alt="done-icon" />
              )}

              {isContractDetails && !isConfirmOrder
                ? charLimit.charLimit(selectedSuggestion.name, 30)
                : [charLimit.charLimit(selectedSuggestion.name, 20), selectedSuggestion.id].join(',')}
              <span className="float-right mr-1" onClick={this.handleRemoveSelection}>
                <img
                  className={'ml-2 cursor-pointer mb-1 '}
                  src={isConfirmOrder ? Constants.icons.crossNew1 : Constants.icons.crossNew1}
                  width={isConfirmOrder ? '15' : '15'}
                  height={isConfirmOrder ? '15' : '15'}
                  alt="trash-icon"
                />
              </span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default CustomerAutoSuggest;
