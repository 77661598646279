import React, { Component } from "react";
import AuthService from "../services/AuthService";
import Config from "../config";
import AdminDashboard from "../components/Dashboard/admin-dashboard";
import ResellerDashboard from "../components/Dashboard/reseller-dashboard";
import { Redirect } from "react-router-dom";

class Dashboard extends Component {
  authService = new AuthService(Config.apiBase);
  state = {};
  render() {
    if (this.authService.isSuperAdminUser() || this.authService.isAdminBackOfficeUser() || this.authService.isAdminSalesUser() ) {
      return <AdminDashboard />;
    }
    if (this.authService.isResellerUser()) {
      return <ResellerDashboard />;
    }
    return <Redirect to={{ pathName: "/signin" }} />;
  }
}

export default Dashboard;
