import { combineReducers } from "redux";
import OrderReducer from "./OrderReducer";
import ContractReducer from "./ContractReducer";
import Products from "./products";
import Utils from "./Utils";

export default combineReducers({
  order: OrderReducer,
  contract: ContractReducer,
  Products: Products,
  Utils: Utils
});
