import {
  SELECT_ORDER,
  CREATE_CUSTOMER,
  CHOOSE_CUSTOMER,
  SELECT_LOCATION,
  CONFIRM_ORDER,
  FINISH_ORDER
} from "./types";

export function selectOrder(orderType) {
  return function(dispatch) {
    dispatch({
      type: SELECT_ORDER,
      payload: { orderType }
    });
  };
}

export function createCustomer(customer) {
  return function(dispatch) {
    dispatch({
      type: CREATE_CUSTOMER,
      payload: { customer }
    });
  };
}

export function chooseCustomer({
  type,
  customer,
  reseller,
  products,
  services,
  customerType,
  customerDetails
}) {
  return function(dispatch) {
    dispatch({
      type: CHOOSE_CUSTOMER,
      payload: {
        type,
        customer,
        reseller,
        products,
        services,
        customerType,
        customerDetails
      }
    });
  };
}

export function selectLocation(location) {
  return function(dispatch) {
    dispatch({
      type: SELECT_LOCATION,
      payload: { location }
    });
  };
}

export function confirmOrder(products, customer, orderType, services, installationData, proposalDetails) {
  return function(dispatch) {
    dispatch({
      type: CONFIRM_ORDER,
      payload: { products, customer, orderType, services, installationData, proposalDetails },
    });
  };
}

export function finishOrder() {
  return function(dispatch) {
    dispatch({
      type: FINISH_ORDER
    });
  };
}
