import React from 'react';
import { FormattedMessage } from 'react-intl';
import RadioButton from '../../../RadioButton';
import Input from '../../../Input/index';
import Constants from '../../../../shared/constants';

const InstallationCard = ({ controls, handleChange, needInstallation, isPriceBasedOnHours }) => {
  const { installationFields } = controls;
  const renderInputElements = control => {
    return (
      <Input
        key={control.name}
        options={control}
        value={control.value}
        onChange={control.onChange}
        ref={input => {
          control.ref = input;
        }}
      />
    );
  };
  let totalPrice = 0;
  if (isPriceBasedOnHours === 'hours') {
    totalPrice = (installationFields[0].value || installationFields[0].defaultValue) * installationFields[1].value;
  } else {
    totalPrice = installationFields[2].value || installationFields[2].defaultValue;
  }
  const { installation } = Constants;
  return (
    <>
      <h4 className="line-height-normal ls-0-3">
        <FormattedMessage id="common_installation" />
      </h4>
      <h5 className="mt-3 mb-2">
        <FormattedMessage id="do_customer_want_installation" />
      </h5>
      <div className="row m-0">
        <RadioButton
          checked={needInstallation === installation.yes}
          onClick={() => handleChange('needInstallation', installation.yes)}
          onChange={() => handleChange('needInstallation', installation.yes)}
          label={Constants.language.create_yes}
          className="mr-4 cursor-pointer"
        />
        <RadioButton
          checked={needInstallation === installation.no}
          onClick={() => handleChange('needInstallation', installation.no)}
          onChange={() => handleChange('needInstallation', installation.no)}
          label={Constants.language.create_no}
        />
      </div>
      <div className={needInstallation === installation.no ? 'disable-card' : ''}>
        <h5 className="mt-3 mb-2">
          <FormattedMessage id="installation_hour_rate" />
        </h5>
        <div className="row m-0">
          <RadioButton
            checked={isPriceBasedOnHours === installation.hours}
            onClick={() => handleChange('isPriceBasedOnHours', installation.hours)}
            onChange={() => handleChange('isPriceBasedOnHours', installation.hours)}
            label={Constants.language.proposal_installation_hours_label}
            className="mr-4 cursor-pointer"
          />
          <RadioButton
            checked={isPriceBasedOnHours === installation.price}
            onClick={() => handleChange('isPriceBasedOnHours', installation.price)}
            onChange={() => handleChange('isPriceBasedOnHours', installation.price)}
            label={Constants.language.proposal_installation_fixedprice_label}
          />
        </div>
        <div className="row m-0 mt-3">
          {isPriceBasedOnHours === 'hours' ? (
            <>
              <div className="col-auto pl-0">
                <p>
                  <FormattedMessage id="installation_hour_rate" />
                </p>
                {renderInputElements(installationFields[0])}
              </div>
              <div className="col-auto">
                <p>
                  <FormattedMessage id="installation_amount_of_hours" />
                </p>
                {renderInputElements(installationFields[1])}
              </div>
            </>
          ) : (
            <div className="col-auto pl-0">
              <p>
                <FormattedMessage id="common_fixed_price" />
              </p>
              {renderInputElements(installationFields[2])}
            </div>
          )}
        </div>
      </div>
      <div className="border-bottom-light mt-4 w-186-max mb-3" />
      <h4 className="font-weight-bold">
        <span className="mr-5">
          <FormattedMessage id="common_total" />:
        </span>
        <span>{totalPrice},-</span>
      </h4>
    </>
  );
};
export default InstallationCard;
