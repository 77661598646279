import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Control } from '../../shared/Control';
import validator from 'validator';
import Constants from '../../shared/constants';

class Select extends Component {
  state = {
    classes: ['form-control', 'px-3', 'input-common', 'py-4', 'text-truncate', 'pr-4'],
    isValid: false,
    showError: false,
    dropDown: false,
    selectedValue: '',
  };

  constructor(props) {
    super(props);
    const { id } = this.props.options;
    this.textInput = React.createRef();
    this.selectRef = [];
    this.selectRef[id] = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }
  componentDidMount() {
    const {
      value,
      options: { options },
    } = this.props;
    if (value) {
      let checkvalue = options.findIndex(obj => obj.value === value);
      if (checkvalue > -1) {
        this.optionSelected(options[checkvalue]);
      }
    }

    document.addEventListener('click', this.handleOutsideClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }
  componentDidUpdate(prevProps) {
    const { value, rule } = this.props.options;
    const { previousValue } = prevProps.options;
    const { dropDown } = this.state;
    if (value !== previousValue && !dropDown) {
      if (rule) {
        this.props.options.isValid = this.validate(value, rule);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      value,
      options: { options },
    } = this.props;

    if (value !== nextProps.value) {
      let checkvalue = options.findIndex(obj => obj.value === nextProps.value);

      if (checkvalue > -1) {
        this.optionSelected(options[checkvalue]);
      }
    }
  }
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    const { dropDown } = this.state;
    if (!this.selectRef[this.props.options.id].contains(e.target) && dropDown) {
      this.closeDropDown();
    }
  }
  handleFocus = event => {
    const { onFocus } = this.props.options;
    const { classes } = this.state;
    this.setState({ classes: classes.concat('focus') });

    if (onFocus) {
      onFocus(event);
    }
  };

  focus = () => {
    this.textInput.current.focus();
  };

  optionSelected = obj => {
    const { name, rule } = this.props.options;

    const { onChange, options } = this.props;
    options.value = obj.value;

    if (rule) {
      options.isValid = this.validate(obj.value, rule);
    }
    this.setState({ dropDown: false, selectedValue: obj.label });
    if (onChange) {
      onChange(name, options);
    }
  };
  handleBlur = event => {
    const { classes } = this.state;
    let { name, onBlur } = this.props.options;
    const { onChange, options } = this.props;
    this.props.options.showError = true;
    if (onChange) {
      onChange(name, options);
    }
    this.setState({ classes: classes.filter(str => str !== 'focus') });
    if (onBlur) {
      onBlur(event, options);
    }

    if (!event.currentTarget.contains(event.relatedTarget)) {
      // this.setState({ dropDown: false });
    }
  };
  closeDropDown = () => {
    const { disabled } = this.props.options;
    if (!disabled) {
      this.setState({ dropDown: !this.state.dropDown });
    }
  };
  render() {
    const {
      name,
      label,
      type,
      isValid,
      rule,
      id,
      showError,
      minLength,
      maxLength,
      disabled,
      value,
      autoFocus,
      options,
      classProps,
    } = this.props.options;

    const { classes, dropDown, selectedValue } = this.state;
    if (value && value.length !== 0 && !classes.includes('not-empty')) {
      classes.push('not-empty');
    }
    return (
      <div
        className={
          'form-group animate-label common-input-container common-select-container ' + (classProps ? classProps : '')
        }
        ref={selectRef => {
          this.selectRef[id] = selectRef;
        }}
      >
        <input
          type="alphanumeric"
          className={classes.join(' ')}
          name={name}
          id={id}
          aria-describedby={name}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur}
          ref={this.textInput}
          value={selectedValue}
          readOnly
          disabled={disabled}
          minLength={minLength}
          maxLength={maxLength}
          autoFocus={autoFocus || false}
          onClick={this.closeDropDown.bind(this)}
        />

        <label htmlFor={id} className="form-control-placeholder ml-3 bg-white px-1 text-truncate w-75-per-max">
          {label}
        </label>
        <span className={(dropDown ? 'rotate ' : ' ') + 'arrow opacity-54'} onClick={this.closeDropDown.bind(this)}>
          <img src={Constants.icons.selectArrow} alt="arrow" width="28px" height="28px" />
        </span>
        {dropDown && !disabled && (
          <div className="dropdownList card ">
            {options.map((obj, index) => (
              <p
                onClick={this.optionSelected.bind(this, obj)}
                key={index}
                className={
                  (obj.value === value ? 'bg-grey font-weight-semibold' : '') +
                  ' m-0 px-4 py-1 cursor-pointer text-truncate'
                }
              >
                {obj.label}
              </p>
            ))}
          </div>
        )}
        <div className="text-danger error-message small line-height-22">
          {showError && !isValid && rule ? rule.message : ' '}
        </div>
        {showError && !isValid && rule && <img className="error-icon" src={Constants.icons.triangle} alt="" />}
      </div>
    );
  }

  validate(value, rule) {
    const args = rule.args || [];
    return rule.presence || !validator.isEmpty(value) ? rule.method(value, ...args) === rule.validWhen : true;
  }
}

Select.propsTypes = {
  options: PropTypes.objectOf(Control),
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  isValid: PropTypes.bool,
  rule: PropTypes.object,
  id: PropTypes.string,
  showError: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};

export default Select;
