import React from 'react';
import Constants from '../../shared/constants';
import PropTypes from 'prop-types';

export const CheckBox = ({ isSelected, onClick, label, isRadio }) => {
  return (
    <>
      {isSelected ? (
        <>
          <span className={isRadio ? 'check-box-radio-selected' : 'check-box-selected'} onClick={onClick}>
            {isRadio ? (
              <span />
            ) : (
              <img className="filter-check-tick-icon text-white" src={Constants.icons.tick} alt="done-icon" />
            )}
          </span>
          {label && (
            <span className="ml-2 cursor-pointer" onClick={onClick}>
              {label}
            </span>
          )}
        </>
      ) : (
        <>
          <span className={isRadio ? 'check-box-radio' : 'check-box-deselect'} onClick={onClick} />
          {label && (
            <span className="ml-2 cursor-pointer" onClick={onClick}>
              {label}
            </span>
          )}
        </>
      )}
    </>
  );
};

CheckBox.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.node,
};
