import React from 'react';
import { FormattedMessage } from 'react-intl';
import AuthService from '../services/AuthService';
import UserService from '../services/UserService';
import Config from '../config';
import Constants from '../shared/constants';
import UserListView from '../components/Users/Components/ListView/UserListView';
import SearchInput from '../components/Search/';
import Pagination from '../components/Pagination/';
import BaseComponent from '../components/BaseComponent';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import { FilterButton } from '../components/FilterData/Components/FilterButton';
import { FilterPopOver } from '../components/FilterData/FilterPopOver';
import UserModal from '../components/Users/UserModal';
import Toast from '../components/Toast/index';

class Users extends BaseComponent {
  userService = new UserService(Config.apiBase);
  authService = new AuthService(Config.apiBase);
  isSuperAdminUser = this.authService.isSuperAdminUser();
  isAdminBackOfficeUser = this.authService.isAdminBackOfficeUser();
  isAdminSalesUser = this.authService.isAdminSalesUser();
  isResellerLoggedIn = this.authService.isResellerUser();

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      meta: { cursor: { pageNumber: 1 } },
      pageSize: 25,
      searchQuery: '',
      searchData: { id: 'name', value: '', component: 'search' },
      showToast: false,
      toastData: {},
      showNext: true,
      message: false,
      sortedBy: 'date',
      sortAscending: false,
      isFilterOpen: false,
      ismodalopen: false,
      typeOptions: [
        {
          value: '1',
          placeholder: 'super-admin',
          label: Constants.language.user_options_superadmin,
        },
        {
          value: '2',
          placeholder: 'admin-backoffice',
          label: Constants.language.user_options_backoffice,
        },
        {
          value: '3',
          placeholder: 'admin-sales',
          label: Constants.language.user_options_adminsales,
        },
        {
          value: '4',
          placeholder: 'reseller',
          label: Constants.language.user_options_reseller,
        },
      ],
      userFilterData: [
        {
          id: 'startsWith',
          component: 'letterSelect',
          label: Constants.language.common_activity_user,
          values: [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
          ],
          defaultValues: [],
        },
        {
          id: 'date',
          component: 'datePicker',
          label: Constants.language.filter_registered_date,
          fromDate: { defaultDate: '' },
          toDate: { defaultDate: '' },
        },
      ],
      toBeFilteredData: this.isResellerLoggedIn
        ? { reseller: [JSON.parse(localStorage.getItem('userData')).visma_id] }
        : {},
    };
    this.searchUsers.bind(this);
  }

  componentDidMount() {
    let typeOptions;
    this.isSuperAdminUser && (typeOptions = this.state.typeOptions);
    this.isAdminBackOfficeUser && (typeOptions = this.state.typeOptions.filter(obj => obj.value !== '1'));
    this.isAdminSalesUser &&
      (typeOptions = this.state.typeOptions.filter(obj => obj.value !== '1' && obj.value !== '2'));
    this.setState({ typeOptions });
    this.fetchUsers();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      searchQuery,
      meta: {
        cursor: { pageNumber },
      },
      sortAscending,
      sortedBy,
    } = this.state;
    const {
      meta: {
        cursor: { pageNumber: prevPageNumber },
      },
      sortAscending: prevSortAscending,
      sortedBy: prevSortedBy,
    } = prevState;

    if (prevPageNumber !== pageNumber || prevSortedBy !== sortedBy || prevSortAscending !== sortAscending) {
      this.fetchUsers();
    }
  }

  searchUsers(value) {
    const { toBeFilteredData, searchQuery } = this.state;
    if (value && value.length > 0) {
      if (toBeFilteredData.name && toBeFilteredData.name.length > 0) {
        toBeFilteredData.name = toBeFilteredData.name.filter(obj => obj !== searchQuery);
        toBeFilteredData.name.push(value);
      } else {
        toBeFilteredData.name = [value];
      }
    } else if (toBeFilteredData.name) {
      delete toBeFilteredData.name;
    }
    let searchData = {
      id: 'name',
      value,
      component: 'search',
    };

    this.fetchUsers(toBeFilteredData, value);
    this.setState({ searchQuery: value, toBeFilteredData, searchData });
  }

  handleGotoPage(pageNumber) {
    this.setState({
      meta: {
        cursor: {
          pageNumber: pageNumber,
        },
      },
    });
  }
  handleUserModalClose = () => {
    this.setState({ ismodalopen: false });
  };

  handleCreateUser = () => {
    this.setState({ loading: true });
  };

  async fetchUsers(toBeFilteredData, searchInput) {
    const { sortedBy, sortAscending, toBeFilteredData: filterData, pageSize, loading } = this.state;
    const queryParams = [
      ['filter', toBeFilteredData || filterData],
      ['pageNumber', searchInput ? 1 : this.state.meta.cursor.pageNumber],
      ['pageSize', pageSize],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
    ];
    if (!loading) {
      this.setState({ loading: true });
    }
    let response;
    try {
      response = await this.userService.getAllUsers(queryParams);
      if (response && response.status === 200) {
        let { status, customers, meta } = response;
        if (customers.length > 0) {
          if (this.state.searchQuery) {
            this.setState({ message: false });
          }
          this.setState({
            users: customers,
            meta,
          });
        } else {
          if (this.state.searchQuery) {
            this.setState({
              message: true,
              unknown: true,
              users: [],
              meta,
            });
          } else {
            this.setState({ users: [], meta });
          }
        }
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.error(e);
    }

    this.setState({ loading: false });
  }

  handleSorting(name) {
    if (this.state.sortedBy === name) {
      this.setState({ sortAscending: !this.state.sortAscending });
    } else {
      this.setState({ sortedBy: name, sortAscending: !this.state.sortAscending });
    }
  }

  // Filter Data Handle
  handleFitlerButton() {
    const { isFilterOpen } = this.state;
    this.setState({ isFilterOpen: !isFilterOpen });
  }

  handleCancelFilter() {
    this.setState({ isFilterOpen: false });
  }

  handleFilterApply(data) {
    let toBeFilteredData = this.isResellerLoggedIn
      ? { reseller: [JSON.parse(localStorage.getItem('userData')).visma_id] }
      : {};
    data.map(obj => {
      if (toBeFilteredData[obj.id]) {
        toBeFilteredData[obj.id].push(obj.value);
      } else {
        toBeFilteredData[obj.id] = [];
        toBeFilteredData[obj.id].push(obj.value);
      }

      return obj;
    });
    this.setState({ toBeFilteredData, isFilterOpen: false });
    this.fetchUsers(toBeFilteredData, data);
  }
  handleAddUser = () => {
    this.setState({ ismodalopen: true }, () => {
      this.openElement.click();
    });
  };

  handleToast = data => {
    this.setState({ showToast: true, toastData: data });
    setTimeout(() => {
      this.setState({
        showToast: false,
      });
    }, data.delay);

    this.fetchUsers();
  };

  render() {
    const {
      users,
      meta,
      loading,
      typeOptions,
      sortAscending,
      sortedBy,
      isFilterOpen,
      userFilterData,
      toBeFilteredData,
      ismodalopen,
      showToast,
      toastData,
      searchData,
    } = this.state;
    return (
      <React.Fragment>
        {showToast && (
          <Toast type={toastData.type} message={toastData.message} showToast={showToast} delay={toastData.delay} />
        )}
        <section id="page-header">
          <div className="container-fluid px-0">
            <div className=" mt-5">
              <div className="row mb-3">
                <div className="col">
                  <h1 className="mb-1 ls-0-25 line-height-normal">
                    <FormattedMessage id="sidemenu_users" />
                  </h1>
                  <p className="fs-14 line-height-22 mb-2 ls-0-47 w-600-max">
                    <FormattedMessage id="user_paragraph" />
                  </p>
                </div>
                {/* <React.Fragment>
                  <div className="col text-right">
                    <a href="#back" className="font-weight-normal fs-14" onClick={this.goBack}>
                      <img className="mr-2" src={Constants.icons.arrowBack} alt="backToPreviousPage" />
                      <u>
                        <FormattedMessage id="common_back" />
                      </u>
                    </a>
                  </div>
                </React.Fragment> */}
              </div>
              <div className="row mx-0 mt-2">
                <button
                  className="new-user-btn  btn btn-primary fs-14 w-233-min p-0 text-align-center py-1 line-height-16 ls-1-27"
                  onClick={this.handleAddUser}
                  ref={openModal => (this.openElement = openModal)}
                >
                  <img src={Constants.icons.iconAddWhite} width="24" height="24" className="mr-1" alt="icon" />
                  <span className="va-middle font-weight-normal">
                    <FormattedMessage id="new_users_add_new_user" />
                  </span>
                </button>
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#customerModal"
                  ref={openModal => (this.openElement = openModal)}
                />
              </div>
            </div>
          </div>
        </section>
        <section id="products" className="bg-green pb-3 loading-bg-height mb-5 mt-4">
          <div id="products-content" className="collapse show">
            <div className="container-fluid mt-3 px-0">
              <div className=" ">
                <div className="card card-rounded">
                  <div className="card-body list-view-card-body">
                    <div className="row font-weight-semibold fs-18 color-1d1d1d pl-3 mb-3">
                      <FormattedMessage id="users_listview_header" />
                    </div>
                    <div
                      className={
                        'row ' + (toBeFilteredData && Object.keys(toBeFilteredData).length > 0 ? 'mb-4' : 'mb-4 pb-3')
                      }
                    >
                      <div className="col-5 pr-0">
                        <SearchInput
                          onSearch={this.searchUsers.bind(this)}
                          containerStyle="w-378-max h-44 border-2-ededed br-4"
                          iconStyle="h-100"
                        />
                      </div>
                      <div className="col-2 w-107-max p-0">
                        <FilterButton
                          className="btn-grey-light btn mr-3 h-44"
                          onClick={this.handleFitlerButton.bind(this)}
                        />
                      </div>
                    </div>
                    <div
                      className={'filter-pop-over-container-users ' + (toBeFilteredData ? 'filter-data-selected' : '')}
                    >
                      <FilterPopOver
                        isOpen={isFilterOpen}
                        onApply={this.handleFilterApply.bind(this)}
                        onCancel={this.handleCancelFilter.bind(this)}
                        options={userFilterData}
                        searchData={searchData}
                        onClearSearch={this.searchUsers.bind(this, '')}
                      />
                    </div>

                    <div className="ri-table mb-3 px-2 fs-16 ls-0-44 pr-4">
                      <LoadingOverlay
                        active={loading}
                        spinner={<BounceLoader />}
                        className="loading__overlay_white_wrapper loader-list-view-common"
                        text={<FormattedMessage id="common_loading_overlay" />}
                      >
                        {users.length > 0 ? (
                          <UserListView
                            users={users}
                            typeOptions={typeOptions}
                            sortedBy={sortedBy}
                            sortAscending={sortAscending}
                            onSort={this.handleSorting.bind(this)}
                            handleCreateUser={this.handleCreateUser}
                            handleToast={this.handleToast}
                          />
                        ) : (
                          <div className=" pt-3 ri-table pb-5">
                            {!loading && users.length === 0 && (
                              <div className="row justify-content-center mb-4">
                                <p className="text-center">
                                  ! &nbsp; <FormattedMessage id="users_no_users_yet" />
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </LoadingOverlay>
                    </div>
                    {!loading && users.length > 0 && (
                      <Pagination cursor={meta.cursor} gotoPage={this.handleGotoPage.bind(this)} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {ismodalopen && (
          <UserModal
            typeOptions={typeOptions}
            handleToast={this.handleToast}
            ismodalopen={ismodalopen}
            userModalClose={this.handleUserModalClose}
            userCreate={this.handleCreateUser}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Users;
