import React, { Component } from "react";
import PropTypes from "prop-types";
import Constants from "../../shared/constants";

class SearchInput extends Component {
  state = {
    searchText: ""
  };

  searchItem = async e => {
    const { searchText } = this.state;
    await this.props.onSearch(searchText);
    this.setState({ searchText: "" });
  };

  _handleKeyPress = e => {
    if (e.key === "Enter") {
      this.searchItem();
    }
  };

  handleOnChange = e => {
    //this.props.onChange(e.target.value);
    this.setState({ searchText: e.target.value });
  };

  render() {
    const { containerStyle, inputStyle, iconStyle } = this.props;
    return (
      <div className={"input-group " + containerStyle}>
        <input
          type="search"
          className={"px-4 common-search-field " + inputStyle}
          id="searchField"
          aria-describedby="search"
          placeholder={Constants.language.ph_search}
          onChange={this.handleOnChange.bind(this)}
          value={this.state.searchText}
          onKeyPress={this._handleKeyPress}
        />
        <div className={"input-group-append bg-white-1 " + iconStyle}>
          <button
            className="btn bg-white p-0"
            type="button"
            onClick={this.searchItem}
          >
            <img
              src={Constants.icons.search}
              width="18"
              height="18"
              alt="search-icon"
              className="mr-3 mb-1"
            />
          </button>
        </div>
      </div>
    );
  }
}

SearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
  containerStyle: PropTypes.string,
  inputStyle: PropTypes.string
};

export default SearchInput;
