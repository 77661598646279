import React from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';

import Constants from '../../shared/constants';
import BaseComponent from '../BaseComponent';
import ContractService from '../../services/ContractService';
import ServiceCards from '../../components/ServiceCards';
import Config from '../../config';
import { SelectedProducts } from '../../components/NewOrder/Components/PrepareOrder/SelectedProducts';
import AuthService from '../../services/AuthService';
import { getCustomerType } from '../../shared/utils';
import { CustomerType } from '../../shared/CustomerType';

class AddNewServiceContract extends BaseComponent {
  constructor(props) {
    super(props);
    this.contractService = new ContractService(Config.apiBase);
    this.authService = new AuthService(Config.apiBase);
    this.state = {
      selectedServicesData: [],
      chooseServiceData: [],
      parentServiceData: [],
      fetching: false,
    };
  }

  componentDidMount() {
    const isReseller = this.authService.isResellerUser();

    if (isReseller) {
      this.props.history.push('/contracts');
    } else {
      this.fetchOrders();
    }
  }

  async fetchOrders() {
    const contractID = this.props.match.params.id;
    this.setState({ fetching: true });
    const response = await this.contractService.getServicesForContract(contractID);
    const {
      status,
      data: { services, customer },
    } = response;

    this.customerType = getCustomerType(customer);

    if (this.customerType === CustomerType.PRIVATE_CUSTOMER) {
      services.map(obj => {
        obj.defaultPrice += Constants.taxPercentage * Number(obj.defaultPrice);
        obj.currentCost += Constants.taxPercentage * Number(obj.currentCost);
      });
    }

    if (status === 200) {
      const childServices = services.filter(obj => obj.parent);
      // parent service
      var parentServices = services.filter(obj => !obj.parent);
      parentServices = parentServices.map(parent => {
        parent.children = childServices.filter(obj => obj.parent.id === parent.inventoryId);

        if (parent.alreadySubscribed) {
          if (
            parent.itemStatus !== Constants.orderItemStatus.terminated &&
            parent.itemStatus !== Constants.orderItemStatus.cancelled
          ) {
            // parent.selected = true;
            parent.totalSubPrice = parent.defaultPrice;
          } else {
            // parent.selected = false;
            parent.alreadySubscribed = false;
          }

          parent.children.map(child => {
            if (child.alreadySubscribed) {
              if (
                child.itemStatus !== Constants.orderItemStatus.terminated &&
                child.itemStatus !== Constants.orderItemStatus.cancelled
              ) {
                // child.selected = true;
                parent.totalSubPrice += child.defaultPrice;
              } else {
                // child.selected = false;
                child.alreadySubscribed = false;
              }
            }
            return child;
          });
        }

        return parent;
      });

      this.setState({
        chooseServiceData: services,
        parentServiceData: parentServices,
      });
    }

    this.setState({ fetching: false });
  }

  handleItemClick = data => {
    let { chooseServiceData } = this.state;
    chooseServiceData.map(obj => {
      if (obj.inventoryNumber === data.inventoryNumber) {
        if (obj.selected) {
          obj.selected = false;
          obj.childrens.map(item => {
            if (item.selected) item.selected = false;
            return obj;
          });
        } else {
          obj.selected = true;
        }
      } else {
        obj.childrens.map(item => {
          if (item.inventoryNumber === data.inventoryNumber) {
            if (item.selected) item.selected = false;
            else item.selected = true;
          }
          return item;
        });
      }
      return obj;
    });
    let selectedServicesData = [];

    chooseServiceData.map(obj => {
      if (obj.selected) {
        selectedServicesData.push(obj);
      }

      if (obj.childrens) {
        obj.childrens.map(child => {
          if (child.selected) {
            selectedServicesData.push(child);
          }
          return child;
        });
      }
      return obj;
    });

    this.setState({ chooseServiceData, selectedServicesData });
  };

  /**
   * to update the price from the child component to the parent class
   *
   */
  handleOnPriceChange = (value, inventoryNumber) => {
    let { chooseServiceData } = this.state;
    chooseServiceData.map(service => {
      if (service.inventoryNumber === inventoryNumber) {
        service.currentCost = Number(value);
      }
      if (service.childrens) {
        service.childrens.map(item => {
          if (item.inventoryNumber === inventoryNumber) {
            item.currentCost = Number(value);
          }

          return item;
        });
      }
      return service;
    });
    this.setState({ chooseServiceData });
    return;
  };

  handleChangePriceOfService = inventoryNumber => {
    let { chooseServiceData } = this.state;
    chooseServiceData.map(service => {
      if (service.inventoryNumber === inventoryNumber) {
        if (service.priceChange) {
          service.priceChange = false;
        } else {
          service.priceChange = true;
        }
      }
      if (service.childrens) {
        service.childrens.map(item => {
          if (item.inventoryNumber === inventoryNumber) {
            if (item.priceChange) {
              item.priceChange = false;
            } else {
              item.priceChange = true;
            }
          }

          return item;
        });
      }
      return service;
    });
    this.setState({ chooseServiceData });
    return;
  };

  handleOnServicePriceChange = item => newPrice => {
    const { chooseServiceData } = this.state;
    const currentEditingService = chooseServiceData.find(obj => item.inventoryId === obj.inventoryId);
    if (currentEditingService) {
      currentEditingService.currentCost = Number(newPrice);
      currentEditingService.defaultPrice = Number(newPrice);
    }

    if (currentEditingService.parent) {
      let currentEditingServiceParent = chooseServiceData.find(obj => item.parent.id === obj.inventoryId);
      currentEditingServiceParent.totalSubPrice = this.getTotalSubPrice(currentEditingServiceParent);
    } else {
      currentEditingService.totalSubPrice = this.getTotalSubPrice(currentEditingService);
    }
    this.setState({ chooseServiceData });
  };

  getTotalSubPrice = service => {
    return (
      service.defaultPrice +
      service.children
        .filter(obj => obj.alreadySubscribed || obj.selected)
        .reduce((accumulator, childService) => accumulator + childService.defaultPrice, 0)
    );
  };

  /**
   * To Submit the selected services to the backend and integration
   */
  handleSubmit = async () => {
    const { parentServiceData } = this.state;
    const selectedServicesData = this.serviceObjectSpread({
      parentServiceData,
    });

    selectedServicesData.map(obj => {
      if (obj.children) {
        obj.isChild = false;
      } else {
        obj.isChild = true;
      }

      if (this.customerType === CustomerType.PRIVATE_CUSTOMER) {
        obj.defaultPrice = obj.defaultPrice - (obj.defaultPrice * Constants.taxPercentage) / 1.25;
        obj.currentCost = obj.currentCost - (obj.currentCost * Constants.taxPercentage) / 1.25;
      }
      return obj;
    });
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState({ fetching: true });

    let response = await this.contractService.addSubscriptionForContract(selectedServicesData, id);
    if (response.status === 200) {
      this.props.history.push(Constants.routes.newOrderThankyouOrder + '/' + id);
    }
    this.setState({ fetching: false });
  };

  handleServices = service => {
    const { chooseServiceData, selectedServicesData } = this.state;

    let chosenService = chooseServiceData.find(obj => obj.inventoryId === service.inventoryId);
    chosenService.selected = !chosenService.selected;

    selectedServicesData.push(chosenService);

    if (!chosenService.parent) {
      chosenService.totalSubPrice = Number(service.defaultPrice);
    } else {
      let chosenServiceParent = chooseServiceData.find(obj => service.parent.id === obj.inventoryId);
      if (service.defaultPrice > 0) {
        chosenServiceParent.totalSubPrice = chosenServiceParent.totalSubPrice + Number(service.defaultPrice);
      }
    }

    this.setState({ chooseServiceData, selectedServicesData });
  };

  handleServiceRemove = service => {
    const { chooseServiceData, selectedServicesData } = this.state;
    let chosenService = chooseServiceData.find(obj => obj.inventoryId === service.inventoryId);
    chosenService.selected = false;
    selectedServicesData.pop(chosenService);

    if (!chosenService.parent) {
      chosenService.totalSubPrice = 0;
    } else {
      let chosenServiceParent = chooseServiceData.find(obj => service.parent.id === obj.inventoryId);
      if (chosenServiceParent.totalSubPrice !== 0) {
        chosenServiceParent.totalSubPrice -= service.defaultPrice;
      }
    }

    this.setState({ chooseServiceData, selectedServicesData });
    if (service.children) {
      service.children.map(child => {
        return this.handleServiceRemove(child);
      });
    }
  };

  handleReadMore = service => {
    const { chooseServiceData } = this.state;
    chooseServiceData.filter(obj => obj.inventoryId === service.inventoryId).map(obj => (obj.readMore = !obj.readMore));
    this.setState({ chooseServiceData });
  };

  handleOnRemoveService = ({ itemData }) => {
    const { parentServiceData } = this.state;
    parentServiceData.map(service => {
      if (service.inventoryId === itemData.inventoryId) {
        service.selected = false;
        service.children.map(child => {
          child.selected = false;
          return child;
        });
      } else {
        if (service.children) {
          service.children.map(child => {
            if (child.inventoryId === itemData.inventoryId && child.selected) {
              child.selected = false;
            }
            return child;
          });
        }
      }
      return service;
    });
    this.setState({ parentServiceData });
  };

  serviceObjectSpread = ({ parentServiceData }) => {
    let spreadedData = [];
    parentServiceData.map(obj => {
      if (obj.selected) {
        spreadedData.push(obj);
      }
      if (obj.children) {
        obj.children.map(child => {
          if (child.selected) {
            spreadedData.push(child);
          }
          return child;
        });
      }
      return obj;
    });
    return spreadedData;
  };

  handleOnPopUpClick = ({ service }) => {
    const { parentServiceData } = this.state;
    const findedService = parentServiceData.find(data => data.inventoryId === service.inventoryId);
    if (!findedService) {
      parentServiceData.map(obj => {
        if (obj.children && obj.children.length > 0) {
          obj.children.map(item => {
            if (item.inventoryId === service.inventoryId) {
              item.isInfoOpened = !item.isInfoOpened;
            }
            return item;
          });
        }
        return obj;
      });
    }

    if (findedService) {
      findedService.isInfoOpened = !findedService.isInfoOpened;
    }
    this.setState({ parentServiceData });
  };

  handleServieToggle = ({ serviceData }) => {
    const { parentServiceData } = this.state;
    if (serviceData) {
      let chosenService = parentServiceData.find(obj => obj.inventoryId === serviceData.inventoryId);
      if (chosenService && chosenService.isExpanded) {
        chosenService.isExpanded = false;
      } else if (chosenService) {
        chosenService.isExpanded = true;
      }
      this.setState({ parentServiceData });
    }
  };

  render() {
    const { parentServiceData, fetching } = this.state;
    let isServices = true;
    let selectedServicesData = this.serviceObjectSpread({ parentServiceData });
    return (
      <React.Fragment>
        <section id="page-header">
          <div className="container-fluid px-0">
            <div className="">
              <div className="row mb-3">
                <div className="col-5">
                  <h1 className="ls-0-25">
                    <FormattedMessage id="contract_add_new_service_header" />
                  </h1>
                  <p>
                    {this.customerType &&
                      (this.customerType !== CustomerType.PRIVATE_CUSTOMER ? (
                        <FormattedMessage id="common_price_excluding_tax" />
                      ) : (
                        <FormattedMessage id="common_price_including_tax" />
                      ))}
                  </p>
                </div>

                <React.Fragment>
                  <div className="col text-right">
                    <a href="#back" className="font-weight-normal fs-14" onClick={this.goBack}>
                      <img className="mr-2" src={Constants.icons.arrowBack} width="18" height="18" alt="back-icon" />
                      <u>
                        <FormattedMessage id="common_back" />
                      </u>
                    </a>
                  </div>
                </React.Fragment>
              </div>
            </div>
          </div>
        </section>

        <LoadingOverlay
          active={fetching}
          spinner={<BounceLoader />}
          text={<FormattedMessage id="common_loading_overlay" />}
        >
          <>
            <section id="services" className="bg-green">
              <div id="services-content" className="collapse show">
                <div className="container-fluid mt-3 px-0">
                  <div className="">
                    <ServiceCards
                      parentServiceData={parentServiceData}
                      onReadMore={this.handleReadMore}
                      onServiceSelect={this.handleServices}
                      onServiceRemove={this.handleServiceRemove}
                      onPopUpClick={this.handleOnPopUpClick}
                      onServiceToggle={this.handleServieToggle}
                      forProducts={false}
                    />
                  </div>
                </div>
              </div>
            </section>

            <div id="products-content" className="collapse show">
              <div className="container-fluid mt-3 px-0">
                <div className="">
                  <div className="card card-rounded">
                    <div className={'card-body list-view-card-body'}>
                      <div className="row">
                        <SelectedProducts
                          isPrivateCustomer={this.customerType === CustomerType.PRIVATE_CUSTOMER}
                          productsData={selectedServicesData}
                          isServices={isServices}
                          onConfirmOrderProducts={this.handleSubmit}
                          onRemoveItem={this.handleOnRemoveService}
                          onServicePriceChange={this.handleOnServicePriceChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </LoadingOverlay>
      </React.Fragment>
    );
  }
}

export default AddNewServiceContract;
