import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Constants from '../../shared/constants';

const ProductListViewHeader = ({ forBasket, onSort, sortAscending, sortedBy, isReseller, isProposals = false }) => {
  return (
    <React.Fragment>
      <div className={'row pb-2 table-header fs-14 ls-0-44 row pb-2 mx-0'}>
        <div className={'cursor-pointer ' + (forBasket ? 'col-4' : 'col')} onClick={onSort.bind(this, 'name')}>
          <u className={sortedBy === 'name' ? 'font-weight-semibold' : 'font-weight-normal'}>
            <FormattedMessage id="common_product_name" />
          </u>
          <img
            src={Constants.icons.arrowBack}
            width="18px"
            height="18px"
            alt="icon"
            className={sortedBy === 'name' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'}
          />
        </div>
        <div
          className={isProposals ? 'col-2' : 'col-3' + ' cursor-pointer w-20-per-max '}
          onClick={onSort.bind(this, 'category')}
        >
          <u className={sortedBy === 'category' ? 'font-weight-semibold' : 'font-weight-normal'}>
            <FormattedMessage id="all_products_category" />
          </u>
          <img
            src={Constants.icons.arrowBack}
            width="18px"
            height="18px"
            alt="icon"
            className={sortedBy === 'category' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'}
          />
        </div>

        <div className={isProposals ? 'col-1' : 'col-2' + ' cursor-pointer '} onClick={onSort.bind(this, 'number')}>
          <u className={sortedBy === 'number' ? 'font-weight-semibold' : 'font-weight-normal'}>
            <FormattedMessage id="all_products_item_number" />
          </u>
          <img
            src={Constants.icons.arrowBack}
            width="18px"
            height="18px"
            alt="icon"
            className={sortedBy === 'number' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'}
          />
        </div>

        {isProposals ? (
          <>
            <div
              className={'cursor-pointer col-2 text-right w-14-per-max pr-4 pl-0'}
              onClick={onSort.bind(this, 'price')}
            >
              <u className={sortedBy === 'price' ? 'font-weight-semibold' : 'font-weight-normal'}>
                <FormattedMessage id="order_reseller_price" />
              </u>
              <img
                src={Constants.icons.arrowBack}
                width="18px"
                height="18px"
                alt="icon"
                className={sortedBy === 'price' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'}
              />
            </div>
            <div
              className={'cursor-pointer col-2 text-right w-14-per-max pr-4 pl-0'}
              onClick={onSort.bind(this, 'price')}
            >
              <u className={sortedBy === 'price' ? 'font-weight-semibold' : 'font-weight-normal'}>
                <FormattedMessage id="order_customer_price" />
              </u>
              <img
                src={Constants.icons.arrowBack}
                width="18px"
                height="18px"
                alt="icon"
                className={sortedBy === 'price' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'}
              />
            </div>
          </>
        ) : (
          <div
            className={'cursor-pointer col-3 text-right w-14-per-max pr-4 pl-0'}
            onClick={onSort.bind(this, 'price')}
          >
            <u className={sortedBy === 'price' ? 'font-weight-semibold' : 'font-weight-normal'}>
              <FormattedMessage id="common_price" />
            </u>
            <img
              src={Constants.icons.arrowBack}
              width="18px"
              height="18px"
              alt="icon"
              className={sortedBy === 'price' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'}
            />
          </div>
        )}
        {!isReseller && (
          <div
            className={'cursor-pointer w-12-per-max  col-2 ' + (forBasket ? 'text-center pl-5' : 'text-left pr-0 pl-4')}
          >
            {forBasket ? (
              <u>
                <FormattedMessage id="contract_button_select_service" />
              </u>
            ) : (
              <React.Fragment>
                <u
                  onClick={onSort.bind(this, 'status')}
                  className={sortedBy === 'status' ? 'font-weight-semibold' : 'font-weight-normal'}
                >
                  <FormattedMessage id="all_orders_order_status" />
                </u>
                <img
                  src={Constants.icons.arrowBack}
                  width="18px"
                  height="18px"
                  alt="icon"
                  className={sortedBy === 'status' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'}
                />
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

ProductListViewHeader.propTypes = {
  forBasket: PropTypes.bool,
  onSort: PropTypes.func,
};

export default ProductListViewHeader;
