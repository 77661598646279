import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Constants from '../../shared/constants';
import Routes from '../../shared/Routes';
import AuthService from '../../services/AuthService';
import DashBoardService from '../../services/DashboardService';

import Config from '../../config';
import { selectOrder } from '../../state/actions/OrderActions';
import { OrderType } from '../../shared/OrderType';
import { ShortMonthNames, FullMonthNames } from './Period';
import LineChart from './LineChart';
import BarChart from './BarChart';

function SalesOrderChat(obj) {
  const numberFormat = new Intl.NumberFormat('sv-SE');
  const { interval } = obj;
  let data = obj.data;
  console.log(interval === 'weekly');
  if (interval === 'weekly') {
    // Weekly data is not sorted
    data = obj.data.sort((a, b) => (a.year * 100 + a.week > b.year * 100 + b.week ? 1 : -1));
  }

  console.log(data);

  const orderSeries = data.map(order => order.orders);

  const totalOrder = orderSeries.reduce((a, b) => a + b, 0);
  const averageOrder = (totalOrder / data.length).toFixed(0);
  const orderAverageSeries = data.map(order => averageOrder);

  const revenueSeries = data.map(order => order.revenue);
  const totalRevenue = revenueSeries.reduce((a, b) => a + b, 0);
  const averageRevenue = (totalRevenue / data.length).toFixed(0);
  const revenueAverageSeries = data.map(order => averageRevenue);
  const axis = data.map(order => (interval === 'monthly' ? `${ShortMonthNames[order.month - 1]}` : order.week));
  const axisMonths = data.map(subscription => `${FullMonthNames[subscription.month - 1]}`);
  const years = data.map(subscription => subscription.year);

  const lineChartSeries = [
    {
      name: `${Constants.language.dashboard_total_order} (${totalOrder})`,
      data: orderSeries,
      discrete: true,
    },
    {
      name: `${Constants.language.dashboard_average_orders} (${averageOrder})`,
      data: orderAverageSeries,
      discrete: false,
    },
  ];
  const barCharSeries = [
    {
      name: `${Constants.language.dashboard_total_revenue1} (${numberFormat.format(totalRevenue)}${
        Constants.currency.norway
      })`,
      data: revenueSeries,
      type: 'bar',
    },
    {
      name: `${Constants.language.dashboard_average_revenue} (${numberFormat.format(averageRevenue)}${
        Constants.currency.norway
      })`,
      data: revenueAverageSeries,
      type: 'line',
    },
  ];

  const CustomLineTooltip = data => {
    const { dataPointIndex } = data;
    return `<div class="card card-white">
                <div class="card-header p-1 text-center tooltip-title">
                ${
                  interval === 'monthly'
                    ? `${axisMonths[dataPointIndex]} ${years[dataPointIndex]}`
                    : `${Constants.language.dashboard_week} ${axis[dataPointIndex]}`
                }
                </div>
                <div class="card-body p-2">
                    <div class="row my-0 px-1">
                        <div class="col-7 activations">${Constants.language.bc_my_orders}:</div>
                        <div class="col-5 activations text-right">${orderSeries[dataPointIndex]}</div>
                    </div>
                </div>
            </div>`;
  };

  const CustomBarTooltip = data => {
    const { series, dataPointIndex } = data;
    return `<div class="card card-white">
                <div class="card-header p-1 text-center tooltip-title">
                ${
                  interval === 'monthly'
                    ? `${axisMonths[dataPointIndex]} ${years[dataPointIndex]}`
                    : `${Constants.language.dashboard_week} ${axis[dataPointIndex]}`
                }
                </div>
                <div class="card-body p-2">
                    <div class="row my-0 px-1">
                        <div class="col-6 activations">${Constants.language.dashboard_sales}:</div>
                        <div class="col-6 activations text-right">
                          ${numberFormat.format(series[0][dataPointIndex])}${Constants.currency.norway}
                        </div>
                    </div>
                </div>
            </div>`;
  };

  return (
    <div className="row p-3">
      <div className="col m-0 p-0 mr-2">
        <LineChart
          series={lineChartSeries}
          xaxis={axis}
          height={350}
          colors={['#009688', '#ff5252']}
          strokes={[2, 1]}
          tooltip={{ custom: CustomLineTooltip }}
        />
      </div>
      <div className="col m-0 p-0 ml-2">
        <BarChart
          series={barCharSeries}
          xaxis={axis}
          height={350}
          color={'#242f33'}
          type={'bar'}
          tooltip={{ custom: CustomBarTooltip }}
        />
      </div>
    </div>
  );
}

export default SalesOrderChat;
