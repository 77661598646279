import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import LoadingOverlay from 'react-loading-overlay';
import { FormattedMessage } from 'react-intl';

import PriceEditable from '../../../Input/PriceEditable';
import Constants from '../../../../shared/constants';
import Config from '../../../../config';
import AuthService from '../../../../services/AuthService';
import { contractModalDateChange } from '../../../../state/actions/ContractActions';
import { CheckBox } from '../../../CheckBox/CheckBox';

class CommonModalView extends Component {
  constructor(props) {
    super(props);
    if (props.item) {
      props.item.price && this.setState({ changedPrice: props.item.price });
    }
    this.state = {
      applyBillingDateToAllServices: false,
    };
  }
  authService = new AuthService(Config.apiBase);

  getModalClassNames = () => {
    let baseClasses = 'modal-dialog ';
    baseClasses = baseClasses + 'modal-small';

    return baseClasses;
  };
  handleOnSaveActionDate = (id, type, price, applyToAll = false) => () => {
    const { onSaveActionDate, contract: { nextBillingDate = ''}, } = this.props;
    onSaveActionDate(id, type, price, nextBillingDate ? applyToAll : false);
    this.closeElement.click();
  };

  handleCheckApplyBillingDateToAllServices = () =>
    this.setState(prevState => ({ applyBillingDateToAllServices: !prevState.applyBillingDateToAllServices }));

  render() {
    const {
      onChangeRaw,

      contract: {
        isSubmitActionLoading,
        isActionSubmissionError,
        contractModalType: {
          terminateAll,
          cancel,
          terminate,
          activate,
          renew,
          editPrice,
          activateAll,
          editBillingPeriod,
        },
        subscriptions,
        selectedContractDate,
        nextBillingDate,
        contractSubItem: item,
        modalType,
      },
      contractModalDateChange,
    } = this.props;
    const { changedPrice, applyBillingDateToAllServices } = this.state;
    const isSuperAdmin = this.authService.isSuperAdminUser();
    let { id } = this.props;
    if (item) {
      id = item.inventoryNumber;
    }
    const ServicesToBeTerminatedData = [];
    subscriptions &&
      subscriptions.map(obj => {
        if (obj.status === Constants.orderItemStatus.activated || obj.status === Constants.orderItemStatus.registered) {
          ServicesToBeTerminatedData.push(obj);
        }
        if (obj.childrens) {
          obj.childrens.map(child => {
            if (
              child.status === Constants.orderItemStatus.activated ||
              child.status === Constants.orderItemStatus.registered
            ) {
              ServicesToBeTerminatedData.push(child);
            }
            return child;
          });
        }
        return obj;
      });
    const ServicesToBeActivatedData = [];
    subscriptions &&
      subscriptions.map(obj => {
        if (obj.status === Constants.orderItemStatus.registered) {
          ServicesToBeActivatedData.push(obj);
        }
        if (obj.childrens) {
          obj.childrens.map(child => {
            if (child.status === Constants.orderItemStatus.registered) {
              ServicesToBeActivatedData.push(child);
            }
            return child;
          });
        }
        return obj;
      });
    return (
      <div
        className="modal fade bd-example-modal-sm fade-contracts"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id={'commonForAllModal'}
      >
        <div className={this.getModalClassNames()}>
          <div className="modal-content contracts-modal bg-green rounded border-0">
            <div className="modal-header pl-5 pt-4 pb-3">
              <h4 className="modal-title font-weight-semibold" id="myLargeModalLabel">
                {terminateAll ? (
                  <FormattedMessage id="contract_button_terminate_all_service" />
                ) : terminate ? (
                  item.dueStatus === Constants.orderItemStatus.pendingTermination ? (
                    <FormattedMessage id="contract_button_edit_service" />
                  ) : (
                    <FormattedMessage id="contract_button_terminate_service" />
                  )
                ) : renew ? (
                  <FormattedMessage id="contract_button_renew_service" />
                ) : activate ? (
                  item.dueStatus === Constants.orderItemStatus.pendingActivation ? (
                    <FormattedMessage id="contract_button_edit_service" />
                  ) : (
                    <FormattedMessage id="contract_button_activate_service" />
                  )
                ) : cancel ? (
                  <>
                    <FormattedMessage id="contract_button_cancel_service" />{' '}
                    {item.status === Constants.orderItemStatus.activated && (
                      <FormattedMessage id="contract_termination" />
                    )}
                  </>
                ) : editPrice ? (
                  <FormattedMessage id="contract_modal_head_edit_price" />
                ) : activateAll ? (
                  <FormattedMessage id="contract_button_activate_all_service" />
                ) : editBillingPeriod ? (
                  <FormattedMessage id="contract_modal_head_edit_billing_period" />
                ) : (
                  ' '
                )}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={close => (this.closeElement = close)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body mx-5 rounded p-0 mb-5 mt-2">
              <LoadingOverlay
                active={isSubmitActionLoading}
                spinner={<BounceLoader />}
                text={<FormattedMessage id="common_loading_overlay" />}
              >
                <div className="row">
                  {!modalType && (
                    <div>
                      {(terminateAll || terminate || renew || activate || cancel || editPrice || activateAll) && (
                        <div className="col-12">
                          {!activateAll && !terminateAll && !cancel && (
                            <div className="mb-3">
                              <h4 className="d-inline-block mr-3 pb-2 font-weight-bold border-bottom border-dark border-bold black">
                                {item.name}
                              </h4>
                              {item.childrens ? (
                                <h6 className="d-inline-block font-weight-light black">
                                  <FormattedMessage id="contract_service_base" />
                                </h6>
                              ) : (
                                <h6 className="d-inline-block font-weight-light black">
                                  <FormattedMessage id="contract_service_add_on" />
                                </h6>
                              )}
                            </div>
                          )}
                          {terminateAll ? (
                            <p className="mb-5">
                              <FormattedMessage id="contract_terminate_all_description" />
                            </p>
                          ) : activateAll ? (
                            <p className="mb-5">
                              <FormattedMessage id="contract_activate_all_description" />
                            </p>
                          ) : terminate ? (
                            item.childrens ? (
                              <p>
                                <FormattedMessage id="contract_terminate_description" />{' '}
                                <FormattedMessage id="contract_terminate_description_1" />
                              </p>
                            ) : (
                              <p>
                                <FormattedMessage id="contract_terminate_description" />
                              </p>
                            )
                          ) : activate ? (
                            <p>
                              <FormattedMessage id="contract_activate_description" />
                            </p>
                          ) : cancel ? (
                            <p>
                              {item.childrens ? (
                                item.status === Constants.orderItemStatus.activated &&
                                item.dueStatus === Constants.orderItemStatus.pendingTermination ? (
                                  <>
                                    <FormattedMessage id="contract_description_cancel_termination_common_parent_1" />
                                    <strong>{item.name}</strong>
                                    <FormattedMessage id="contract_description_cancel_termination_common_parent_2" />
                                  </>
                                ) : (item.status === Constants.orderItemStatus.registered && !item.dueStatus) ||
                                  (item.status === Constants.orderItemStatus.terminated &&
                                    item.dueStatus === Constants.orderItemStatus.pendingActivation) ||
                                  (item.status === Constants.orderItemStatus.registered &&
                                    item.dueStatus === Constants.orderItemStatus.pendingActivation) ? (
                                  <>
                                    <FormattedMessage id="contract_description_cancel_activation_common_parent_1" />
                                    <strong>{item.name}</strong>
                                    <FormattedMessage id="contract_description_cancel_activation_common_parent_2" />
                                  </>
                                ) : item.status === Constants.orderItemStatus.cancelled &&
                                  item.dueStatus === Constants.orderItemStatus.pendingActivation ? (
                                  <>
                                    <FormattedMessage id="contract_description_cancel_activation_common_parent_1" />
                                    <strong>{item.name}</strong>?
                                  </>
                                ) : (
                                  ''
                                )
                              ) : item.status === Constants.orderItemStatus.activated &&
                                item.dueStatus === Constants.orderItemStatus.pendingTermination ? (
                                <>
                                  <FormattedMessage id="contract_description_cancel_termination_common_child_1" />
                                  <strong>{item.name}</strong>
                                  <FormattedMessage id="contract_description_cancel_activation_common_child_2" />
                                </>
                              ) : (item.status === Constants.orderItemStatus.registered && !item.dueStatus) ||
                                (item.status === Constants.orderItemStatus.terminated &&
                                  item.dueStatus === Constants.orderItemStatus.pendingActivation) ||
                                (item.status === Constants.orderItemStatus.registered &&
                                  item.dueStatus === Constants.orderItemStatus.pendingActivation) ? (
                                <>
                                  <FormattedMessage id="contract_description_cancel_activation_common_child_1" />
                                  <strong>{item.name}</strong>
                                  <FormattedMessage id="contract_description_cancel_activation_common_child_2" />
                                </>
                              ) : item.status === Constants.orderItemStatus.cancelled &&
                                item.dueStatus === Constants.orderItemStatus.pendingActivation ? (
                                <>
                                  <FormattedMessage id="contract_description_cancel_activation_common_child_1" />
                                  <strong>{item.name}</strong>?
                                </>
                              ) : (
                                ''
                              )}{' '}
                            </p>
                          ) : (
                            renew && (
                              <p>
                                <FormattedMessage id="contract_renew_description" />
                              </p>
                            )
                          )}
                          {!cancel && !editPrice && !terminateAll && !activateAll && (
                            <>
                              <div className="col my-3 px-0">
                                <div className="d-inline-block bg-white border-radius-5px py-1 mb-4 date-picker-holder">
                                  <label className="mb-0">
                                    <DatePicker
                                      onChange={date =>
                                        contractModalDateChange({
                                          selectedContractDate: date,
                                        })
                                      }
                                      dateFormat="dd/MM/yyyy"
                                      selected={
                                        selectedContractDate ||
                                        (item.dueStatus === Constants.orderItemStatus.pendingTermination
                                          ? moment(item.terminationDate.slice(0, 10)).toDate()
                                          : item.dueStatus === Constants.orderItemStatus.pendingActivation
                                          ? moment(item.activationDate.slice(0, 10)).toDate()
                                          : selectedContractDate)
                                      }
                                      disabledKeyboardNavigation
                                      onChangeRaw={onChangeRaw}
                                      placeholderText={Constants.language.contract_modals_date_placeholder}
                                    />
                                    <i className="ml-2 fa fa-calendar" aria-hidden="true" />
                                  </label>
                                </div>
                                <div className="d-inline-block bg-white border-radius-5px py-1 mb-4 date-picker-holder">
                                  <label className="mb-0">
                                    <DatePicker
                                      onChange={date =>
                                        contractModalDateChange({
                                          nextBillingDate: date,
                                        })
                                      }
                                      dateFormat="dd/MM/yyyy"
                                      selected={nextBillingDate}
                                      disabledKeyboardNavigation
                                      placeholderText={Constants.language.contract_modal_next_billing_placeholder}
                                      onChangeRaw={onChangeRaw}
                                    />
                                    <i className="ml-2 fa fa-calendar" aria-hidden="true" />
                                  </label>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {editPrice && (
                        <div className="col-12 mb-4">
                          <label className="d-block mb-3">
                            <FormattedMessage id="contract_new_monthly_price" />
                          </label>
                          <span className="mr-3">
                            <span className="px-3 bg-white border-radius-5px py-1">
                              <PriceEditable
                                text={item.price}
                                onChange={value => {
                                  this.setState({ changedPrice: value });
                                }}
                                labelClassName="ItemPriceEditable"
                                inputClassName="ItemPriceEditable"
                                inputWidth="165px"
                                inputHeight="25px"
                                inputMaxLength="50"
                              />
                            </span>
                          </span>
                          <h6 className="fs-md font-weight-semibold black d-inline-block">
                            ,- /<FormattedMessage id="common_per_month" />
                          </h6>
                        </div>
                      )}{' '}
                      {editBillingPeriod && (
                        <div className="col-12 mb-4">
                          <div className="d-inline-block bg-white border-radius-5px py-1 mb-4 date-picker-holder">
                            <label className="mb-0">
                              <DatePicker
                                onChange={date =>
                                  contractModalDateChange({
                                    nextBillingDate: date,
                                  })
                                }
                                dateFormat="dd/MM/yyyy"
                                selected={nextBillingDate}
                                disabledKeyboardNavigation
                                placeholderText={Constants.language.contract_modal_next_billing_placeholder}
                                onChangeRaw={onChangeRaw}
                              />
                              <i className="ml-2 fa fa-calendar" aria-hidden="true" />
                            </label>
                          </div>
                          <div className="row mx-0">
                            <div className="d-flex mt-2" onClick={this.handleTermsConditionCheck}>
                              <CheckBox
                                isSelected={applyBillingDateToAllServices}
                                onClick={this.handleCheckApplyBillingDateToAllServices}
                              />
                            </div>
                            <div className="col pl-2">
                              <FormattedMessage id="contract_modal_edit_billing_period_for_all_services" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {modalType && (
                    <div className="col-12">
                      <p className="fs-16">
                        {modalType === Constants.contractModalType.terminate ||
                        modalType === Constants.contractModalType.terminateAll ? (
                          <FormattedMessage id="contract_operation_message_terminate" />
                        ) : modalType === Constants.contractModalType.activate ||
                          modalType === Constants.contractModalType.activateAll ? (
                          <FormattedMessage id="contract_operation_message_activation" />
                        ) : modalType === Constants.contractModalType.renew ? (
                          <FormattedMessage id="contract_operation_message_renew" />
                        ) : modalType === Constants.contractModalType.cancel ? (
                          <FormattedMessage id="contract_operation_message_cancel" />
                        ) : (
                          modalType === Constants.contractModalType.editPrice && (
                            <span className="font-weight-normal">
                              <FormattedMessage id="contract_operation_message_edit_price" />
                            </span>
                          )
                        )}
                      </p>
                      <button
                        type="button"
                        className="btn btn-md btn-dark d-inline-block px-5 mx-2 modal-apply-btn font-extra-services font-weight-semibold mt-4 float-right"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <FormattedMessage id="common_close" />
                      </button>
                    </div>
                  )}
                </div>
                {!modalType &&
                  (terminateAll || activateAll) &&
                  (ServicesToBeTerminatedData || ServicesToBeActivatedData) && (
                    <div className="row justify-content-center mt-0 mb-3">
                      <div className="col-12">
                        <h6 className="mb-3 font-weight-semibold">
                          <FormattedMessage id="contract_list_to_terminate" />
                        </h6>
                        <div className="mb-4">
                          <>
                            {ServicesToBeTerminatedData &&
                              terminateAll &&
                              ServicesToBeTerminatedData.length > 0 &&
                              ServicesToBeTerminatedData.map(obj => (
                                <div className="row align-items-center my-3" key={Math.random() * 100}>
                                  <div className="col-auto font-weight-bold">1</div>
                                  <span>X</span>
                                  <div className="col-auto">
                                    <img
                                      src={Constants.noImage}
                                      width="35"
                                      height="35"
                                      alt="product_name"
                                      className="rounded-circle border"
                                    />
                                  </div>
                                  <div className="col-6">{obj.name}</div>
                                </div>
                              ))}
                          </>
                          <>
                            {ServicesToBeActivatedData &&
                              activateAll &&
                              ServicesToBeActivatedData.length > 0 &&
                              ServicesToBeActivatedData.map(obj => (
                                <div className="row align-items-center my-3" key={Math.random() * 100}>
                                  <div className="col-auto font-weight-bold">1</div>
                                  <span>X</span>
                                  <div className="col-auto">
                                    <img
                                      src={Constants.noImage}
                                      width="35"
                                      height="35"
                                      alt="product_name"
                                      className="rounded-circle border"
                                    />
                                  </div>
                                  <div className="col-6">{obj.name}</div>
                                </div>
                              ))}
                          </>
                        </div>
                        <div className="col my-3 px-0">
                          <div className="d-inline-block bg-white border-radius-5px py-1 mb-4 date-picker-holder">
                            <label className="mb-0">
                              <DatePicker
                                onChange={date =>
                                  contractModalDateChange({
                                    selectedContractDate: date,
                                  })
                                }
                                dateFormat="dd/MM/yyyy"
                                selected={selectedContractDate}
                                disabledKeyboardNavigation
                                placeholderText={Constants.language.contract_modals_date_placeholder}
                                onChangeRaw={onChangeRaw}
                              />
                              <i className="ml-2 fa fa-calendar" aria-hidden="true" />
                            </label>
                          </div>
                          {isSuperAdmin && activateAll && (
                            <div className="d-inline-block bg-white border-radius-5px py-1 mb-4 date-picker-holder">
                              <label className="mb-0">
                                <DatePicker
                                  onChange={date =>
                                    contractModalDateChange({
                                      nextBillingDate: date,
                                    })
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  selected={nextBillingDate}
                                  disabledKeyboardNavigation
                                  placeholderText={Constants.language.contract_modal_next_billing_placeholder}
                                  onChangeRaw={onChangeRaw}
                                />
                                <i className="ml-2 fa fa-calendar" aria-hidden="true" />
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                {!modalType && (
                  <div className="d-flex flex-row-reverse">
                    <div className={(cancel ? 'col-12 text-right ' : 'col-7 offset-2 ') + 'pt-3 px-0'}>
                      {isActionSubmissionError && (
                        <p className="text-danger mb-2">
                          <FormattedMessage id="contract_action_error_message" />
                        </p>
                      )}
                      {renew && (
                        <button
                          onClick={this.handleOnSaveActionDate(id, Constants.contractModalType.renew)}
                          className="btn btn-dark btn-xs d-block w-100 modal-apply-btn font-extra-services font-weight-semibold"
                          disabled={!selectedContractDate}
                        >
                          <FormattedMessage id="contract_button_renew_service" />
                        </button>
                      )}
                      {terminate && (
                        <button
                          onClick={this.handleOnSaveActionDate(id, Constants.contractModalType.terminate)}
                          className="btn btn-dark btn-xs d-block w-100 modal-apply-btn font-extra-services font-weight-semibold"
                          disabled={!selectedContractDate}
                        >
                          <FormattedMessage id="contract_button_terminate_service" />
                        </button>
                      )}
                      {activate && (
                        <button
                          onClick={this.handleOnSaveActionDate(id, Constants.contractModalType.activate)}
                          className="btn btn-dark btn-xs d-block w-100 modal-apply-btn font-extra-services font-weight-semibold"
                          disabled={!selectedContractDate}
                        >
                          <FormattedMessage id="contract_button_activate_service" />
                        </button>
                      )}
                      {cancel && (
                        <>
                          <button
                            onClick={this.handleOnSaveActionDate(id, Constants.contractModalType.cancel)}
                            className="btn btn-md btn-dark d-inline-block px-5 mx-2 modal-apply-btn font-extra-services font-weight-semibold"
                          >
                            <FormattedMessage id="contract_button_apply" />
                          </button>
                        </>
                      )}
                      {editPrice && (
                        <button
                          onClick={this.handleOnSaveActionDate(
                            id,
                            Constants.contractModalType.editPrice,
                            changedPrice
                          )}
                          className="btn btn-dark btn-xs d-block w-100 modal-apply-btn font-extra-services font-weight-semibold"
                        >
                          <FormattedMessage id="contract_button_apply" />
                        </button>
                      )}
                      {terminateAll && (
                        <button
                          onClick={this.handleOnSaveActionDate(id, Constants.contractModalType.terminateAll)}
                          disabled={!selectedContractDate}
                          className="btn btn-dark btn-xs d-block w-100 modal-apply-btn font-extra-services font-weight-semibold"
                        >
                          <FormattedMessage id="contract_button_terminate_all_service" />
                        </button>
                      )}
                      {activateAll && (
                        <button
                          onClick={this.handleOnSaveActionDate(id, Constants.contractModalType.activateAll)}
                          disabled={!selectedContractDate}
                          className="btn btn-dark btn-xs d-block w-100 modal-apply-btn font-extra-services font-weight-semibold"
                        >
                          <FormattedMessage id="contract_button_activate_all_service" />
                        </button>
                      )}
                      {editBillingPeriod && (
                        <button
                          disabled={!nextBillingDate}
                          onClick={this.handleOnSaveActionDate(
                            id,
                            Constants.contractModalType.editBillingPeriod,
                            nextBillingDate,
                            applyBillingDateToAllServices
                          )}
                          className="btn btn-dark btn-xs d-block w-100 modal-apply-btn font-extra-services font-weight-semibold"
                        >
                          <FormattedMessage id="contract_button_apply" />
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  contract: state.contract,
});

export default connect(
  mapStateToProps,
  { contractModalDateChange }
)(CommonModalView);
