import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import Input from '../Input/index';
import Select from '../Input/select';
import Config from '../../config';
import RadioButtonComponent from '../RadioButton';
import { CheckBox } from '../CheckBox/CheckBox';
import ReactQuill from 'react-quill';
import Constants from '../../shared/constants';
import ProductService from '../../services/ProductService';
import AuthService from '../../services/AuthService';
import { groupByKey, removeHTMLTags } from '../../shared/utils';
import { charLimit } from '../../shared/stringOperations';
import Autosuggest from 'react-autosuggest';

export default class EditProductForm extends Component {
  productService = new ProductService(Config.apiBase);
  authService = new AuthService(Config.apiBase);

  state = {
    isPrivatePClicked: false,
    isPublicClicked: false,
    isResellerClicked: false,
    tooglebox: false,
    Dropship1: false,
    Dropship0: false,
    enterReseller: false,
    rackitWebAvailability1: false,
    rackitWebAvailability0: false,
    searchData: [],
    vendors: [],
    value: '',
    mediaId: [],
    productDescription: '',
    specifications: [],
    isValid: false,
    formValid: {
      description: false,
      inventoryNumber: false,
      defaultPrice: false,
      customerClass: false,
      dropship: false,
      vendor: false,
    },
  };
  quillModules = {
    toolbar: {
      container: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean'], ['custom']],
      handlers: {
        custom: () => {
          alert('Hello');
        },
      },
    },
    clipboard: {
      matchVisual: false,
    },
  };

  async componentWillMount() {
    const {
      editItem,
      editItem: {
        itemClass,
        status,
        supplierDetails,
        priceClass,
        attributes,
        rackitWebAvailability1,
        rackitWebAvailability0,
        specifications,
        description,
        inventoryNumber,
        defaultPrice,
      },
    } = this.props;
    let { data: { defaultSupplierIndex } } = await this.productService.getDefaultSupplier(inventoryNumber);
    let { isPrivatePClicked, isPublicClicked, isResellerClicked } = this.state;

    isPrivatePClicked =
      priceClass &&
      priceClass.find(obj => {
        if (obj.customerClass === '1') {
          return obj.availability;
        }
        return false;
      });
    isPublicClicked =
      priceClass &&
      priceClass.find(obj => {
        if (obj.customerClass === '4') {
          return obj.availability;
        }
        return false;
      });
    isResellerClicked =
      priceClass &&
      priceClass.find(obj => {
        if (obj.customerClass === '5') {
          return obj.availability;
        }
        return false;
      });
    let dropship = attributes.find(obj => obj.id === 'DROPSHIP');
    let dropSelection1 = dropship && dropship.value === '1' ? true : false;
    let dropSelection0 = dropship && dropship.value === '0' ? true : false;
    let vendor =
      supplierDetails.length > 0
        ? {
            id: supplierDetails[defaultSupplierIndex].supplierId,
            name: supplierDetails[defaultSupplierIndex].supplierName,
          }
        : null;
    let formValid = {
      description: description ? true : false,
      inventoryNumber: inventoryNumber ? true : false,
      defaultPrice: defaultPrice >= 0 ? true : false,
      customerClass: isPrivatePClicked || isPublicClicked || isResellerClicked ? true : false,
      dropship: dropship ? true : false,
      vendor: vendor ? true : false,
    };

    this.setState({
      mediaId: editItem.media || [],
      productDescription: editItem.productBrief || '',
      selectedVendor: vendor,
      value: vendor ? vendor.supplierName : '',
      isPrivatePClicked: isPrivatePClicked ? true : false,
      isPublicClicked: isPublicClicked ? true : false,
      isResellerClicked: isResellerClicked ? true : false,
      Dropship1: dropSelection1,
      Dropship0: dropSelection0,
      rackitWebAvailability1: rackitWebAvailability1 || null,
      rackitWebAvailability0: rackitWebAvailability0 || null,
      specifications: specifications || [],
      formValid,
      editItem,
      defaultSupplierIndex
    });
  }

  productEditorChange = value => {
    this.setState({ productDescription: value });
  };

  renderInputElements = control => {
    return control.type === 'select' ? (
      <Select
        options={control}
        className={' cursor-pointer'}
        placeholder={control.label}
        key={control.name}
        value={control.value}
        onChange={control.onChange || this.handleInputChange}
        ref={input => {
          control.ref = input;
        }}
      />
    ) : (
      <Input
        key={control.name}
        options={control}
        value={control.value}
        onChange={control.onChange || this.handleInputChange}
        ref={input => {
          control.ref = input;
        }}
      />
    );
  };
  handleInputChange = (name, value) => {
    let { formValid } = this.state;
    if (value && value.isValid !== undefined) {
      formValid[name] = value.isValid;
    }
    this.validate(formValid);
  };
  validate(data) {
    let { isValid } = this.state;
    let valid = Object.keys(data).map(keys => {
      return data[keys];
    });
    let validarr = valid.filter(obj => {
      return obj === false;
    });
    isValid = validarr.length > 0 ? false : true;
    this.setState({ isValid });
  }

  rackitWebAvailabilityTrue = () => {
    this.setState({ rackitWebAvailability1: true });
    this.handleInputChange();
    this.setState({ rackitWebAvailability0: true });
  };
  rackitWebAvailabilityFalse = () => {
    this.setState({ rackitWebAvailability1: false });
    this.setState({ rackitWebAvailability0: false });
  };
  handleShowNumber = () => {
    this.setState({ Dropship1: true });
    const { formValid } = this.state;
    formValid.dropship = true;
    this.handleInputChange();
    this.setState({ Dropship0: false, formValid });
  };
  handleDontShowNumber = () => {
    this.setState({ Dropship1: false });
    const { formValid } = this.state;
    formValid.dropship = true;
    this.handleInputChange();
    this.setState({ Dropship0: true, formValid });
  };
  getAppendedComponents = () => {
    const { specifications } = this.state;
    return specifications && specifications.map((obj, index) => this.handleForm(specifications, index));
  };
  handleSpecsDelete = id => {
    const { specifications } = this.state;
    specifications.splice(id, 1);
    this.setState({ specifications });
  };
  handleAddSpecData = () => {
    const { specifications } = this.state;
    // specifications.push({ spec: '', description: '' });
    if (specifications.length > 0) {
      if (
        specifications[specifications.length - 1].spec !== '' &&
        specifications[specifications.length - 1].description !== ''
      ) {
        specifications.push({ spec: '', description: '' });
      }
    } else {
      specifications.push({ spec: '', description: '' });
    }
  };
  handleOnChangeSpecsData = (name, options) => {
    const { specifications } = this.state;

    if (options.value) {
      let key = options.name.slice(0, -1);
      let index = name.charAt(name.length - 1);
      specifications[index][key] = options.value;
      //this.updateParentState();
    }
  };
  handleForm(specifications, index) {
    return (
      <div className="row" key={index}>
        <div className="col-3 postal-input-width status pr-0">
          {this.renderInputElements({
            name: 'spec' + index,
            label: <FormattedMessage id="create_title" />,
            id: 'title1' + index,
            onChange: this.handleOnChangeSpecsData,
            value: specifications[index].spec,
            autoFocus: specifications.length - 1 === index ? true : false,
          })}
        </div>
        <div className="col-7 product-bar pr-3 postal-input-width">
          {this.renderInputElements({
            name: 'description' + index,
            label: <FormattedMessage id="common_description" />,
            id: 'description1' + index,
            onChange: this.handleOnChangeSpecsData,
            value: specifications[index].description,
          })}{' '}
        </div>
        <div className="col-1 text-center pl-0 pr-5 pt-1">
          <label
            className="close align-items-center mt-3"
            aria-label="Close"
            onClick={() => this.handleSpecsDelete(index)}
          >
            ×
          </label>
        </div>
      </div>
    );
  }
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };
  onBlur = event => {
    this.setState({ enterReseller: true });
  };
  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = async ({ value }) => {
    if (value.length > 3 && !this.state.fetchVendor) {
      this.setState({ fetchVendor: true });
      const response = await this.productService.getAllUsers([['filter', { name: [value] }]]);
      const { data } = response;
      if (data && data.length > 0) {
        this.setState({ notFound: false });
      } else {
        this.setState({ notFound: true });
      }
      this.setState({ vendors: data, fetchVendor: false });
    }
  };
  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      vendors: [],
    });
  };

  handleSuggestionSelected = (event, obj) => {
    let { formValid } = this.state;
    formValid.vendor = true;
    this.validate(formValid);
    this.setState({ selectedVendor: obj.suggestion });
  };

  onRemoveSelection = () => {
    let { formValid } = this.state;
    formValid.vendor = false;
    this.validate(formValid);
    this.setState({ value: '', selectedVendor: '' });
  };
  handlePrivatePrice = () => {
    const { formValid } = this.state;
    if (this.state.isPrivatePClicked) {
      this.setState({ isPrivatePClicked: false });
      this.state.isPublicClicked || this.state.isResellerClicked
        ? (formValid.customerClass = true)
        : (formValid.customerClass = false);
    } else {
      this.setState({ isPrivatePClicked: true });
      formValid.customerClass = true;
    }
    this.handleInputChange();
  };
  handleCompanyPrice = () => {
    const { formValid } = this.state;
    if (this.state.isPublicClicked) {
      this.setState({ isPublicClicked: false });
      this.state.isPrivatePClicked || this.state.isResellerClicked
        ? (formValid.customerClass = true)
        : (formValid.customerClass = false);
    } else {
      this.setState({ isPublicClicked: true });
      formValid.customerClass = true;
    }
    this.handleInputChange();
  };
  handleResellerPrice = () => {
    const { formValid } = this.state;
    if (this.state.isResellerClicked) {
      this.setState({ isResellerClicked: false });
      this.state.isPublicClicked || this.state.isPrivatePClicked
        ? (formValid.customerClass = true)
        : (formValid.customerClass = false);
    } else {
      this.setState({ isResellerClicked: true });
      formValid.customerClass = true;
    }
    this.handleInputChange();
  };
  handleRemoveMedia = async id => {
    const {
      editItem: { inventoryNumber },
      mediaId,
    } = this.state;
    let response;

    let index = mediaId.findIndex(obj => obj.file_id === id);

    if (index > -1) {
      try {
        response = await this.productService.removemediaFile(id, inventoryNumber);
        if (response.status === 200) {
          mediaId.splice(index, 1);
          this.setState(mediaId);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  getMediaCount = () => {
    const { mediaId } = this.state;
    return mediaId.reduce(function(obj, v) {
      obj[v.file_type] = (obj[v.file_type] || 0) + 1;
      return obj;
    }, {});
  };
  handleFiles = async (type, event) => {
    let response,
      arrData = {};
    const {
      mediaId,
      editItem: { inventoryNumber },
    } = this.state;
    const array = this.fileListToArray(event.target.files || event.dataTransfer.files);
    let imageCount = this.getMediaCount();
    if ((imageCount[type] === undefined || imageCount[type] < 3) && array) {
      try {
        response = await this.productService.uploadFile(array[0], inventoryNumber, type);
        let {
          data: {
            data: { id, name },
          },
        } = response;
        arrData.file_id = id;
        arrData.file_type =
          type === Constants.FileTypes.DOCUMENT ? Constants.FileTypes.DOCS : Constants.FileTypes.IMAGE;
        arrData.file_name = name;
        mediaId.push(arrData);
        this.setState(mediaId);
      } catch (error) {
        console.log(error);
      }
    }
  };
  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }
  renderSelectedMedia = (type, data) => {
    let typesKey = groupByKey(data, 'file_type');
    let icon = type === Constants.FileTypes.IMAGE ? Constants.icons.image : Constants.icons.document;
    if (typesKey[type]) {
      return typesKey[type].map(obj => {
        return (
          <div className="w-100 border-bottom-light pl-1 pb-2 add-products-media-preview" key={obj.file_id}>
            <img src={icon} width="18" height="18" alt="" />
            <span> {obj.file_name}</span>
            <span className="pb-1">
              <button className="close" aria-label="Close" onClick={this.handleRemoveMedia.bind(this, obj.file_id)}>
                &times;
              </button>
            </span>
          </div>
        );
      });
    }
  };

  handleUpdateProducts = async () => {
    const {
      selectedVendor,
      productDescription,
      isPrivatePClicked,
      isPublicClicked,
      isResellerClicked,
      specifications,
      Dropship1,
      rackitWebAvailability1,
      editItem,
      defaultSupplierIndex
    } = this.state;
    const {
      controls: {
        addProduct,
        addPrice,
        addItem,
        addEl,
        addPrivatePrice,
        addResellerPrice,
        addCompanyPrice,
        addStatus,
        addItemClass,
        installationTime
      },
      productResponse,
    } = this.props;
    let data = {};
    this.props.controls.addProduct.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    this.props.controls.addPrice.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    this.props.controls.addStatus.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    this.props.controls.addItem.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    if (!this.state.formValid.vendor) {
      this.setState({ enterReseller: true });
    }
    //trigger State ToCall InputComponent;
    this.setState({ triigerState: true });
    if (this.state.isValid) {
      data = {
        inventoryNumber: addItem[0].value,
        description: addProduct[0].value,
        status: addStatus[0].value,
        itemClass: addItemClass[0].value,
        defaultPrice: Number(addPrice[0].value),
        vendor: selectedVendor.id,
        productBrief: productDescription,
        dropship: Dropship1 === true ? true : false,
        elNumber: addEl[0].value,
        installationTime: installationTime[0].value ? Number(installationTime[0].value) : 0.0,
        priceClass: [
          {
            customerClass: '1',
            availability: isPrivatePClicked,
            price: this.state.isPrivatePClicked ? Number(addPrivatePrice[0].value) : null,
          },
          {
            customerClass: '4',
            availability: isPublicClicked,
            price: this.state.isPublicClicked ? Number(addCompanyPrice[0].value) : null,
          },
          {
            customerClass: '5',
            availability: isResellerClicked,
            price: this.state.isResellerClicked ? Number(addResellerPrice[0].value) : null,
          },
        ],
        specifications: specifications,
        rackitWebAvailability: rackitWebAvailability1 === true ? true : false,
      };
      if (editItem.supplierDetails.length > 0 && editItem.supplierDetails[defaultSupplierIndex].supplierId === selectedVendor.id) {
        data['vendor'] = editItem.supplierDetails[defaultSupplierIndex].supplierId;
      }

      try {
        this.setState({ isValid: false });
        let response = await this.productService.updateProductDetails(data);

        if (response.status === 200) {
          productResponse({
            type: Constants.toast.SUCCESS,
            message: <FormattedMessage id="toast_product_updated" />,
            delay: Constants.toast.DELAY,
          });
          this.setState({ newProductSubmitted: true });
          window.location.reload();
        } else {
          productResponse({
            type: Constants.toast.ERROR,
            message: <FormattedMessage id="toast_product_unable_update" />,
            delay: Constants.toast.DELAY,
          });
          this.setState({ productFailed: true });
        }
        this.closeElement.click();
      } catch (error) {
        if (error.message) {
          productResponse({
            type: Constants.toast.ERROR,
            message: <FormattedMessage id="toast_product_unable_update" />,
            delay: Constants.toast.DELAY,
          });
          this.closeElement.click();
        }
        this.setState({ productFailed: true });
      }
    }
  };
  handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDropImage = event => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      //this.setState({ file: event });
      this.handleFiles(Constants.FileTypes.IMAGE, event);
    }
  };
  handleDropDocument = event => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      //this.setState({ file: event });
      this.handleFiles(Constants.FileTypes.DOCUMENT, event);
    }
  };
  render() {
    const { loading, editProdutsModalClose, controls } = this.props;

    const {
      selectedVendor,
      vendors,
      value,
      mediaId,
      productDescription,
      enterReseller,
      isPrivatePClicked,
      isPublicClicked,
      isResellerClicked,
      Dropship1,
      Dropship0,
      rackitWebAvailability1,
      rackitWebAvailability0,
      specifications,
      isValid,
      isSubmitted,
      formValid,
    } = this.state;

    const inputProps = {
      placeholder: Constants.language.create_select_vendor,
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
    };
    const options = vendors.slice(0, 20).map(vendor => {
      return { id: vendor.number, name: vendor.name };
    });

    return (
      <div
        className="modal fade bd-example-modal-lg fade-products"
        tabIndex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="editproductmodal"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-lg modal-product">
          <div className="modal-content contracts-modal">
            <div className="modal-header modal-header-product">
              <h3 className="modal-title font-weight-semibold" id="myLargeModalLabel">
                <FormattedMessage id="create_edit_product" />
              </h3>
              <button
                type="button"
                className="close outline-0"
                data-dismiss="modal"
                aria-label="Close"
                onClick={editProdutsModalClose}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-4  my-2 line-height-normal ls-normal">
              <LoadingOverlay
                active={loading}
                spinner={<BounceLoader />}
                text={<FormattedMessage id="common_loading_overlay" />}
                classNamePrefix={'light_modal_ '}
                className={'loader-list-view-common'}
              >
                <div className="row">
                  <div className="col">
                    <div className="row justify-content-between mb-4 pb-2 ">
                      <div className="col-12">
                        <label className="product-heading mb-0">
                          <FormattedMessage id="create_product" />
                        </label>
                        <div className="row mt-0 w-100 ">
                          <div className="col-7 product-bar pr-3 postal-input-width">
                            {' '}
                            {controls.addProduct.map(cont => this.renderInputElements(cont))}
                          </div>

                          <div className=" col-3 mt-2 pt-1 pl-0 cursor-pointer">
                            {controls.addStatus.map(control => this.renderInputElements(control))}
                          </div>
                        </div>
                        <div className="row w-100">
                          <div className="col-7 product-bar pr-3 postal-input-width">
                            {controls.addItem.map(control => this.renderInputElements(control))}
                          </div>
                          <div className=" postal-input-width status">
                            {controls.addEl.map(control => this.renderInputElements(control))}
                          </div>
                        </div>
                        <label className="product-heading mb-0">
                          <FormattedMessage id="create_default_price" />
                        </label>
                        <div className="row  status ml-0 p-0">
                          {controls.addPrice.map(control => this.renderInputElements(control))}
                        </div>
                        <label className="product-heading mb-0">
                          <FormattedMessage id="create_installation_time" />
                        </label>
                        <div className="row status ml-0 p-0">
                          {controls.installationTime.map(control => this.renderInputElements(control))}
                        </div>
                        <label className="product-heading mb-2">
                          <FormattedMessage id="create_customer_class" />
                        </label>
                        <label className="d-flex align-items-center mb-2 pb-1">
                          <CheckBox
                            isSelected={isPrivatePClicked}
                            onClick={this.handlePrivatePrice}
                            onChange={this.handlePrivatePrice}
                            label={<FormattedMessage id="create_consumer" />}
                            className={'space-remove'}
                          />
                        </label>
                        {isPrivatePClicked && (
                          <div className="row col-3 pb-1 space-remove">
                            {controls.addPrivatePrice.map(control => this.renderInputElements(control))}
                            <div>
                              <p className="col optional pl-2 space-remove">
                                <FormattedMessage id="create_optional" />
                              </p>
                            </div>
                          </div>
                        )}
                        <label className="d-flex align-items-center mb-2 pb-1">
                          <CheckBox
                            isSelected={isPublicClicked}
                            onClick={this.handleCompanyPrice}
                            onChange={this.handleCompanyPrice}
                            label={<FormattedMessage id="create_company" />}
                          />
                        </label>
                        {isPublicClicked && (
                          <div className="row col-3 space-remove">
                            {controls.addCompanyPrice.map(control => this.renderInputElements(control))}
                            <div>
                              <p className="col optional pl-2 space-remove">
                                <FormattedMessage id="create_optional" />
                              </p>
                            </div>
                          </div>
                        )}
                        <label className="d-flex align-items-center mb-0">
                          <CheckBox
                            isSelected={isResellerClicked}
                            onClick={this.handleResellerPrice}
                            onChange={this.handleResellerPrice}
                            label={<FormattedMessage id="all_contracts_reseller" />}
                          />
                        </label>
                        {isResellerClicked && (
                          <div className="row col-3 space-remove">
                            {controls.addResellerPrice.map(control => this.renderInputElements(control))}
                            <div>
                              <p className="col optional pl-2 space-remove">
                                <FormattedMessage id="create_optional" />
                              </p>
                            </div>
                          </div>
                        )}
                        {!isPrivatePClicked && !isPublicClicked && !isResellerClicked && (
                          <div className="text-danger error-message small mt-2">
                            <FormattedMessage id="create_product_customer_class_error" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-5 pb-2">
                      <div className="col">
                        <label className="product-heading">
                          <FormattedMessage id="all_products_product_snapshot" />
                        </label>
                        <ReactQuill
                          className="product-quill w-634-max"
                          theme="snow"
                          value={productDescription}
                          modules={this.quillModules}
                          onChange={this.productEditorChange}
                        />
                      </div>
                    </div>

                    <label className="product-heading mb-2">
                      <FormattedMessage id="create_technical_data" />
                    </label>
                    <div className="pb-2 mb-4 cursor-pointer">
                      {specifications.length > 0 && this.getAppendedComponents()}
                      <a
                        href="#"
                        onClick={this.handleAddSpecData}
                        className={specifications.length > 0 ? 'col-10 d-flex justify-content-end pr-2' : ''}
                      >
                        <u>
                          + <FormattedMessage id="create_add_new_table" />
                        </u>
                      </a>
                    </div>
                    <label className="product-heading">
                      <FormattedMessage id="create_images" />
                    </label>
                    <div className="row ml-0">
                      <label
                        className="col-3 document-upload ml-0"
                        onDrop={e => this.handleDropImage(e)}
                        onDragOver={e => this.handleDragOver(e)}
                        onDragEnter={e => this.handleDragEnter(e)}
                        onDragLeave={e => this.handleDragLeave(e)}
                      >
                        <p className="fs-14 my-4 pcolor mb-0 text-center line-height-normal ls-normal ">
                          <span className="plus-upload1">+</span>
                          <br />
                          <FormattedMessage id="create_drag_and_drop_image" />
                        </p>
                        <input
                          accept={
                            Constants.inputAcceptImageTypes // single={"true"}
                          }
                          onClick={this.openFileDialog}
                          onChange={this.handleFiles.bind(this, Constants.FileTypes.IMAGE)}
                          className="FileInput"
                          type="file"
                        />
                      </label>
                      <div className="col-8  ml-1 mb-4 pr-5">
                        {this.renderSelectedMedia(Constants.FileTypes.IMAGE, mediaId)}
                      </div>
                    </div>
                    <label className="product-heading">
                      <FormattedMessage id="create_documents" />
                    </label>
                    <div className="row ml-0 pb-2">
                      <label
                        className="col-3 document-upload mb-4 pl-0 pr-0"
                        onDrop={e => this.handleDropDocument(e)}
                        onDragOver={e => this.handleDragOver(e)}
                        onDragEnter={e => this.handleDragEnter(e)}
                        onDragLeave={e => this.handleDragLeave(e)}
                      >
                        <p className=" my-4 mb-0 pcolor text-center line-height-normal ls-normal">
                          <span className="plus-upload1">+</span>
                          <br />
                          <FormattedMessage id="create_drag_and_drop_document1" />
                          <br />
                          <FormattedMessage id="create_drag_and_drop_document2" />
                        </p>

                        <input
                          accept={
                            Constants.inputAcceptDocumentTypes // single={"true"}
                          }
                          onClick={this.openFileDialog}
                          onChange={this.handleFiles.bind(this, Constants.FileTypes.DOCUMENT)}
                          className="FileInput"
                          type="file"
                        />
                      </label>
                      <div className="col-8  ml-1 mb-4 pr-5">
                        {this.renderSelectedMedia(Constants.FileTypes.DOCS, mediaId)}
                      </div>
                    </div>

                    <label className="product-heading">
                      <FormattedMessage id="create_category" />
                    </label>
                    <div className="mb-4 row">
                      <div className="col-6 ml-3 pl-0 pr-0 w-378-max">
                        {controls.addItemClass.map(control => this.renderInputElements(control))}
                      </div>
                    </div>
                    <label className="product-heading mb-0">
                      <FormattedMessage id="create_dropship" />
                    </label>
                    <div className="row col-6 mt-2">
                      <RadioButtonComponent
                        checked={Dropship1}
                        onClick={this.handleShowNumber}
                        onChange={this.handleShowNumber}
                        label={<FormattedMessage id="create_yes" />}
                      />
                    </div>
                    <div className="row col-6 mb-0 pb-2 mt-2 pt-1">
                      <RadioButtonComponent
                        checked={Dropship0}
                        onClick={this.handleDontShowNumber}
                        onChange={this.handleDontShowNumber}
                        label={<FormattedMessage id="create_no" />}
                      />{' '}
                    </div>
                    {isSubmitted && !formValid.dropship && (
                      <div className="text-danger error-message small mt-2">
                        <FormattedMessage id="create_product_dropship_error" />
                      </div>
                    )}

                    <label className="product-heading mb-2 mt-4">
                      <FormattedMessage id="create_vendor" />
                    </label>
                    <div className="mb-4 row">
                      <div className="col-6 ml-3 pl-0 pr-0 searchvendor">
                        {!selectedVendor && (
                          <div
                            id="search-box"
                            className="input-group border-vendor vendor-color bg-green pl-4 w-378-max"
                          >
                            <div className="form-control border-0 pt-2 pl-0">
                              <Autosuggest
                                suggestions={options}
                                onSuggestionSelected={this.handleSuggestionSelected}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={option => option.name}
                                renderSuggestion={suggestion => (
                                  <div>
                                    {suggestion.name} - #{suggestion.id}
                                  </div>
                                )}
                                inputProps={inputProps}
                              />
                            </div>
                            <div className="search-icon">
                              <button
                                className="btn py-2 mr-1 z-index-4 bg-transparent bg-color-inherit h-100"
                                type="button"
                              >
                                {this.state.fetchVendor && (
                                  <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only">
                                      <FormattedMessage id="common_loading_overlay" />
                                    </span>
                                  </div>
                                )}
                                {!this.state.fetchVendor ? (
                                  value.length > 0 ? (
                                    <span className="float-right cursor-pointer" onClick={this.onRemoveSelection}>
                                      <img
                                        className="mr-2 cursor-pointer"
                                        src={Constants.icons.crossNew1}
                                        width="14"
                                        height="14"
                                        alt="trash-icon"
                                      />
                                    </span>
                                  ) : (
                                    <img src={Constants.icons.search} width="18" height="18" alt="search-icon" />
                                  )
                                ) : (
                                  ''
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                        {enterReseller && !this.state.notFound && (
                          <div className="text-danger error-message small customer-suggest-error mt-1">
                            <FormattedMessage id="warning_vendor" />
                          </div>
                        )}
                        {this.state.notFound && (
                          <div className="text-danger error-message small customer-suggest-error mt-1">
                            <FormattedMessage id="vendor_search_error" />
                          </div>
                        )}
                        {selectedVendor && (
                          <div id="selectedVendor" className="input-group mb-0 w-378-max">
                            <div
                              className="pl-0 pr-0 border-vendor vendor-color bg-green pl-3"
                              title={selectedVendor.name + ', ' + selectedVendor.id}
                            >
                              {[charLimit.charLimit(selectedVendor.name, 12), selectedVendor.id].join(',  ')}
                              <span className="float-right cursor-pointer px-1" onClick={this.onRemoveSelection}>
                                <img
                                  className="mr-2 cursor-pointer "
                                  src={Constants.icons.crossNew1}
                                  width="14"
                                  height="14"
                                  alt="trash-icon"
                                />
                              </span>
                            </div>
                          </div>
                        )}
                        {!options && (
                          <div className="text-danger error-message small customer-suggest-error ml-2">
                            <span>
                              <FormattedMessage id="vendor_search_error" />
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    <label className="product-heading mb-2">
                      <FormattedMessage id="create_show_on_rackit_no" />
                    </label>
                    <div className="row col-6 mb-2 pb-1">
                      <RadioButtonComponent
                        checked={rackitWebAvailability1}
                        onClick={this.rackitWebAvailabilityTrue}
                        onChange={this.rackitWebAvailabilityTrue}
                        label={<FormattedMessage id="create_yes" />}
                      />
                    </div>
                    <div className="row col-6 mb-4 pb-2">
                      <RadioButtonComponent
                        checked={!rackitWebAvailability0}
                        onClick={this.rackitWebAvailabilityFalse}
                        onChange={this.rackitWebAvailabilityFalse}
                        label={<FormattedMessage id="create_no" />}
                      />
                    </div>
                    <div className="">
                      <div className="d-inline-block mr-1">
                        <button
                          className={isValid ? 'btn-primary btn-pro' : 'btn-pro'}
                          onClick={this.handleUpdateProducts}
                        >
                          {' '}
                          <FormattedMessage id="create_save" />
                        </button>
                      </div>
                      <div className="d-inline-block ml-5 pl-5">
                        <a
                          href="#table"
                          className="font-weight-bold black"
                          onClick={editProdutsModalClose}
                          data-dismiss="modal"
                          ref={closeModal => (this.closeElement = closeModal)}
                        >
                          <u>
                            <FormattedMessage id="create_cancel" />
                          </u>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
EditProductForm.propTypes = {};
