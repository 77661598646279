import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Constants from '../../../../shared/constants';

const CustomerListViewHeader = ({ onSort, sortAscending, sortedBy }) => {
  return (
    <React.Fragment>
      <div className="row pb-2 table-header fs-14">
        <div className="col-3 pl-2 cursor-pointer" onClick={onSort.bind(this, 'name')}>
          <u className={sortedBy === 'name' ? 'font-weight-semibold' : 'font-weight-normal'}>
            <FormattedMessage id="customer_head_1" />
          </u>
          <img
            src={Constants.icons.arrowBack}
            width="18px"
            height="18px"
            alt="icon"
            className={sortedBy === 'name' ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'}
          />
        </div>
        <div className="col-2 cursor-pointer" onClick={onSort.bind(this, 'id')}>
          <u className={sortedBy === 'id' ? 'font-weight-semibold' : 'font-weight-normal'}>
            <FormattedMessage id="common_customer_ID" />
          </u>
          <img
            src={Constants.icons.arrowBack}
            width="18px"
            height="18px"
            alt="icon"
            className={sortedBy === 'id' ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'}
          />
        </div>
        <div className="col-3 cursor-pointer" onClick={onSort.bind(this, 'reseller')}>
          <u className={sortedBy === 'reseller' ? 'font-weight-semibold' : 'font-weight-normal'}>
            <FormattedMessage id="all_contracts_reseller" />
          </u>
          <img
            src={Constants.icons.arrowBack}
            width="18px"
            height="18px"
            alt="icon"
            className={sortedBy === 'reseller' ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'}
          />
        </div>
        <div className="col-2 cursor-pointer pl-0" onClick={onSort.bind(this, 'type')}>
          <u className={sortedBy === 'type' ? 'font-weight-semibold' : 'font-weight-normal'}>
            <FormattedMessage id="all_orders_type" />
          </u>
          <img
            src={Constants.icons.arrowBack}
            width="18px"
            height="18px"
            alt="icon"
            className={sortedBy === 'type' ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'}
          />
        </div>
        <div className="col-2 cursor-pointer pr-0" onClick={onSort.bind(this, 'date')}>
          <u className={sortedBy === 'date' ? 'font-weight-semibold' : 'font-weight-normal'}>
            <FormattedMessage id="customer_head_5" />
          </u>
          <img
            src={Constants.icons.arrowBack}
            width="18px"
            height="18px"
            alt="icon"
            className={sortedBy === 'date' ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

CustomerListViewHeader.propTypes = {
  onSort: PropTypes.func,
};

export default CustomerListViewHeader;
