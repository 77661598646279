import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';

import PostalInput from '../PostalInput/index';
import Input from '../Input/index';
import FileUpload from '../FileUpload/index';
import Constants from '../../shared/constants';
import RadioButtonComponent from '../RadioButton';

const AddServiceForm = ({
  controls,
  isAddOnClicked,
  onAddOn,
  onCreateNewService,
  newSubscriptionFileChange,
  newSubscriptionEditorChange,
  onSelectParentService,
  newServiceDescription,
  newServiceSubmitted,
  isValid,
  serviceFailed,
  mediaArray,
  parentServicesOptions,
  quillModules,
}) => {
  const renderInputElements = control =>
    control.name === 'postalCode' ? (
      <PostalInput
        key={control.name}
        options={control}
        onChange={control.onChange}
        ref={input => {
          control.ref = input;
        }}
      />
    ) : (
      <Input
        key={control.name}
        options={control}
        onChange={control.onChange}
        ref={input => {
          control.ref = input;
        }}
      />
    );
  let item = {};
  item.media = mediaArray;
  return (
    <>
      {newServiceSubmitted ? (
        <p className="text-center">
          <FormattedMessage id="create_service_success_message" />
        </p>
      ) : (
        <>
          <div className="row justify-content-between mb-3 ">
            <div className="col-6">
              {controls.addServices.map(control => renderInputElements(control))}
              <div className="row align-items-center">
                <div className="col-6">
                  <RadioButtonComponent
                    checked={isAddOnClicked}
                    onChange={onAddOn}
                    order={true}
                    label={<FormattedMessage id="create_service_add_on" />}
                  />
                </div>

                {isAddOnClicked && (
                  <div className="col">
                    <Select
                      options={parentServicesOptions}
                      className={'parent-select-dropdown cursor-pointer'}
                      classNamePrefix={'parent-service'}
                      onChange={value => onSelectParentService(value)}
                      defaultValue={parentServicesOptions && parentServicesOptions[0]}
                      placeholder={''}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-6">
              <FileUpload
                disabled={false}
                onFilesAdded={file => newSubscriptionFileChange(file)}
                multiple={false}
                item={item}
                fileType={Constants.FileTypes.IMAGE}
                editIcon={Constants.icons.translateBlack}
                editIconWhite={Constants.icons.translate}
                trash={Constants.icons.trash}
                rackitLogoBlackBG={Constants.rackitLogoBlackBG}
              />
            </div>
          </div>
          <div className="row mb-5">
            <div className="col">
              <ReactQuill
                theme="snow"
                value={newServiceDescription}
                onChange={value => newSubscriptionEditorChange(value)}
                modules={quillModules}
                placeholder={Constants.language.all_products_contract_text_edit}
              />
            </div>
          </div>
          <div className="">
            <div className="d-inline-block">
              <button className="btn btn-dark btn-sm" disabled={!isValid} onClick={() => onCreateNewService()}>
                {' '}
                <FormattedMessage id="create_save" />
              </button>
            </div>
          </div>

          <div className="d-inline-block">
            {serviceFailed && <span className="text-danger pr-5">Something wrong...</span>}
          </div>
        </>
      )}
    </>
  );
};

export default AddServiceForm;
