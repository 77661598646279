import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_no from 'react-intl/locale-data/no';
import messages_en from './translations/en.json';
import messages_no from './translations/no.json';
import Config from './config';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import configureMarkerIO from "./configureMarkerIO";

//css
import './styles/main.css';
import './styles/custom.css';
import 'font-awesome/css/font-awesome.min.css';

//Components
import Constants from './shared/constants';
import Signin from './pages/Signin';
import ForgotPassword from './pages/Signin/forgotPassword';
import ResetPassword from './pages/Signin/resetPassword';
import Products from './pages/Products';
import Resellers from './pages/Resellers';
import Customers from './pages/Customers';
import Services from './pages/Services';
import ResellerDetailView from './components/Resellers/ResellerDetailView';
import CustomerDetailView from './components/Customers/Components/DetailView/CustomerDetailView';
import Orders from './pages/Orders';
import Proposals from './pages/Proposals';
import Container from './components/Container';
import NoMatch from './pages/NoMatch';
import Users from './pages/Users';

//admin components
import Dashboard from './pages/Dashboard';
import NewReseller from './pages/NewReseller';
import ChooseCustomer from './components/NewOrder/ChooseCustomer';
import { store } from './state/store';
import PrepareOrder from './components/NewOrder/PrepareOrder';
import ConfirmOrder from './components/NewOrder/ConfirmOrder';
import ThankYouOrder from './components/NewOrder/ThankyouOrder';
import CreateNewReseller from './components/NewReseller/CreateNewReseller';
import ConnectExistingCustomer from './components/NewReseller/ConnectExistingCustomer';
import NewPassword from './components/NewReseller/NewPassword';
import PrivateRoute from './shared/PrivateRoute';
import ContractDetails from './components/ContractDetails/ContractDetails.jsx';
import AddNewServiceContract from './components/ContractDetails/AddNewServiceContract';
import CustomerDetailViewTabContent from './components/Customers/Components/Tabs/CustomerDetailViewTabContent.jsx';

//translate
addLocaleData([...locale_en, ...locale_no]);

const messages = {
  en: messages_en,
  no: messages_no,
};

configureMarkerIO();

const language = Config.language;

//const store = configureStore();
const App = () => (
  <Router>
    <Provider store={store}>
      <Container>
        <Switch>
          <PrivateRoute exact path={Constants.routes.home.url} component={Signin} />
          <Route exact path={Constants.routes.forgotPassword} component={ForgotPassword} />
          <Route exact path={Constants.routes.resetPassword} component={ResetPassword} />
          <Route exact path={Constants.routes.newPassword} component={NewPassword} />

          <PrivateRoute exact path={Constants.routes.dashboard.url} component={Dashboard} />
          <PrivateRoute exact path={Constants.routes.products} component={Products} />
          <PrivateRoute exact path={Constants.routes.services} component={Services} />
          <PrivateRoute exact path={Constants.routes.orders} component={Orders} />
          <PrivateRoute exact path={Constants.routes.proposals} component={Proposals} />
          <PrivateRoute exact path={Constants.routes.contracts} component={Orders} />
          <PrivateRoute exact path={Constants.routes.contracts + '/:id'} component={ContractDetails} />
          <PrivateRoute
            exact
            path={Constants.routes.contracts + '/:id/add-new-service'}
            component={AddNewServiceContract}
          />
          <PrivateRoute
            exact
            path={Constants.routes.resellers + '/contracts' + '/:id'}
            component={CustomerDetailViewTabContent}
          />
          <PrivateRoute exact path={Constants.routes.myOrders} component={Orders} />
          <PrivateRoute exact path={Constants.routes.myProposals} component={Proposals} />
          <PrivateRoute exact path={Constants.routes.resellers} component={Resellers} />
          <PrivateRoute exact path={Constants.routes.customers} component={Customers} />
          <PrivateRoute exact path={Constants.routes.users} component={Users} />
          <PrivateRoute exact path={Constants.routes.customers + '/:name'} component={CustomerDetailView} />
          <PrivateRoute exact path={Constants.routes.resellers + '/:name'} component={ResellerDetailView} />
          <PrivateRoute exact path={Constants.routes.newOrderChooseCustomerProduct} component={ChooseCustomer} />
          <PrivateRoute exact path={Constants.routes.newOrderChooseCustomerService} component={ChooseCustomer} />
          <PrivateRoute exact path={Constants.routes.newProposalChooseCustomerProduct} component={ChooseCustomer} />
          <PrivateRoute exact path={Constants.routes.newProposalChooseCustomerService} component={ChooseCustomer} />
          <PrivateRoute exact path={Constants.routes.newProductPrepareOrder} component={PrepareOrder} />
          <PrivateRoute exact path={Constants.routes.newServicePrepareOrder} component={PrepareOrder} />
          <PrivateRoute exact path={Constants.routes.newProductPrepareProposal} component={PrepareOrder} />
          <PrivateRoute exact path={Constants.routes.newServicePrepareProposal} component={PrepareOrder} />
          <PrivateRoute exact path={Constants.routes.newOrderConfirmOrderProducts} component={ConfirmOrder} />
          <PrivateRoute exact path={Constants.routes.newOrderConfirmOrderServices} component={ConfirmOrder} />
          <PrivateRoute exact path={Constants.routes.newProposalConfirmProposalProducts} component={ConfirmOrder} />
          <PrivateRoute exact path={Constants.routes.newProposalConfirmProposalServices} component={ConfirmOrder} />
          <PrivateRoute exact path={Constants.routes.newOrderThankyouOrder} component={ThankYouOrder} />
          <PrivateRoute exact path={Constants.routes.newOrderThankyouOrder + '/:id'} component={ThankYouOrder} />
          <PrivateRoute exact path={Constants.routes.newProposalThankyou} component={ThankYouOrder} />
          <PrivateRoute exact path={Constants.routes.newReseller} component={NewReseller} />

          <PrivateRoute exact path={Constants.routes.createNewReseller} component={CreateNewReseller} />
          <PrivateRoute exact path={Constants.routes.connectExistingReseller} component={ConnectExistingCustomer} />
          <PrivateRoute exact path={Constants.routes.thankYouOrder} component={ThankYouOrder} />

          <Route component={NoMatch} />
        </Switch>
      </Container>
    </Provider>
  </Router>
);
ReactDOM.render(
  <IntlProvider locale={language} messages={messages[language]}>
    <App />
  </IntlProvider>,
  document.getElementById('root')
);
serviceWorker.unregister();

if (window.addEventListener) {
  window.addEventListener(
    'storage',
    () => {
      const userData = localStorage.getItem('userData');
      if (!userData) {
        window.location.reload();
      }
    },
    false
  );
}
