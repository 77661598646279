import React from 'react';
import AuthService from '../../../../services/AuthService';
import { FormattedMessage } from 'react-intl';
import PropType from 'prop-types';
const CustomerDetailViewTab = ({ currentTab, reseller, customer, tab, resellerloggedin }) => {
  const customerDetailsNavItems = ['Details', 'Orders', 'Subscriptions', 'Invoices', 'Log'];
  const resellerDetailsNavItems = ['Details', 'Orders', 'Subscriptions', 'Invoices', 'Customers', 'Users', 'Log'];
  return (
    <>
      <React.Fragment>
        {/* <div className="col-12 customer-tab p-0"> */}
        {customer && (
          <div className="col-12 ri-tab-header mb-1 p-0">
            <ul className="nav nav-tabs  pb-1 border-bottom-light" role="tablist">
              {customerDetailsNavItems.map((obj, index) => (
                <li className="nav-item text-left " key={index}>
                  <a
                    href="#"
                    id="21"
                    className={
                      'd-inline nav-link pt-4 pb-1 mx-4 font-weight-normal contract-details-tab-head fs-16 p-0 black ' +
                      (tab === obj ? 'active font-weight-semibold' : '')
                    }
                    data-toogle="tab"
                    role="tab"
                    onClick={currentTab.bind(this, obj)}
                  >
                    {obj === 'Details' ? (
                      <FormattedMessage id="customer_nav_tab_1" />
                    ) : obj === 'Orders' ? (
                      <FormattedMessage id="common_orders" />
                    ) : obj === 'Subscriptions' && !resellerloggedin ? (
                      <FormattedMessage id="bc_contracts" />
                    ) : obj === 'Invoices' && !resellerloggedin ? (
                      <FormattedMessage id="customer_nav_tab_6" />
                    ) : obj === 'Log' && !resellerloggedin ? (
                      <FormattedMessage id="customer_nav_tab_7" />
                    ) : (
                      ''
                    )}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {reseller && (
          <div className="col-12 ri-tab-header mb-1 p-0">
            <ul className="nav nav-tabs  pb-1 border-bottom-light" role="tablist">
              {resellerDetailsNavItems.map((obj, index) => ((obj==='Subscriptions'||obj==='Invoices')&& resellerloggedin?'':
                <li className="nav-item text-left" key={index}>
                  <a
                    href="#reseller-03"
                    id="03"
                    className={
                      'd-inline nav-link pt-4 pb-1 mx-4 font-weight-normal contract-details-tab-head fs-16 p-0 black ' +
                      (tab === obj ? 'active font-weight-semibold' : '')
                    }
                    data-toogle="tab"
                    role="tab"
                    onClick={currentTab.bind(this, obj)}
                  >
                    {obj === 'Details' ? (
                      <FormattedMessage id="customer_nav_tab_1" />
                    ) : obj === 'Orders' ? (
                      <FormattedMessage id="common_orders" />
                    ) : obj === 'Subscriptions' && !resellerloggedin ? (
                      <FormattedMessage id="bc_contracts" />
                    ) : obj === 'Invoices' && !resellerloggedin ? (
                      <FormattedMessage id="customer_nav_tab_6" />
                    ) : obj === 'Customers' ? (
                      <FormattedMessage id="bc_customers" />
                    ) : obj === 'Users' ? (
                      <FormattedMessage id="sidemenu_users" />
                    ) : obj === 'Log' ? (
                      <FormattedMessage id="customer_nav_tab_7" />
                    ) : (
                      ''
                    )}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {/* </div> */}
      </React.Fragment>
    </>
  );
};
CustomerDetailViewTab.propTypes = {
  currentTab: PropType.func,
};
export default CustomerDetailViewTab;
