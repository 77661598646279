import React from 'react';
import { connect } from 'react-redux';
import { contractModalDataItems, contractModalTypeSelection } from '../../../state/actions/ContractActions';
import RenderServiceCardElements from './RenderServiceCardElements';
import Constants from '../../../shared/constants';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ContractServicesCard = ({
  data,
  chooseService,
  onItemClick,
  changePriceOfService,
  onClickActionButtons,
  isSuperAdmin,
  isAdminSales,
  isAdminBackOffice,
  //To update the current selected price in parent class
  onPriceChange,
  contractModalTypeSelection,
  contractModalDataItems,
  openButtonMenu,
  handleOpenButtonMenu,
}) => {
  return (
    <div className={'card mx-3 mb-3 service-holder col-12 p-4'}>
      {
        <RenderServiceCardElements
          type={Constants.contractModalType.parent}
          data={data}
          chooseService={chooseService}
          changePriceOfService={changePriceOfService}
          onPriceChange={onPriceChange}
          isSuperAdmin={isSuperAdmin}
          isAdminSales={isAdminSales}
          isAdminBackOffice={isAdminBackOffice}
          onClickActionButtons={onClickActionButtons}
          onItemClick={onItemClick}
          contractModalTypeSelection={contractModalTypeSelection}
          contractModalDataItems={contractModalDataItems}
          openButtonMenu={openButtonMenu}
          handleOpenButtonMenu={handleOpenButtonMenu}
          key={Math.random() * 100}
        />
      }
      {data.childrens && (
        <div className={'shadow-none services bg-white'}>
          <div class="mt-4 pt-2 pl-2 ml-2 mb-1">
            <h3 class="fs-18 mb-0 ">
              <FormattedMessage id="contract_service_add_on" />
            </h3>
          </div>
        </div>
      )}
      {data.childrens &&
        data.childrens.map(child => (
          <RenderServiceCardElements
            key={Math.random() * 100}
            type={Constants.contractModalType.child}
            data={child}
            parentData={data}
            chooseService={chooseService}
            changePriceOfService={changePriceOfService}
            onPriceChange={onPriceChange}
            isSuperAdmin={isSuperAdmin}
            isAdminSales={isAdminSales}
            isAdminBackOffice={isAdminBackOffice}
            onClickActionButtons={onClickActionButtons}
            onItemClick={onItemClick}
            contractModalTypeSelection={contractModalTypeSelection}
            contractModalDataItems={contractModalDataItems}
            openButtonMenu={openButtonMenu}
            handleOpenButtonMenu={handleOpenButtonMenu}
          />
        ))}
    </div>
  );
};

ContractServicesCard.propTypes = {
  data: PropTypes.object,
  chooseService: PropTypes.bool,
  onItemClick: PropTypes.func,
  changePriceOfService: PropTypes.func,
  onClickActionButtons: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
  isAdminSales: PropTypes.bool,
  isAdminBackOffice: PropTypes.bool,
  onPriceChange: PropTypes.func,
  contractModalTypeSelection: PropTypes.func,
  contractModalDataItems: PropTypes.func,
};

const mapStateToProps = state => ({
  contract: state.contract,
});

export default connect(
  mapStateToProps,
  {
    contractModalDataItems,
    contractModalTypeSelection,
  }
)(ContractServicesCard);
