import validator from "validator";

class Control {
  name;
  label;
  type;
  rule;
  value = "";
  defaultValue = "";
  isValid;
  onBlur;
  onFocus;

  constructor(args) {
    Object.assign(this, args);
  }

  setValue(value) {
    this.value = value;
    this.isValid = this.validate(this.value, this.rule);
  }

  validate(value, rule) {
    if (value !== undefined && rule) {
      const args = rule.args || [];
      return rule.presence || !validator.isEmpty(value)
        ? rule.method(value, ...args) === rule.validWhen
        : true;
    }
  }

  checkValidity() {
    return this.validate(this.value, this.rule);
  }
}

class ValidationRule {
  method; // https://www.npmjs.com/package/validator
  args;
  message;
  presence = true;

  constructor(args) {
    Object.assign(this, args);
  }
}

export { Control, ValidationRule };
