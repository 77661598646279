import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthService from '../services/AuthService';
import Constants from './constants';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authService = new AuthService();

  return (
    <Route
      {...rest}
      render={props => {
        return (authService.isAuthenticated() === true) | (props.location.pathname === '/') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: Constants.routes.home.url,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
