import React from "react";
import PropTypes from "prop-types";

const CounterChange = ({
  onDecrease,
  value,
  onChangeValue,
  onBlurValue,
  onKeyPressValue,
  onIncrease
}) => {
  return (
    <div className="pl-0 fs-16 counter-change-container mx-auto br-4">
      <button
        className="btn font-weight-bold p-0 btn-calc bg-white-1"
        onClick={onDecrease}
      >
        −
      </button>

      <input
        type="number"
        className="col bg-white border-bottom-0"
        value={value}
        onChange={onChangeValue}
        onBlur={onBlurValue}
        onKeyPress={onKeyPressValue}
      />
      <button
        className="btn font-weight-bold p-0 btn-calc bg-white-1"
        onClick={onIncrease}
      >
        +
      </button>
    </div>
  );
};

CounterChange.propTypes = {
  onDecrease: PropTypes.func,
  value: PropTypes.number,
  onChangeValue: PropTypes.func,
  onBlurValue: PropTypes.func,
  onKeyPressValue: PropTypes.func,
  onIncrease: PropTypes.func
};

export default CounterChange;
