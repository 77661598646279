import React from 'react';
import PostalInput from '../../PostalInput/index';
import Input from '../../Input';
import CustomerAutoSuggest from '../../NewOrder/Components/ChooseCustomer/CustomerAutoSuggest';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from '../../Input/select';

const EditContractsDetails = ({
  controls,
  onSubmit,
  isValid,
  // Below props is for customer auto suggest component
  customerService,
  onCustomerSelected,
  contractDetails,
}) => {
  const renderInputElements = control =>
    control.name === 'postalCode' ? (
      <PostalInput
        key={control.name}
        options={control}
        onChange={control.onChange}
        ref={input => {
          control.ref = input;
        }}
      />
    ) : control.name === 'system' || control.name === 'housing_type' ? (
      <Select
        options={control}
        className={' cursor-pointer'}
        placeholder={control.label}
        key={control.name}
        value={control.value}
        onChange={control.onChange}
        ref={input => {
          control.ref = input;
        }}
      />
    ) : (
      <Input
        key={control.name}
        options={control}
        onChange={control.onChange}
        ref={input => {
          control.ref = input;
        }}
      />
    );
  return (
    <>
      <div className="row ls-0-25" id="chooseCustomer">
        <div className="col-6 mb-3">
          <h5 className="mb-3">
            <FormattedMessage id="contract_contract_details" />*
          </h5>
          <div className="w-378-max mb-4">
            <div className=" p-0 ">
              <FormattedMessage id="common_order_contract_no" /> <FormattedMessage id="all_contracts_contract_id" />
              <p className="mt-1">{contractDetails && contractDetails.contractNo}</p>
            </div>
          </div>

          <div className="pl-3 row position-relative mt-0-85 w-378-max">
            <label className="text-grey mb-0 select-reseller-label-confirm-order px-1">
              <FormattedMessage id="customer_name" />
            </label>
            <div className="w-100 search-input-container-confirm-order p-0 select-cust-search-width mr-4 mb-4 h-48">
              <CustomerAutoSuggest
                lists={customerService}
                onNext={onCustomerSelected}
                isConfirmOrder
                isContractDetails={{
                  name: controls.customerSection[1].value,
                  id: controls.customerSection[0].value,
                }}
              />
            </div>
            {controls.customerSection.map((control, id) => (
              <div className={(id === 0 || id === 1 || id === 2) && 'd-none'}>{renderInputElements(control)}</div>
            ))}
          </div>
          <div className="row">
            <div className="col-9">
              {controls.promAvarn.map((control, id) => (
                <div>{renderInputElements(control)}</div>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-9">
              {controls.system.map((control, id) => (
                <div>{renderInputElements(control)}</div>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              {controls.alarmSection.map((control, id) => (
                <div>{renderInputElements(control)}</div>
              ))}
            </div>
            <div className="col-6 px-4">
              <p className="mt-2 mb-1">
                <FormattedMessage id="all_orders_order_status" />
              </p>
              <p>{contractDetails && contractDetails.status}</p>
            </div>
          </div>
          <div className="row w-378-max">
            <div className="col-6">
              <p className="mt-2 mb-1">
                <FormattedMessage id="filter_registered_date" />
              </p>
              <p>
                {contractDetails &&
                moment(String(contractDetails.registeredDate).slice(0, 10)).format('DD-MM-YYYY') === 'Invalid date'
                  ? 'N/A'
                  : moment(String(contractDetails.registeredDate).slice(0, 10)).format('DD-MM-YYYY')}
              </p>
            </div>
            <div className="col-6 px-4">
              <p className="mt-2 mb-1">
                <FormattedMessage id="filter_activation_date" />
              </p>
              <p>
                {contractDetails &&
                moment(String(contractDetails.activationDate).slice(0, 10)).format('DD-MM-YYYY') === 'Invalid date'
                  ? 'N/A'
                  : moment(String(contractDetails.activationDate).slice(0, 10)).format('DD-MM-YYYY')}
              </p>
            </div>
          </div>
          <div className="row w-378-max">
            <div className="col-6">
              <p className="mt-2 mb-1">
                <FormattedMessage id="filter_cancellation_date" />
              </p>
              <p>
                {contractDetails &&
                moment(String(contractDetails.cancellationDate).slice(0, 10)).format('DD-MM-YYYY') === 'Invalid date'
                  ? 'N/A'
                  : moment(String(contractDetails.cancellationDate).slice(0, 10)).format('DD-MM-YYYY')}
              </p>
            </div>
            <div className="col-6 px-4">
              <p className="mt-2 mb-1">
                <FormattedMessage id="filter_termination_date" />
              </p>
              <p>
                {contractDetails &&
                moment(String(contractDetails.terminationDate).slice(0, 10)).format('DD-MM-YYYY') === 'Invalid date'
                  ? 'N/A'
                  : moment(String(contractDetails.terminationDate).slice(0, 10)).format('DD-MM-YYYY')}
              </p>
            </div>
          </div>
        </div>
        <div className="col-6 mb-3">
          <h5 className="mb-3">
            <FormattedMessage id="contract_location_details" />
          </h5>

          <div className="row w-378-max">
            <div className="col">
              {renderInputElements(controls.addressSection[1])}
              {renderInputElements(controls.addressSection[2])}
              {controls.contactSection.map(control => renderInputElements(control))}
              {renderInputElements(controls.addressSection[0])}
              {renderInputElements(controls.addressSection[3])}
            </div>
            {/* <div className="col">{controls.resellerSection.map(control => renderInputElements(control))}</div> */}
          </div>
        </div>
      </div>

      <div className="mt-3 ml-3">
        <div className="d-inline-block">
          <button className={'btn-dark btn-pro'} onClick={onSubmit.bind(this)}>
            {''}
            <FormattedMessage id="create_save" />
          </button>
        </div>
        <div className="d-inline-block ml-5">
          <a href="#" className="font-weight-semibold " data-dismiss="modal">
            <u>
              <FormattedMessage id="create_cancel" />
            </u>
          </a>
        </div>
      </div>
    </>
  );
};

EditContractsDetails.propType = {
  controls: PropTypes.object,
  onSubmit: PropTypes.func,
  isValid: PropTypes.bool,
  customerService: PropTypes.array,
  onCustomerSelected: PropTypes.func,
};

export default EditContractsDetails;
