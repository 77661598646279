import React from 'react';
import { SelectedProductsListView } from './SelectedProductsListView';
import { FormattedMessage } from 'react-intl';
import Constants from '../../../../shared/constants';
import PropTypes from 'prop-types';
export const SelectedProducts = ({
  productsData,
  isServices,
  isProposals,
  onQtyIncrease,
  onQtyDecrease,
  onConfirmOrderProducts,
  onRemoveItem,
  isPrivateCustomer,
  onServicePriceChange,
  controls = {},
  isPriceBasedOnHours,
  needInstallation,
  onRemoveInstallation,
  handleIncreaseOrDecreaseHours,
  isServiceProposal,
  proposalDetails,
}) => {
  const { installationFields = [] } = controls;
  let totalPrice = 0;
  if (productsData && productsData.length && !isServices && !isProposals) {
    productsData.map(obj => {
      totalPrice = totalPrice + Number(obj.defaultPrice) * Number(obj.quantity);
      return obj;
    });
  }
  if (isServices && productsData && productsData.length) {
    totalPrice = Number(
      productsData.map(obj => obj.defaultPrice).reduce((accumulator, price) => Number(accumulator) + Number(price))
    );
  }
  if (productsData && productsData.length && isProposals) {
    productsData.map(obj => {
      totalPrice = totalPrice + Number(obj.defaultPrice) * Number(obj.quantity);
      return obj;
    });
    if (needInstallation) {
      if (isPriceBasedOnHours) {
        totalPrice =
          Number(installationFields[1] && installationFields[1].value) *
            Number(installationFields[0] && installationFields[0].value) +
          totalPrice;
      } else {
        totalPrice = Number(installationFields[2] && installationFields[2].value) + totalPrice;
      }
    }
  }
  return (
    <div className={'col color-1d1d1d' + (isServices ? ' pr-0' : '')}>
      <div className={'row ' + (isServices ? '' : 'pl-0')} id="selected-products-head">
        <div className="col mb-1 px-3">
          <h3 className="line-height-normal ls-0-3">
            {isProposals || isServiceProposal || isServices ? (
              <FormattedMessage id="common_overview" />
            ) : (
              <FormattedMessage id="new_order_your_products" />
            )}
          </h3>
          {!isServices && (
            <span className="product-sub-text fs-14">
              {productsData.length} <FormattedMessage id="new_order_products_selected" />
            </span>
          )}
        </div>
      </div>
      <SelectedProductsListView
        productsData={productsData}
        isServices={isServices}
        isProposals={isProposals}
        onQtyDecrease={onQtyDecrease}
        onQtyIncrease={onQtyIncrease}
        onRemoveItem={onRemoveItem}
        isPrivateCustomer={isPrivateCustomer}
        onServicePriceChange={onServicePriceChange}
        needInstallation={needInstallation}
        isPriceBasedOnHours={isPriceBasedOnHours}
        installationFields={installationFields}
        onRemoveInstallation={onRemoveInstallation}
        handleIncreaseOrDecreaseHours={handleIncreaseOrDecreaseHours}
        isServiceProposal={isServiceProposal}
        proposalDetails={proposalDetails}
      />
      <div className={'row ' + (isServices ? 'mx-0 mt-2' : 'ml-1 mt-2')}>
        <div className="col-4 px-0">
          <div className="d-flex pb-1">
            <h4 className="d-inline-block font-weight-bold mb-0 pr-2 mr-4">
              <span className="font-weight-bold">
                <FormattedMessage id="common_total" />:
              </span>
            </h4>
            <h4 className={'font-weight-bold mb-0 ml-5 pl-4 ' + (!isServices && 'mr-5')}>
              <span className="font-weight-bold">
                {(totalPrice === 0 ? 0 : Number(totalPrice).toFixed(2)) + Constants.currency.norway}
                {isServices && (
                  <>
                    {' /'}
                    <FormattedMessage id="common_per_month" />
                  </>
                )}
              </span>
            </h4>
          </div>
          <div className="pb-0 fs-14">
            <>
              {isServices ? (
                isPrivateCustomer ? (
                  <FormattedMessage id="new_order_tax_included" />
                ) : (
                  <FormattedMessage id="new_order_tax_excluded" />
                )
              ) : (
                <FormattedMessage id="new_order_tax_excluded" />
              )}
            </>
          </div>
        </div>
        <div className="col text-right">
          <div className="w-180-min h-44-max float-right">
            <button
              className="btn btn-dark font-weight-semibold"
              disabled={productsData.length === 0}
              onClick={onConfirmOrderProducts.bind(this, {
                selectedProducts: productsData,
              })}
            >
              <FormattedMessage id="common_next" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
SelectedProducts.propTypes = {
  productsData: PropTypes.array,
  isServices: PropTypes.bool,
  onQtyIncrease: PropTypes.func,
  onQtyDecrease: PropTypes.func,
  onConfirmOrderProducts: PropTypes.func,
  onRemoveItem: PropTypes.func,
  proposalDetails: PropTypes.array,
};