import React from 'react';
import { FormattedMessage } from 'react-intl';
import DatePicker from 'react-datepicker';
import OrderService from '../../services/OrderService';
import Config from '../../config';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { finishOrder } from '../../state/actions/OrderActions';
import Routes from '../../shared/Routes';
import Constants from '../../shared/constants';
import Card from '../Card';
import Input from '../Input';
import Select from '../Input/select';
import { Control, ValidationRule } from '../../shared/Control';
import RadioButtonComponent from '../RadioButton';
import validator from 'validator';
import BaseComponent from '../BaseComponent';
import PostalInput from '../PostalInput';
import { CustomerType } from '../../shared/CustomerType';
import { selectOrder } from '../../state/actions/OrderActions';
import CustomerService from '../../services/CustomerService';
import AuthService from '../../services/AuthService';
import { Modal } from 'semantic-ui-react';
import BounceLoader from 'react-spinners/BounceLoader';
import { OrderType } from '../../shared/OrderType';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, getWeekends } from '../../shared/utils';
import { CheckBox } from '../CheckBox/CheckBox';
import ResellerService from '../../services/ResellerService';
import CustomerAutoSuggest from './Components/ChooseCustomer/CustomerAutoSuggest';
import { charLimit } from '../../shared/stringOperations';
import Toast from '../Toast/index';
import PriceEditable from '../Input/PriceEditable';
import ProposalService from '../../services/ProposalService';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';

class ConfirmOrder extends BaseComponent {
  state = {
    houseOptions: [
      { value: 'house', label: Constants.language.option_house },
      { value: 'apartment', label: Constants.language.option_apartment },
      { value: 'office', label: Constants.language.option_office },
    ],
  };
  constructor(props) {
    super(props);
    const housingTypeValidation =
      props.order.orderType === OrderType.SERVICE_ORDER
        ? new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_hosing_type" />,
          })
        : null;
    this.controls = {
      customerDetails: [
        new Control({
          name: 'name',
          type: 'string',
          label: <FormattedMessage id="ph_company_name" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_company_name" />,
          }),
          group: 'mainContact',
          id: 'customerName',
          formGroup: 5,
          onBlur: this.handleCustomerDetailsBlur,
          onFocus: this.handleCustomerDetailsFocus,
          onChange: this.handleCustomerDetailsChange,
          donotCheck: true,
        }),

        new Control({
          name: 'corporateId',
          type: 'phone',
          label: <FormattedMessage id="ph_organization_number" />,
          rule: new ValidationRule({
            method: validator.isLength,
            validWhen: true,
            args: [{ min: 9, max: 9 }],
            message: <FormattedMessage id="warning_organization_number" />,
          }),
          id: 'customerCorporateId',
          formGroup: 5,
          isValid: true,
          onBlur: this.handleCustomerDetailsBlur,
          onFocus: this.handleCustomerDetailsFocus,
          onChange: this.handleCustomerDetailsChange,
          donotCheck: true,
        }),

        new Control({
          name: 'attention',
          type: 'string',
          label: <FormattedMessage id="all_reseller_contact_person" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_contact_person" />,
          }),
          group: 'mainContact',
          id: 'customerAttention',
          formGroup: 5,
          onBlur: this.handleCustomerDetailsBlur,
          onFocus: this.handleCustomerDetailsFocus,
          onChange: this.handleCustomerDetailsChange,
        }),

        new Control({
          name: 'phone1',
          type: 'phone',
          label: <FormattedMessage id="ph_phone" />,
          rule: new ValidationRule({
            method: validator.isLength,
            validWhen: true,
            args: [{ min: 8, max: 8 }],
            message: <FormattedMessage id="warning_phone_number" />,
          }),
          group: 'mainContact',
          id: 'customerPhone1',
          formGroup: 0,
          onBlur: this.handleCustomerDetailsBlur,
          onFocus: this.handleCustomerDetailsFocus,
          onChange: this.handleCustomerDetailsChange,
        }),
        new Control({
          name: 'email',
          type: 'email',
          label: <FormattedMessage id="ph_email" />,
          rule: new ValidationRule({
            method: validator.isEmail,
            validWhen: true,
            message: <FormattedMessage id="warning_email" />,
          }),
          group: 'mainContact',
          id: 'customerEmail',
          formGroup: 0,
          onBlur: this.handleCustomerDetailsBlur,
          onFocus: this.handleCustomerDetailsFocus,
          onChange: this.handleCustomerDetailsChange,
        }),
        new Control({
          name: 'addressLine1',
          type: 'string',
          label: <FormattedMessage id="ph_address_info" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_address" />,
          }),
          group: 'mainAddress',
          id: 'customerAddressLine1',
          formGroup: 1,
          onBlur: this.handleCustomerDetailsBlur,
          onFocus: this.handleCustomerDetailsFocus,
          onChange: this.handleCustomerDetailsChange,
        }),
        new Control({
          name: 'postalCode',
          type: 'number',
          label: <FormattedMessage id="ph_postal_code" />,
          rule: new ValidationRule({
            method: validator.isLength,
            validWhen: true,
            args: [{ min: 0, max: 4 }],
            message: <FormattedMessage id="warning_postal_code" />,
          }),
          group: 'mainAddress',
          id: 'customerPostalCode',
          formGroup: 1,
          onBlur: this.handleCustomerDetailsBlur,
          onFocus: this.handleCustomerDetailsFocus,
          onChange: this.handleCustomerDetailsChange,
        }),
        new Control({
          name: 'resellerName',
          type: 'string',
          label: <FormattedMessage id="all_contracts_reseller" />,
          // rule: new ValidationRule({
          //   method: validator.isLength,
          //   validWhen: true,
          //   args: [{ min: 8, max: 8 }],
          //   message: <FormattedMessage id="warning_phone_number" />
          // }),
          disabled: true,
          group: 'mainContact',
          id: 'customerPhone1',
          formGroup: 0,
          onBlur: this.handleCustomerDetailsBlur,
          onFocus: this.handleCustomerDetailsFocus,
          onChange: this.handleCustomerDetailsChange,
        }),
      ],
      billingAddress: [
        new Control({
          name: 'email',
          type: 'email',
          label: <FormattedMessage id="ph_billing_email" />,
          rule: new ValidationRule({
            method: validator.isEmail,
            validWhen: true,
            message: <FormattedMessage id="warning_email" />,
          }),
          group: 'soBillingContact',
          id: 'billingEmail',
          formGroup: 0,
          onBlur: this.handleBillingDetailsBlur,
          onFocus: this.handleBillingDetailsFocus,
          onChange: this.handleBillingAddressChange,
        }),
        new Control({
          name: 'attention',
          type: 'string',
          label: <FormattedMessage id="ph_billing_contact" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_contact_person" />,
          }),
          group: 'soBillingContact',
          id: 'billingContact',
          formGroup: 1,
          onBlur: this.handleBillingDetailsBlur,
          onFocus: this.handleBillingDetailsFocus,
          onChange: this.handleBillingAddressChange,
        }),
        new Control({
          name: 'phone1',
          type: 'phone',
          label: <FormattedMessage id="ph_phone" />,
          rule: new ValidationRule({
            method: validator.isLength,
            validWhen: true,
            args: [{ min: 8, max: 8 }],
            message: <FormattedMessage id="warning_phone_number" />,
          }),
          group: 'soBillingContact',
          id: 'billingPhone',
          formGroup: 1,
          onBlur: this.handleBillingDetailsBlur,
          onFocus: this.handleBillingDetailsFocus,
          onChange: this.handleBillingAddressChange,
        }),
        new Control({
          name: 'customerOrder',
          type: 'string',
          label: <FormattedMessage id="ph_billing_reference" />,
          group: 'customerOrder',
          id: 'billingReference',
          formGroup: 1,
          onBlur: this.handleBillingDetailsBlur,
          onFocus: this.handleBillingDetailsFocus,
          onChange: this.handleBillingAddressChange,
        }),
      ],
      shippingAddress: [
        new Control({
          name: 'attention',
          type: 'string',
          label: <FormattedMessage id="all_reseller_contact_person" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_contact_person" />,
          }),
          group: 'soShippingContact',
          id: 'shippingAttention',
          formGroup: 0,
          onBlur: this.handleShippingDetailsBlur,
          onFocus: this.handleShippingDetailsFocus,
          onChange: this.handleShippingAddressChange,
        }),
        new Control({
          name: 'phone1',
          type: 'phone',
          label: <FormattedMessage id="ph_phone" />,
          rule: new ValidationRule({
            method: validator.isLength,
            validWhen: true,
            args: [{ min: 8, max: 8 }],
            message: <FormattedMessage id="warning_phone_number" />,
          }),
          group: 'soShippingContact',
          id: 'shippingPhone',
          formGroup: 0,
          onBlur: this.handleShippingDetailsBlur,
          onFocus: this.handleShippingDetailsFocus,
          onChange: this.handleShippingAddressChange,
        }),
        new Control({
          name: 'email',
          type: 'email',
          label: <FormattedMessage id="ph_email" />,
          rule: new ValidationRule({
            method: validator.isEmail,
            validWhen: true,
            message: <FormattedMessage id="warning_email" />,
          }),
          group: 'soShippingContact',
          id: 'shippingEmail',
          formGroup: 0,
          onBlur: this.handleShippingDetailsBlur,
          onFocus: this.handleShippingDetailsFocus,
          onChange: this.handleShippingAddressChange,
        }),
        new Control({
          name: 'housing_type',
          type: 'select',
          rule: housingTypeValidation,
          label: Constants.language.title_housing_type,
          group: 'soShippingAddress',
          id: 'shippingHousingType',
          formGroup: 1,
          options: this.state.houseOptions,
          onBlur: this.handleShippingDetailsBlur,
          onFocus: this.handleShippingDetailsFocus,
          onChange: this.handleShippingAddressChange,
        }),
        new Control({
          name: 'addressLine1',
          type: 'string',
          label: <FormattedMessage id="ph_address_info" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_address" />,
          }),
          group: 'soShippingAddress',
          id: 'shippingAddressLine1',
          formGroup: 1,
          onBlur: this.handleShippingDetailsBlur,
          onFocus: this.handleShippingDetailsFocus,
          onChange: this.handleShippingAddressChange,
        }),
        new Control({
          name: 'postalCode',
          type: 'phone',
          label: <FormattedMessage id="ph_postal_code" />,
          rule: new ValidationRule({
            method: validator.isLength,
            validWhen: true,
            args: [{ min: 0, max: 4 }],
            message: <FormattedMessage id="warning_postal_code" />,
          }),
          group: 'soShippingAddress',
          id: 'shippingPostalCode',
          formGroup: 1,
          onBlur: this.handleShippingDetailsBlur,
          onFocus: this.handleShippingDetailsFocus,
          onChange: this.handleShippingAddressChange,
        }),
      ],
    };
    this.addTenDays = addDays(new Date(), 10);

    const total = this.getOrderTotal(props.order);

    this.state = {
      loading: false,
      postponedDate:
        this.addTenDays.getDay() === 0
          ? addDays(new Date(), 11)
          : this.addTenDays.getDay() === 6
          ? addDays(new Date(), 12)
          : this.addTenDays,
      postponedCheck: false,
      customerDetails: {
        isValid: false,
        isFocus: false,
      },
      billingAddress: {
        isValid: false,
        isFocus: false,
      },
      shippingAddress: {
        isValid: false,
        isFocus: false,
      },
      showToast: false,
      toastData: {},
      billingIsSameAsMain: false,
      shippingIsSameAsMain: false,
      postponeDelivery: false,
      isSubmitted: false,
      showErrors: false,
      sendInvoice: true,
      cardId: 0,
      agreeCheck: false,
      serviceSection: false,
      services: [],
      customer: {
        id: '',
      },
      location: {
        id: '',
      },
      serviceTotal: {
        total: '',
        totalex: '',
        moms: '',
      },
      isTermsConditionsSelected: false,
      isOptInSelected: true,
      isOptInAvarnSelected: true,
      shippingCharges: this.getShippingCharges(total),
      emails: [],
    };

    this.orderService = new OrderService(Config.apiBase);
    this.customerService = new CustomerService(Config.apiBase);
    this.resellerService = new ResellerService(Config.apiBase);
    this.proposalService = new ProposalService(Config.apiBase);
    this.authService = new AuthService(Config.apiBase);
    this.isResellerUser = this.authService.isResellerUser();
    this.isSuperAdminUser = this.authService.isSuperAdminUser();
    this.isAdminBackOffice = this.authService.isAdminBackOfficeUser();
    this.taxPercentage = 25.0 / 100;
    if (localStorage.userData) {
      this.userData = JSON.parse(localStorage.getItem('userData')) || {};
      let name = this.userData.name || '';
      this.userName = name && name.split(' ', 1)[0];
    }

    const { order } = this.props;
    if (order && order.reseller) {
      const {
        order: {
          reseller: { name: resellerName = '' },
        },
      } = this.props;
      this.controls.customerDetails[7].setValue(resellerName);
      this.reseller = order.reseller;
    } else if (order && order.proposalDetails) {
      const {
        order: { proposalDetails: { reseller: { name: resellerName = '' } = {} } = {} },
      } = this.props;
      this.controls.customerDetails[7].setValue(resellerName);
      this.reseller = order.proposalDetails.reseller;
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.propToStateDataProcessing();
    const {
      order: { customerType },
    } = this.props;
    if (customerType === CustomerType.BUSINESS_CUSTOMER) {
      this.setState({ businessCustomer: true });
    } else if (customerType === CustomerType.PRIVATE_CUSTOMER) {
      this.setState({ privateCustomer: true });
    }
  }
  handleToast = data => {
    this.setState({ showToast: true, toastData: data });
    setTimeout(() => {
      this.setState({
        showToast: false,
      });
    }, data.delay);
  };
  propToStateDataProcessing() {
    const { order, location, history } = this.props;
    if (order.orderType === OrderType.SERVICE_ORDER) {
      /**
       * Below code will add an extra field only for service order
       */
      this.controls.shippingAddress.push(
        new Control({
          name: 'locationName',
          type: 'string',
          label: Constants.language.ph_location_name,
          // rule: new ValidationRule({
          //   method: validator.isEmpty,
          //   validWhen: false,
          //   message: <FormattedMessage id="warning_location_name" />,
          // }),
          group: 'soShippingAddress',
          id: 'shippingLocationName',
          formGroup: 0,
          onBlur: this.handleShippingDetailsBlur,
          onFocus: this.handleShippingDetailsFocus,
          onChange: this.handleShippingAddressChange,
        })
      );
      if (!order) {
        history.push(Routes.newOrderChooseCustomerService);
      }
    } else {
      if (!order || !order.type || !order.products) {
        if (!order.customer) {
          if (location.pathname === Routes.newOrderConfirmOrderProducts) {
            history.push(Routes.newOrderChooseCustomerProduct);
          } else if (location.pathname === Routes.newProposalConfirmProposalProducts) {
            // history.push(Routes.newProposalConfirmProposalProducts);
          } else {
            history.push(Routes.newOrderChooseCustomerService);
          }
        }
      }
    }
    let userName;
    if (order && order.reseller) {
      if (order.reseller.isCustomer) {
        userName = this.userName;
      } else {
        userName = (order.reseller && order.reseller.name) || '';
      }
    } else if (order && order.proposalDetails) {
      const { proposalDetails = {} } = order;
      const { reseller = {} } = proposalDetails;
      const { name = '' } = reseller;
      userName = name;
    } else {
      userName = '';
    }
    if (
      order &&
      (order.type === CustomerType.EXISTING_CUSTOMER ||
        order.type === CustomerType.NEW_INSTALLATION ||
        (order.customer && order.customer.customerClass)) &&
      order.customer &&
      order.customer.mainContact
    ) {
      const { customerDetails, billingAddress, shippingAddress } = this.controls;
      const { customer = {} } = order;
      let resellerName = '';
      if (this.reseller) {
        resellerName = this.reseller.name;
      } else {
        resellerName = '';
      }
      customerDetails[0].setValue(customer && customer.name ? customer.name : '');
      customerDetails[1].setValue(customer.corporateId);
      customerDetails[2].setValue(
        customer.mainContact.attention
          ? customer.mainContact.attention
          : customer.mainContact.name
          ? customer.mainContact.name
          : '-'
      );
      customerDetails[3].setValue(customer.mainContact.phone1);
      customerDetails[4].setValue(customer.mainContact.email);

      customerDetails[5].setValue(customer.mainAddress.addressLine1);

      customerDetails[6].setValue(customer.mainAddress.postalCode);
      customerDetails[7].setValue(resellerName);
      customerDetails[6].city = customer.mainAddress.city;
      billingAddress[0].setValue(customer.invoiceContact.email);
      billingAddress[1].setValue(customer.invoiceContact.attention);
      billingAddress[2].setValue(customer.invoiceContact.phone1);
      billingAddress[3].setValue((customer.customerOrder && customer.customerOrder.value) || userName);

      if (order.type !== CustomerType.NEW_INSTALLATION) {
        shippingAddress[0].setValue(customer.deliveryContact.attention);
        shippingAddress[1].setValue(customer.deliveryContact.phone1);
        shippingAddress[2].setValue(customer.deliveryContact.email);
        shippingAddress[4].setValue(customer.deliveryAddress.addressLine1);
        shippingAddress[5].setValue(customer.deliveryAddress.postalCode);
        shippingAddress[5].city = customer.deliveryAddress.city;
      }
      const isProposal = location.pathname === Routes.newProposalConfirmProposalProducts;
      const isServiceProposal = location.pathname === Routes.newProposalConfirmProposalServices;
      const isbillingAddressValid = !isProposal && !isServiceProposal && this.validate(this.controls.billingAddress);
      const isShippingAddressValid = !isProposal && !isServiceProposal && this.validate(this.controls.shippingAddress);

      let cardId = 1;
      if (isbillingAddressValid) {
        if (isShippingAddressValid) {
          cardId = 3;
        } else {
          cardId = 2;
        }
      }

      this.setState({
        cardId,
        customerDetails: { isValid: true },
        order,
        billingAddress: { isValid: isbillingAddressValid },
        shippingAddress: { isValid: isShippingAddressValid },
      });
    } else {
      this.setState({ order });
    }

    const serviceType = order.orderType;

    if (serviceType === OrderType.SERVICE_ORDER || serviceType === Constants.orderTypes.proposalService) {
      this.setState({ serviceSection: true });
    } else {
      this.setState({ serviceSection: false });
    }

    const serviceData = order.services;
    this.setState({ services: serviceData });
  }

  handleCustomerDetailsFocus = () => {
    let { customerDetails } = this.state;
    customerDetails.isFocus = true;
    customerDetails.isValid = this.validate(this.controls.customerDetails);
  };
  handleCustomerDetailsBlur = () => {
    let { customerDetails } = this.state;
    customerDetails.isFocus = false;
    this.setState({ customerDetails });
  };

  handleShippingDetailsFocus = () => {
    let { shippingAddress } = this.state;
    shippingAddress.isFocus = true;
    shippingAddress.isValid = this.validate(this.controls.shippingAddress);
  };

  handleShippingDetailsBlur = () => {
    let { shippingAddress } = this.state;
    shippingAddress.isFocus = false;
    this.setState({ shippingAddress });
  };

  handleBillingDetailsFocus = () => {
    let { billingAddress } = this.state;
    billingAddress.isFocus = true;
    billingAddress.isValid = this.validate(this.controls.billingAddress);
  };
  handleBillingDetailsBlur = () => {
    let { billingAddress } = this.state;
    billingAddress.isFocus = false;
    this.setState({ billingAddress });
  };

  handleCustomerDetailsChange = async () => {
    let { customerDetails } = this.state;
    customerDetails.isValid = this.validate(this.controls.customerDetails);
    this.setState({
      customerDetails,
      billingIsSameAsMain: false,
      shippingIsSameAsMain: false,
    });
  };

  handleBillingAddressChange = async () => {
    let { billingAddress, billingIsSameAsMain } = this.state;
    billingAddress.isValid = this.validate(this.controls.billingAddress);
    if (
      this.controls.billingAddress[0].value === this.controls.customerDetails[4].value &&
      this.controls.billingAddress[1].value === this.controls.customerDetails[2].value &&
      this.controls.billingAddress[2].value === this.controls.customerDetails[3].value
    ) {
      billingIsSameAsMain = true;
    } else {
      billingIsSameAsMain = false;
    }
    this.setState({ billingAddress, billingIsSameAsMain });
  };

  handleShippingAddressChange = async () => {
    let { shippingAddress, shippingIsSameAsMain } = this.state;
    shippingAddress.isValid = this.validate(this.controls.shippingAddress);
    if (
      this.controls.shippingAddress[0].value === this.controls.customerDetails[2].value &&
      this.controls.shippingAddress[1].value === this.controls.customerDetails[3].value &&
      this.controls.shippingAddress[2].value === this.controls.customerDetails[4].value &&
      this.controls.shippingAddress[4].value === this.controls.customerDetails[5].value &&
      this.controls.shippingAddress[5].value === this.controls.customerDetails[6].value
    ) {
      shippingIsSameAsMain = true;
    } else {
      shippingIsSameAsMain = false;
    }
    this.setState({ shippingAddress, shippingIsSameAsMain });
  };

  validate(controls) {
    return controls.find(obj => obj.rule && !obj.isValid && !obj.donotCheck) === undefined;
  }

  handleBillingSameAsMain = () => {
    const { billingAddress, customerDetails } = this.controls;
    const { order } = this.props;
    const { billingIsSameAsMain } = this.state;
    let userName;
    if (order && order.reseller.isCustomer) {
      userName = this.userName;
    } else {
      userName = order.reseller.name;
    }
    let isValid = this.validate(billingAddress);
    if (!billingIsSameAsMain) {
      const { customer } = order;
      if (order.type === CustomerType.EXISTING_CUSTOMER && customer.mainContact && customer.mainContact.attention) {
        billingAddress[0].setValue(customer.mainContact.email);
        billingAddress[1].setValue(customer.mainContact.attention);
        billingAddress[2].setValue(customer.mainContact.phone1);
        billingAddress[3].setValue((customer.customerOrder && customer.customerOrder.value) || userName);
      } else {
        billingAddress[0].setValue(customerDetails[4].value);
        billingAddress[1].setValue(customerDetails[2].value);
        billingAddress[2].setValue(customerDetails[3].value);
        //billingAddress[3].setValue(billingAddress[3].value || userName);
      }
      isValid = this.validate(billingAddress);
    }
    this.setState({
      billingIsSameAsMain: !billingIsSameAsMain,
      billingAddress: { isValid },
    });
  };

  handleShippingSameAsMain = () => {
    const { shippingAddress, customerDetails } = this.controls;
    const { shippingIsSameAsMain } = this.state;
    let isValid = this.validate(shippingAddress);
    if (!shippingIsSameAsMain) {
      const {
        order: { customer, type, orderType },
      } = this.props;
      if (type === CustomerType.EXISTING_CUSTOMER && customer.mainContact) {
        shippingAddress[0].setValue(customer.mainContact.attention);
        shippingAddress[1].setValue(customer.mainContact.phone1);
        shippingAddress[2].setValue(customer.mainContact.email);
        shippingAddress[4].setValue(customer.mainAddress.addressLine1);
        shippingAddress[5].setValue(customer.mainAddress.postalCode);
        orderType === OrderType.SERVICE_ORDER && shippingAddress[5].setValue(customer.mainAddress.locationName);
      } else {
        shippingAddress[0].setValue(customerDetails[2].value);
        shippingAddress[1].setValue(customerDetails[3].value);
        shippingAddress[2].setValue(customerDetails[4].value);
        shippingAddress[4].setValue(customerDetails[5].value);
        shippingAddress[5].setValue(customerDetails[6].value);
      }
      isValid = this.validate(shippingAddress);
    }
    this.setState({
      shippingIsSameAsMain: !shippingIsSameAsMain,
      shippingAddress: { isValid },
    });
  };

  handlePostponedDelivery = () => {
    this.setState({ postponedCheck: !this.state.postponedCheck });
  };

  handleDateChange = date => {
    this.setState({ postponedDate: date });
  };

  onAgreeOrder = checked => {
    let { customerDetails, billingAddress, shippingAddress } = this.state;
    if (customerDetails.isValid && billingAddress.isValid && shippingAddress.isValid) {
      this.setState({
        agreeCheck: checked,
      });
    }
  };

  confirmProductOrder = async () => {
    const {
      order: { products, customer, proposalDetails = {} },
      history,
      finishOrder,
    } = this.props;
    let response;
    const { postponedDate, postponedCheck, shippingCharges } = this.state;
    // Make sure the user is not logout because inactivity
    if (!this.authService.isAuthenticated()) {
      this.setState({ showTimeoutError: true });
      return;
    }

    this.setState({ loading: true, isSubmitted: false });
    try {
      response = await this.orderService
        .createProductOrder(
          products,
          this.controls,
          customer,
          this.reseller,
          postponedCheck && postponedDate,
          shippingCharges,
          proposalDetails
        )
        .then(() => {
          finishOrder();
          history.push(Constants.routes.newOrderThankyouOrder);
        });
      if (response.status !== 200 && response.status !== 201) {
        this.setState({ loading: false });
        this.handleToast({
          type: Constants.toast.ERROR,
          message: Constants.language.toast_order_failed,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (error) {
      this.setState({ error: error, loading: false });
      this.handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_order_failed,
        delay: Constants.toast.DELAY,
      });
    }
    this.setState({ loading: false });
  };

  confirmProductProposal = async () => {
    const {
      order: { products, customer, installationData, proposalDetails },
      history,
      finishOrder,
    } = this.props;
    let response;
    const { postponedDate, postponedCheck, emails } = this.state;
    // Make sure the user is not logout because inactivity
    if (!this.authService.isAuthenticated()) {
      this.setState({ showTimeoutError: true });
      return;
    }

    this.setState({ loading: true, isSubmitted: false });
    try {
      if (proposalDetails && Object.keys(proposalDetails).length > 0) {
        response = await this.orderService
          .editProductProposal(
            products,
            this.controls,
            customer,
            this.reseller,
            emails,
            installationData,
            proposalDetails
          )
          .then(() => {
            finishOrder();
            history.push(Constants.routes.newProposalThankyou);
          });
      } else {
        response = await this.orderService
          .createProductProposal(products, this.controls, customer, this.reseller, emails, installationData)
          .then(() => {
            finishOrder();
            history.push(Constants.routes.newProposalThankyou);
          });
      }
      if (response.status !== 200 && response.status !== 201) {
        this.setState({ loading: false });
        this.handleToast({
          type: Constants.toast.ERROR,
          message: Constants.language.toast_proposal_failed,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (error) {
      this.setState({ error: error, loading: false });
      this.handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_proposal_failed,
        delay: Constants.toast.DELAY,
      });
    }
    this.setState({ loading: false });
  };

  confirmServiceProposal = async () => {
    const {
      order: { services, customer, installationData, proposalDetails },
      history,
      finishOrder,
    } = this.props;
    let response;
    const { emails } = this.state;
    // Make sure the user is not logout because inactivity
    if (!this.authService.isAuthenticated()) {
      this.setState({ showTimeoutError: true });
      return;
    }

    this.setState({ loading: true, isSubmitted: false });
    try {
      if (proposalDetails && Object.keys(proposalDetails).length > 0) {
        response = await this.orderService
          .editServiceProposal(
            services,
            this.controls,
            customer,
            this.reseller,
            emails,
            installationData,
            proposalDetails
          )
          .then(() => {
            finishOrder();
            history.push(Constants.routes.newProposalThankyou);
          });
      } else {
        response = await this.orderService
          .createServiceProposal(services, this.controls, customer, this.reseller, emails, installationData)
          .then(() => {
            finishOrder();
            history.push(Constants.routes.newProposalThankyou);
          });
      }
      if (response.status !== 200 && response.status !== 201) {
        this.setState({ loading: false });
        this.handleToast({
          type: Constants.toast.ERROR,
          message: Constants.language.toast_proposal_failed,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (error) {
      this.setState({ error: error, loading: false });
      this.handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_proposal_failed,
        delay: Constants.toast.DELAY,
      });
    }
    this.setState({ loading: false });
  };

  confirmServiceOrder = async () => {
    const {
      order: { type, services, customer, customerType, proposalDetails },
    } = this.props;
    let response;
    // Make sure the user is not logout because inactivity
    if (!this.authService.isAuthenticated()) {
      this.setState({ showTimeoutError: true });
      return;
    }
    this.setState({ loading: true, isSubmitted: false });
    const newCustomer = type === CustomerType.NEW_CONSUMER;
    const newInstallation = type === CustomerType.NEW_INSTALLATION;
    if (customerType === CustomerType.PRIVATE_CUSTOMER) {
      services.map(obj => {
        obj.defaultPrice = obj.defaultPrice - (obj.defaultPrice * 0.25) / 1.25;
        return obj;
      });
    }

    try {
      response = await this.orderService.createServiceOrder(
        services,
        this.controls,
        customer,
        newCustomer,
        newInstallation,
        this.state.total,
        this.reseller,
        proposalDetails,
        this.state.isOptInSelected,
        this.state.isOptInAvarnSelected,
      );
      if (response.status !== 200 && response.status !== 201) {
        this.setState({ loading: false });
        this.handleToast({
          type: Constants.toast.ERROR,
          message: Constants.language.toast_order_failed,
          delay: Constants.toast.DELAY,
        });
      } else {
        if (!response.response.data.success) {
          this.setState({ loading: true });
          this.handleToast({
            type: Constants.toast.ERROR,
            title: Constants.language.alarm_error_title,
            message: response.response.data.message[0],
            delay: Constants.toast.LONGER_DELAY,
          });
          setTimeout(() => {
            this.props.finishOrder();
            this.props.history.push(Constants.routes.newOrderThankyouOrder);
            this.setState({ loading: false });
          }, Constants.toast.LONGER_DELAY);
        } else {
          this.props.finishOrder();
          this.props.history.push(Constants.routes.newOrderThankyouOrder);
          this.setState({ loading: false });
        }
      }
    } catch (error) {
      this.setState({ error: error, loading: false });
      this.handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_order_failed,
        delay: Constants.toast.DELAY,
      });
    }
  };

  handleOnResellerSelected = value => {
    if (value) {
      this.reseller = value;
    }
  };

  renderGroup(controls, formGroup) {
    return controls
      .filter(control => control.formGroup === formGroup)
      .map(control => {
        if (control.type === 'select') {
          if (control.name === 'housing_type') {
            return this.props.order && this.props.order.orderType === OrderType.SERVICE_ORDER ? (
              <Select
                options={control}
                className={'cursor-pointer'}
                placeholder={control.label}
                key={control.name}
                value={control.id}
                onChange={control.onChange}
                ref={input => {
                  control.ref = input;
                }}
              />
            ) : null;
          } else {
            return (
              <Select
                options={control}
                className={'cursor-pointer'}
                placeholder={control.label}
                key={control.name}
                value={control.id}
                onChange={control.onChange}
                ref={input => {
                  control.ref = input;
                }}
              />
            );
          }
        } else if (control.name === 'postalCode') {
          return (
            <PostalInput
              key={control.name}
              options={control}
              onChange={control.onChange}
              ref={input => {
                control.ref = input;
              }}
            />
          );
        } else {
          return control.name !== 'resellerName' ? (
            <Input
              key={control.name}
              options={control}
              onChange={control.onChange}
              ref={input => {
                control.ref = input;
              }}
            />
          ) : (
            !this.isResellerUser && (
              <div className="mb-5 mt-3 pl-3 row position-relative">
                <label className="text-grey mb-0 select-reseller-label-confirm-order px-1">
                  <FormattedMessage id="all_contracts_reseller" />
                </label>
                <div className="w-100 search-input-container-confirm-order p-0 select-cust-search-width mr-4">
                  <CustomerAutoSuggest
                    lists={this.resellerService}
                    onSuggestionSelected={this.handleOnResellerSelected}
                    isConfirmOrder
                    isContractDetails={{
                      name: this.reseller && this.reseller.name,
                      id: (this.reseller && this.reseller.id) || (this.reseller && this.reseller.internalId),
                    }}
                  />
                </div>
              </div>
            )
          );
        }
      });
  }

  formatPhone(phone) {
    if (phone) {
      return phone.slice(0, 3) + ' ' + phone.slice(3, 5) + ' ' + phone.slice(5, 8);
    }
    return phone;
  }

  handleOnChangeRawDate = e => {
    e.preventDefault();
  };

  handleChangeEmail = _emails => {
    this.setState({ emails: _emails });
  };

  removeEmail = index => {};

  onEditCard({ index }) {
    this.setState({
      previousCardId: this.state.cardId,
      cardId: index,
    });
  }

  handleTermsConditionCheck = e => {
    const { isTermsConditionsSelected } = this.state;
    this.setState({ isTermsConditionsSelected: !isTermsConditionsSelected });
  };

  handleOptInCheck = e => {
    const { isOptInSelected } = this.state;
    this.setState({ isOptInSelected: !isOptInSelected });
  };

  handleAvarnOptInCheck = e => {
    const { isOptInAvarnSelected } = this.state;
    this.setState({ isOptInAvarnSelected: !isOptInAvarnSelected });
  };

  handleFormNext = async ({ index, businessCustomer, corporateId, email, phone }, event) => {
    event.preventDefault();
    if (index === 0) {
      if (businessCustomer) {
        this.setState({
          // loading: true,
          errorMessage: undefined,
        });
        const customer = await this.customerService.getBusinessCustomerDetails(corporateId.value);
        if (customer && customer.number) {
          this.setState({
            errorMessage: Constants.language.warning_organization_number_already_used,
          });
        } else {
          this.setState({ cardId: index + 1 });
        }
        // this.setState({ loading: false });
      } else {
        this.setState({
          // loading: true,
          errorMessage: false,
        });
        const customer = await this.customerService.getEndCustomerDetails(email.value, phone.value);
        const emailMatchingCustomer = customer.filter(obj => obj.mainContact.email === email.value).length > 0;
        const phoneMatchingCustomer = customer.filter(obj => obj.mainContact.phone1 === phone.value).length > 0;
        if (customer && (emailMatchingCustomer || phoneMatchingCustomer)) {
          if (emailMatchingCustomer && phoneMatchingCustomer) {
            this.setState({
              errorMessage: Constants.language.warning_email_phone_already_used,
            });
          } else {
            if (emailMatchingCustomer) {
              this.setState({
                errorMessage: Constants.language.warning_email_already_exists,
              });
            }
            if (phoneMatchingCustomer) {
              this.setState({
                errorMessage: Constants.language.warning_phone_number_already_used,
              });
            }
          }
        } else {
          this.setState({ cardId: index + 1 });
        }
        // this.setState({ loading: false });
      }
    } else {
      this.setState({ cardId: index + 1 });
    }
  };

  renderForm(
    group,
    controls,
    index,
    showNext,
    groupCount,
    cardLabelIndex,
    cardLabel,
    bodyTarget,
    showBillingAsCustomer,
    billingAsCustomer,
    onBillingSameAsCustomer,
    postponeDelivery,
    onPostponedDelivery
  ) {
    const { isValid, isFocus } = group;
    const {
      cardId,
      order,
      businessCustomer,
      privateCustomer,
      errorMessage,
      postponedCheck,
      previousCardId,
      postponedDate,
    } = this.state;
    const { customerDetails } = this.controls;
    const companyName = customerDetails[0];
    const corporateId = customerDetails[1];
    const name = customerDetails[2];
    const phone = customerDetails[3];
    const email = customerDetails[4];
    if (!privateCustomer) {
      name.label = <FormattedMessage id="all_reseller_contact_person" />;
    } else {
      name.label = <FormattedMessage id="customer_name" />;
    }
    const customerDetailsForm = order.type !== CustomerType.EXISTING_CUSTOMER && index === 0;
    const {
      order: { customer },
    } = this.props;
    return (
      <div className="row justify-content-md-center">
        <div className="col">
          <Card
            index={index}
            isFilled={cardId > index}
            cardLabelIndex={cardLabelIndex}
            label={cardLabel}
            valid={isValid && !isFocus}
            bodyTarget={bodyTarget}
            showBody={index <= cardId}
            editable={
              cardId !== index &&
              index < cardId &&
              customer &&
              // !customer._id && need to check in environment
              (index !== 0 ||
                (order.type !== CustomerType.EXISTING_CUSTOMER && order.type !== CustomerType.NEW_INSTALLATION))
            }
            onEdit={this.onEditCard.bind(this, { index })}
            forConfirmOrder={true}
          >
            <div className={'col text-left' + (cardId === index ? '' : ' d-none ')}>
              <React.Fragment>
                {/*  Same as Customer Details Button  */}
                {showBillingAsCustomer && (
                  <div className="form-check pl-0 mt-3">
                    <label className="d-flex align-items-center mb-4">
                      <CheckBox
                        isSelected={billingAsCustomer}
                        onClick={onBillingSameAsCustomer.bind(this)}
                        label={<FormattedMessage id="common_same_as_customer_details" />}
                      />
                    </label>
                  </div>
                )}

                <div className={' mb-1 ' + (showBillingAsCustomer ? '' : 'mt-3 pt-1')}>
                  {customerDetailsForm && (
                    <React.Fragment>
                      {!privateCustomer && (
                        <React.Fragment>
                          <Input
                            key={companyName.name}
                            options={companyName}
                            onChange={companyName.onChange}
                            ref={input => {
                              companyName.ref = input;
                            }}
                          />
                          <Input
                            key={corporateId.name}
                            options={corporateId}
                            onChange={corporateId.onChange}
                            ref={input => {
                              corporateId.ref = input;
                            }}
                          />
                          <Input
                            key={name.name}
                            options={name}
                            onChange={name.onChange}
                            ref={input => {
                              name.ref = input;
                            }}
                          />
                        </React.Fragment>
                      )}

                      {privateCustomer && (
                        <Input
                          key={name.name}
                          options={name}
                          onChange={name.onChange}
                          ref={input => {
                            name.ref = input;
                          }}
                        />
                      )}
                    </React.Fragment>
                  )}

                  {Array.from(Array(groupCount).keys()).map(index => (
                    <div key={index} className="">
                      {this.renderGroup(controls, index)}
                    </div>
                  ))}
                  {postponeDelivery && (
                    <div className="postpone-delivery mt-0">
                      {postponeDelivery && (
                        <div className="row mb-2">
                          <div className="col">
                            <span>
                              <RadioButtonComponent
                                checked={postponedCheck}
                                onChange={onPostponedDelivery}
                                className={'cursor-pointer'}
                                label={<FormattedMessage id="ph_choose_desire_date" />}
                              />
                              <img
                                src={Constants.icons.infoIconGreen}
                                className="ml-2 cursor-pointer"
                                width="24"
                                height="24"
                                alt="tick-icon"
                                title={Constants.language.ph_choose_desire_date}
                              />
                            </span>
                          </div>
                        </div>
                      )}
                      {postponeDelivery && postponedCheck && (
                        <>
                          <div className="row ml-3">
                            <div className="col">
                              <div className="row">
                                <div className="col postpone-delivery-label">
                                  <FormattedMessage id="ph_futute_delivery" />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-5 pl-3 ">
                                  <div className="date-box">
                                    <label style={{ display: 'flex' }} className="mb-0">
                                      <DatePicker
                                        onChange={this.handleDateChange}
                                        selected={postponedDate}
                                        minDate={addDays(new Date(), 10)}
                                        disabledKeyboardNavigation
                                        maxDate={addDays(new Date(), 365)}
                                        excludeDates={getWeekends()}
                                        onChangeRaw={this.handleOnChangeRawDate}
                                      />
                                      <div className="m-2 ml-0">
                                        <i className="ml-0 fa fa-calendar cursor-pointer" aria-hidden="true" />
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
                {errorMessage && (
                  <div className="row">
                    <div className="invalid-feedback d-inline-block text-center px-4 mt-0 mb-3">
                      <p className={'' + errorMessage ? 'visible mb-0' : 'invisible mb-0'}>
                        <small> {errorMessage} </small>
                      </p>
                    </div>
                  </div>
                )}

                {showNext && (
                  <div className="d-inline-block checkout-next-btn-size">
                    {!previousCardId && (
                      <button
                        className={
                          'btn ' +
                          (isValid && (!customerDetailsForm || !businessCustomer || corporateId.isValid)
                            ? 'btn-primary'
                            : 'btn-primary')
                        }
                        disabled={!isValid || (customerDetailsForm && businessCustomer && !corporateId.isValid)}
                        onClick={this.handleFormNext.bind(this, {
                          index,
                          businessCustomer,
                          corporateId,
                          email,
                          phone,
                        })}
                      >
                        <FormattedMessage id="common_next" />
                      </button>
                    )}
                    {previousCardId && (
                      <button
                        className={
                          'btn ' +
                          (isValid && (!customerDetailsForm || !businessCustomer || corporateId.isValid)
                            ? 'btn-primary'
                            : 'btn-secondary')
                        }
                        disabled={!isValid || (customerDetailsForm && businessCustomer && !corporateId.isValid)}
                        onClick={this.handleFormNext.bind(this, {
                          index,
                          businessCustomer,
                          corporateId,
                          email,
                          phone,
                        })}
                      >
                        <FormattedMessage id="common_done" />
                      </button>
                    )}
                  </div>
                )}
              </React.Fragment>
            </div>

            {/* Card Filled */}
            {/* Customer Details */}
            {cardId !== index && index === 0 && (
              <div className="filled-form col mb-1 mt-3 pt-1">
                <div className="row justify-content-between line-height-20">
                  {!privateCustomer && (
                    <div className="col-12 mb-0">
                      <p className="mb-1">
                        <span className="font-weight-semibold">
                          <FormattedMessage id="ph_company_name" />:{' '}
                        </span>{' '}
                        {' ' + controls[0].value}
                      </p>
                      {controls[1].value && (
                        <p className="mb-1">
                          <span className="font-weight-semibold">Org.nr.:</span>
                          {controls[1].value}{' '}
                        </p>
                      )}
                      {/*this.reseller.name && (
                        <p className="mb-1">
                          <span className="font-weight-semibold">
                            <FormattedMessage id="all_contracts_reseller" />:{" "}
                          </span>
                          {this.reseller.name}
                        </p>
                      )*/}
                    </div>
                  )}

                  <div className="col-12 mb-3">
                    {privateCustomer && (
                      <p className="mb-1">
                        <span className="font-weight-semibold">
                          <FormattedMessage id="customer_name" />:{' '}
                        </span>
                        {this.isResellerUser ? controls[2].value : controls[2].value}
                      </p>
                    )}
                    {this.reseller && this.reseller.name && (
                      <p className="mb-0">
                        <span className="font-weight-semibold">
                          <FormattedMessage id="all_contracts_reseller" />:{' '}
                        </span>
                        {this.reseller.name}
                      </p>
                    )}
                  </div>
                  <div className="col-12 mb-3">
                    <p className={'font-weight-semibold mb-0'}>
                      <FormattedMessage id="ph_contact_details" />
                    </p>
                    {!privateCustomer && <p className="mb-0">{controls[2].value}</p>}
                    <p className="mb-0"> {controls[4].value} </p>
                    <p className="mb-0">{controls[3].value} </p>
                  </div>

                  {/* {!businessCustomer && <div className="col" />} */}
                  <div className="col-12 mb-3">
                    <p className="font-weight-semibold mb-0">
                      <FormattedMessage id="contract_details_edit_address" />:
                    </p>
                    <p className="mb-0"> {controls[5].value} </p>
                    <p className="mb-0">
                      {' '}
                      {controls[6].value}
                      {' - '}
                      {controls[6].city}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* Billing Details */}
            {cardId !== index && index === 1 && (
              <>
                <div className="filled-form col mb-1 mt-3">
                  <div className="row line-height-20">
                    <div className="col-12 mb-3">
                      <p className="mb-1">
                        <span className="font-weight-semibold">
                          <FormattedMessage id="ph_billing_email" />:{' '}
                        </span>
                        {controls[0].value}
                      </p>
                      <p className="mb-0">
                        <span className="font-weight-semibold">
                          <FormattedMessage id="ph_billing_reference" />:{' '}
                        </span>
                        {controls[3].value}
                      </p>
                    </div>
                    <div className="col-12">
                      <p className="font-weight-semibold mb-0">
                        <FormattedMessage id="ph_contact_details" />
                      </p>
                      <p className="mb-0"> {controls[1].value} </p>
                      <p className="mb-0">
                        {controls[2].value}
                        {/* {this.isResellerUser
                          ? this.formatPhone(this.reseller.name)
                          : this.formatPhone(controls[2].value)} */}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Installation Details */}
            {cardId !== index && index === 2 && (
              <div className="filled-form col mb-1 mt-3">
                <div className="row line-height-20">
                  <div className="col-12 mb-3">
                    <p className="font-weight-semibold mb-0">
                      <FormattedMessage id="ph_contact_details" />
                    </p>
                    <p className="mb-0"> {controls[0].value} </p>
                    <p className="mb-0">{controls[2].value}</p>
                    <p className="mb-0">{controls[1].value} </p>
                    {/* <p className="mb-1"> {this.isResellerUser ? this.reseller.name : controls[2].value} </p> */}
                  </div>

                  <div className="col-12">
                    <p className="font-weight-semibold mb-0">
                      <FormattedMessage id="contract_details_edit_address" />:
                    </p>
                    <p className="mb-0"> {controls[3].value} </p>
                    <p className="mb-0">
                      {' '}
                      {controls[4].value}
                      {' - '}
                      {controls[4].city}
                    </p>
                    <p className="mb-0">{controls[5] && controls[5].value} </p>
                  </div>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
    );
  }

  handleOnClickSubmit({ serviceSection }) {
    if (serviceSection === Constants.orderTypes.service) {
      this.confirmServiceOrder();
    } else if (serviceSection === Constants.orderTypes.proposalProduct) {
      this.confirmProductProposal();
    } else if (serviceSection === Constants.orderTypes.proposalService) {
      this.confirmServiceProposal();
    } else {
      this.confirmProductOrder();
    }
  }

  handleShippingChargesChange = newShippingCharges => this.setState({ shippingCharges: Number(newShippingCharges) });

  renderCheckoutSection = ({
    items,
    total,
    type,
    isTermsConditionsSelected,
    isOptInSelected,
    isOptInAvarnSelected,
    customerDetails,
    billingAddress,
    shippingAddress,
    cardId,
    totalService,
    shippingCharges,
    customerType,
    onShippingChargesChange,
    isProposal = false,
    installationData,
    isServiceProposal = false,
  }) => {
    const hasRequiredItems = items.some(
      item => item.description === 'Brannvesenet Direkte' || item.description === 'Avarn Utrykning'
    );
    const isServiceSection = type === 'serviceSection' ? true : false;
    const { emails } = this.state;
    return (
      <div className="col-6 card confirm-order-total h-100 card-rounded">
        <h3 className="mb-0">
          {isServiceSection ? <FormattedMessage id="common_services" /> : <FormattedMessage id="all_products_header" />}
        </h3>
        <p className="product-sub-text mb-0">
          {isServiceSection ? (
            <>
              {items.length} <FormattedMessage id="new_order_services_selected" />
            </>
          ) : (
            <>
              {items.map(obj => obj.quantity).reduce((accumulator, quantity) => accumulator + quantity)}{' '}
              <FormattedMessage id="new_order_products_selected" />
            </>
          )}
        </p>
        <div className="border-bottom color-1d1d1d fs-14 row mb-1 mt-2 mx-0">
          <div className="col pl-0">
            <FormattedMessage id="common_name" />{' '}
          </div>
          {!isServiceSection && (
            <div className="col-2 text-center px-0">
              <FormattedMessage id="common_quantity" />
            </div>
          )}
          <div className="col-3 text-right pr-0">
            {!isServiceSection ? <FormattedMessage id="common_price" /> : <FormattedMessage id="common_price" />}
            {isServiceSection && (
              <>
                {' /'}
                <FormattedMessage id="common_per_month" />
              </>
            )}
          </div>
        </div>
        <div className={' fs-14 ' + (isServiceSection ? 'mb-2 pb-0' : '')}>
          {items &&
            items.map((item, index) => (
              <div
                key={index}
                className={
                  'color-1d1d1d row mx-0 ' +
                  (isServiceSection ? (item.children ? 'font-weight-semibold ' : '') : 'font-weight-semibold ') +
                  (index + 1 === items.length ? 'border-bottom pb-1 ' : '')
                }
              >
                <div className="col text-truncate pl-0" title={item.description}>
                  {isServiceSection && item.description}
                  {!isServiceSection && item.description}
                </div>
                {!isServiceSection && <div className="col-2 text-center px-0">{item.quantity}</div>}

                {isProposal && !isServiceProposal ? (
                  <>
                    <div className="col-3 text-right pr-0">
                      {Number(item.quantity * item.defaultPrice).toFixed(2)}
                      {Constants.currency.norway}
                    </div>
                  </>
                ) : (
                  <div className="col-3 text-right pr-0">
                    {!isServiceSection ? (
                      <>
                        {Number(item.quantity * item.defaultPrice).toFixed(2)}
                        {Constants.currency.norway}
                      </>
                    ) : (
                      <>
                        {Number(item.defaultPrice).toFixed(2)}
                        {Constants.currency.norway}
                      </>
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
        {isProposal && installationData && installationData.installationRequired && (
          <>
            <div className="border-bottom color-1d1d1d fs-14 row mb-1 mt-2 mx-0 font-weight-semibold">
              <div className="col pl-0">
                <FormattedMessage id="common_installation" />
              </div>
              {isProposal && installationData && installationData.fixedPrice ? (
                <div className="col-3 text-right pr-0">
                  {installationData.price}
                  {Constants.currency.norway}
                </div>
              ) : (
                <>
                  <div className="col-2 text-center px-0">{installationData.hourlyPrice.hours}</div>
                  <div className="col-3 text-right pr-0">
                    {installationData.hourlyPrice.pricePerHour * installationData.hourlyPrice.hours}
                    {Constants.currency.norway}
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {!isServiceSection && !isProposal && (
          <div className="row border-bottom fs-14 color-1d1d1d mb-2 py-1 mx-0">
            <div className="col pl-0">
              <span>
                <FormattedMessage id="common_shipping" />
              </span>
            </div>
            <div className="col-auto pr-0">
              <span className={(this.isSuperAdminUser || this.isAdminBackOffice) && 'price-edit p-1'}>
                {this.isSuperAdminUser || this.isAdminBackOffice ? (
                  <PriceEditable
                    text={shippingCharges}
                    onChange={onShippingChargesChange}
                    labelClassName="ItemPriceEditable"
                    inputClassName="ItemPriceEditable bg-transparent text-right"
                    inputWidth="90px"
                    inputHeight="30px"
                    inputMaxLength="50"
                  />
                ) : (
                  shippingCharges
                )}
                {Constants.currency.norway}
              </span>
            </div>
          </div>
        )}
        {customerType !== CustomerType.PRIVATE_CUSTOMER && (
          <div className="row color-1d1d1d fs-14">
            <div className="col">
              <FormattedMessage id="common_total" />
            </div>
            <div className="col text-right">
              <>
                {Number(total ? total : totalService).toFixed(2)}
                {Constants.currency.norway}
              </>
            </div>
          </div>
        )}
        <div className="row color-1d1d1d fs-14 mb-1">
          <div className="col">
            <FormattedMessage id="common_tax" />
          </div>
          <div className="col text-right">
            {isServiceSection
              ? customerType === CustomerType.PRIVATE_CUSTOMER
                ? Number((Number(totalService) * 0.25) / 1.25).toFixed(2)
                : Number(Number(totalService) * Number(this.taxPercentage)).toFixed(2)
              : Number(Number(total) * Number(this.taxPercentage)).toFixed(2)}
            {Constants.currency.norway}
          </div>
        </div>
        <div className="row font-weight-bold mb-2">
          <div className="col">
            <h4 className="font-weight-bold m-0">
              <FormattedMessage id="common_total_inc_tax" />
            </h4>
          </div>
          <div className="col text-right">
            <h4 className="font-weight-bold m-0">
              {isServiceSection
                ? customerType === CustomerType.PRIVATE_CUSTOMER
                  ? Number(totalService).toFixed(2)
                  : Number(totalService + totalService * this.taxPercentage).toFixed(2)
                : Number(total + total * this.taxPercentage).toFixed(2)}
              {Constants.currency.norway}
              {isServiceSection && (
                <>
                  {' /'}
                  <FormattedMessage id="common_per_month" />
                </>
              )}
            </h4>
          </div>
        </div>
        {(isProposal || isServiceProposal) && (
          <div className="my-2">
            <p className="mt-3 mb-1 fs-14">
              <FormattedMessage id="common_send_pdf_text" />
            </p>
            <ReactMultiEmail
              placeholder={<FormattedMessage id="warning_email" />}
              emails={emails}
              onChange={this.handleChangeEmail}
              validateEmail={email => {
                return isEmail(email);
              }}
              getLabel={(email, index, removeEmail = index) => {
                return (
                  <div data-tag key={index} className="px-3 py-2">
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      <img src={Constants.icons.crossNew1} alt="icon" height="10px" width="10px" />
                    </span>
                  </div>
                );
              }}
            />
          </div>
        )}
        {(!isProposal || !isServiceProposal) && (
          <div className="row pl-3">
            <div className="d-flex mt-2" onClick={this.handleOptInCheck}>
              <CheckBox isSelected={isOptInSelected} onClick={this.handleOptInCheck} />
            </div>

            <div className="col pl-0">
              <div className="row ml-2 font-extra-services">
                <p className="mb-2 pb-1">
                  <FormattedMessage id={'send_order_confirmation_label'} />
                </p>
              </div>
            </div>
          </div>
        )}
        {(!isProposal || !isServiceProposal) && !this.isResellerUser && hasRequiredItems && (
          <div className="row pl-3">
            <div className="d-flex mt-2" onClick={this.handleAvarnOptInCheck}>
              <CheckBox isSelected={isOptInAvarnSelected} onClick={this.handleAvarnOptInCheck} />
            </div>

            <div className="col pl-0">
              <div className="row ml-2 font-extra-services">
                <p className="mb-2 pb-1">
                  <FormattedMessage id={'send_confirmation_email_to_avarn_label'} />
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="row pl-3">
          <div className="d-flex mt-2" onClick={this.handleTermsConditionCheck}>
            <CheckBox isSelected={isTermsConditionsSelected} onClick={this.handleTermsConditionCheck} />
          </div>

          <div className="col pl-0">
            <div className="row ml-2 font-extra-services">
              <p className="mb-2 pb-1">
                {isProposal || isServiceProposal ? (
                  <FormattedMessage id={'new_proposal_agree_terms'} />
                ) : (
                  <FormattedMessage id={'new_order_agree_terms'} />
                )}{' '}
                <u>
                  <a
                    className="font-weight-normal"
                    href={'https://rackit.no/abonnementsvilkar/'}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FormattedMessage id={isServiceSection ? 'common_subscription_terms_1' : 'common_terms_1'} />
                  </a>
                </u>{' '}
                {<FormattedMessage id="common_and" />}
                <u>
                  <a
                    className="font-weight-normal mr-1"
                    href="https://rackit.no/personvern/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FormattedMessage id="common_privacy_policy_1" />.
                  </a>
                </u>
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-dark w-180-max "
              disabled={
                isProposal || isServiceProposal
                  ? !(customerDetails.isValid && cardId > 0 && isTermsConditionsSelected)
                  : !(
                      customerDetails.isValid &&
                      billingAddress.isValid &&
                      shippingAddress.isValid &&
                      cardId > 2 &&
                      isTermsConditionsSelected
                    )
              }
              onClick={this.handleOnClickSubmit.bind(this, {
                serviceSection:
                  isServiceSection && !isServiceProposal
                    ? Constants.orderTypes.service
                    : isProposal
                    ? Constants.orderTypes.proposalProduct
                    : isServiceProposal && isServiceProposal
                    ? Constants.orderTypes.proposalService
                    : Constants.orderTypes.product,
              })}
            >
              {isProposal || isServiceProposal ? (
                <FormattedMessage id="proposals_confirm_new_proposal" />
              ) : (
                <FormattedMessage id="orders_create_new_order" />
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };

  getShippingCharges = (total = 0) => {
    if (total <= 5000) {
      return 160;
    } else if (total > 5000 && total <= 20000) {
      return 350;
    } else if (total > 20000) {
      return 550;
    }
    return 0;
  };

  getOrderTotal = order => {
    const products = (order && order.products) || [];
    let total = products.reduce((prevTotal, currentProduct) => {
      return prevTotal + Number(currentProduct.quantity) * Number(currentProduct.defaultPrice);
    }, 0);
    if (
      order &&
      order.orderType === 'proposal' &&
      order.installationData &&
      order.installationData.installationRequired
    ) {
      const { installationData = {} } = order;
      if (installationData && installationData.fixedPrice) {
        total = Number(total) + Number(installationData.price);
      } else {
        const { hourlyPrice: { hours = 0, pricePerHour = 0 } = {} } = installationData;
        total = Number(total) + Number(hours) * Number(pricePerHour);
      }
    }
    return total;
  };

  render() {
    const {
      isSubmitted,
      order,
      navigation,
      location: { pathname },
    } = this.props;
    const {
      billingIsSameAsMain,
      shippingIsSameAsMain,
      customerDetails,
      billingAddress,
      shippingAddress,
      cardId,
      showTimeoutError,
      loading,
      toastData,
      showToast,
      serviceSection,
      isTermsConditionsSelected,
      isOptInSelected,
      isOptInAvarnSelected,
      shippingCharges,
    } = this.state;
    let products = [];
    const { installationData = {} } = order;
    if (order && order.products) {
      products = order.products;
    }
    const isProposal = pathname === Constants.routes.newProposalConfirmProposalProducts;
    const isServiceProposal = pathname === Constants.routes.newProposalConfirmProposalServices;
    let total = this.getOrderTotal(order);

    total = isProposal ? total : total + shippingCharges;

    // let totalWithTax = total + total * this.taxPercentage;
    let services = [];
    if (order && order.services) {
      services = order.services;
    }
    let totalService = 0;
    if (services && services.length) {
      for (let i = 0; i < services.length; i++) {
        const service = services[i];
        totalService = totalService + Number(service.defaultPrice);
      }
    }

    totalService += Number(
      isProposal && installationData && installationData.fixedPrice
        ? installationData.price
        : installationData.hourlyPrice
        ? installationData.hourlyPrice.pricePerHour * installationData.hourlyPrice.hours
        : 0
    );

    total += totalService;
    const { customer, customerType } = order;
    return (
      <React.Fragment>
        {showToast && (
          <Toast
            type={toastData.type}
            title={toastData.title}
            message={toastData.message}
            showToast={showToast}
            delay={toastData.delay}
          />
        )}
        <div id="confirmOrder">
          <div className={'container-fluid px-0 mt-3'}>
            <div className=" ">
              <div className="row mb-3">
                <div className="col-6  bg-green">
                  <h1 className="mb-3 font-weight-semibold">
                    <FormattedMessage id="new_order_order_completion" />
                  </h1>
                  <p className="">
                    {order.orderType === 'service-order' ? (
                      customerType === CustomerType.PRIVATE_CUSTOMER ? (
                        <FormattedMessage id="new_service_tax_included" />
                      ) : (
                        <FormattedMessage id="new_service_tax_excluded" />
                      )
                    ) : (
                      ' '
                    )}
                  </p>
                </div>
                <div className="col" />
                <div className="col-2 pr-0 text-right bg-red justify-content-end">
                  <a href="#back" className="font-weight-normal fs-14" onClick={this.goBack}>
                    <img className="mr-2" src={Constants.icons.arrowBack} width="18" height="18" alt="back-icon" />
                    <u>
                      <FormattedMessage id="common_back" />
                    </u>
                  </a>
                </div>
              </div>

              <LoadingOverlay
                active={loading}
                spinner={
                  <BounceLoader
                    size={Constants.LOADER_PROPS.SIZE}
                    sizeUnit={Constants.LOADER_PROPS.SIZE_UNIT}
                    loading={loading}
                  />
                }
                classNamePrefix={'confirmOrder_'}
                text={<FormattedMessage id="common_loading_overlay" />}
              >
                {!isSubmitted && customer && this.state.order && (
                  <section
                    id="selected-products"
                    className={(isProposal || isServiceProposal) && loading ? 'pb-5' : '' + 'mb-5'}
                  >
                    <div>
                      <div className="row justify-content-right">
                        <div className="col-6">
                          {!serviceSection && (
                            <React.Fragment>
                              {this.renderForm(
                                customerDetails,
                                this.controls.customerDetails,
                                0,
                                true,
                                3,
                                '1',
                                <FormattedMessage id="common_customer_details" />,
                                'customerDetails'
                              )}

                              {!isProposal &&
                                !isServiceProposal &&
                                this.renderForm(
                                  billingAddress,
                                  this.controls.billingAddress,
                                  1,
                                  true,
                                  2,
                                  '2',
                                  <FormattedMessage id="common_billing" />,
                                  'billingAddress',
                                  true,
                                  billingIsSameAsMain,
                                  this.handleBillingSameAsMain
                                )}

                              {!isProposal &&
                                !isServiceProposal &&
                                this.renderForm(
                                  shippingAddress,
                                  this.controls.shippingAddress,
                                  2,
                                  true,
                                  2,
                                  '3',
                                  <FormattedMessage id="common_shipping_address" />,
                                  'shippingAddress',
                                  true,
                                  shippingIsSameAsMain,
                                  this.handleShippingSameAsMain,
                                  true,
                                  this.handlePostponedDelivery
                                )}
                            </React.Fragment>
                          )}

                          {serviceSection && (
                            <React.Fragment>
                              {this.renderForm(
                                customerDetails,
                                this.controls.customerDetails,
                                0,
                                true,
                                3,
                                '1',
                                <FormattedMessage id="common_customer_details" />,
                                'customerDetails'
                              )}

                              {!isProposal &&
                                !isServiceProposal &&
                                this.renderForm(
                                  billingAddress,
                                  this.controls.billingAddress,
                                  1,
                                  true,
                                  2,
                                  '2',
                                  <FormattedMessage id="common_billing" />,
                                  'billingAddress',

                                  true,
                                  billingIsSameAsMain,
                                  this.handleBillingSameAsMain
                                )}

                              {!isProposal &&
                                !isServiceProposal &&
                                this.renderForm(
                                  shippingAddress,
                                  this.controls.shippingAddress,
                                  2,
                                  true,
                                  2,
                                  '3',
                                  <FormattedMessage id="common_installation_address" />,
                                  'shippingAddress',
                                  true,
                                  shippingIsSameAsMain,
                                  this.handleShippingSameAsMain
                                )}
                            </React.Fragment>
                          )}
                        </div>

                        {/* Products Section */}
                        {!serviceSection &&
                          this.renderCheckoutSection({
                            items: products,
                            total,
                            type: 'productSection',
                            isTermsConditionsSelected,
                            isOptInSelected,
                            isOptInAvarnSelected,
                            customerDetails,
                            billingAddress,
                            shippingAddress,
                            cardId,
                            shippingCharges,
                            isProposal,
                            installationData,
                            onShippingChargesChange: this.handleShippingChargesChange,
                          })}

                        {/* Services Section */}
                        {serviceSection &&
                          this.renderCheckoutSection({
                            items: services,
                            totalService,
                            billingAddress,
                            shippingAddress,
                            isTermsConditionsSelected,
                            isOptInSelected,
                            isOptInAvarnSelected,
                            customerDetails,
                            cardId,
                            type: 'serviceSection',
                            customerType,
                            isServiceProposal,
                          })}
                      </div>
                    </div>
                  </section>
                )}
                {isSubmitted && (
                  <React.Fragment>
                    <section id="selected-products" className="mb-5">
                      <div className="container">
                        <div className="row justify-content-center">
                          <h4>
                            <FormattedMessage id="new_order_order_success" />
                          </h4>
                          <p className="mb-5">
                            <FormattedMessage id="new_order_order_success_for_product" />
                          </p>
                          <p>
                            <FormattedMessage id="new_order_order_success_2" />{' '}
                            <a href="#my-order">
                              <FormattedMessage id="bc_my_orders" />
                            </a>{' '}
                            <FormattedMessage id="new_order_order_success_3" />
                          </p>
                        </div>
                        <div className="row">
                          <div className="col text-center">
                            <button className="btn btn-dark btn-sm w-50 " onClick={navigation.goBack()}>
                              <FormattedMessage id="new_order_back_to_dashboard" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </section>
                  </React.Fragment>
                )}
              </LoadingOverlay>
              <Modal open={showTimeoutError} centered={false}>
                <Modal.Header>Timed out</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <p>Your session is timeout. Please login again and create order again.</p>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  order: state.order,
});

export default connect(
  mapStateToProps,
  { finishOrder, selectOrder }
)(ConfirmOrder);
