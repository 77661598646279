import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import Constants from '../../shared/constants';

class Pagination extends Component {
  handleClick = (evt, pageNumber) => {
    evt.preventDefault();
    this.props.gotoPage(pageNumber);
  };

  handlePageClick = ({ pageNumber }, e) => {
    const { cursor } = this.props;
    e.preventDefault();
    if (cursor.pageNumber > 1 || cursor.pageNumber < cursor.pages) {
      this.props.gotoPage(pageNumber);
    }
  };
  render() {
    const { cursor } = this.props;
    return (
      <Fragment>
        <nav>
          <ul className="pagination">
            <li className="page-item my-auto">
              <a
                href="#previous"
                aria-label="Previous"
                className={'mr-2 pr-1 ' + (cursor.pageNumber <= 1 ? 'disabled-state pointer-none' : '')}
                onClick={this.handlePageClick.bind(this, {
                  pageNumber: cursor.pageNumber - 1,
                })}
              >
                <img src={Constants.icons.paginationLeft} width="24" height="24" alt="icon" />
              </a>
            </li>

            <li className="page-item">
              <span className="page-link page-details border-0 font-weight-normal text-black-1">
                <span>
                  {cursor.pageSize * (cursor.pageNumber - 1) + 1} -{' '}
                  {cursor.pageSize * cursor.pageNumber > cursor.totalCount
                    ? cursor.totalCount
                    : cursor.pageSize * cursor.pageNumber}{' '}
                  of {cursor.totalCount}
                </span>
              </span>
            </li>

            <li className="page-item my-auto">
              <a
                className={'ml-2 pl-1 ' + (cursor.pageNumber >= cursor.pages ? 'disabled-state pointer-none' : '')}
                href="#next"
                aria-label="Next"
                onClick={this.handlePageClick.bind(this, {
                  pageNumber: cursor.pageNumber + 1,
                })}
              >
                <img src={Constants.icons.paginationRight} width="24" height="24" alt="icon" />
              </a>
            </li>
          </ul>
        </nav>
      </Fragment>
    );
  }
}

Pagination.propTypes = {
  cursor: PropTypes.object.isRequired,
  gotoPage: PropTypes.func.isRequired,
};

export default Pagination;
