import {
  GET_ALL_PRODUCTS,
  PRODUCTS_ARE_LOADING,
  UPDATE_TOTAL_PRODUCTS
} from "../actions/types";
const initialState = {
  products: [],
  meta: {
    cursor: {
      startCursor: null,
      endCursor: null,
      pageNumber: 1,
      pageSize: 25,
      totalCount: null,
      pages: null
    }
  },
  totalProducts: [],
  isLoading: false
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        totalProducts: action.payLoad.totalProducts,
        products: action.payLoad.products,
        meta: action.payLoad.meta
      };
    case UPDATE_TOTAL_PRODUCTS:
      return { ...state, totalProducts: action.payLoad };
    case PRODUCTS_ARE_LOADING:
      return { ...state, isLoading: action.isLoading };
    default:
      return { ...state };
  }
};
