import React from 'react';

import Constants from '../../shared/constants';
import { ShortMonthNames, FullMonthNames } from './Period';
import LineChart from './LineChart';
import BarChart from './BarChart';

function SubscriptionOrderChat(obj) {
  const { interval } = obj;
  let data = obj.data;
  if (interval === 'weekly') {
    // Weekly data is not sorted
    data = obj.data.sort((a, b) => (a.year * 100 + a.week > b.year * 100 + b.week ? 1 : -1));
  }

  const numberFormat = new Intl.NumberFormat('sv-SE');

  const registrationSeries = data.map(subscription => subscription.registration);
  const totalRegistration = registrationSeries.reduce((a, b) => a + b, 0);

  const activationSeries = data.map(subscription => subscription.activation);
  const totalActivation = activationSeries.reduce((a, b) => a + b, 0);

  const terminationSeries = data.map(subscription => subscription.termination);
  const totalTermination = terminationSeries.reduce((a, b) => a + b, 0);

  const revenueSeries = data.map(subscription => parseInt(subscription.revenue.toFixed(0)));
  const cumulativeRevenueSeries = data.map(subscription => parseInt(subscription.cumulativeRevenue.toFixed(0)));
  const totalCumulativeRevenue = cumulativeRevenueSeries.reduce((a, b) => a + b, 0);

  const activationSubscriptionSeries = data.map(subscription => subscription.cumulativeActivation);
  const totalActiveSubscriptions = activationSubscriptionSeries.reduce((a, b) => a + b, 0);

  const axis = data.map(order => (interval === 'monthly' ? `${ShortMonthNames[order.month - 1]}` : order.week));
  const axisMonths = data.map(subscription => `${FullMonthNames[subscription.month - 1]}`);
  const years = data.map(subscription => subscription.year);

  const lineChartSeries = [
    {
      id: `${Constants.language.dashboard_registrations}`,
      name: `${Constants.language.dashboard_registrations} (${totalRegistration})`,
      data: registrationSeries,
      discrete: true,
    },
    {
      id: `${Constants.language.dashboard_activations}`,
      name: `${Constants.language.dashboard_activations} (${totalActivation})`,
      data: activationSeries,
      discrete: true,
    },
    {
      id: `${Constants.language.dashboard_terminations}`,
      name: `${Constants.language.dashboard_terminations} (${totalTermination})`,
      data: terminationSeries,
      discrete: true,
    },
  ];
  const barCharSeries = [
    {
      name: `${Constants.language.dashboard_total_revenue} (${numberFormat.format(totalCumulativeRevenue)}${
        Constants.currency.norway
      })`,
      data: cumulativeRevenueSeries,
      type: 'bar',
    },
  ];

  const CustomTooltip = data => {
    const { series, seriesIndex, dataPointIndex } = data;
    console.log(data);
    return `<div class="card card-white">
                <div class="card-header p-1 text-center tooltip-title">
                ${
                  interval === 'monthly'
                    ? `${axisMonths[dataPointIndex]} ${years[dataPointIndex]}`
                    : `${Constants.language.dashboard_week} ${axis[dataPointIndex]}`
                }
                </div>
                <div class="card-body p-1">
                  ${
                    series[0] && series[0][dataPointIndex] != undefined
                      ? `<div class="row my-0 px-1">
                          <div class="col-7 registrations">${lineChartSeries[0].id}:</div>
                          <div class="col-5 registrations text-right">${series[0][dataPointIndex]}</div>
                        </div>`
                      : ''
                  }
                  ${
                    series[1] && series[1][dataPointIndex] != undefined
                      ? `<div class="row my-0 px-1">
                          <div class="col-7 activations">${lineChartSeries[1].id}:</div>
                          <div class="col-5 activations text-right">${series[1][dataPointIndex]}</div>
                        </div>`
                      : ''
                  }
                  ${
                    series[2] && series[2][dataPointIndex] != undefined
                      ? `<div class="row my-0 px-1">
                          <div class="col-7 terminations">${lineChartSeries[2].id}:</div>
                          <div class="col-5 terminations text-right">${series[2][dataPointIndex]}</div>
                        </div>`
                      : ''
                  }
                </div>
                <div class="card-footer p-1 tooltip-footer">
                    <div class="row my-0 px-1">
                        <div class="col-7 text-left">MRR:</div>
                        <div class="col-5 text-right">
                          ${numberFormat.format(revenueSeries[dataPointIndex])}${Constants.currency.norway}
                        </div>
                    </div>
                </div>
            </div>`;
  };

  const CustomBarTooltip = data => {
    const { series, dataPointIndex } = data;
    return `<div class="card card-white">
                <div class="card-header p-1 text-center tooltip-title">
                ${
                  interval === 'monthly'
                    ? `${axisMonths[dataPointIndex]} ${years[dataPointIndex]}`
                    : `${Constants.language.dashboard_week} ${axis[dataPointIndex]}`
                }
                </div>
                <div class="card-body p-2">
                    <div class="row my-0 px-1">
                        <div class="col-7 activations">MRR:</div>
                        <div class="col-5 activations text-right">
                          ${numberFormat.format(series[0][dataPointIndex])}${Constants.currency.norway}
                        </div>
                    </div>
                </div>
                <div class="card-footer p-1 tooltip-footer">
                    <div class="row my-0 px-1">
                        <div class="col-7 text-left">${Constants.language.dashboard_active_subscriptions1}:</div>
                        <div class="col-5 text-right">
                          ${activationSubscriptionSeries[dataPointIndex]}${Constants.currency.norway}
                        </div>
                    </div>
                </div>
            </div>`;
  };

  return (
    <div className="row p-3">
      <div className="col m-0 p-0 mr-2">
        <LineChart
          series={lineChartSeries}
          xaxis={axis}
          height={350}
          colors={['#3794fc', '#009688', '#ff5252']}
          strokes={2}
          tooltip={{ custom: CustomTooltip }}
        />
      </div>
      <div className="col m-0 p-0 ml-2">
        <BarChart
          series={barCharSeries}
          xaxis={axis}
          height={350}
          color={'#009688'}
          tooltip={{ custom: CustomBarTooltip }}
          bottomPadding={15}
        />
        <div className="legend text-center">
          <span className="legend-revenue-marker"></span>
          {`${Constants.language.dashboard_total_revenue}`}
          <span className="legend-activation-marker ml-2"></span>
          {`${Constants.language.dashboard_active_subscriptions}`}
        </div>
      </div>
    </div>
  );
}

export default SubscriptionOrderChat;
