import React from 'react';
import { Link } from "react-router-dom";

const Thankyou = ( props ) => {

    return (
      <React.Fragment>
        <div className="row mt-8 align-items-center">
            <div className="col text-center">
                <h1 className="font-weight-light mb-5">{props.title}</h1>
                <p className="mb-5">
                  {props.message}
                </p>
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-4">
                <div className="text-center mt-3">
                    <Link to={props.url} className="btn btn-dark btn-sm">{props.btnText}</Link>
                </div>
            </div>
        </div>
      </React.Fragment>
    )
  }

  export default Thankyou;
