import AddProductForm from './AddProductForm';
import { FormattedMessage } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import React, { Component } from 'react';
import { Control, ValidationRule } from '../../shared/Control';
import Config from '../../config';
import ProductService from '../../services/ProductService';
import AuthService from '../../services/AuthService';
import Constants from '../../shared/constants';
import PropTypes from 'prop-types';
import validator from 'validator';
import { charLimit } from '../../shared/stringOperations';
// import EditProductmodal from "./EditProductForm";
import Toast from '../../components/Toast/index';

export class CreateProductmodal extends Component {
  productService = new ProductService(Config.apiBase);
  authService = new AuthService(Config.apiBase);

  state = {
    addProduct: { isValid: false, isFocus: false },
    addPrice: { isValid: false, isFocus: false },
    addStatus: { isValid: false, isFocus: false },
    addItem: { isValid: false, isFocus: false },
    newProductDescription: '',
    isPrivatePClicked: false,
    isPublicClicked: false,
    isResellerClicked3: false,
    tooglebox: false,
    enterReseller: false,
    Dropship1: false,
    Dropship0: false,
    show: false,
    dontshow: false,
    notFound: false,
    appendedCompsCount: 0,
    tbox: [],
    EditItems: false,
    query: '',
    mediaId: [],
    searchData: [],
    addProductState: {},
    isSubmitted: false,
    formValid: {
      description: false,
      inventoryNumber: false,
      defaultPrice: false,
      customerClass: false,
      dropship: false,
      vendor: false,
    },
    ismodalopen: false,
    activeOptions: [
      { value: 'Active', label: Constants.language.all_products_status_active },
      { value: 'Inactive', label: Constants.language.create_inactive },
      { value: 'NoSales', label: Constants.language.all_products_status_no_sales },
    ],
    showToast: false,
  };

  constructor(props) {
    super(props);
    this.controls = {
      addProduct: [
        new Control({
          name: 'description',
          type: 'string',
          label: <FormattedMessage id="create_product_name" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="create_product_name_error" />,
          }),
          id: 'addproduct',
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
      addStatus: [
        new Control({
          name: 'status',
          type: 'select',
          label: charLimit.charLimit(Constants.language.all_orders_order_status, 10) + '*',
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_status" />,
          }),

          id: 'customerType',
          classProps: 'w-186-max mt-0',
          options: this.state.activeOptions,
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
      addItem: [
        new Control({
          name: 'inventoryNumber',
          type: 'alphanumeric',
          label: <FormattedMessage id="create_item_nr" />,
          rule: new ValidationRule({
            method: validator.isLength,
            validWhen: true,
            message: <FormattedMessage id="warning_status" />,
            args: [{ min: 1, max: 20 }],
          }),
          id: 'additem',
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
      addEl: [
        new Control({
          name: 'elNumber',
          type: 'text',
          label: <FormattedMessage id="create_El_nr" />,
          id: 'addEl',
          onChange: this.handleInputChange,
        }),
      ],
      addPrice: [
        new Control({
          name: 'defaultPrice',
          type: 'number',
          label: <FormattedMessage id="create_Price" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="create_service_price_error" />,
          }),
          id: 'addPrice',
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
      addCompanyPrice: [
        new Control({
          name: 'priceClass',
          type: 'number',
          label: <FormattedMessage id="create_price_company" />,
          customerClass: '1',
          id: 'addCompanyPrice',
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
      addResellerPrice: [
        new Control({
          name: 'priceClass',
          type: 'number',
          label: <FormattedMessage id="create_price_reseller" />,
          customerClass: '4',
          id: 'addResellerPrice',
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
      addPrivatePrice: [
        new Control({
          name: 'priceClass',
          type: 'number',
          label: <FormattedMessage id="create_price_private" />,
          id: 'addPrivatePrice',
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
      itemClass: [
        new Control({
          name: 'itemClass',
          type: 'select',
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_item_class" />,
          }),

          id: 'itemClass',
          classProps: 'w-auto mt-0',
          options: this.state.itemClassOptions,
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
      installationTime: [
        new Control({
          name: 'installationTime',
          type: 'number',
          label: <FormattedMessage id="installation_time" />,
          id: 'addInstallationTime',
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        })
      ],
    };
  }

  quillModules = {
    toolbar: {
      container: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean'], ['custom']],
      handlers: {
        custom: () => {
          alert('Hello');
        },
      },
    },
    clipboard: { matchVisual: false },
  };
  async componentWillMount() {
    const { activeOptions } = this.state;
    let response = await this.productService.getItemClassEntity();
    const itemClassOptions = [];
    response.data.map(obj => {
      itemClassOptions.push({ label: obj.description, value: obj.id });
      return obj;
    });

    this.controls.itemClass[0].options = itemClassOptions;
    this.controls.itemClass[0].setValue(itemClassOptions[0].value);
    this.controls.addStatus[0].setValue(activeOptions[0].value);
    this.setState({ itemClassOptions, selectedCategory: itemClassOptions[0] });
  }
  componentWillUnmount() {
    this.controls = {};
  }
  validation(controls) {
    return controls.find(obj => obj.rule && !obj.isValid) === undefined;
  }
  validate(data) {
    let { isValid } = this.state;

    let valid = Object.keys(data).map(keys => {
      return data[keys];
    });
    let validarr = valid.filter(obj => {
      return obj === false;
    });
    isValid = validarr.length > 0 ? false : true;
    this.setState({ isValid });
  }

  handleInputChange = (name, value) => {
    if (name === 'inventoryNumber') {
      this.controls.addItem[0].setValue(value.value.replace(/[+\/]/, ''));
    }
    let { formValid } = this.state;
    if (value && value.isValid !== undefined) {
      formValid[name] = value.isValid;
    }
    this.validate(formValid);
  };
  handlePrivatePrice = () => {
    const { formValid } = this.state;
    if (this.state.isPrivatePClicked) {
      this.setState({ isPrivatePClicked: false });
      this.controls.addPrivatePrice[0].value = null;
      this.state.isPublicClicked || this.state.isResellerClicked3
        ? (formValid.customerClass = true)
        : (formValid.customerClass = false);
    } else {
      this.setState({ isPrivatePClicked: true });
      formValid.customerClass = true;
    }
    this.handleInputChange();
  };
  handleCompanyPrice = () => {
    const { formValid } = this.state;
    if (this.state.isPublicClicked) {
      this.setState({ isPublicClicked: false });
      this.controls.addCompanyPrice[0].value = null;
      this.state.isPrivatePClicked || this.state.isResellerClicked3
        ? (formValid.customerClass = true)
        : (formValid.customerClass = false);
    } else {
      this.setState({ isPublicClicked: true });
      formValid.customerClass = true;
    }
    this.handleInputChange();
  };
  handleResellerPrice = () => {
    const { formValid } = this.state;
    if (this.state.isResellerClicked3) {
      this.setState({ isResellerClicked3: false });
      this.controls.addResellerPrice[0].value = null;
      this.state.isPublicClicked || this.state.isPrivatePClicked
        ? (formValid.customerClass = true)
        : (formValid.customerClass = false);
    } else {
      this.setState({ isResellerClicked3: true });
      formValid.customerClass = true;
    }
    this.handleInputChange();
  };
  handleShowNumber = () => {
    this.setState({ Dropship1: true });
    this.setState({ Dropship0: false });
  };
  handleDontShowNumber = () => {
    this.setState({ Dropship1: false });
    this.setState({ Dropship0: true });
  };
  handleDropshipYes = () => {
    this.setState({ show: true });
    const { formValid } = this.state;
    formValid.dropship = true;
    this.handleInputChange();
    this.setState({ dontshow: false, formValid });
  };
  handleDropshipNo = () => {
    this.setState({ show: false });
    const { formValid } = this.state;
    formValid.dropship = true;
    this.handleInputChange();
    this.setState({ dontshow: true, formValid });
  };

  handleSearchVendor = async (name, options) => {
    let searchData;
    this.setState({ query: name });
    if (name.length > 3) {
      searchData = await this.productService.getVendor(name);
      this.setState({ searchData: searchData.data });
    }
  };

  handlepassvalue = value => {
    this.setState({ searchData: value });
  };
  getMediaCount = () => {
    const { mediaId } = this.state;
    return mediaId.reduce(function(obj, v) {
      obj[v.type] = (obj[v.type] || 0) + 1;
      return obj;
    }, {});
  };
  handleDocuments = async event => {
    let response,
      arrData = {};
    const { mediaId } = this.state;
    const array = this.fileListToArray(event.target.files || event.dataTransfer.files);
    let imageCount = this.getMediaCount();
    if (
      (imageCount[Constants.FileTypes.DOCUMENT] === undefined || imageCount[Constants.FileTypes.DOCUMENT] < 3) &&
      array
    ) {
      try {
        response = await this.productService.uploadmediaFile(array[0], Constants.FileTypes.DOCUMENT);
        let {
          data: {
            data: { id, name },
          },
        } = response;
        arrData.id = id;
        arrData.type = Constants.FileTypes.DOCUMENT;
        arrData.name = name;
        mediaId.push(arrData);
        this.setState(mediaId);
      } catch (error) {
        console.log(error);
      }
    }
  };
  handleFileDelete = ({ id, inventoryNumber }) => {
    const response = this.productService.removemediaFile(id, inventoryNumber);
    const { products } = this.state;
    if (response.status === 200) {
      const findedProduct = products.find(obj => obj.inventoryNumber === inventoryNumber)['media'];
      findedProduct.findIndex(obj => obj.file_id);
    }
    const findedProduct = products.find(obj => obj.inventoryNumber === inventoryNumber)['media'];
    const index = findedProduct.findIndex(obj => obj.file_id === id);
    findedProduct.splice(index, 1);
    this.setState({ products });
  };
  handleRemoveMedia = async data => {
    const { mediaId } = this.state;
    let index = mediaId.findIndex(obj => obj.id === data.id);

    let response;
    if (index > -1) {
      try {
        response = await this.productService.removemediaFile(data.id);
        if (response.status === 200) {
          mediaId.splice(index, 1);
          this.setState(mediaId);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  handleimage = async event => {
    let response,
      arrData = {};
    const { mediaId } = this.state;
    const array = this.fileListToArray(event.target.files || event.dataTransfer.files);
    let imageCount = this.getMediaCount();
    if ((imageCount[Constants.FileTypes.IMAGE] === undefined || imageCount[Constants.FileTypes.IMAGE] < 3) && array) {
      try {
        response = await this.productService.uploadmediaFile(array[0], Constants.FileTypes.IMAGE);

        let {
          data: {
            data: { id, name },
          },
        } = response;
        arrData.id = id;
        arrData.type = Constants.FileTypes.IMAGE;
        arrData.name = name;
        mediaId.push(arrData);
        this.setState(mediaId);
      } catch (error) {
        console.log(error);
      }
    }
  };
  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  productResponse = data => {
    this.setState({ showToast: true, toastData: data });
    setTimeout(() => {
      this.setState({ showToast: false });
    }, data.delay);
    if (data.type === Constants.toast.SUCCESS) {
      this.fetchProducts();
    }
  };

  handleOnCreateNewProduct = async () => {
    const { newProductDescription, selectedCategory, mediaId, addProductState, selectedStatus } = this.state;
    let mediaIds = mediaId.map(function(obj) {
      return obj.id;
    });
    this.controls.addProduct.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    // this.handleUserDetailsFocus();
    this.controls.addPrice.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    this.controls.addStatus.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    this.controls.addItem.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    if (!this.state.formValid.vendor) {
      this.setState({ notFound: true });
    }

    // this.handleUserDetailsFocus();
    //trigger State ToCall InputComponent;
    this.setState({ isSubmitted: true });

    const {
      addProduct,
      addPrice,
      addItem,
      addEl,
      addPrivatePrice,
      addResellerPrice,
      addCompanyPrice,
      addStatus,
      itemClass,
      installationTime
    } = this.controls;
    let data = {};
    if (this.state.isValid) {
      data = {
        inventoryNumber: addItem[0].value,
        description: addProduct[0].value,
        status: addStatus[0].value,
        itemClass: itemClass[0].value,
        defaultPrice: Number(addPrice[0].value),
        vendor: addProductState.selectedVendor.id,
        productBrief: newProductDescription,
        dropship: this.state.show,
        elNumber: addEl[0].value ? addEl[0].value : '',
        installationTime: installationTime[0].value ? Number(installationTime[0].value) : 0.0,
        priceClass: [
          {
            customerClass: '1',
            availability: this.state.isPrivatePClicked,
            price: this.state.isPrivatePClicked ? Number(addPrivatePrice[0].value) : null,
          },
          {
            customerClass: '4',
            availability: this.state.isPublicClicked,
            price: this.state.isPublicClicked ? Number(addCompanyPrice[0].value) : null,
          },
          {
            customerClass: '5',
            availability: this.state.isResellerClicked3,
            price: this.state.isResellerClicked3 ? Number(addResellerPrice[0].value) : null,
          },
        ],
        specifications: addProductState.techData,
        media: mediaIds,
        rackitWebAvailability: this.state.Dropship1,
      };

      this.props.onProductSaving();
      this.closeElement.click();
      try {
        let response = await this.productService.createProductItem(data);

        if (response.status === 201) {
          this.props.productResponse({
            type: Constants.toast.SUCCESS,
            message: <FormattedMessage id="toast_product_added" />,
            delay: Constants.toast.DELAY,
          });
          this.setState({ newProductSubmitted: true, loading: false });
          this.props.onProductSaved();
        } else {
          this.props.productResponse({
            type: Constants.toast.ERROR,
            message: <FormattedMessage id="toast_product_unable_added" />,
            delay: Constants.toast.DELAY,
          });
          this.setState({ productFailed: true, loading: false });
        }
      } catch (error) {
        if (error.message) {
          this.props.productResponse({
            type: Constants.toast.ERROR,
            message: <FormattedMessage id="toast_product_unable_added" />,
            delay: Constants.toast.DELAY,
          });
        }
        this.setState({ productFailed: true, loading: false });
      }
    }
  };

  handleNewProductEditorChange = value => {
    this.setState({ newProductDescription: value });
  };

  handleEditProductDetails = () => {
    let {
      addProduct,
      addCompanyPrice,
      addEl,
      addItem,
      addPrice,
      addPrivatePrice,
      addResellerPrice,
      addVendor,
    } = this.controls;
    addProduct[0].setValue(addProduct[0].value);
    addItem[0].setValue(addItem[0].value);
    addEl[0].setValue(addEl[0].value);
    addPrice[0].setValue(addPrice[0].value);
    addCompanyPrice[0].setValue(addCompanyPrice[0].value);
    addPrivatePrice[0].setValue(addPrivatePrice[0].value);
    addResellerPrice[0].setValue(addResellerPrice[0].value);
    addVendor[0].setValue(addVendor[0].value);
    this.setState({ EditItems: true });
  };
  handleOnClickServiceModalClose = () => {
    this.props.createProdutsModalClose();
  };
  handleAddDataState = data => {
    const { formValid } = this.state;
    formValid.vendor = data.selectedVendor && data.selectedVendor.id ? true : false;
    this.handleInputChange();
    this.setState({ addProductState: data, formValid });
  };

  render() {
    const {
      loading,
      isPrivatePClicked,
      isPublicClicked,
      isResellerClicked3,
      tooglebox,
      Dropship1,
      Dropship0,
      show,
      dontshow,
      newProductDescription,
      newProductFile,
      itemClassOptions,
      appendedCompsCount,
      mediaId,
      isValid,
      showToast,
      toastData,
      notFound,
      formValid,
      isSubmitted,
      enterReseller,
    } = this.state;

    return (
      <>
        <div
          className="modal fade bd-example-modal-lg fade-products"
          tabIndex="-1"
          data-backdrop="static"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="createproductmodal"
        >
          <div className="modal-dialog modal-lg modal-product">
            <div className="modal-content contracts-modal">
              <div className="modal-header modal-header-product ">
                <h3 className="modal-title font-weight-semibold" id="myLargeModalLabel">
                  <FormattedMessage id="create_add_product" />
                </h3>

                <button
                  type="button"
                  className="close outline-0"
                  data-dismiss="modal"
                  aria-label="Close"
                  ref={closeModal => (this.closeElement = closeModal)}
                  onClick={this.handleOnClickServiceModalClose}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body p-0 m-0 line-height-normal ls-normal">
                <LoadingOverlay
                  active={loading}
                  spinner={<BounceLoader />}
                  text={<FormattedMessage id="common_loading_overlay" />}
                  classNamePrefix={'light_modal_ '}
                  className={'loader-list-view-common'}
                >
                  <div className="p-4 my-2">
                    {showToast && (
                      <Toast
                        type={toastData.type}
                        message={toastData.message}
                        showToast={showToast}
                        delay={toastData.delay}
                      />
                    )}
                    <div className="row">
                      <div className="col">
                        <AddProductForm
                          notFound={notFound}
                          controls={this.controls}
                          isPrivatePClicked={isPrivatePClicked}
                          isPublicClicked={isPublicClicked}
                          isResellerClicked3={isResellerClicked3}
                          tooglebox={tooglebox}
                          Dropship1={Dropship1}
                          Dropship0={Dropship0}
                          show={show}
                          enterReseller={enterReseller}
                          dontshow={dontshow}
                          privatePrice={this.handlePrivatePrice}
                          companyPrice={this.handleCompanyPrice}
                          resellerPrice={this.handleResellerPrice}
                          ShowNumber={this.handleShowNumber}
                          DontShowNumber={this.handleDontShowNumber}
                          DropshipYes={this.handleDropshipYes}
                          DropshipNo={this.handleDropshipNo}
                          mediaArray={newProductFile}
                          quillModules={this.quillModules}
                          onFilesAdded={this.handleOnFilesAdded}
                          newProductDescription={newProductDescription}
                          newProductEditorChange={this.handleNewProductEditorChange}
                          itemClassOptions={itemClassOptions}
                          ProductModalClose={this.handleOnClickServiceModalClose}
                          handleOnSelectedCategory={this.handleOnSelectedCategory}
                          handleOnCreateNewProduct={this.handleOnCreateNewProduct}
                          onCreateNewProduct={this.handleOnCreateNewProduct}
                          appendedCompsCount={appendedCompsCount}
                          onFileUploading={this.props.onFileUploading}
                          onFileDelete={this.props.onFileDelete}
                          handleDocuments={this.handleDocuments}
                          handleimage={this.handleimage}
                          mediaId={mediaId}
                          handleSearchVendor={this.handleSearchVendor}
                          productService={this.productService}
                          searchData={this.state.searchData}
                          addDataState={this.handleAddDataState}
                          handleCollectVendorValue={this.handleCollectVendorValue}
                          isValid={isValid}
                          handleOnSelectedStatus={this.handleOnSelectedStatus}
                          formValid={formValid}
                          handleRemoveMedia={this.handleRemoveMedia}
                          quillblur={this.handleOnBlur}
                          isSubmitted={isSubmitted}
                        />
                      </div>
                    </div>
                  </div>
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
CreateProductmodal.propTypes = {
  onFileUploading: PropTypes.func,
};

export default CreateProductmodal;
