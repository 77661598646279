import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropType from 'prop-types';

const ContractDetailsTab = ({ currentTab }) => {
  const contractDetailsNavItems = ['Services', 'Details', 'Orders', 'Invoice', 'Activity Log'];
  return (
    <div className="col-12 ri-tab-header mb-4 pl-0">
      <ul className="nav nav-tabs pb-1 border-bottom-light mt-3" role="tablist">
        {contractDetailsNavItems.map((obj, index) => (
          <li className="nav-item text-left" key={index}>
            <a
              href="#product-10"
              id="10"
              className={
                'd-inline nav-link pb-1 mx-4 font-weight-normal contracts contract-details-tab-head fs-16 p-0 ' +
                (index === 0 && 'active')
              }
              data-toggle="tab"
              role="tab"
              onClick={currentTab.bind(this, obj)}
            >
              {obj === 'Services' ? (
                <FormattedMessage id="bc_services" />
              ) : obj === 'Details' ? (
                <FormattedMessage id="customer_nav_tab_1" />
              ) : obj === 'Orders' ? (
                <FormattedMessage id="contract_detail_order_tab" />
              ) : obj === 'Invoice' ? (
                <FormattedMessage id="contract_detail_invoice_tab" />
              ) : obj === 'Activity Log' ? (
                <FormattedMessage id="contract_detail_activity_tab" />
              ) : (
                ''
              )}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

ContractDetailsTab.propTypes = {
  currentTab: PropType.func,
};

export default ContractDetailsTab;
