import {
  CONTRACT_ACTION_LOADING,
  CONTRACT_MODAL_TYPE,
  CONTRACT_MODAL_TYPE_TERMINATE,
  CONTRACT_MODAL_TYPE_TERMINATE_ALL,
  CONTRACT_SUBSCRIPTION_DATA,
  CONTRACT_MODAL_DATE_CHANGE,
  CONTRACT_MODAL_DATA_ITEMS,
  CONTRACT_MODAL_SUCCESS_TYPE
} from "./types";

export function contractActionLoader(loadBool) {
  return function(dispatch) {
    dispatch({
      type: CONTRACT_ACTION_LOADING,
      payload: loadBool
    });
  };
}
export function contractActionError(loadBool) {
  return function(dispatch) {
    dispatch({
      type: CONTRACT_ACTION_LOADING,
      payload: loadBool
    });
  };
}

export function contractSubscriptionUpdate(data) {
  return function(dispatch) {
    dispatch({
      type: CONTRACT_SUBSCRIPTION_DATA,
      payload: data
    });
  };
}

export function contractModalTypeTerminate(type) {
  return function(dispatch) {
    dispatch({
      type: CONTRACT_MODAL_TYPE_TERMINATE,
      payload: type
    });
  };
}
export function contractModalTypeTerminateAll(type) {
  return function(dispatch) {
    dispatch({
      type: CONTRACT_MODAL_TYPE_TERMINATE_ALL,
      payload: type
    });
  };
}

export function contractModalTypeSelection(type) {
  return function(dispatch) {
    dispatch({
      type: CONTRACT_MODAL_TYPE,
      payload: type
    });
  };
}

export function contractModalDateChange(date) {
  return function(dispatch) {
    dispatch({
      type: CONTRACT_MODAL_DATE_CHANGE,
      payload: date
    });
  };
}

export function contractModalDataItems(data) {
  return function(dispatch) {
    dispatch({
      type: CONTRACT_MODAL_DATA_ITEMS,
      payload: data
    });
  };
}

export function contractModalSuccesModalType(data) {
  return function(dispatch) {
    dispatch({
      type: CONTRACT_MODAL_SUCCESS_TYPE,
      payload: data
    });
  };
}
