import React from 'react';
import Constants from '../../shared/constants';
import PropTypes from 'prop-types';

const PopUpClickable = ({ type, onClick, infoData, isOpen, isChildService, iconBlack }) => {
  const { title, subTitle, brief } = infoData;
  function toFocusOnDiv() {
    document.getElementById('pop-up-clickable-component').focus();
  }
  return (
    <div className="d-inline-block cursor-text">
      {type === 'info' && (
        <>
          <img
            src={iconBlack ? Constants.icons.infoBlack : Constants.icons.infoIconGreen}
            width="24"
            height="24"
            alt="icon"
            className={'mr-2 cursor-pointer ' + (isChildService ? '' : 'mb-1')}
            onClick={onClick}
          />
          {isOpen && (
            <div
              className={
                'bg-white-2 w-619-max h-225-max z-index-5 text-black card pop-up-info-service ' +
                (isChildService ? '' : 'pop-up-info-parent-service')
              }
              onBlur={onClick}
              tabIndex="-1"
              id="pop-up-clickable-component"
              onLoad={toFocusOnDiv.bind(this)}
            >
              <div className="row justify-content-between mb-3">
                <div className="col text-black">
                  <h3 className="d-inline-block font-weight-semibold mb-0 mr-2 ls-0-3">{title}</h3>
                  {subTitle && (
                    <>
                      <span className="font-weight-normal">｜</span>
                      <span className="ml-2 font-weight-normal fs-16">{subTitle}</span>
                    </>
                  )}
                </div>
                <div className="col-2 text-right">
                  <img
                    src={Constants.icons.crossNew1}
                    width="14"
                    height="14"
                    alt="icon"
                    className="cursor-pointer"
                    onClick={onClick}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col fs-16 font-weight-normal">{brief}</div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

PopUpClickable.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  infoData: PropTypes.object,
  isOpen: PropTypes.bool,
  isChildService: PropTypes.bool,
};

export default PopUpClickable;
