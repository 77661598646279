import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Constants from '../../../shared/constants';

import { contractModalTypeSelection } from '../../../state/actions/ContractActions';
import { contractModalDataItems } from '../../../state/actions/ContractActions';

class ContractsActionButtons extends Component {
  render() {
    const {
      addService,
      select,
      selected,
      onItemClick,
      data,
      createService,
      onClickActionButtons,
      contractID,
      contractModalTypeSelection,
      contractModalDataItems,
      type,
      contractStatus,
      handleOpenButtonMenu,
    } = this.props;
    return (
      <>
        {addService && contractStatus !== 'Terminated' && contractStatus !== 'Cancelled' && (
          <>
            <div className="contracts-action-button contracts-action-add-service font-weight-normal">
              <Link to={contractID + '/add-new-service'}>
                {' '}
                <img src={Constants.icons.iconAddBlack} width="24" height="24" className="m-0 mr-2" alt="icon" />{' '}
                {/* <i className="fa fa-plus add-service-icon" aria-hidden="true"></i>{' '} */}
                <span className="font-weight-normal">
                  {' '}
                  <FormattedMessage id="contract_button_add_service" />
                </span>
              </Link>
            </div>
          </>
        )}
        {createService && (
          <>
            <div
              className="btn btn-primary px-5"
              data-toggle="modal"
              data-target="#createServiceModal"
              onClick={onClickActionButtons}
            >
              <span>
                {' '}
                <i className="fa fa-plus add-service-icon" aria-hidden="true" />{' '}
                <FormattedMessage id="contract_button_create_service" />
              </span>
            </div>
          </>
        )}
        {(type === 'terminateAll' || type === 'terminate') && (
          <>
            <div
              className={'contracts-action-button contracts-action-terminate ' + (type === 'terminate' ? 'ml-2' : '')}
              data-toggle="modal"
              data-target={'#commonForAllModal'}
              onClick={() => {
                onClickActionButtons();
                handleOpenButtonMenu();
                contractModalTypeSelection({
                  contractModalType:
                    type === 'terminate'
                      ? {
                          terminate: true,
                        }
                      : { terminateAll: true },
                });
                contractModalDataItems({ contractSubItem: data });
              }}
            >
              {type === 'terminate' ? (
                <FormattedMessage id="contract_button_terminate_service" />
              ) : (
                <>
                  <img src={Constants.icons.iconAddBlack} className="add-service-icon-terminateall mr-2" alt="icon" />
                  {/* <i className="fa fa-plus add-service-icon add-service-icon-terminateall" aria-hidden="true"></i>{' '} */}
                  <span>
                    <FormattedMessage id="contract_button_terminate_all_service" />
                  </span>
                </>
              )}
            </div>
          </>
        )}
        {type === 'cancel' && (
          <>
            <div
              className="contracts-action-button contracts-action-cancel ml-2"
              data-toggle="modal"
              data-target={'#commonForAllModal'}
              onClick={() => {
                onClickActionButtons();
                handleOpenButtonMenu();
                contractModalTypeSelection({ contractModalType: { cancel: true } });
                contractModalDataItems({ contractSubItem: data });
              }}
            >
              <FormattedMessage id="contract_button_cancel_service" />
            </div>
          </>
        )}

        {(type === 'editTerminate' || type === 'editActivate') && (
          <>
            <div
              className="contracts-action-button contracts-action-edit ml-2 "
              data-toggle="modal"
              data-target={'#commonForAllModal'}
              onClick={() => {
                onClickActionButtons();
                handleOpenButtonMenu();
                contractModalTypeSelection({
                  contractModalType:
                    type === 'editTerminate'
                      ? {
                          terminate: true,
                        }
                      : {
                          activate: true,
                        },
                });
                contractModalDataItems({ contractSubItem: data });
              }}
            >
              <FormattedMessage id="contract_button_edit_service" />
            </div>
          </>
        )}
        {type === 'renew' && (
          <>
            <div
              className="contracts-action-button contracts-action-renew "
              data-toggle="modal"
              data-target={'#commonForAllModal'}
              onClick={() => {
                onClickActionButtons();
                handleOpenButtonMenu();
                contractModalTypeSelection({ contractModalType: { renew: true } });
                contractModalDataItems({ contractSubItem: data });
              }}
            >
              <FormattedMessage id="contract_button_renew_service" />
            </div>
          </>
        )}
        {(type === 'activate' || type === 'activateAll') && (
          <>
            <div
              className={
                (type === 'activate' ? 'ml-2' : 'mr-2 contracts-action-activateall') +
                ' contracts-action-button contracts-action-renew'
              }
              data-toggle="modal"
              data-target={'#commonForAllModal'}
              onClick={() => {
                onClickActionButtons();
                handleOpenButtonMenu();
                contractModalTypeSelection({
                  contractModalType:
                    type === 'activate'
                      ? {
                          activate: true,
                        }
                      : { activateAll: true },
                });
                contractModalDataItems({ contractSubItem: data });
              }}
            >
              {type === 'activate' ? (
                <FormattedMessage id="contract_button_activate_service" />
              ) : (
                <>
                  {/* <i
                    className="fa fa-check add-service-icon add-service-icon-activateall  m-0 mr-2"
                    aria-hidden="true"
                  ></i> */}
                  <img src={Constants.icons.tickWhite} className="add-service-icon-activateall  m-0 mr-2 " />
                  <span>
                    <FormattedMessage id="contract_button_activate_all_service" />
                  </span>
                </>
              )}
            </div>
          </>
        )}
        {selected && (
          <>
            <div className="contracts-action-button contracts-action-remove" onClick={onItemClick.bind(this, data)}>
              <span>Remove</span>
            </div>
          </>
        )}
        {select && (
          <>
            <div className="contracts-action-button contracts-action-select" onClick={onItemClick.bind(this, data)}>
              <FormattedMessage id="contract_button_select_service" />
            </div>
          </>
        )}
      </>
    );
  }
}

ContractsActionButtons.propTypes = {
  addService: PropTypes.bool,
  select: PropTypes.bool,
  selected: PropTypes.bool,
  onItemClick: PropTypes.func,
  data: PropTypes.object,
  createService: PropTypes.bool,
  onClickActionButtons: PropTypes.func,
  contractID: PropTypes.string,
  contractModalTypeSelection: PropTypes.func,
  contractModalDataItems: PropTypes.func,
  type: PropTypes.string,
  contractStatus: PropTypes.string,
};

const mapStateToProps = state => ({
  contract: state.contract,
});

export default connect(
  mapStateToProps,
  {
    contractModalTypeSelection,
    contractModalDataItems,
  }
)(ContractsActionButtons);
