import {
  CONTRACT_ACTION_LOADING,
  CONTRACT_ACTION_ERROR,
  CONTRACT_MODAL_TYPE,
  CONTRACT_SUBSCRIPTION_DATA,
  CONTRACT_MODAL_DATE_CHANGE,
  CONTRACT_MODAL_DATA_ITEMS,
  CONTRACT_MODAL_SUCCESS_TYPE
} from "../actions/types";

const initialState = { contractModalType: {}, selectedContractDate: undefined, nextBillingDate: undefined };

export default function(state = initialState, action) {
  switch (action.type) {
    case CONTRACT_ACTION_LOADING:
      return {
        ...state,
        ...action.payload
      };
    case CONTRACT_ACTION_ERROR:
      return {
        ...state,
        ...action.payload
      };

    case CONTRACT_MODAL_TYPE:
      return {
        ...state,
        ...action.payload
      };
    case CONTRACT_SUBSCRIPTION_DATA:
      return {
        ...state,
        ...action.payload
      };
    case CONTRACT_MODAL_DATE_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case CONTRACT_MODAL_DATA_ITEMS:
      return {
        ...state,
        ...action.payload
      };
    case CONTRACT_MODAL_SUCCESS_TYPE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
