import React from 'react';
import PropTypes from 'prop-types';
import ListViewComponent from '../../../ListView/ListView';
import UserListViewHeader from './UserListViewHeader';
import { charLimit } from '../../../../shared/stringOperations';
import { FormattedMessage } from 'react-intl';
import Constants from '../../../../shared/constants';
import UserModal from '../../UserModal';

class UserListView extends ListViewComponent {
  constructor(props) {
    super(props);

    this.state = {
      openColumn: '',
      userData: {},
      isEditModalOpen: false,
    };
  }

  handleUserOptions = (value, data) => {
    const { openColumn, userData } = this.state;
    if (openColumn === value) {
      this.setState({ openColumn: '', userData: data });
    } else {
      this.setState({ openColumn: Number(value), userData: data });
    }
  };

  handleDisplayOptions = () => () => {
    this.setState({ openColumn: '' });
  };

  handleOpenEditUserModal = () => {
    this.setState({ isEditModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  handleCloseModal = () => {
    this.setState({ isEditModalOpen: false });
  };

  render() {
    const { users, onSort, sortedBy, sortAscending, typeOptions, handleCreateUser, handleToast } = this.props;
    const { openColumn, userData, isEditModalOpen } = this.state;
    const roles = {
      'super-admin': Constants.language.user_options_superadmin,
      'admin-backoffice': Constants.language.user_options_backoffice,
      'admin-sales': Constants.language.user_options_adminsales,
      reseller: Constants.language.user_options_reseller,
    };

    return (
      <>
        <UserListViewHeader onSort={onSort} sortedBy={sortedBy} sortAscending={sortAscending} />

        {users.map((user, index) => {
          return (
            <div
              className={
                'row py-2 align-items-center table-row fs-14 ' +
                (index + 1 === users.length && ' border-bottom-979797 ')
              }
              key={Math.random() * 100}
            >
              <div className="col-3 text-truncate" title={user.name}>
                {user.name || ''}
              </div>
              <div className="col-3 text-truncate" title={user.email}>
                {user.email || ''}
              </div>
              <div className="col-2">{roles[user.role]}</div>
              <div className="col-2 text-truncate" title={user.reseller.name}>
                {user.reseller.name || ''}
              </div>
              <div className="col-2 d-flex justify-content-between">
                {user.status === 'Active' ? (
                  <FormattedMessage id="all_products_status_active" />
                ) : user.status === 'Registered' ? (
                  <FormattedMessage id="all_contracts_status_registered" />
                ) : user.status === 'Inactive' ? (
                  <FormattedMessage id="all_products_status_in_active" />
                ) : (
                  ''
                )}
                {user.status === 'Active' && (
                  <>
                    <span
                      className="align-text-bottom fs-18 ml-4 font-weight-bold cursor-pointer"
                      onClick={() => this.handleUserOptions(index, user)}
                    >
                      ...
                    </span>

                    {openColumn === index && (
                      <div className={'mt-0 menuPopup contract-service-card-button right-2 top-30'}>
                        <div
                          className="mx-2 contracts-action-button "
                          onClick={() => this.handleOpenEditUserModal(user)}
                        >
                          <FormattedMessage id="proposal_actions_edit_proposal" />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
        <a
          href="#"
          data-toggle="modal"
          data-target="#customerModal"
          ref={openModal => (this.openElement = openModal)}
        />
        {isEditModalOpen && (
          <UserModal
            typeOptions={typeOptions}
            edit
            userData={userData}
            ismodalopen={isEditModalOpen}
            userModalClose={this.handleCloseModal}
            handleToast={handleToast}
            userCreate={handleCreateUser}
          />
        )}
      </>
    );
  }
}

UserListView.propTypes = {
  users: PropTypes.array.isRequired,
  onSort: PropTypes.func,
  sortAscending: PropTypes.func,
};

export default UserListView;
