import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import Constants from '../../shared/constants';
import validator from 'validator';
import Select from '../Input/select';
import Input from '../Input/index';
import ReactQuill from 'react-quill';
import { Control, ValidationRule } from '../../shared/Control';
import { CheckBox } from '../CheckBox/CheckBox';
import Config from '../../config';
import RadioButtonComponent from '../RadioButton';
import ContractService from '../../services/ContractService';
import SubscriptionService from '../../services/SubscriptionService';
import { removeHTMLTags } from '../../shared/utils';
import { charLimit } from '../../shared/stringOperations';

class ServiceModal extends Component {
  contractService = new ContractService(Config.apiBase);
  subscriptionService = new SubscriptionService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      addName: {
        isValid: false,
        isFocus: false,
      },
      defaultPrice: {
        isValid: false,
        isFocus: false,
      },
      addStatus: {
        isValid: false,
        isFocus: false,
      },
      serviceModalShow: false,
      loading: false,
      display: false,
      hide: false,
      isValid: false,
      isPrivateClicked: false,
      isPublicClicked: false,
      isResellerClicked: false,
      newProductDescription: '',
      bulletData: [],
      editItem: {},
      inNum: 0,
      data: {},
      formSubmitted: false,
      formValid: {
        default_price: false,
        service_name: false,
        customerClass: false,
        typeofsubscription: false,
      },

      statusoptions: [
        {
          value: 'Active',
          label: Constants.language.all_products_status_active,
        },
        {
          value: 'Inactive',
          label: Constants.language.create_inactive,
        },
        {
          value: 'Hold',
          label: Constants.language.on_hold,
        },
      ],
      parentServicesOptions: [],
    };
    this.controls = {
      addName: [
        new Control({
          name: 'service_name',
          type: 'string',
          label: <FormattedMessage id="common_service_name" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="create_service_name_error" />,
          }),
          group: 'ServiceForm',
          id: 'abonnementName',
          formGroup: 0,
          onBlur: this.handleAddServiceBlur,
          onFocus: this.handleAddServiceFocus,
          onChange: this.handleInputChange,
        }),
      ],
      defaultPrice: [
        new Control({
          name: 'default_price',
          type: 'number',
          label: <FormattedMessage id="create_service_price" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="create_service_price_error" />,
          }),
          group: 'ServiceForm',
          id: 'defaultPrice21',
          formGroup: 0,
          onBlur: this.handleAddServiceBlur,
          onFocus: this.handleAddServiceFocus,
          onChange: this.handleInputChange,
        }),
      ],
      addCompanyPrice: [
        new Control({
          name: 'priceClass',
          type: 'number',
          label: <FormattedMessage id="create_price_company" />,
          customerClass: '1',
          id: 'addCompanyPriceS',
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
      addResellerPrice: [
        new Control({
          name: 'priceClass',
          type: 'number',
          label: <FormattedMessage id="create_price_reseller" />,
          customerClass: '4',
          id: 'addResellerPriceS',
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
      addPrivatePrice: [
        new Control({
          name: 'priceClass',
          type: 'number',
          label: <FormattedMessage id="create_price_private" />,
          id: 'addPrivatePriceS',
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
      addStatus: [
        new Control({
          name: 'status',
          type: 'select',
          label: charLimit.charLimit(Constants.language.all_orders_order_status, 10),
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_status" />,
          }),
          id: 'serviceStatus',
          classProps: 'w-auto mt-0',
          options: this.state.statusoptions,
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
      addParentService: [
        new Control({
          name: 'parentService',
          type: 'select',
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_item_class" />,
          }),
          id: 'parentService',
          classProps: 'w-auto mt-0',
          options: this.state.parentServicesOptions,
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
    };
  }
  async componentWillMount() {
    const { edit, editService } = this.props;
    const { formValid, statusoptions, newServiceSelectedParent } = this.state;
    this.state.formValid = {
      service_name: editService.description ? true : false,
      default_price: editService.defaultPrice >= 0 ? true : false,
      typeofsubscription: editService.parent ? true : true,
      customerClass: editService.priceClass ? true : false,
    };

    const {
      addName,
      defaultPrice,
      addPrivatePrice,
      addCompanyPrice,
      addResellerPrice,
      addStatus,
      addParentService,
    } = this.controls;
    addStatus[0].setValue(statusoptions[0].value);
    let response = await this.contractService.getParentServicesEntity();
    let parentServicesOptions = [];
    response.data.map(obj => {
      parentServicesOptions.push({
        label: obj.description,
        value: String(obj.inventoryId),
      });
      return obj;
    });

    addParentService[0].options = parentServicesOptions;
    addParentService[0].setValue(parentServicesOptions[0].value);
    this.setState({
      parentServicesOptions,
    });
    if (edit && editService) {
      {
        addName[0].setValue(editService.description || '');
        defaultPrice[0].setValue(String(editService.defaultPrice) || '');
        addCompanyPrice[0].setValue(
          editService.priceClass[1].price === null ? '' : String(editService.priceClass[1].price)
        );
        addPrivatePrice[0].setValue(
          editService.priceClass[0].price === null ? '' : String(editService.priceClass[0].price)
        );
        addResellerPrice[0].setValue(
          editService.priceClass[2].price === null ? '' : String(editService.priceClass[2].price)
        );
        addStatus[0].setValue(editService.status || '');

        if (editService.isChild) {
          addParentService[0].setValue(String(editService.parent.id));
        }
        this.setState({ EditService: true });
      }
      this.setState({
        newProductDescription: editService.serviceBrief || '' || '',
        parentServicesOptions,
        newServiceSelectedParent: newServiceSelectedParent,
        inNum: editService.inventoryNumber,
        selectedStatus: {
          label:
            editService.status === ('Active' || 'Aktiv') ? (
              <FormattedMessage id="all_products_status_active" />
            ) : editService.status === 'Inactive' || 'Inaktiv' ? (
              <FormattedMessage id="create_inactive" />
            ) : editService.status === 'Hold' ? (
              <FormattedMessage id="all_products_status_no_sales" />
            ) : (
              ' '
            ),
          value: editService.status,
          formValid,
          editService,
        },
      });
      editService.servicePoints
        ? this.setState({ bulletData: editService.servicePoints })
        : this.setState({ bulletData: [] });
      editService.parent ? this.setState({ display: true }) : this.setState({ hide: true });
      editService.priceClass[0].availability
        ? this.setState({ isPrivateClicked: true })
        : this.setState({ isPrivateClicked: false });
      editService.priceClass[1].availability
        ? this.setState({ isPublicClicked: true })
        : this.setState({ isPublicClicked: false });
      editService.priceClass[2].availability
        ? this.setState({ isResellerClicked: true })
        : this.setState({ isResellerClicked: false });
    }
  }
  componentWillUnmount() {
    this.controls = {};
  }
  quillModules = {
    toolbar: {
      container: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean'], ['custom']],
      handlers: {
        custom: () => {
          alert('Hello');
        },
      },
    },
    clipboard: {
      matchVisual: false,
    },
  };
  handleServiceDetailsFocus = () => {
    let { addName, addStatus, defaultPrice } = this.state;
    addName.isFocus = true;
    addName.isValid = this.validation(this.controls.addName);
  };
  validation(controls) {
    return controls.find(obj => obj.rule && !obj.isValid) === undefined;
  }
  handleNewProductEditorChange = value => {
    this.setState({ newProductDescription: value });
  };

  handleCloseServiceModal = () => {
    this.props.createServiceModalClose();
  };

  handleMainsubscription = () => {
    this.setState({ hide: true });
    const { formValid } = this.state;
    formValid.typeofsubscription = true;
    this.handleInputChange();
    this.setState({ display: false, formValid });
  };
  handleAddOn = () => {
    this.setState({ hide: false });
    const { formValid } = this.state;
    formValid.typeofsubscription = true;
    this.handleInputChange();
    this.setState({ display: true, formValid });
  };
  handleOnSelectedStatus = value => {
    const { formValid } = this.state;
    formValid.status = true;
    this.handleInputChange();
    this.setState({ selectedStatus: value, formValid });
  };
  handlePrivatePrice = () => {
    const { formValid } = this.state;
    if (this.state.isPrivateClicked) {
      this.setState({ isPrivateClicked: false });
      this.controls.addPrivatePrice[0].value = null;
      this.state.isPublicClicked || this.state.isResellerClicked
        ? (formValid.customerClass = true)
        : (formValid.customerClass = false);
    } else {
      this.setState({ isPrivateClicked: true });
      formValid.customerClass = true;
    }
    this.handleInputChange();
  };
  handleCompanyPrice = () => {
    const { formValid } = this.state;
    if (this.state.isPublicClicked) {
      this.setState({ isPublicClicked: false });
      this.state.isPrivateClicked || this.state.isResellerClicked
        ? (formValid.customerClass = true)
        : (formValid.customerClass = false);
    } else {
      this.setState({ isPublicClicked: true });
      formValid.customerClass = true;
    }
    this.handleInputChange();
  };
  handleResellerPrice = () => {
    const { formValid } = this.state;
    if (this.state.isResellerClicked) {
      this.setState({ isResellerClicked: false });
      this.controls.addResellerPrice[0].value = null;
      this.state.isPublicClicked || this.state.isPrivateClicked
        ? (formValid.customerClass = true)
        : (formValid.customerClass = false);
    } else {
      this.setState({ isResellerClicked: true });
      formValid.customerClass = true;
    }
    this.handleInputChange();
  };
  handleAddBulletData = () => {
    const { bulletData } = this.state;
    if (bulletData.length > 0) {
      if (bulletData[bulletData.length - 1] !== ' ') {
        bulletData.push(' ');
      }
    } else {
      bulletData.push(' ');
    }

    this.setState(bulletData);
  };
  handleOnChangeBulletDataTile = (name, options) => {
    const { bulletData } = this.state;
    if (options.value) {
      let index = name.charAt(name.length - 1);
      bulletData[index] = options.value;
      this.updateParentState();
    }
  };
  handleForm(bulletData, index) {
    const { edit, editService } = this.props;
    return (
      <div className="row" key={index}>
        <div className="col-7 postal-input-width pr-3">
          {edit ? (
            <div>
              {this.renderInputElements({
                name: 'bullet' + index,
                label: <FormattedMessage id="Bullet_point" />,
                id: 'bulltpoint' + index,
                onChange: this.handleOnChangeBulletDataTile,
                value: bulletData[index].value || bulletData[index] || [],
                autoFocus: bulletData.length - 1 === index ? true : false,
              })}
            </div>
          ) : (
            <div>
              {this.renderInputElements({
                name: 'bullet' + index,
                label: <FormattedMessage id="Bullet_point" />,
                id: 'bulltpoint' + index,
                onChange: this.handleOnChangeBulletDataTile,
                value: bulletData[index] || ' ',
                autoFocus: bulletData.length - 1 === index ? true : false,
              })}
            </div>
          )}
        </div>

        <div className="col-1 text-center pl-0 pr-5 pt-1">
          <label className="close align-items-center mt-3" aria-label="Close" onClick={() => this.handleTdelete(index)}>
            ×
          </label>
        </div>
      </div>
    );
  }
  getAppendedComponents = () => {
    const { bulletData } = this.state;
    const { edit, editService } = this.props;
    return bulletData.map((obj, index) => this.handleForm(bulletData, index));
  };
  handleTdelete = id => {
    const { bulletData } = this.state;

    bulletData.splice(id, 1);
    this.updateParentState();
  };
  updateParentState = async () => {
    const { addDataState } = this.state;
    try {
      await this.handleAddDataState(this.state);
    } catch (err) {
      console.log(err);
    }
  };
  handleAddDataState = data => {
    this.setState({ addProductState: data });
  };
  handleOnSelectParentService = value => {
    this.setState({ newServiceSelectedParent: value });
  };

  handleSubmit = async () => {
    const { edit, editService } = this.props;
    this.controls.addName.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    this.controls.defaultPrice.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });
    this.controls.addStatus.map(e => {
      if (e.value.length === 0 && e.rule !== undefined) {
        e.isValid = false;
        e.showError = true;
      }
    });

    //trigger State ToCall InputComponent;
    this.setState({ formSubmitted: true });
    let response;
    const {
      addName,
      defaultPrice,
      addCompanyPrice,
      addPrivatePrice,
      addResellerPrice,
      addStatus,
      addParentService,
    } = this.controls;
    const { display, newServiceSelectedParent, newProductDescription, selectedStatus, bulletData, inNum } = this.state;
    let data;
    if (this.state.isValid) {
      data = {
        description: addName[0].value,
        status: addStatus[0].value,
        defaultPrice: Number(defaultPrice[0].value),
        priceClass: [
          {
            customerClass: '1',
            availability: this.state.isPrivateClicked,
            price: this.state.isPrivateClicked ? Number(addPrivatePrice[0].value) : null,
          },
          {
            customerClass: '4',
            availability: this.state.isPublicClicked,
            price: this.state.isPublicClicked ? Number(addCompanyPrice[0].value) : null,
          },
          {
            customerClass: '5',
            availability: this.state.isResellerClicked,
            price: this.state.isResellerClicked ? Number(addResellerPrice[0].value) : null,
          },
        ],
        serviceBrief: newProductDescription,
        isChild: this.state.display,
        parent: { id: Number(addParentService[0].value) },
        servicePoints: bulletData,
      };
      this.closeElement.click();
      edit ? this.handleEditDetails(data) : this.handleCreateDetails(data);
      this.props.toUpdateProductService();
    }
  };

  handleEditDetails = async data => {
    const { handleToast, edit, editService, isSubmitted } = this.props;
    const { inNum } = this.state;
    let response;
    try {
      response = await this.subscriptionService.updateServiceDetails(data, editService.inventoryNumber);
      if (response && response.status === 200) {
        this.setState({ isSubmitted: true, error: undefined, loading: false });
        handleToast({
          type: Constants.toast.SUCCESS,
          message: <FormattedMessage id="toast_service_updated" />,
          delay: Constants.toast.DELAY,
        });
        window.location.reload();
      } else {
        this.setState({ error: response.data.message, loading: false });
        handleToast({
          type: Constants.toast.ERROR,
          message: <FormattedMessage id="toast_service_unable_update" />,
          delay: Constants.toast.DELAY,
        });
      }
      response = await this.contractService.getParentServicesEntity();
      let parentServicesOptions = [];
      response.data.map(obj => {
        parentServicesOptions.push({
          label: obj.description,
          value: obj.inventoryId,
        });
        return obj;
      });
      this.setState({
        parentServicesOptions,
        newServiceSelectedParent: parentServicesOptions[0],
      });
    } catch (error) {
      this.setState({ error: error, loading: false });
      handleToast({
        type: Constants.toast.ERROR,
        message: edit ? (
          <FormattedMessage id="toast_service_unable_update" />
        ) : (
          <FormattedMessage id="toast_service_unable_add" />
        ),
        delay: Constants.toast.DELAY,
      });
    }
  };

  handleCreateDetails = async data => {
    const { handleToast, edit, isSubmitted } = this.props;
    const { newServiceSelectedParent } = this.state;
    let response;
    try {
      response = await this.subscriptionService.createNewSubscription(data);
      if (response && response.status === 201) {
        handleToast({
          type: Constants.toast.SUCCESS,
          message: edit ? (
            <FormattedMessage id="toast_service_updated" />
          ) : (
            <FormattedMessage id="toast_service_added" />
          ),
          delay: Constants.toast.DELAY,
        });
        this.setState({ isSubmitted: true, error: undefined, loading: false });
      } else {
        handleToast({
          type: Constants.toast.ERROR,
          message: edit ? (
            <FormattedMessage id="toast_service_unable_update" />
          ) : (
            <FormattedMessage id="toast_service_unable_add" />
          ),
          delay: Constants.toast.DELAY,
        });
        this.setState({ error: response.data.message, loading: false });
      }
      response = await this.contractService.getParentServicesEntity();
      let parentServicesOptions = [];
      response.data.map(obj => {
        parentServicesOptions.push({
          label: obj.description,
          value: String(obj.inventoryId),
        });
        return obj;
      });
      this.setState({
        parentServicesOptions,
        newServiceSelectedParent: parentServicesOptions[0],
      });
    } catch (error) {
      this.setState({ error: error, loading: false });
      handleToast({
        type: Constants.toast.ERROR,
        message: edit ? (
          <FormattedMessage id="toast_service_unable_update" />
        ) : (
          <FormattedMessage id="toast_service_unable_add" />
        ),
        delay: Constants.toast.DELAY,
      });
    }
  };
  validate(data) {
    let { isValid, display, hide } = this.state;
    let valid = Object.keys(data).map(keys => {
      return data[keys];
    });
    let validarr = valid.filter(obj => {
      return obj === false;
    });
    isValid = validarr.length > 0 ? false : true;
    isValid = display && hide ? false : isValid;
    this.setState({ isValid });
  }
  handleInputChange = (name, value) => {
    let { formValid, isPrivateClicked, isPublicClicked, isResellerClicked } = this.state;
    if (value && value.isValid !== undefined) {
      formValid[name] = value.isValid;
    }
    this.validate(formValid);
  };
  renderInputElements = control => {
    return control.type === 'select' ? (
      <Select
        options={control}
        className={' cursor-pointer'}
        placeholder={control.label}
        key={control.name}
        value={control.value}
        onChange={control.onChange}
        ref={input => {
          control.ref = input;
        }}
      />
    ) : (
      <Input
        key={control.name}
        options={control}
        value={control.value}
        onChange={control.onChange}
        ref={input => {
          control.ref = input;
        }}
      />
    );
  };
  render() {
    // let { isValid } = this.state;
    const {
      loading,
      statusoptions,
      hide,
      display,
      isPrivateClicked,
      isPublicClicked,
      isResellerClicked,
      newProductDescription,
      bulletData,
      parentServicesOptions,
      isValid,
      newServiceSelectedParent,
      selectedStatus,
      formSubmitted,
      formValid,
    } = this.state;
    const {
      serviceModalShow,
      edit,
      editServiceModalClose,
      editServiceModal,
      createServiceModalClose,
      data,
      isSubmitted,
    } = this.props;
    const {
      addName,
      defaultPrice,
      addPrivatePrice,
      addCompanyPrice,
      addResellerPrice,
      addStatus,
      addParentService,
    } = this.controls;
    return (
      <>
        <div
          className="modal fade bd-example-modal-lg fade-products"
          tabIndex="-1"
          data-backdrop="static"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="servicemodal"
        >
          <div className="modal-dialog modal-lg modal-product">
            <div className="modal-content contracts-modal">
              <div className="modal-header modal-header-product">
                {edit ? (
                  <>
                    <h3 className="modal-title font-weight-semibold pl-2" id="myLargeModalLabel">
                      <FormattedMessage id="create_update_service" />
                    </h3>

                    <button
                      type="button"
                      className="close outline-0"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={closeModal => (this.closeElement = closeModal)}
                      onClick={editServiceModalClose}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </>
                ) : (
                  <>
                    <h3 className="modal-title font-weight-semibold" id="myLargeModalLabel">
                      <FormattedMessage id="create_add_service" />
                    </h3>
                    <button
                      type="button"
                      className="close outline-0"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={closeModal => (this.closeElement = closeModal)}
                      onClick={this.handleCloseServiceModal}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </>
                )}
              </div>
              <div className="modal-body p-4  my-2 line-height-normal ls-normal">
                <LoadingOverlay
                  active={loading}
                  spinner={<BounceLoader />}
                  text={<FormattedMessage id="common_loading_overlay" />}
                  classNamePrefix={'light_modal_ '}
                  className={'loader-list-view-common'}
                >
                  <div className="row">
                    <div className="col">
                      <div className="row justify-content-between mb-3 ">
                        <div className="col-12 pl-4">
                          <label className="product-heading mb-2">
                            <FormattedMessage id="service_type_of_subscription" />
                          </label>

                          <div className="row col-6 pcolor mb-2 pb-1">
                            <RadioButtonComponent
                              checked={hide}
                              onClick={this.handleMainsubscription}
                              onChange={this.handleMainsubscription}
                              label={<FormattedMessage id="main_subscription" />}
                            />
                          </div>
                          <div className="row col-6 mb-4 pcolor">
                            <RadioButtonComponent
                              checked={display}
                              onClick={this.handleAddOn}
                              onChange={this.handleAddOn}
                              label={<FormattedMessage id="add_on" />}
                            />
                          </div>
                          {formSubmitted && !display && !hide && (
                            <div className="text-danger error-message small mt-2">
                              <FormattedMessage id="create_product_dropship_error" />
                            </div>
                          )}
                          <label className="product-heading mb-0">
                            <FormattedMessage id="service_abonnement" />
                          </label>

                          <div className="row mt-0 w-100">
                            <div className="col-7 product-bar pr-3 postal-input-width">
                              {addName.map(control => this.renderInputElements(control))}
                            </div>
                            <div className=" col-3 mt-2 pt-1 px-0 cursor-pointer w-186-max">
                              {addStatus.map(control => this.renderInputElements(control))}
                            </div>
                          </div>

                          <label className="product-heading mb-0">
                            <FormattedMessage id="create_default_price" />
                          </label>

                          <div className="col-3 product-bar pr-3 postal-input-width pl-0 mb-3">
                            {defaultPrice.map(control => this.renderInputElements(control))}
                          </div>
                          <label className="product-heading mb-2">
                            <FormattedMessage id="create_customer_class" />
                          </label>
                          <label className="d-flex align-items-center mb-2 pb-1 pcolor">
                            <CheckBox
                              isSelected={isPrivateClicked}
                              onClick={this.handlePrivatePrice}
                              onChange={this.handlePrivatePrice}
                              label={<FormattedMessage id="create_consumer" />}
                              className={'space-remove pcolor'}
                            />
                          </label>
                          {isPrivateClicked && (
                            <div className="row col-3 pb-1 postal-input-width space-remove pr-0">
                              {addPrivatePrice.map(control => this.renderInputElements(control))}
                              <p className="col optional  pl-2 space-remove">
                                <FormattedMessage id="create_optional" />
                              </p>
                            </div>
                          )}

                          <label className="d-flex align-items-center mb-2 pb-1 pcolor">
                            <CheckBox
                              isSelected={isPublicClicked}
                              onClick={this.handleCompanyPrice}
                              onChange={this.handleCompanyPrice}
                              label={<FormattedMessage id="create_company" />}
                              className={'space-remove'}
                            />
                          </label>
                          {isPublicClicked && (
                            <div className="row col-3 postal-input-width  space-remove pr-0">
                              {addCompanyPrice.map(control => this.renderInputElements(control))}
                              <div>
                                <p className="col optional pl-2 space-remove">
                                  <FormattedMessage id="create_optional" />
                                </p>
                              </div>
                            </div>
                          )}
                          <label className="d-flex align-items-center pcolor">
                            <CheckBox
                              isSelected={isResellerClicked}
                              onClick={this.handleResellerPrice}
                              onChange={this.handleResellerPrice}
                              label={<FormattedMessage id="all_contracts_reseller" />}
                              className={'space-remove'}
                            />
                          </label>
                          {isResellerClicked && (
                            <div className="row col-3 postal-input-width space-remove pr-0 mb-3">
                              {addResellerPrice.map(control => this.renderInputElements(control))}
                              <div>
                                <p className="col optional pl-2 space-remove">
                                  <FormattedMessage id="create_optional" />
                                </p>
                              </div>
                            </div>
                          )}
                          {formSubmitted && !isPrivateClicked && !isPublicClicked && !isResellerClicked && (
                            <div className="text-danger error-message small mt-2">
                              <FormattedMessage id="create_product_customer_class_error" />
                            </div>
                          )}
                          {display && (
                            <div className="mt-4 pt-2">
                              <label className="product-heading mb-2">
                                <FormattedMessage id="service_select" />
                              </label>
                              <div className="col-6 mb-3 pl-0 pr-0">
                                {addParentService.map(control => this.renderInputElements(control))}
                              </div>
                            </div>
                          )}
                          {hide && (
                            <div className="mt-4 pt-2">
                              <label className="product-heading mb-2">
                                <FormattedMessage id="create_bullet_points" />
                              </label>
                              <div className="mb-4 pb-2 cursor-pointer">
                                {bulletData.length > 0 && this.getAppendedComponents()}
                                <a
                                  href="#"
                                  onClick={this.handleAddBulletData}
                                  className={
                                    bulletData.length > 0 ? 'col-10 d-flex justify-content-center pl-5 ml-4' : ''
                                  }
                                >
                                  <u>
                                    + <FormattedMessage id="create_bullet_table" />
                                  </u>
                                </a>
                              </div>
                            </div>
                          )}
                          {(display || hide) && (
                            <div>
                              <label className="product-heading">
                                <img src={Constants.icons.infoIconGreen} width="24" height="24" alt="icon" />{' '}
                                <FormattedMessage id="create_service_description" />
                              </label>
                              <ReactQuill
                                className="product-quill w-634-max"
                                theme="snow"
                                value={newProductDescription}
                                modules={this.quillModules}
                                onChange={value => this.handleNewProductEditorChange(value)}
                              />
                            </div>
                          )}

                          <div className={hide || display ? 'mt-5 mr-1' : 'mt-4 pt-2'}>
                            <div className="d-inline-block">
                              <button
                                className={isValid ? 'btn-primary btn-pro' : 'btn-pro'}
                                onClick={this.handleSubmit}
                              >
                                {' '}
                                {edit ? (
                                  <FormattedMessage id="create_save" />
                                ) : (
                                  <FormattedMessage id="create_add_new" />
                                )}
                              </button>
                            </div>
                            {edit ? (
                              <div className="d-inline-block ml-5 pl-5">
                                <a
                                  href="#table"
                                  className="font-weight-bold "
                                  onClick={editServiceModalClose}
                                  data-dismiss="modal"
                                >
                                  <u>
                                    <FormattedMessage id="create_cancel" />
                                  </u>
                                </a>
                              </div>
                            ) : (
                              <div className="d-inline-block ml-5 pl-5">
                                <a
                                  href="#table"
                                  className="font-weight-bold fs-14"
                                  onClick={this.handleCloseServiceModal}
                                  data-dismiss="modal"
                                >
                                  <u>
                                    <FormattedMessage id="create_cancel" />
                                  </u>
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ServiceModal;
