import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Constants from '../../shared/constants';

const AllOrderListViewHeader = ({ isProposal, onSort, isContracts, sortAscending, sortedBy }) => {
  return (
    <React.Fragment>
      <div className={'row pb-2 table-header fs-14 ls-0-44 mx-0'}>
        <div
          className={'col-2 cursor-pointer fs-14'}
          onClick={onSort.bind(
            this,
            isContracts
              ? Constants.sortingOptions.contractNo
              : isProposal
              ? Constants.sortingOptions.proposalNo
              : Constants.sortingOptions.orderNo
          )}
        >
          <u
            className={
              sortedBy === Constants.sortingOptions.orderNo ||
              sortedBy === Constants.sortingOptions.contractNo ||
              sortedBy === Constants.sortingOptions.proposalNo
                ? 'font-weight-semibold'
                : 'font-weight-normal'
            }
          >
            {isContracts ? (
              <FormattedMessage id="all_contracts_contract_id" />
            ) : isProposal ? (
              <FormattedMessage id="common_proposal_ID" />
            ) : (
              <FormattedMessage id="common_order_ID" />
            )}
          </u>
          <img
            src={Constants.icons.arrowBack}
            width="18px"
            height="18px"
            alt="icon"
            className={
              sortedBy === Constants.sortingOptions.orderNo ||
              sortedBy === Constants.sortingOptions.contractNo ||
              sortedBy === Constants.sortingOptions.proposalNo
                ? sortAscending
                  ? 'arrow-up-sort'
                  : 'arrow-down-sort'
                : 'd-none'
            }
          />
        </div>
        <div
          className={isContracts ? 'col-4 cursor-pointer fs-14 ' : 'col-3 cursor-pointer fs-14'}
          onClick={onSort.bind(this, Constants.sortingOptions.customerName)}
        >
          <u className={sortedBy === 'customer.name' ? 'font-weight-semibold' : 'font-weight-normal'}>
            {isContracts ? <FormattedMessage id="customer_name" /> : <FormattedMessage id="customer_name" />}
          </u>
          <img
            src={Constants.icons.arrowBack}
            width="18px"
            height="18px"
            alt="icon"
            className={sortedBy === 'customer.name' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'}
          />
        </div>
        <div
          className={isProposal ? 'col' : 'col-2' + ' cursor-pointer fs-14'}
          onClick={onSort.bind(
            this,
            isContracts ? Constants.sortingOptions.registeredDate : Constants.sortingOptions.type
          )}
        >
          <u
            className={
              sortedBy === 'registeredDate' || sortedBy === 'type' ? 'font-weight-semibold' : 'font-weight-normal'
            }
          >
            {isContracts ? (
              <FormattedMessage id="all_contracts_contract_date" />
            ) : (
              <FormattedMessage id="all_orders_type" />
            )}
          </u>
          <img
            src={Constants.icons.arrowBack}
            width="18px"
            height="18px"
            alt="icon"
            className={
              sortedBy === 'registeredDate' || sortedBy === 'type' || (!isProposal && sortedBy === 'date')
                ? sortAscending
                  ? 'arrow-up-sort '
                  : 'arrow-down-sort '
                : 'd-none'
            }
          />
        </div>

        <div
          className={isContracts ? 'col-2 cursor-pointer fs-14 pr-0' : 'col-2 cursor-pointer fs-14'}
          onClick={onSort.bind(
            this,
            isContracts ? Constants.sortingOptions.activationDate : Constants.sortingOptions.date
          )}
        >
          <u
            className={
              sortedBy === 'activationDate' || sortedBy === 'date' ? 'font-weight-semibold' : 'font-weight-normal'
            }
          >
            {isContracts ? (
              <FormattedMessage id="filter_activation_date" />
            ) : isProposal ? (
              <FormattedMessage id="common_date" />
            ) : (
              <FormattedMessage id="all_orders_order_date" />
            )}
          </u>
          <img
            src={Constants.icons.arrowBack}
            width="18px"
            height="18px"
            alt="icon"
            className={
              sortedBy === 'activationDate' || sortedBy === 'date'
                ? sortAscending
                  ? 'arrow-up-sort '
                  : 'arrow-down-sort '
                : 'd-none'
            }
          />
        </div>
        {!isContracts && (
          <div
            className={'col cursor-pointer text-right pr-0 fs-14'}
            onClick={onSort.bind(this, Constants.sortingOptions.amount)}
          >
            <u className={sortedBy === 'orderTotal' ? 'font-weight-semibold' : 'font-weight-normal'}>
              <FormattedMessage id="order_amount" />
            </u>
            <img
              src={Constants.icons.arrowBack}
              width="18px"
              height="18px"
              alt="icon"
              className={sortedBy === 'orderTotal' ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'}
            />
          </div>
        )}
        <div
          className={'col text-left pl-5 cursor-pointer fs-14'}
          onClick={onSort.bind(this, Constants.sortingOptions.status)}
        >
          <u className={sortedBy === 'status' ? 'font-weight-semibold' : 'font-weight-normal'}>
            {isContracts ? (
              <FormattedMessage id="all_orders_order_status" />
            ) : (
              <FormattedMessage id="all_orders_order_status" />
            )}
          </u>
          <img
            src={Constants.icons.arrowBack}
            width="18px"
            height="18px"
            alt="icon"
            className={sortedBy === 'status' ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

AllOrderListViewHeader.propTypes = {
  onSort: PropTypes.func,
  isContracts: PropTypes.bool,
};

export default AllOrderListViewHeader;
