import ApiService from './ApiService';
class SubscriptionService extends ApiService {
  endPoint = {
    services: 'services',
    createNewService: 'create-service',
  };
  async createNewSubscription(payload) {
    let response = await this.post(
      this.baseURL + this.endPoint.services + '/' + this.endPoint.createNewService,
      payload
    );
    if (response.status !== 201) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }
  async updateServiceDetails(data, inventoryNumber) {
    let response = await this.put(this.baseURL + this.endPoint.services + '/' + inventoryNumber + '/' + 'update', data);
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }
  async getServices({ queryParam = '' }) {
    let response = await this.get(this.baseURL + 'services' + (queryParam ? this.queryParamString(queryParam) : ''));

    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        services: response.data.data.map(obj => obj),
        meta: response.data.meta,
      };
    }
  }
}
export default SubscriptionService;
