import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Breadcrumb, Form } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import Constants from '../../shared/constants';
import { store } from '../../state/store';

const BreadcrumbComponent = props => {
  const pathname = props.location.pathname;
  const splitPathName = pathname.split('/');
  let content = [];
  const order = store.getState().order;
  const routes = {
    home: {
      url: '/',
      title: <FormattedMessage id="bc_sign_in" />,
      nav: [],
    },
    dashboard: { url: '/dashboard', title: '', nav: [] },
    createExistingReseller: {
      url: '/new-reseller-select',
      title: '',
      nav: [[props.location, <FormattedMessage id="bc_connect_reseller" />]],
    },
    signin: {
      url: '/signin',
      title: '',
      nav: [],
    },
    resellersID: {
      url: splitPathName[1] === 'resellers' ? pathname : '',
      title: '',
      nav: [
        [pathname.slice(0, 10), <FormattedMessage id="bc_resellers" />],
        [props.location, (props.location.query && props.location.query.name) || pathname.slice(11)],
      ],
    },
    // allCustomers: {
    //   url: '/customers',
    //   title: '',
    //   nav: [[props.location, 'Customers']],
    // },
    customersID: {
      url: splitPathName[1] === 'customers' ? pathname : '',
      title: '',
      nav: [
        [pathname.slice(0, 10), <FormattedMessage id="bc_customers" />],
        [props.location, (props.location.query && props.location.query.name) || pathname.slice(11)],
      ],
    },
    users: {
      url: '/users',
      title: '',
      nav: [[props.location, 'Users']],
    },

    contracts: {
      url: '/contracts',
      title: '',
      nav: [
        [pathname.slice(0, 10), <FormattedMessage id="bc_contracts" />],
        // [props.location, pathname.slice(11)]
      ],
    },
    addNewService: {
      url: splitPathName[3] === 'add-new-service' ? pathname : '',
      title: '',
      nav: [
        [pathname.slice(0, 10), <FormattedMessage id="bc_contracts" />],
        [pathname.slice(0, pathname.length - 16), splitPathName[2]],
        [props.location, <FormattedMessage id="contract_add_new_service_header" />],
      ],
    },
    ResellerToContract: {
      url: '/resellers' + splitPathName[1] === 'contracts' ? pathname : '',
      title: '',
      nav: [
        [props.location, <FormattedMessage id="bc_resellers" />],
        [pathname.slice(0, 10), <FormattedMessage id="bc_contracts" />],
        [props.location, pathname.slice(11)],
      ],
    },
    contractsID: {
      url: splitPathName[1] === 'contracts' ? pathname : '',
      title: '',
      nav: [[pathname.slice(0, 10), <FormattedMessage id="bc_contracts" />], [props.location, pathname.slice(11)]],
    },

    allOrders: {
      url: '/orders',
      title: '',
      nav: [[props.location, <FormattedMessage id="bc_orders" />]],
    },
    myOrders: {
      url: '/my-orders',
      title: 'My Orders',
      nav: [[props.location, <FormattedMessage id="bc_my_orders" />]],
    },
    newOrder: {
      url: '/new-order',
      title: '',
      nav: [[props.location, <FormattedMessage id="bc_new_order" />]],
    },
    newOrderChooseCustomerService: {
      url: '/new-order/choose-customer/service',
      title: 'New Order - Choose your customer',
      nav: [
        [Constants.routes.orders, <FormattedMessage id="common_orders" />],
        [props.location, <FormattedMessage id="bc_new_subscription" />],
      ],
    },
    newOrderChooseCustomerProduct: {
      url: '/new-order/choose-customer/product',
      title: 'New Order - Choose your customer',
      nav: [
        [Constants.routes.orders, <FormattedMessage id="common_orders" />],
        [props.location, <FormattedMessage id="bc_new_order_products" />],
      ],
    },
    newProposalChooseCustomerProduct: {
      url: '/new-proposal/choose-customer/product',
      title: 'New Proposal - Choose your customer',
      nav: [
        [Constants.routes.proposals, <FormattedMessage id="common_proposal" />],
        [props.location, <FormattedMessage id="bc_new_proposal" />],
      ],
    },
    newProposalChooseCustomerService: {
      url: Constants.routes.newProposalChooseCustomerService,
      title: 'New Proposal - Choose your customer',
      nav: [
        [Constants.routes.proposals, <FormattedMessage id="common_proposal" />],
        [props.location, <FormattedMessage id="bc_new_proposal" />]
      ],
    },
    newOrderChooseLocation: {
      url: '/new-order/choose-location',
      title: 'New Order - Choose Location',
      nav: [
        [Constants.routes.newOrderChooseCustomer, <FormattedMessage id="bc_new_order" />],
        [props.location, 'Choose Location'],
      ],
    },
    newOrderPrepareOrder: {
      url: '/new-order/prepare-order',
      title: 'New Order - Select products',
      nav: [
        [Constants.routes.newOrderChooseCustomer, <FormattedMessage id="bc_new_order" />],
        [props.location, 'Select products'],
      ],
    },
    addressOrder: {
      url: '/new-order/address-order',
      title: 'New Order - Confirm Order',
      nav: [
        [Constants.routes.newOrderChooseCustomer, <FormattedMessage id="bc_new_order" />],
        [props.location, <FormattedMessage id="common_next" />],
      ],
    },
    forgotPassword: {
      url: '/forgot-password',
      title: '',
      nav: [
        [Constants.routes.home.url, <FormattedMessage id="bc_sign_in" />],
        [props.location, <FormattedMessage id="bc_forgot_password" />],
      ],
    },
    resetPassword: {
      url: '/reset-password',
      title: '',
      nav: [[props.location, <FormattedMessage id="bc_reset_password" />]],
    },
    newPassword: {
      url: '/new-password',
      title: '',
      nav: [
        [Constants.routes.home.url, <FormattedMessage id="bc_new_user" />],
        [props.location, <FormattedMessage id="bc_verify_account" />],
      ],
    },
    newReseller: {
      url: '/new-reseller',
      title: 'New Reseller',
      nav: [[props.location, <FormattedMessage id="bc_new_reseller" />]],
    },
    createNewReseller: {
      url: '/new-reseller/create-new-reseller',
      title: 'Create new Reseller',
      nav: [[props.location, <FormattedMessage id="bc_new_reseller" />]],
    },
    //for services
    newOrderServices: {
      url: '/new-order/choose-customer',
      title: 'Services',
      nav: [[props.location, <FormattedMessage id="bc_new_subscription" />]],
    },
    newServicePrepareOrder: {
      url: '/new-order/select-service',
      title: 'New Order - Select services',
      nav: [
        [Constants.routes.orders, <FormattedMessage id="common_orders" />],
        [props.location, <FormattedMessage id="bc_new_subscription" />],
      ],
    },
    newProductPrepareOrder: {
      url: '/new-order/select-product',
      title: 'New Order - Select products',
      nav: [
        [Constants.routes.orders, <FormattedMessage id="common_orders" />],
        [props.location, <FormattedMessage id="bc_new_order_products" />],
      ],
    },
    newProductPrepareProposal: {
      url: '/new-proposal/select-product',
      title: 'New Proposal - Select products',
      nav: [
        [Constants.routes.proposals, <FormattedMessage id="common_proposal" />],
        [props.location, <FormattedMessage id="bc_new_proposal_product" />],
      ],
    },
    newServicePrepareProposal: {
      url: Constants.routes.newServicePrepareProposal,
      title: 'New Proposal - Select subscriptions',
      nav: [
        [Constants.routes.proposals, <FormattedMessage id="common_proposal" />],
        [props.location, <FormattedMessage id="bc_new_proposal_service" />]
      ],
    },
    confirmOrder: {
      url: '/new-order/confirm-order',
      title: 'Confirm Order',
      nav: [
        [props.location, <FormattedMessage id="common_orders" />],
        [
          order
            ? order.orderType === 'service-order'
              ? Constants.routes.newOrderChooseCustomerService
              : Constants.routes.newOrderChooseCustomerProduct
            : Constants.routes.newOrderChooseCustomerProduct,
          <FormattedMessage id="bc_new_order" />,
        ],
        [props.location, <FormattedMessage id="new_order_order_completion" />],
      ],
    },
    serviceConfirmOrder: {
      url: '/new-order/services/confirm-order',
      title: 'Confirm Order',
      nav: [
        [Constants.routes.orders, <FormattedMessage id="common_orders" />],
        [Constants.routes.newOrderChooseCustomerService, <FormattedMessage id="bc_new_subscription" />],
        [props.location, <FormattedMessage id="new_order_order_completion" />],
      ],
    },
    productConfirmOrder: {
      url: '/new-order/products/confirm-order',
      title: 'Confirm Order',
      nav: [
        [Constants.routes.orders, <FormattedMessage id="common_orders" />],
        [Constants.routes.newOrderChooseCustomerProduct, <FormattedMessage id="bc_new_order_products" />],
        [props.location, <FormattedMessage id="new_order_order_completion" />],
      ],
    },
    newProductProposalConfirmOrder: {
      url: '/new-proposal/products/confirm-proposal',
      title: 'Confirm Order',
      nav: [
        [Constants.routes.proposals, <FormattedMessage id="common_proposal" />],
        [Constants.routes.newProposalChooseCustomerProduct, <FormattedMessage id="bc_new_proposal_product" />],
        [props.location, <FormattedMessage id="new_order_order_completion" />],
      ],
    },
    newServiceProposalConfirmOrder: {
      url: Constants.routes.newProposalConfirmProposalServices,
      title: 'Confirm Order',
      nav: [
        [Constants.routes.proposals, <FormattedMessage id="common_proposal" />],
        [Constants.routes.newProposalChooseCustomerService, <FormattedMessage id="bc_new_proposal_service" />],
        [props.location, <FormattedMessage id="new_order_order_completion" />],
      ],    
    },
  };

  const routesList = Object.entries(routes).map(obj => obj[1]);
  const route = routesList.find(obj => obj.url === props.location.pathname);
  if (route) {
    const nav = route.nav;
    for (let i = 0; i < nav.length; i++) {
      if (i + 1 === nav.length) {
        content.push(
          <span className="font-weight-normal pb-0" key={i}>
            <Breadcrumb.Section active>{nav[i][1]}</Breadcrumb.Section>
          </span>
        );
      } else {
        content.push(
          <React.Fragment key={i}>
            <div className="font-weight-normal pb-0">
              {' '}
              <Breadcrumb.Section href={nav[i][0]}>{nav[i][1]}</Breadcrumb.Section>
            </div>
            &nbsp;
            <Breadcrumb.Divider>
              <div className="text-black-50 pb-0"> / </div>
            </Breadcrumb.Divider>
            &nbsp;
          </React.Fragment>
        );
      }
    }
  }

  return (
    <Breadcrumb size="tiny" className="pb-0 mb-0">
      {content}
    </Breadcrumb>
  );
};

export default withRouter(BreadcrumbComponent);
