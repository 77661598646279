import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import validator from 'validator';

import Constants from '../../shared/constants';
import ProductService from '../../services/ProductService';
import { ProductListView } from '../Products';
import Config from '../../config';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { getAll } from '../../state/actions/products';
import Routes from '../../shared/Routes';
import CustomerService from '../../services/CustomerService';
import BaseComponent from '../BaseComponent';
import { CustomerType } from '../../shared/CustomerType';
import ServiceCards from '../../components/ServiceCards';
import { OrderType } from '../../shared/OrderType';
import AuthService from '../../services/AuthService';
import BounceLoader from 'react-spinners/BounceLoader';
import { FilterPopOver } from '../FilterData/FilterPopOver';
import { FilterButton } from '../FilterData/Components/FilterButton';
import { SelectedProducts } from './Components/PrepareOrder/SelectedProducts';
import SearchInput from '../Search';
import Pagination from '../Pagination';
import { Control, ValidationRule } from '../../shared/Control';
import InstallationCard from './Components/PrepareOrder/InstallationCard';
import ProposalService from '../../services/ProposalService';

class PrepareOrder extends BaseComponent {
  productService = new ProductService(Config.apiBase);
  customerService = new CustomerService(Config.apiBase);
  authService = new AuthService(Config.apiBase);
  proposalService = new ProposalService(Config.apiBase);

  state = {
    products: [],
    parentServices: [],
    selectedProducts: [],
    selectedServices: [],
    itemClasses: [],
    services: [],
    parentServiceData: [],
    isFilterOpen: false,
    totalSubPrice: 0,
    loading: true,
    meta: {
      cursor: {
        previous: null,
        current: 0,
        next: 1,
        startCursor: null,
        endCursor: null,
        count: null,
        pageNumber: 1,
        pageSize: 25,
        totalCount: null,
        pages: null,
      },
    },
    sortedBy: 'name',
    searchQuery: '',
    searchData: {
      id: 'name',
      value: '',
      component: 'search',
    },
    sortAscending: true,
    newOrderProductFilterData: [
      {
        id: 'category',
        multiGroup: true,
        component: 'checkBox',
        groups: [
          {
            label: 'Smarthjem & sikkerhet',
            id: 'category1',
            values: [
              Constants.language.filter_select_all,
              'Alarm Climax',
              'Alarm IQ Panel',
              'Kamera',
              'Dør og Vindus automasjon',
              'Dør & Vindu Servicepakke',
              'Adgangskontroll',
              'Lys og Varmestyring',
            ],
            defaultValues: [],
          },
          {
            label: 'Skap & nettverk',
            id: 'category2',
            values: [
              Constants.language.filter_select_all,
              'Svakstrømskap - Media Rack',
              'Nettverksutstyr',
              'WiFi',
              'Kabel & Festematriell',
            ],
            defaultValues: [],
          },
          {
            label: 'Lyd & Bilde',
            id: 'category3',
            values: [
              Constants.language.filter_select_all,
              'Forsterkere & Subwoofer',
              'Integrerte Høyttalere',
              'TV Utstyr',
            ],
            defaultValues: [],
          },
        ],
      },
      {
        id: 'price',
        component: 'rangeSlider',
        label: Constants.language.filter_price_range,
        min: 0,
        max: 5000,
        placeholderValues: [0, 5000],
        defaultValues: [],
      },
    ],
    featureData: [
      {
        feature: 'Video Camera',
        description: 'Video Camera 180º View angle, auto-scale, full color',
      },
      {
        feature: 'Nightvision',
        description: 'Nightvision Full color for HD, and Infrared for Slim Line',
      },
      {
        feature: 'Audio',
        description: 'Audio Omni-directional microphone',
      },
      {
        feature: 'Motion sensor',
        description: 'Motion sensor Detects movement up to 2,4m distance',
      },
      {
        feature: 'Dimensions and weight',
        description: 'Dimensions and weight (W x D; Weight): 7.1 x 2.2 cm; 53.8g',
      },
      {
        feature: 'Required Power',
        description: 'Required Power Power: 8.36VAC, 10VA or 12VDC, 0.5 to 1.0A',
      },
    ],
    toBeFilteredData: {},
    amountOfHours: 0,
    needInstallation: Constants.installation.no,
    isPriceBasedOnHours: Constants.installation.hours,
    selectedItems: [],
    proposalDetails: {},
  };
  customer = {};
  constructor(props) {
    super(props);
    this.controls = {
      installationFields: [
        new Control({
          name: 'hour rate',
          type: 'number',
          defaultValue: 0,
          classProps: 'w-186-max mt-0',
          id: 'addHourRate',
          onChange: this.handleChangePrice,
        }),
        new Control({
          name: 'hours',
          type: 'number',
          defaultValue: 0,
          classProps: 'w-186-max mt-0',
          id: 'addHours',
          onChange: this.handleChangePrice,
        }),
        new Control({
          name: 'price',
          type: 'number',
          defaultValue: 600,
          classProps: 'w-186-max mt-0',
          id: 'addPrice',
          onChange: this.handleChangePrice,
        }),
      ],
    };
  }
  async componentDidMount() {
    const {
      order: { customer, location, type, orderType, products, customerType, services: propServices, customerDetails },
      location: { pathname, acceptProposal, acceptServiceProposal, proposalNo, serviceOrder = false },
      history,
      updateProductList,
    } = this.props;
    const { toBeFilteredData } = this.state;
    const { installationFields } = this.controls;
    this.customer = customer || {};
    this.setState({
      selectedProducts: products || [],
      loading: true,
      products: [],
    });

    if (!customer && type !== CustomerType.NEW_CONSUMER) {
      if (pathname === Constants.routes.newProductPrepareOrder && !acceptProposal) {
        history.push(Routes.newOrderChooseCustomerProduct);
      } else if (pathname === Constants.routes.newServicePrepareOrder && !acceptServiceProposal) {
        history.push(Routes.newOrderChooseCustomerService);
      } else if (pathname === Constants.routes.newProductPrepareProposal && !acceptProposal) {
        history.push(Routes.newProposalChooseCustomerProduct);
      } else if (pathname === Constants.routes.newServicePrepareProposal && !acceptServiceProposal) {
        history.push(Routes.newProposalChooseCustomerService);
      } else {
      }
    }
    if (customerDetails) {
      this.customer = customerDetails;
    }
    if (customer && location) {
      const response = await this.customerService.getCustomerContracts(customer.id, location.location.locationId);

      if (response.contracts > 0) {
        let itemCode = response.contracts[0].details.lines.map(line => line.itemCode);
        this.setState({ itemCode: itemCode, contracts: response.contracts[0] });
      }
    }

    try {
      if (
        (orderType === OrderType.SERVICE_ORDER ||
          acceptServiceProposal ||
          pathname === Constants.routes.newServicePrepareProposal ||
          pathname === Constants.routes.newServicePrepareOrder) &&
        !acceptProposal
      ) {
        this.onFetchServices();
      } else {
        updateProductList([]);

        this.onFetchProducts();
      }
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
      });
    }
    let serviceSection = serviceOrder
      ? serviceOrder
      : OrderType
      ? OrderType.SERVICE_ORDER
        ? OrderType.SERVICE_ORDER === orderType
        : false
      : false;
    this.setState({
      serviceSection,
    });
    if (pathname === Constants.routes.newProductPrepareProposal) {
      installationFields[0].setValue(String(0));
      installationFields[1].setValue(String(0));
      installationFields[2].setValue(String(600));
    }
  }
  async onFetchServices() {
    const {
      order: { customer, customerType, services: propServices, customerDetails },
      location: { acceptServiceProposal, proposalNo },
    } = this.props;
    this.customer = customerDetails || {};

    if (acceptServiceProposal) {
      let proposalDetails = await this.proposalService.getProposalDetails(proposalNo);
      proposalDetails = proposalDetails.data;
      const {
        data: { customer: selectedCustomer = {}, proposal = {} },
      } = proposalDetails;
      let {
        data: { selectedItems = [] },
      } = proposalDetails;
      selectedItems = selectedItems.map(item => {
        const { lines } = proposal;
        const selectedData = lines.find(data => data.inventory.number === item.inventoryNumber);
        if (selectedData) {
          return { ...item, defaultPrice: selectedData.unitPrice };
        }
        return item;
      });
      this.customer = selectedCustomer;
      this.setState({ selectedItems, proposalDetails: proposal });
    }
    let queryParam;
    const customerId = Number(this.customer && this.customer.customerClass && this.customer.customerClass.id);
    if (customerType) {
      if (customerType === 'private-customer') {
        queryParam = [['customer', 'private']];
      } else if (customerType === 'business-customer') {
        queryParam = [['customer', 'business']];
      }
    }
    if (customerId) {
      if (customerId === 1) {
        queryParam = [['customer', 'private']];
      } else if (customerId === 4) {
        queryParam = [['customer', 'business']];
      } else if (customerId === 5) {
        queryParam = [['customer', 'reseller']];
      }
    }
    let response = await this.productService.getServices({ queryParam });
    let services = response.services;
    if (customerType === 'private-customer') {
      services.map(obj => (obj.defaultPrice += 0.25 * Number(obj.defaultPrice)));
    }
    if (propServices) {
      propServices.map(obj => {
        const findedService = services.find(item => item.inventoryId === obj.inventoryId);
        if (findedService) {
          findedService.selected = true;
          findedService.defaultPrice = obj.defaultPrice;
          findedService.currentCost = obj.currentCost;
          findedService.totalSubPrice = obj.totalSubPrice;
        }
        return obj;
      });
    }
    const childServices = response.services.filter(obj => obj.parent);
    // parent service
    var parentServices = response.services.filter(obj => !obj.parent);
    parentServices = parentServices.map(parent => {
      parent.children = childServices.filter(obj => obj.parent.id === parent.inventoryId);

      return parent;
    });

    this.setState({
      loading: false,
      services: services,
      parentServiceData: parentServices,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      meta: {
        cursor: { pageNumber },
      },
      sortedBy,
      sortAscending,
      toBeFilteredData,
      products,
      selectedItems,
      selectedProducts,
      services,
    } = this.state;
    const { Products, order } = this.props;
    if (!(this.state.meta === Products.meta)) {
      this.setState({
        meta: Products.meta,
        selectedProducts: order.products,
        products: [],
      });
      setTimeout(() => {
        this.setState({ loading: false });
      }, 3000);
    }
    if (
      prevState.meta.cursor.pageNumber !== null &&
      (prevState.meta.cursor.pageNumber !== pageNumber ||
        prevState.sortedBy !== sortedBy ||
        prevState.sortAscending !== sortAscending)
    ) {
      this.setState({
        loading: true,
      });
      this.onFetchProducts(toBeFilteredData);
    }
    if (Products.totalProducts.length >= pageNumber && Products.totalProducts[pageNumber] !== undefined && products) {
      if (
        selectedItems &&
        selectedItems.length > 0 &&
        selectedProducts &&
        selectedProducts.length === 0 &&
        prevState.selectedProducts &&
        prevState.selectedProducts.length === 0
      ) {
        this.handleProposalProducts(selectedItems, Products);
      } else {
        this.handleSelectedProducts(order.products ? order.products : [], Products);
      }
    }
    if (services && services.length > 0 && selectedItems && selectedItems.length > 0) {
      this.handleSelectedServices();
    }
  }

  async componentWillReceiveProps(nextProps) {
    const { order } = this.props;
    await this.handleSelectedProducts(order.products ? order.products : [], nextProps.Products);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  }

  async handleSelectedProducts(selectedProducts, Products) {
    const {
      meta: {
        cursor: { pageNumber },
      },
      products,
    } = this.state;
    if (Products.totalProducts[pageNumber]) {
      var result = await Products.totalProducts[pageNumber].filter(function(o1) {
        return !products.some(function(o2) {
          return o1.inventoryId === o2.inventoryId;
        });
      });
      if (result.length > 0) {
        if (selectedProducts.length) {
          Products.totalProducts[pageNumber].map(obj => {
            selectedProducts.find(item => {
              if (item.inventoryId === obj.inventoryId) {
                obj.selected = item.selected;
                obj.defaultPrice = item.defaultPrice;
                obj.quantity = item.quantity;
                return obj;
              }
            });
            return obj;
          });
        }
        this.setState({
          products: Products.totalProducts[pageNumber],
          meta: Products.meta,
          itemClasses: this.toProductsArray(Products.products),
          selectedProducts,
          // selectedItems: [],
        });
      }
    }
  }

  async handleProposalProducts(selectProducts = [], Products) {
    const {
      meta: {
        cursor: { pageNumber },
      },
      selectedProducts,
    } = this.state;
    const {
      updateSelectedProductList,
      location: { acceptServiceProposal = false },
    } = this.props;
    if (Products.totalProducts[pageNumber] && !acceptServiceProposal) {
      if (Products && Products.totalProducts[pageNumber].length > 0) {
        selectProducts.map(item => {
          const product = item;
          product.defaultPrice = item.defaultPrice ? item.defaultPrice : 0;
          product.quantity = item.qty || item.quantity;
          product.selected = true;
          product.description = item.name || item.description;
          if (!(selectedProducts && selectedProducts.find(obj => obj.inventoryId === product.inventoryId))) {
            selectedProducts.push(product);
          }
        });
      }
      updateSelectedProductList(selectedProducts);
      this.setState({ selectedProducts });
    }
  }

  handleSorting = name => {
    const { sortedBy, sortAscending, toBeFilteredData } = this.state;
    if (sortedBy === name) {
      this.setState({ sortAscending: !sortAscending });
    } else {
      this.setState({ sortedBy: name, sortAscending: true });
    }
    this.onFetchProducts(toBeFilteredData, null, true);
  };

  async onFetchProducts(toBeFilteredData, searchInput, filter = false) {
    const {
      meta: {
        cursor: { pageNumber },
      },
      pageSize,
      sortedBy,
      sortAscending,
      toBeFilteredData: filterData,
    } = this.state;
    let queryParams = [
      ['pageNumber', searchInput ? 1 : pageNumber],
      ['pageSize', pageSize],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
      ['filter', toBeFilteredData || filterData],
    ];
    let { installationFields } = this.controls;
    const {
      Products,
      getAll,
      order,
      updateProductList,
      location: { acceptProposal, proposalNo, pathname },
    } = this.props;
    if (acceptProposal) {
      let proposalDetails = await this.proposalService.getProposalDetails(proposalNo);
      proposalDetails = proposalDetails.data;
      const {
        data: { customer: selectedCustomer = {}, proposal = {} },
      } = proposalDetails;
      const { installation = {} } = proposal;
      const { price, required, fixedPrice, hours = {} } = installation;
      let noOfHours = 0;
      let pricePerHour = 0;
      if (hours) {
        noOfHours = hours.hours;
        pricePerHour = hours.pricePerHour;
      }
      let {
        data: { selectedItems = [] },
      } = proposalDetails;
      selectedItems = selectedItems.map(item => {
        const { lines } = proposal;
        const selectedData = lines.find(data => data.inventory.number === item.inventoryNumber);
        if (selectedData) {
          return { ...item, defaultPrice: selectedData.unitPrice };
        }
        return item;
      });
      installationFields[0].setValue(String(pricePerHour));
      installationFields[1].setValue(String(noOfHours));
      installationFields[2].setValue(String(price));
      let needInstallation = required ? Constants.installation.yes : Constants.installation.no;
      let isPriceBasedOnHours = fixedPrice ? Constants.installation.price : Constants.installation.hours;
      this.customer = selectedCustomer;
      this.setState({ selectedItems, proposalDetails: proposal, needInstallation, isPriceBasedOnHours });
    } else if (order && order.installationData) {
      const { installationData = {} } = order;
      const { price, installationRequired: required, fixedPrice, hourlyPrice = {} } = installationData;
      const { hours: noOfHours = 0, pricePerHour = 0 } = hourlyPrice;
      installationFields[0].setValue(String(pricePerHour));
      installationFields[1].setValue(String(noOfHours));
      installationFields[2].setValue(String(price));
      let needInstallation = required ? Constants.installation.yes : Constants.installation.no;
      let isPriceBasedOnHours = fixedPrice ? Constants.installation.price : Constants.installation.hours;
      this.setState({ needInstallation, isPriceBasedOnHours });
    } else {
      installationFields[0].setValue(String(0));
      installationFields[1].setValue(String(0));
      installationFields[2].setValue(String(600));
    }
    let customer = this.customer;
    if (order.type == CustomerType.NEW_CONSUMER) {
      if (order.customerType) {
        if (order.customerType === CustomerType.PRIVATE_CUSTOMER) {
          queryParams.push(['customer', 'private']);
        } else if (order.customerType === CustomerType.BUSINESS_CUSTOMER) {
          queryParams.push(['customer', 'business']);
        }
      }
    }
    if (order.type == CustomerType.EXISTING_CUSTOMER || acceptProposal) {
      const customerId = Number(customer && customer.customerClass && customer.customerClass.id);
      if (customerId === 1) {
        queryParams.push(['customer', 'private']);
      } else if (customerId === 4) {
        queryParams.push(['customer', 'business']);
      } else if (customerId === 5) {
        queryParams.push(['customer', 'reseller']);
      }
    }

    if (filter) {
      this.setState({ products: [] });
      updateProductList([]);
    }
    this.setState({ loading: true });
    try {
      await getAll(queryParams, pageNumber);
      if (Products.totalProducts && Products.totalProducts[pageNumber]) {
        this.setState({
          products: Products.totalProducts[pageNumber],
          selectedProducts: order.products,
        });
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  handleItemClick = item => {
    let { products, selectedProducts } = this.state;
    products.filter(obj => obj.lowStock).map(obj => (obj.lowStock = false));
    let findedProduct = products.find(obj => obj.inventoryId === item.inventoryId);

    findedProduct.selected = !findedProduct.selected;
    if (findedProduct.selected) {
      findedProduct.quantity = 1;
      findedProduct.lowStock = findedProduct.quantity > this.getItemQuantity(findedProduct);
      findedProduct.originalPrice = findedProduct.defaultPrice;
    } else {
      findedProduct.quantity = 0;
    }
    selectedProducts.push(findedProduct);
    this.setState({ products, selectedProducts });
  };

  handleServices = service => {
    const { services } = this.state;
    let chosenService = services.find(obj => obj.inventoryId === service.inventoryId);
    chosenService.selected = !chosenService.selected;

    if (!chosenService.parent) {
      chosenService.totalSubPrice = Number(service.defaultPrice);
    } else {
      let chosenServiceParent = services.find(obj => service.parent.id === obj.inventoryId);
      if (service.defaultPrice > 0) {
        chosenServiceParent.totalSubPrice += Number(service.defaultPrice);
      }
    }

    this.setState({ services, selectedItems: [] });
  };

  handleSelectedServices = () => {
    const { services, selectedItems = [] } = this.state;
    selectedItems.map(service => {
      let chosenService = services.find(obj => obj.inventoryId === service.inventoryId);
      if (chosenService) {
        chosenService.selected = true;

        if (!chosenService.parent) {
          chosenService.totalSubPrice = Number(service.defaultPrice);
        } else {
          let chosenServiceParent = services.find(obj => service.parent.id === obj.inventoryId);
          if (service.defaultPrice > 0) {
            chosenServiceParent.totalSubPrice += Number(service.defaultPrice);
          }
        }
      }
    });
    this.setState({ services, selectedItems: [] });
  };

  handleServiceRemove = service => {
    const { services } = this.state;
    let chosenService = services.find(obj => obj.inventoryId === service.inventoryId);
    chosenService.selected = false;
    if (!chosenService.parent) {
      chosenService.totalSubPrice = 0;
    } else {
      let chosenServiceParent = services.find(obj => service.parent.id === obj.inventoryId);
      if (chosenServiceParent.totalSubPrice !== 0) {
        chosenServiceParent.totalSubPrice -= service.defaultPrice;
      }
    }
    this.setState({ services });
    if (service.children) {
      service.children.map(child => {
        return this.handleServiceRemove(child);
      });
    }
  };

  handleServieToggle = ({ serviceData }) => {
    const { services } = this.state;
    if (serviceData) {
      let chosenService = services.find(obj => obj.inventoryId === serviceData.inventoryId);
      if (chosenService && chosenService.isExpanded) {
        chosenService.isExpanded = false;
      } else if (chosenService) {
        chosenService.isExpanded = true;
      }
      this.setState({ services });
    }
  };

  handleRemoveSelectedService = ({ itemData }) => {
    const { parentServiceData } = this.state;
    parentServiceData.map(service => {
      if (service.inventoryId === itemData.inventoryId) {
        service.selected = false;
        service.children.map(child => {
          child.selected = false;
          return child;
        });
      } else {
        if (service.children) {
          service.children.map(child => {
            if (child.inventoryId === itemData.inventoryId && child.selected) {
              child.selected = false;
            }
            return child;
          });
        }
      }
      return service;
    });
    this.setState({ parentServiceData });
  };

  handleReadMore = service => {
    const { services } = this.state;
    services.filter(obj => obj.inventoryId === service.inventoryId).map(obj => (obj.readMore = !obj.readMore));
    this.setState({ services });
  };

  handleIncreaseOrDecreaseHours = n => {
    const { installationFields } = this.controls;
    const amountOfHours = Number(installationFields[1].value) + n;
    if (amountOfHours >= 0) {
      installationFields[1].setValue(amountOfHours);
    }
    this.setState({ isPriceBasedOnHours: Constants.installation.hours });
  };

  handleIncreaseItemQuanity = item => {
    const { products, selectedProducts, proposalDetails = {} } = this.state;
    const { updateSelectedProductList } = this.props;
    const selectedProductindex = selectedProducts.findIndex(obj => obj.inventoryId === item.inventoryId);
    const productIndex = products.findIndex(obj => obj.inventoryId === item.inventoryId);
    if (selectedProductindex >= 0) {
      const product = selectedProducts[selectedProductindex];
      const listProduct = products[productIndex];
      if (product.quantity < 999) {
        product.quantity += 1;
      }
      if (
        proposalDetails &&
        Object.keys(proposalDetails).length &&
        listProduct.quantity < 999 &&
        !listProduct.productSelected
      ) {
        listProduct.quantity += 1;
      }
      products[productIndex] = listProduct;
      selectedProducts[selectedProductindex] = product;
    } else {
      const product = products.find(obj => obj.inventoryId === item.inventoryId);
      product.quantity = 1;
      product.selected = true;
      selectedProducts.push(product);
    }
    updateSelectedProductList(selectedProducts);
    this.handleCalculateInstallationPrice(selectedProducts);
    this.setState({ selectedItems: [] });
  };

  handleDecreaseItemQuantity = item => {
    const { products, selectedProducts, proposalDetails } = this.state;
    const { updateSelectedProductList, updateProductList, Products } = this.props;
    const { totalProducts } = Products;

    // Find in the selected products
    const selectedProductindex = selectedProducts.findIndex(obj => obj.inventoryId === item.inventoryId);
    const product = selectedProducts[selectedProductindex];
    const productIndex = products.findIndex(obj => obj.inventoryId === item.inventoryId);
    let listProduct = products[productIndex];
    if (proposalDetails && Object.keys(proposalDetails).length && !listProduct.productSelected) {
      if (listProduct.quantity > 1) {
        listProduct.quantity -= 1;
      } else {
        listProduct.selected = false;
        listProduct.productSelected = true;
        listProduct.quantity = 0;
      }
    }
    products[productIndex] = listProduct;
    if (product && product.quantity && product.quantity > 1) {
      product.quantity -= 1;
      selectedProducts[selectedProductindex] = product;
    } else if (product) {
      product.selected = false;
      product.quantity = 0;
      selectedProducts.splice(selectedProductindex, 1);
    }
    updateSelectedProductList(selectedProducts);
    this.handleCalculateInstallationPrice(selectedProducts);
    this.setState({ selectedItems: [] });
  };

  getItemQuantity(item) {
    return item.warehouseDetails
      .map(warehouse => warehouse.available)
      .reduce((accumulator, quantity) => {
        return accumulator + quantity;
      });
  }

  handleRemoveItem = item => {
    const { products, selectedProducts } = this.state;
    const { updateSelectedProductList } = this.props;
    let findedProduct = products.find(obj => obj.inventoryId === item.inventoryId);
    findedProduct.defaultPrice = findedProduct.originalPrice;
    findedProduct.selected = false;
    findedProduct.quantity = 0;
    let index = selectedProducts.findIndex(obj => obj.inventoryId === item.inventoryId);
    if (index > -1) {
      selectedProducts.splice(index, 1);
    }
    updateSelectedProductList(selectedProducts);
    this.setState({ products, selectedProducts });
  };

  handleQuantityUpdated = (item, value) => {
    console.log(item, value, 'increasing ');
    const { products, selectedProducts } = this.state;
    const { updateSelectedProductList } = this.props;
    let findedProduct = products.find(obj => obj.inventoryId === item.inventoryId);
    let index = selectedProducts.findIndex(obj => obj.inventoryId === item.inventoryId);
    if (Number(value) <= this.getItemQuantity(findedProduct)) {
      if (value.length !== 0) {
        if (Number(value) <= 0) {
          this.handleRemoveItem(item);
        } else {
          console.log(item, value, 'increasing ');
          findedProduct.lowStock = false;
          findedProduct.selected = false;
          findedProduct.quantity = Number(value);
        }
      }
      if (value.length === 0) {
        findedProduct.lowStock = false;
        findedProduct.selected = false;
        findedProduct.quantity = '';
      }
    } else if (Number(value) > this.getItemQuantity(findedProduct) && Number(value) < 1000) {
      findedProduct.lowStock = true;
      if (value.length === 0) {
        findedProduct.selected = false;
        findedProduct.quantity = '';
      } else {
        findedProduct.selected = true;
        findedProduct.quantity = Number(value);
      }
    }

    if (index > -1 && findedProduct) {
      selectedProducts[index] = findedProduct;
    } else if (findedProduct && Number(value) > 0) {
      selectedProducts.push(findedProduct);
    }
    if (Number(value) === 0 && index > -1) {
      selectedProducts.splice(index, 1);
    }
    updateSelectedProductList(selectedProducts);
    this.setState({ products, selectedProducts });
  };

  handlePriceChanged = item => {
    const { products, selectedProducts, proposalDetails } = this.state;
    let selectProductChange = selectedProducts.find(obj => obj.inventoryId === item.inventoryId);
    let findedProduct = products.find(obj => obj.inventoryId === item.inventoryId);
    findedProduct.defaultPrice = Number(item.defaultPrice);
    if (selectProductChange) {
      selectProductChange.defaultPrice = Number(item.defaultPrice);
      if (proposalDetails) {
        this.setState({
          proposalDetails: {
            ...proposalDetails,
            lines: proposalDetails.lines.map(data => ({
              ...data,
              unitPrice: data.inventory.number === item.inventoryNumber ? Number(item.defaultPrice) : data.unitPrice,
            })),
          },
        });
      }
    }
    this.setState({ products, selectedProducts });
  };

  handleResellerPriceChanged = item => {
    const { products } = this.state;
    let findedProduct = products.find(obj => obj.inventoryId === item.inventoryId);
    findedProduct.resellerPrice = Number(item.resellerPrice);
    this.setState({ products });
    this.state.products = products;
  };

  handleOnServicePriceChange = item => newPrice => {
    const { services } = this.state;
    const currentEditingService = services.find(obj => item.inventoryId === obj.inventoryId);
    if (currentEditingService) {
      currentEditingService.currentCost = Number(newPrice);
      currentEditingService.defaultPrice = Number(newPrice);
    }

    if (currentEditingService.parent) {
      let currentEditingServiceParent = services.find(obj => item.parent.id === obj.inventoryId);
      currentEditingServiceParent.totalSubPrice = this.getTotalSubPrice(currentEditingServiceParent);
    } else {
      currentEditingService.totalSubPrice = this.getTotalSubPrice(currentEditingService);
    }
    this.setState({ services });
  };

  handleChangePrice = (name, value) => {
    const { products } = this.state;
    const { installationFields } = this.controls;
    installationFields[2].setValue(installationFields[2].value);
    this.setState({ products });
  };

  getTotalSubPrice = service => {
    return (
      service.defaultPrice +
      service.children
        .filter(obj => obj.selected)
        .reduce((accumulator, childService) => accumulator + childService.defaultPrice, 0)
    );
  };

  handleOnQualityUpdatedBlur = (item, value) => {
    const { products } = this.state;
    let findedProduct = products.find(obj => obj.inventoryId === item.inventoryId);
    if (Number(value) === 0) {
      findedProduct.quantity = 0;
      findedProduct.selected = false;
    } else {
      findedProduct.lowStock = false;
    }
    this.setState({ products });
  };

  handleOnRemoveToolTip = item => {
    const { products } = this.state;
    let findedProduct = products.find(obj => obj.inventoryId === item.inventoryId);
    findedProduct.lowStock = false;
    this.setState({ products });
  };

  toProductsArray = products => {
    return products
      .map(project => project.itemClass)
      .filter(item => item && parseInt(item.id) > 9 && parseInt(item.id) !== 16)
      .filter((item, i, objects) => {
        return objects.find(obj => obj.id === item.id) === item;
      });
  };

  renderProductListView = ({
    products,
    isReseller,
    isSuperAdmin,
    isAdminSales,
    isAdminBackOffice,
    featureData,
    sortAscending,
    sortedBy,
    meta,
    proposalDetails,
    isProposals,
  }) => (
    <ProductListView
      items={products}
      sorting={false}
      forBasket={true}
      onItemClick={this.handleItemClick}
      onIncreaseItem={this.handleIncreaseItemQuanity}
      onDecreaseItem={this.handleDecreaseItemQuantity}
      onRemoveItem={this.handleRemoveItem}
      onQuantityUpdated={this.handleQuantityUpdated}
      onQuantityUpdatedBlur={this.handleOnQualityUpdatedBlur}
      onPriceChanged={this.handlePriceChanged}
      onChangeInResellerPrice={this.handleResellerPriceChanged}
      onRemoveToolTip={this.handleOnRemoveToolTip}
      isReseller={isReseller}
      isSuperAdmin={isSuperAdmin}
      isAdminSales={isAdminSales}
      isAdminBackOffice={isAdminBackOffice}
      featureData={featureData}
      editFeature={this.handleEditFeature}
      onFeatureChange={this.handleOnFeatureChange}
      deleteFeature={this.handleDeleteFeature}
      onSort={this.handleSorting}
      onFileUploading={this.handleFileUploading}
      onFileDelete={this.handleFileDelete}
      sortAscending={sortAscending}
      sortedBy={sortedBy}
      meta={meta}
      proposalDetails={proposalDetails}
      isProposals={isProposals}
    />
  );

  // Filter Pop Over Handles
  handleFitlerButton = () => {
    const { isFilterOpen } = this.state;
    this.setState({ isFilterOpen: !isFilterOpen });
  };

  handleOnClickValues = (obj, value) => {
    const { productCategoriesData } = this.state;
    const arrayIndex = productCategoriesData[obj].selectedValues.indexOf(value);
    if (arrayIndex > -1) {
      productCategoriesData[obj].selectedValues.splice(arrayIndex, 1);
    } else {
      productCategoriesData[obj].selectedValues.push(value);
    }
    this.setState({ productCategoriesData });
  };

  handleCancelFilter = () => {
    this.setState({ isFilterOpen: false });
  };

  handleApplyFilter = data => {
    let toBeFilteredData = {};
    data.map(obj => {
      if (toBeFilteredData[obj.id]) {
        toBeFilteredData[obj.id].push(obj.value);
      } else {
        toBeFilteredData[obj.id] = [];
        toBeFilteredData[obj.id].push(obj.value);
      }
      return obj;
    });
    this.setState({ toBeFilteredData, isFilterOpen: false, products: [] });
    this.onFetchProducts(toBeFilteredData, data, true);
  };

  handleClearFilter = () => {
    const { productCategoriesData } = this.state;
    const keys = Object.keys(productCategoriesData);
    keys.map(obj => (productCategoriesData[obj].selectedValues = []));

    this.setState({ productCategoriesData, searchQuery: '', products: [] });
  };

  handleEditFeature = indexId => {
    const { featureData } = this.state;
    if (featureData[indexId].selected) {
      featureData[indexId].selected = false;
    } else {
      featureData[indexId].selected = true;
    }
    featureData.map((obj, index) => index !== indexId && (obj.selected = false));
    this.setState({ featureData });
  };
  handleOnFeatureChange = ({ index, type }, e) => {
    const { featureData } = this.state;
    featureData[index][type] = e.target.value;
    this.setState({ featureData });
  };

  handleDeleteFeature = ({ index }) => {
    const { featureData } = this.state;
    featureData.splice(index, 1);
    this.setState({ featureData });
  };

  handleOnConfirmOrderServices = ({ services, customer = this.customer, orderType = OrderType.SERVICE_ORDER }) => {
    const {
      confirmOrder,
      history,
      location: { pathname },
    } = this.props;
    const { proposalDetails } = this.state;
    const selectedServices = this.serviceObjectSpread({
      parentServiceData: services,
    });
    if (pathname === Constants.routes.newServicePrepareProposal) {
      confirmOrder(services, customer, Constants.orderTypes.proposalService, selectedServices, {}, proposalDetails);
      history.push(Routes.newProposalConfirmProposalServices);
    } else {
      confirmOrder(services, customer, orderType, selectedServices, {}, proposalDetails);
      history.push(Routes.newOrderConfirmOrderServices);
    }
  };

  handleOnConfirmOrderProducts = ({ selectedProducts, customer = this.customer }) => {
    const {
      confirmOrder,
      history,
      updateProductList,
      location: { pathname },
    } = this.props;
    const { needInstallation, isPriceBasedOnHours, proposalDetails } = this.state;
    const { installationFields } = this.controls;
    updateProductList([]);

    if (pathname === Constants.routes.newProductPrepareProposal) {
      const installationData = {
        installationRequired: needInstallation === Constants.installation.yes,
        fixedPrice: isPriceBasedOnHours !== Constants.installation.hours,
        hourlyPrice: {
          hours: installationFields[1].value,
          pricePerHour: installationFields[0].value,
        },
        price:
          isPriceBasedOnHours !== Constants.installation.hours
            ? installationFields[2].value
            : Number(installationFields[1].value) * Number(installationFields[0].value),
      };
      confirmOrder(
        selectedProducts,
        customer,
        Constants.orderTypes.proposalProduct,
        [],
        installationData,
        proposalDetails
      );
      history.push(Routes.newProposalConfirmProposalProducts);
    } else {
      confirmOrder(selectedProducts, customer, OrderType.PRODUCT_ORDER, [], {}, proposalDetails);
      history.push(Routes.newOrderConfirmOrderProducts);
    }
  };

  handleSearchOrders = async value => {
    const { toBeFilteredData, searchQuery } = this.state;
    if (value && value.length > 0) {
      if (toBeFilteredData.name && toBeFilteredData.name.length > 0) {
        toBeFilteredData.name = toBeFilteredData.name.filter(obj => obj !== searchQuery);
        toBeFilteredData.name.push(value);
      } else {
        toBeFilteredData.name = [value];
      }
    } else {
      delete toBeFilteredData.name;
    }
    let searchData = {
      id: 'name',
      value,
      component: 'search',
    };
    this.setState({ searchQuery: value, toBeFilteredData, products: [], searchData });
    this.onFetchProducts(toBeFilteredData, value, true);
  };

  handleGotoPage = pageNumber => {
    this.updateProductListToRedux(this.state.meta.cursor.pageNumber);
    this.setState({
      loading: true,
      meta: {
        cursor: {
          pageNumber: pageNumber,
          pageSize: this.state.meta.cursor.pageSize,
          totalCount: this.state.meta.cursor.totalCount,
          pages: this.state.meta.cursor.pages,
        },
      },
    });
  };
  updateReduxList = () => {
    const { updateProductsList } = this.props;
    updateProductsList([]);
  };
  handleFileUploading = async ({ item }, event) => {
    let response;
    const array = this.fileListToArray(event.target.files);

    try {
      response = await this.productService.uploadFile(array[0], item.inventoryNumber, Constants.FileTypes.DOCUMENT);
    } catch (error) {
      console.error(error);
    }
    if (response.status) {
      const { products } = this.state;
      const {
        data: {
          data: { id, name, url },
        },
      } = response;
      const findedProduct = products.find(obj => obj.inventoryNumber === item.inventoryNumber);
      if (!findedProduct.media) {
        findedProduct.media = [];
      }
      findedProduct.media.push({
        file_id: id,
        file_name: name,
        file_url: url,
        file_type: Constants.FileTypes.DOCS,
      });
      this.setState({ products });
    }
  };

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  handleFileDelete = ({ id, inventoryNumber }) => {
    const response = this.productService.removeProductDocument(id, inventoryNumber);
    const { products } = this.state;
    if (response.status === 200) {
      const findedProduct = products.find(obj => obj.inventoryNumber === inventoryNumber)['media'];
      findedProduct.findIndex(obj => obj.file_id);
    }
    const findedProduct = products.find(obj => obj.inventoryNumber === inventoryNumber)['media'];
    const index = findedProduct.findIndex(obj => obj.file_id === id);
    findedProduct.splice(index, 1);
    this.setState({ products });
  };
  handleCalculateInstallationPrice = selectedProducts => {
    const { installationFields } = this.controls;
    let fixedPrice = 0;
    let totalHours = 0;
    selectedProducts.map(o => {
      if (o && o.installationTime) {
        const { installationTime: time } = o;
        totalHours = totalHours + time;
      }
    });
    installationFields[1].setValue(totalHours);
  };

  handleOnRemoveProduct = ({ itemData }) => {
    let { selectedProducts, products } = this.state;
    let { updateSelectedProductList, Products } = this.props;

    // TODO: Object mutated. Need to analyse and see whether this acceptable.
    let mergedProducts = Products.totalProducts.flat();
    let findedProduct = mergedProducts.find(obj => obj.inventoryId === itemData.inventoryId);
    if (findedProduct) {
      findedProduct.quantity = 0;
      findedProduct.selected = false;
    }
    let index = selectedProducts.findIndex(obj => obj.inventoryId === itemData.inventoryId);
    selectedProducts.splice(index, 1);
    updateSelectedProductList(selectedProducts);
    this.handleCalculateInstallationPrice(selectedProducts);
    this.setState({ selectedProducts, products });
  };

  serviceObjectSpread = ({ parentServiceData }) => {
    let spreadedData = [];
    parentServiceData.map(obj => {
      if (obj.selected) {
        spreadedData.push(obj);
      }
      if (obj.children) {
        obj.children.map(child => {
          if (child.selected) {
            spreadedData.push(child);
          }
          return child;
        });
      }
      return obj;
    });
    return spreadedData;
  };

  handleChangeInPriceOrInstallation = (name, value) => {
    const { installationFields } = this.controls;
    if (name === 'needInstallation' && value === Constants.installation.no) {
      installationFields[0].setValue(String(0));
      installationFields[1].setValue(String(0));
      installationFields[2].setValue(String(600));
    }
    this.setState({ [name]: value });
  };

  handleOnPopUpClick = ({ service }) => {
    const { parentServiceData } = this.state;
    const findedService = parentServiceData.find(data => data.inventoryId === service.inventoryId);
    if (!findedService) {
      parentServiceData.map(obj => {
        if (obj.children && obj.children.length > 0) {
          obj.children.map(item => {
            if (item.inventoryId === service.inventoryId) {
              item.isInfoOpened = !item.isInfoOpened;
            }
            return item;
          });
        }
        return obj;
      });
    }

    if (findedService) {
      findedService.isInfoOpened = !findedService.isInfoOpened;
    }

    this.setState({ parentServiceData });
  };

  handleoutside = () => {
    const { isFilterOpen } = this.state;
    if (isFilterOpen) {
      this.setState({ isFilterOpen: !isFilterOpen });
    }
  };

  updateProductListToRedux = pageNumber => {
    const { updateProductList, Products } = this.props;
    const { products } = this.state;
    let data = Products;
    data.totalProducts[pageNumber] = products;
    updateProductList(data.totalProducts);
  };
  backFunc = event => {
    const { updateProductList } = this.props;
    updateProductList([]);

    this.goBack(event);
  };
  render() {
    const {
      itemClasses,
      serviceSection,
      isFilterOpen,
      newOrderProductFilterData,
      featureData,
      parentServiceData,
      meta,
      toBeFilteredData,
      sortAscending,
      sortedBy,
      loading,
      searchQuery,
      products,
      searchData,
      isPriceBasedOnHours,
      needInstallation,
      proposalDetails,
    } = this.state;

    const {
      order: { customerType, orderType },
      location: { pathname: pathName },
    } = this.props;
    let selectedProducts = this.state.selectedProducts ? this.state.selectedProducts : [];

    let isServices = false;
    if (
      pathName === Constants.routes.newServicePrepareOrder ||
      pathName === Constants.routes.newServicePrepareProposal
    ) {
      isServices = true;
    }
    const isReseller = this.authService.isResellerUser();
    const isSuperAdmin = this.authService.isSuperAdminUser();
    const isAdminSales = this.authService.isAdminSalesUser();
    const isAdminBackOffice = this.authService.isAdminBackOfficeUser();
    const isServiceOrder = orderType === OrderType.SERVICE_ORDER ? true : false;
    const isPrivateCustomer = customerType === CustomerType.PRIVATE_CUSTOMER ? true : false;
    let selectedServicesData = this.serviceObjectSpread({ parentServiceData });
    const isServiceProposal = pathName === Constants.routes.newServicePrepareProposal;

    return (
      <React.Fragment>
        <section id="page-header">
          <div className="container-fluid px-0">
            <div className=" mt-0">
              <div className="row justify-content-end pr-0 mx-0">
                <React.Fragment>
                  <div className="col text-right margin-top-neg-11 px-0">
                    <a href="#back" className="font-weight-normal fs-14" onClick={this.backFunc}>
                      <img
                        className="mr-2 pr-1"
                        src={Constants.icons.arrowBack}
                        width="24"
                        height="24"
                        alt="back-icon"
                      />

                      <u>
                        <FormattedMessage id="common_back" />
                      </u>
                    </a>
                  </div>
                </React.Fragment>
              </div>
              <div className="row mb-4">
                <div className="col-10">
                  <h1 className="mb-3 ls-0-25">
                    {isServiceOrder && pathName !== Constants.routes.newServicePrepareProposal ? (
                      <FormattedMessage id="bc_new_subscription" />
                    ) : pathName === Constants.routes.newProductPrepareProposal ? (
                      <FormattedMessage id="bc_new_proposal_product" />
                    ) : pathName === Constants.routes.newServicePrepareProposal ? (
                      <FormattedMessage id="bc_new_proposal_service" />
                    ) : (
                      <FormattedMessage id="bc_new_order_products" />
                    )}
                  </h1>

                  <p className="mb-0">{!isServiceOrder && <FormattedMessage id="new_order_product_description" />}</p>
                  <p className="">
                    {isServices ? (
                      customerType === CustomerType.PRIVATE_CUSTOMER ? (
                        <FormattedMessage id="new_service_tax_included" />
                      ) : (
                        <FormattedMessage id="new_service_tax_excluded" />
                      )
                    ) : (
                      ' '
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <React.Fragment>
          {!serviceSection && !isServiceProposal && (
            <section id="products" className="bg-green pb-3 mb-3">
              <React.Fragment>
                <div id="products-content" className="collapse show">
                  <div className="container-fluid mt-3 px-0">
                    <div className="">
                      <div className="card card-rounded">
                        <div className="card-body list-view-card-body">
                          <div className="row ">
                            <h4 className="mb-3 pl-3 font-weight-semibold line-height-normal ls-0-25">
                              <FormattedMessage id="new_order_select_item" />
                            </h4>
                          </div>
                          <div className={'row ' + (Object.keys(toBeFilteredData).length > 0 ? 'mb-4' : 'mb-4 pb-4')}>
                            <div className="col-5 mr-4 w-378-max p-0 ml-3">
                              <SearchInput
                                onSearch={this.handleSearchOrders}
                                containerStyle="h-44 border-2-ededed br-4"
                                iconStyle="h-100"
                              />
                            </div>

                            <div className="col-2 w-107-max p-0">
                              <FilterButton
                                className="btn-grey-light btn py-2 mr-3 text-black filter-btn-text"
                                onClick={this.handleFitlerButton}
                              />
                            </div>
                          </div>
                          <div className="">
                            <FilterPopOver
                              isOpen={isFilterOpen}
                              forBasket={true}
                              options={newOrderProductFilterData}
                              onCancel={this.handleCancelFilter}
                              onApply={this.handleApplyFilter}
                              onClearFilters={this.handleClearFilter}
                              searchData={searchData}
                              onClearSearch={this.handleSearchOrders.bind(this, '')}
                            />
                          </div>
                          <div className="ri-table mb-3 px-0 fs-14 ls-0-44">
                            <div className="tab-content">
                              <LoadingOverlay
                                active={loading}
                                spinner={<BounceLoader />}
                                className="loading__overlay_white_wrapper loader-list-view-common"
                                text={<FormattedMessage id="common_loading_overlay" />}
                              >
                                {!loading && (
                                  <React.Fragment>
                                    {products.length > 0 ? (
                                      this.renderProductListView({
                                        itemClasses,
                                        products,
                                        isReseller,
                                        isSuperAdmin,
                                        isAdminSales,
                                        isAdminBackOffice,
                                        featureData,
                                        sortAscending,
                                        sortedBy,
                                        meta,
                                        proposalDetails,
                                        isProposals: pathName === Constants.routes.newProductPrepareProposal,
                                      })
                                    ) : (
                                      <div className="container pt-3 ri-table">
                                        <React.Fragment>
                                          <div className="row justify-content-center mb-4 min-height-44 align-items-center">
                                            <p className="text-center">
                                              ! &nbsp;
                                              <FormattedMessage id="all_products_no_products" />
                                            </p>
                                          </div>
                                        </React.Fragment>
                                      </div>
                                    )}
                                  </React.Fragment>
                                )}
                              </LoadingOverlay>
                            </div>
                          </div>
                          {!loading && products.length > 0 && (
                            <Pagination cursor={meta.cursor} gotoPage={this.handleGotoPage} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </section>
          )}

          {(serviceSection || isServiceProposal) && (
            <>
              <section id="services" className="bg-green">
                <div id="services-content" className="collapse show">
                  <div className="container-fluid mt-3 px-0">
                    <LoadingOverlay
                      active={loading}
                      spinner={<BounceLoader />}
                      className="loading__overlay_white_wrapper loader-list-view-common"
                      text={<FormattedMessage id="common_loading_overlay" />}
                    >
                      {!loading && (
                        <div className="">
                          <ServiceCards
                            parentServiceData={parentServiceData}
                            onReadMore={this.handleReadMore}
                            onServiceSelect={this.handleServices}
                            onServiceRemove={this.handleServiceRemove}
                            onPopUpClick={this.handleOnPopUpClick}
                            onServiceToggle={this.handleServieToggle}
                          />
                        </div>
                      )}
                    </LoadingOverlay>
                  </div>
                </div>
              </section>
            </>
          )}
          {pathName === Constants.routes.newProductPrepareProposal && (
            <div className={'container-fluid px-0 box-shadow-none ' + (!isServices ? 'mt-3' : '')}>
              <div className="card card-rounded p-4">
                <InstallationCard
                  controls={this.controls}
                  needInstallation={needInstallation}
                  isPriceBasedOnHours={isPriceBasedOnHours}
                  handleChange={this.handleChangeInPriceOrInstallation}
                />
              </div>
            </div>
          )}
          <div id="products-content" className="collapse show mb-4 pb-2">
            <div className={'container-fluid px-0 ' + (!isServices ? 'mt-3' : '')}>
              <div className="box-shadow-none">
                <div className="card card-rounded">
                  <div className="">
                    <div className={'card-body list-view-card-body m-4 p-0'}>
                      <div className="row">
                        <SelectedProducts
                          needInstallation={needInstallation === Constants.installation.yes}
                          isPriceBasedOnHours={isPriceBasedOnHours === Constants.installation.hours}
                          productsData={isServices ? selectedServicesData : selectedProducts}
                          isServices={isServices}
                          onQtyDecrease={this.handleDecreaseItemQuantity}
                          onQtyIncrease={this.handleIncreaseItemQuanity}
                          onConfirmOrderProducts={
                            isServices
                              ? this.handleOnConfirmOrderServices.bind(this, {
                                  orderType,
                                  services: parentServiceData,
                                })
                              : this.handleOnConfirmOrderProducts
                          }
                          onRemoveItem={isServiceOrder ? this.handleRemoveSelectedService : this.handleOnRemoveProduct}
                          isPrivateCustomer={isPrivateCustomer}
                          onServicePriceChange={this.handleOnServicePriceChange}
                          isProposals={pathName === Constants.routes.newProductPrepareProposal}
                          isServiceProposal={pathName === Constants.routes.newServicePrepareProposal}
                          controls={this.controls}
                          onRemoveInstallation={this.handleChangeInPriceOrInstallation}
                          handleIncreaseOrDecreaseHours={this.handleIncreaseOrDecreaseHours}
                          proposalDetails={proposalDetails}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

PrepareOrder.propTypes = {
  order: PropTypes.object,
  history: PropTypes.object,
  confirmOrder: PropTypes.func,
};

const mapStateToProps = ({ order, Products }) => ({
  order,
  Products,
});

const mapDispatchToProps = dispatch => {
  return {
    confirmOrder: (products, customer, orderType, services, installationData, proposalDetails) => {
      dispatch({
        type: 'CONFIRM_ORDER',
        payload: { products, customer, orderType, services, installationData, proposalDetails },
      });
    },
    getAll: (url, pageNumber) => dispatch(getAll(url, pageNumber)),
    updateProductList: value => {
      dispatch({
        type: 'UPDATE_TOTAL_PRODUCTS',
        payLoad: value,
      });
    },
    updateSelectedProductList: value => {
      dispatch({
        type: 'UPDATE_SELECTED_PRODUCTS',
        payload: value,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrepareOrder);
