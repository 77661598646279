import React from 'react';
import { FormattedMessage } from 'react-intl';
import 'react-datepicker/dist/react-datepicker.css';
import EditContractsDetails from '../EditContractsDetails';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import PropTypes from 'prop-types';

const EditContractDetailsModal = ({
  controls,
  installationEdit,
  onSubmit,
  isValid,
  // Below prop is for Customer Auto Suggest component
  customerService,
  onExistingCustomer,
  onCustomerSelected,
  editContractLoader,
  contractDetails,
}) => {
  return (
    <div
      className="modal fade bd-example-modal-lg"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
      id="editContractDetailsModal"
    >
      <div className="modal-dialog modal-lg modal-product">
        <div className="modal-content contracts-modal">
          <div className="modal-header modal-header-product">
            <h3 className="modal-title font-weight-semibold" id="myLargeModalLabel">
              <FormattedMessage id="edit_contract_details" />
            </h3>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className=" modal-body p-4 my-2 line-height-normal ls-normal">
            <LoadingOverlay
              active={editContractLoader}
              spinner={<BounceLoader />}
              text={<FormattedMessage id="common_loading_overlay" />}
              classNamePrefix={'light_modal_'}
            >
              <div className="row">
                <div className="col">
                  {installationEdit ? (
                    <EditContractsDetails
                      controls={controls}
                      onSubmit={onSubmit}
                      isValid={isValid}
                      // Below props is for customer auto suggest component
                      customerService={customerService}
                      onExistingCustomer={onExistingCustomer}
                      onCustomerSelected={onCustomerSelected}
                      contractDetails={contractDetails}
                    />
                  ) : (
                    <>
                      <p className="text-center">
                        <FormattedMessage id="contract_details_edit_success_message" />
                      </p>
                      <button
                        type="button"
                        className="btn btn-dark btn-xs d-block w-100 py-2 modal-apply-btn font-weight-light col-2 offset-5 mt-5 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <FormattedMessage id="common_close" />
                      </button>
                    </>
                  )}
                </div>
              </div>
            </LoadingOverlay>
          </div>
        </div>
      </div>
    </div>
  );
};

EditContractDetailsModal.propTypes = {
  controls: PropTypes.object,
  installationEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
  isValid: PropTypes.bool,
  customerService: PropTypes.object,
  onExistingCustomer: PropTypes.func,
  onCustomerSelected: PropTypes.func,
  editContractLoader: PropTypes.bool,
};

export default EditContractDetailsModal;
