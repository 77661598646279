import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import moment from 'moment';

import Constants from '../../shared/constants';
import Routes from '../../shared/Routes';
import AuthService from '../../services/AuthService';
import DashBoardService from '../../services/DashboardService';

import Config from '../../config';
import { selectOrder } from '../../state/actions/OrderActions';
import { OrderType } from '../../shared/OrderType';
import { Period, Periods } from './Period';
import DashboardItem from './DashboardItem';
import SalesOrderChat from './SalesOrderChat';
import SubscriptionChart from './SubscriptionChart';

function getDateString(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toFixed(0).padStart(2, 0);
  const day = date
    .getDate()
    .toFixed(0)
    .padStart(2, 0);

  return `${year}-${month}-${day}`;
}

function getCurrentPreviousQuarter(value) {
  var today = new Date(),
    quarter = Math.floor(today.getMonth() / 3),
    startDate,
    endDate;

  switch (value) {
    case 'previous':
      startDate = new Date(today.getFullYear(), quarter * 3 - 3, 1);
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
      break;
    default:
      startDate = new Date(today.getFullYear(), quarter * 3, 1);
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
      break;
  }
  return {
    StartDate: getDateString(startDate),
    EndDate: getDateString(endDate),
  };
}
class AdminDashboard extends Component {
  dashboardService = new DashBoardService(Config.apiBase);
  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      textAreaEnabled: false,
      dashboardText: '',
      orderType: false,
      selectedPeriod: Periods[Period.LastTwelveMonths],
      salesOrders: [],
      salesOrderPeriod: [],
      loading: false,
      stats: {
        cumulative: {
          customers: { value: 0, changeIn30Days: 0 },
          salesOrders: {
            orders: { value: 0, changeIn30Days: 0 },
            revenue: { value: 0, changeIn30Days: 0 },
          },
          subscriptions: { active: { value: 0, changeIn30Days: 0 }, revenue: { value: 0, changeIn30Days: 0 } },
        },
        details: {
          subscriptions: [],
          salesOrders: [],
        },
      },
    };
  }

  async fetchStats(selectedPeriod) {
    this.setState({ loading: true });
    let queryParams;
    const now = new Date();
    const date = new Date();
    switch (selectedPeriod.id) {
      case Period.LastTwelveMonths:
        date.setMonth(date.getMonth() - 11);
        queryParams = [
          [
            'start',
            `${date.getFullYear()}-${date
              .getMonth()
              .toFixed(0)
              .padStart(2, 0)}`,
          ],
          [
            'end',
            `${now.getFullYear()}-${now
              .getMonth()
              .toFixed(0)
              .padStart(2, 0)}`,
          ],
        ];
        break;
      case Period.ThisYear:
        queryParams = [
          ['start', `${now.getFullYear()}-01`],
          ['end', `${now.getFullYear()}-12`],
        ];
        break;
      case Period.LastYear:
        date.setYear(date.getFullYear() - 1);
        queryParams = [
          ['start', `${date.getFullYear()}-01`],
          ['end', `${date.getFullYear()}-12`],
        ];
        break;
      case Period.LastQuarter:
        const previousQuarter = getCurrentPreviousQuarter('previous');
        queryParams = [
          ['start', previousQuarter.StartDate],
          ['end', previousQuarter.EndDate],
        ];
        break;
      case Period.ThisQuarter:
        const quarter = getCurrentPreviousQuarter('current');
        queryParams = [
          ['start', quarter.StartDate],
          ['end', quarter.EndDate],
        ];
        break;
      case Period.LastTwelveWeeks:
        moment.locale('no');
        const previousWeek = moment().week() - 1;
        const beginingWeek = moment().week() - 12;
        const startDate = moment()
          .weeks(beginingWeek)
          .days(1);
        const endDate = moment()
          .weeks(previousWeek)
          .days(7);

        queryParams = [
          ['start', startDate.format('YYYY-MM-DD')],
          ['end', endDate.format('YYYY-MM-DD')],
        ];
        break;
    }
    let response;
    try {
      response = await this.dashboardService.getStats(queryParams);
    } catch (e) {
      console.error(e);
    }

    let { data = {} } = response;
    this.setState({ stats: data, selectedPeriod, loading: false });
  }

  async componentDidMount() {
    this.setState({
      dashboardText: localStorage.getItem('adminMessage'),
    });
    this.fetchStats(Periods[Period.LastTwelveMonths]);
    try {
      let response = await this.authService.getOption('message');
      if (response !== this.state.dashboardText) {
        this.setState({ dashboardText: response });
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleTextArea = () => {
    this.setState({ textAreaEnabled: true });
  };

  closeTextArea = () => {
    this.setState({
      textAreaEnabled: false,
      dashboardText: localStorage.getItem('adminMessage'),
    });
  };

  saveTextArea = async () => {
    this.setState({
      textAreaEnabled: false,
    });

    //Set this message as adminMessage
    var adminMessage = this.state.dashboardText;
    try {
      if (adminMessage !== '' && adminMessage !== undefined) {
        this.authService.setOption({
          option: 'message',
          value: adminMessage,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  textAreaChange = e => {
    this.setState({ dashboardText: e.target.value });
  };

  handleServiceOrder = event => {
    const { history, selectOrder } = this.props;
    event.preventDefault();
    selectOrder(OrderType.SERVICE_ORDER);
    history.push(Routes.newOrderChooseCustomerService);
  };

  handleProductOrder = event => {
    const { history, selectOrder } = this.props;
    event.preventDefault();
    selectOrder(OrderType.PRODUCT_ORDER);
    history.push(Routes.newOrderChooseCustomerProduct);
  };

  handleClickDropDown(value, event) {
    this.fetchStats(value);
  }

  render() {
    const { textAreaEnabled, dashboardText, stats = {}, selectedPeriod, salesOrderPeriod, loading } = this.state;
    const { cumulative = {}, details = {} } = stats;
    const { customers = {}, salesOrders = {}, subscriptions = {}, orderOnHold = {}, proposal = {} } = cumulative;
    if (localStorage.userData) {
      this.userData = JSON.parse(localStorage.getItem('userData'));
      let name = this.userData.name;
      this.userName = name.split(' ', 1);
      this.showSuperAdmin = this.userData.name === 'Admin Backoffice' || this.userData.name === 'Admin User';
    }
    this.showSuperAdmin = this.authService.isAdminBackOfficeUser() || this.authService.isSuperAdminUser();

    return (
      <>
        <section className="mt-0 mb-6">
          <div className="row mx-0 h-130 mt-3">
            <div className="col">
              <h1>
                <FormattedMessage id="dashboard_home" />
              </h1>
            </div>
            <div className="col">
              <div className="row">
                <div className="col">
                  <div className="w-180-min">
                    <Link
                      to={'new-order/choose-customer/product'}
                      className={'btn btn-primary h-44 pt-2 pb-1 line-height-16 ls-1-27'}
                    >
                      <img src={Constants.icons.iconAddWhite} width="24" height="24" className="mr-1" alt="icon" />
                      <span className="va-middle font-weight-normal">
                        <FormattedMessage id="bc_new_order_products" />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="col">
                  <div className="w-180-min">
                    <Link
                      to={'new-order/choose-customer/service'}
                      className={'btn btn-primary h-44 pt-2 pb-1 line-height-16 ls-1-27'}
                    >
                      <img src={Constants.icons.iconAddWhite} width="24" height="24" className="mr-1" alt="icon" />

                      <span className="va-middle font-weight-normal">
                        <FormattedMessage id="bc_new_subscription" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <hr />
            </div>
          </div>
          <LoadingOverlay
            active={loading}
            spinner={<BounceLoader />}
            className="loading__overlay_white_wrapper loader-list-view-common"
            text={<FormattedMessage id="common_loading_overlay" />}
          >
            <div className="row mx-0 h-130 mt-3">
            {/* <div className="col">
                  <div className="col p-3">
                    <DashboardItem
                      icon={Constants.icons.dashboardIncome}
                      title="dashboard_total_revenue_ytd"
                      data={salesOrders.revenue}
                      isValueCurrency={true}
                    ></DashboardItem>
                  </div>
                  <span className="border-right my-4" />
                  <div className="col p-3">
                    <DashboardItem
                      icon={Constants.icons.dashboardIncome}
                      title="dashboard_total_revenue_ytd"
                      data={salesOrders.revenue}
                      isValueCurrency={true}
                    ></DashboardItem>
                  </div>
                  <span className="border-right my-4" />
                  <div className="col p-3">
                    <DashboardItem
                      icon={Constants.icons.dashboardIncome}
                      title="dashboard_total_revenue_ytd"
                      data={salesOrders.revenue}
                      isValueCurrency={true}
                    ></DashboardItem>
                  </div>
                </div>
            <div className="row">
              <div className="col">
                <hr />
              </div>
            </div> */}
              <div className="col-7 card mr-2 shadow-none">
              <div className="col card mr-2 shadow-none">
              <div className="row h-130">
                <div className="col-4 pl-3 pr-1 py-3">
                  <DashboardItem
                    icon={Constants.icons.dashboardIncome}
                    title="dashboard_total_revenue_ytd"
                    data={salesOrders.revenue}
                    isValueCurrency={true}
                  ></DashboardItem>
                </div>
                <span className="border-right my-4" />
                <div className="col p-3">
                  <DashboardItem
                    icon={Constants.icons.dashboardOrders}
                    title="on_hold_order"
                    data={orderOnHold.revenue}
                    isValueCurrency={true}
                    order
                  ></DashboardItem>
                </div>
                <span className="border-right my-4" />
                  <div className="col p-3">
                    <DashboardItem
                      icon={Constants.SideMenu.sProposalActive}
                      title="open_proposal"
                      data={proposal.revenue}
                      isValueCurrency={true}
                      proposal
                    ></DashboardItem>
                  </div>
                </div>
              </div>
              </div>
              <div className="col card mr-2 shadow-none">
                <div className="row h-130">
                  <div className="col pl-3 pr-1 py-3 ">
                    <DashboardItem
                      icon={Constants.icons.dashboardSubscriptions}
                      route={Routes.contracts}
                      title="dashboard_active_subscriptions"
                      data={subscriptions.active}
                    ></DashboardItem>
                  </div>
                  <span className="border-right my-4" />
                  <div className="col p-3">
                    <DashboardItem
                      icon={Constants.icons.dashboardSubscriptions}
                      title="dashboard_mrr"
                      data={subscriptions.revenue}
                      isValueCurrency={true}
                    ></DashboardItem>
                  </div>
                </div>
              </div>
              {/* <div className="col card shadow-none">
                <div className="row">
                  <div className="col pl-3 pr-1 py-3">
                    <DashboardItem
                      icon={Constants.icons.dashboardOrders}
                      route={Routes.orders}
                      title="dashboard_product_orders_ytd"
                      data={salesOrders.orders}
                    ></DashboardItem>
                  </div>
                  <span className="border-right my-4" />
                  <div className="col p-3">
                    <DashboardItem
                      icon={Constants.icons.dashboardIncome}
                      title="dashboard_total_revenue_ytd"
                      data={salesOrders.revenue}
                      isValueCurrency={true}
                    ></DashboardItem>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row mx-0 mt-5 h-44">
              <div id="select-period" className="dropdown card align-left w-100 h-44 px-0">
                <button
                  className="btn bg-white dropdown-toggle pl-3 text-left fs-14 px-0"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FormattedMessage id={selectedPeriod.label} />
                </button>
                <div className="dropdown-menu border-0 mt-1 w-100" aria-labelledby="dropdownMenuButton">
                  {Periods.map(period => (
                    <li
                      key={period.id}
                      className="dropdown-item cursor-pointer w-100 fs-14"
                      onClick={this.handleClickDropDown.bind(this, period)}
                    >
                      <span className={period.id === selectedPeriod.id ? 'font-weight-semibold' : 'font-weight-normal'}>
                        <FormattedMessage id={period.label} />
                      </span>
                    </li>
                  ))}
                </div>
              </div>
            </div>

            <div className="row mx-0 mt-3 mb-3">
              <div className="col p-3 card">
                <div>
                  <h4>
                    <Link to={Routes.orders}>
                      <u className="color-242f33 font-weight-normal">
                        <FormattedMessage id="bc_contracts" />
                      </u>
                    </Link>
                  </h4>
                </div>
                <SubscriptionChart data={details.subscriptions} interval={details.interval} />
              </div>
            </div>

            <div className="row mx-0 mb-3">
              <div className="col p-3 card">
                <div>
                  <h4>
                    <Link to={Routes.orders}>
                      <u className="color-242f33 font-weight-normal">
                        <FormattedMessage id="dashboard_product_revenue" />
                      </u>
                    </Link>
                  </h4>
                </div>

                <SalesOrderChat data={details.salesOrders} interval={details.interval} />
              </div>
            </div>
          </LoadingOverlay>
        </section>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    orderType: state.orderType,
  };
};

const AdminDashboardComponent = connect(mapStateToProps, { selectOrder })(AdminDashboard);

export default withRouter(AdminDashboardComponent);
