import { Component } from "react";

class ListViewComponent extends Component {
  state = { sorting: false, sortField: "", sortAscending: true };

  componentDidMount() {
    if (this.props.sorting) {
      this.setState({ sorting: this.props.sorting });
    }
  }

  getSortedItems(items) {
    const { sorting, sortField, sortAscending } = this.state;
    if (!sorting) {
      return items;
    }
    return items.sort((a, b) => {
      if (sortAscending) {
        if (
          (typeof a[sortField] === "string") |
          (a[sortField] instanceof String)
        ) {
          return a[sortField].localeCompare(b[sortField]);
        }
        return a[sortField] - b[sortField];
      }

      if (
        (typeof a[sortField] === "string") |
        (a[sortField] instanceof String)
      ) {
        return b[sortField].localeCompare(a[sortField]);
      }
      return b[sortField] - a[sortField];
    });
  }
}

export default ListViewComponent;
