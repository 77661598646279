import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import Constants from '../../shared/constants';
import validator from 'validator';
import Select from '../Input/select';
import Input from '../Input/index';
import ReactQuill from 'react-quill';
import { Control, ValidationRule } from '../../shared/Control';
import { CheckBox } from '../CheckBox/CheckBox';
import Config from '../../config';

class ImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { imageModalClose, imageOpen, image } = this.props;

    return (
      <div
        className="modal fade bd-example-modal-lg fade-products"
        tabIndex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="imagemodal"
      >
        <div className="modal-dialog image-width">
          <button
            type="button"
            className="close outline-0 font-size-2rem"
            data-dismiss="modal"
            aria-label="Close"
            ref={closeModal => (this.closeElement = closeModal)}
            onClick={imageModalClose}
          >
            <span aria-hidden="true">×</span>
          </button>

          <div
            className={'image-no-crop-image'}
            style={{
              backgroundImage: `url("${image.file_url}")`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </div>
      </div>
    );
  }
}

export default ImageModal;
