import ApiService from './ApiService';

class DashboardService extends ApiService {
  endPoint = 'stats';

  async getStats(queryParam) {
    let response = await this.get(this.baseURL + this.endPoint + this.queryParamString(queryParam));
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      const { stats } = response.data;

      return { status: response.status, data: stats };
    }
  }
}

export default DashboardService;
