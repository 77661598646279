import React from "react";
import Constants from "../../shared/constants";
import PropTypes from "prop-types";

export const HeadCaps = ({ text, onClickIcon }) => {
  return (
    <div className="head-caps-button">
      <span>{text}</span>
      <img
        className="cross-icon-head-cap"
        src={Constants.icons.crossGrey}
        onClick={onClickIcon}
        alt="cross-icon"
      />
    </div>
  );
};

HeadCaps.propTypes = {
  text: PropTypes.string,
  onClickIcon: PropTypes.func
};
