import React from 'react';
import Constants from '../../../shared/constants';
import PropTypes from 'prop-types';

export const FilterButton = ({ className, onClick, children, onBlur }) => {
  return (
    <div onClick={onClick} className={className} onBlur={onBlur} id="filter-button">
      <span>Filter</span>
      <img src={Constants.icons.filterIcon} className="filter-button-icon" alt="filter icon" />
    </div>
  );
};

FilterButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  onBlur: PropTypes.func,
};
