import React, { Component } from "react";
import validator from "validator";
import Constants from "../shared/constants";

class BaseComponent extends Component {
  state = {};
  validations = [];

  constructor(props) {
    super(props);
    this.initialize();
  }

  initialize() {}

  validate() {
    let validation = {};
    this.validations.map(
      rule => (validation[rule.name] = { isInvalid: false, message: "" })
    );
    validation = { isValid: true, ...validation };

    this.validations.forEach(rule => {
      if (!validation[rule.name].isInvalid) {
        const field_value = this.state[rule.name].toString();
        const args = rule.args || [];
        const validation_method =
          typeof rule.method === "string"
            ? validator[rule.method]
            : rule.method;
        if (
          validation_method(field_value, ...args, this.state) !== rule.validWhen
        ) {
          validation[rule.name] = {
            isInvalid: true,
            message: rule.message
          };
          validation.isValid = false;
        }
      }
    });
    return validation;
  }

  canShowNext(lastOrders, orders, cursor) {
    // Check, Is the last records are same between last request and
    // current request
    const lastItemInPreviousOrders = lastOrders.last;
    const lastItemInCurrentOrders = orders.last;
    return !(
      (lastItemInPreviousOrders &&
        lastItemInPreviousOrders.orderNo === lastItemInCurrentOrders.orderNo) ||
      orders.length < cursor.count
    );
  }

  render() {
    return <React.Fragment />;
  }

  goBack = event => {
    const { history } = this.props;
    event.preventDefault();
    if (history) {
      if (history.length > 1) {
        return history.goBack();
      } else {
        return history.push({
          pathname: Constants.routes.home.url
        });
      }
    } else {
      return history.push({
        pathname: Constants.routes.home.url
      });
    }
  };
}

export default BaseComponent;
