import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactQuill from 'react-quill';
import Select from '../Input/select';
import Input from '../Input/index';
import Constants from '../../shared/constants';
import RadioButtonComponent from '../RadioButton';
import { CheckBox } from '../CheckBox/CheckBox';
import PropTypes from 'prop-types';
import { charLimit } from '../../shared/stringOperations';
import Autosuggest from 'react-autosuggest';
import { groupByKey } from '../../shared/utils';

export default class AddProductForm extends Component {
  state = {
    techData: [],
    open: false,
    enterReseller: false,
    value: '',
    vendors: [],
  };
  constructor(props) {
    super(props);
    this.inputElement = React.createRef();
  }
  toggleDropdown() {
    this.setState({ open: !this.state.open });
  }
  handleTdelete = id => {
    const { techData } = this.state;

    techData.splice(id, 1);
    this.updateParentState();
  };
  handleAddTechData = () => {
    const { techData } = this.state;

    if (techData.length > 0) {
      if (techData[techData.length - 1].spec !== '' && techData[techData.length - 1].description !== '') {
        techData.push({ spec: '', description: '' });
      }
    } else {
      techData.push({ spec: '', description: '' });
    }

    this.setState(techData);
  };
  handleOnChangeTechDataTile = (name, options) => {
    const { techData } = this.state;

    if (options.value) {
      let key = options.name.slice(0, -1);
      let index = name.charAt(name.length - 1);
      techData[index][key] = options.value;
      this.updateParentState();
    }
  };
  handleForm(techData, index) {
    return (
      <div className="row" key={index}>
        <div className="col-3 postal-input-width status pr-0">
          {this.renderInputElements({
            name: 'spec' + index,
            label: <FormattedMessage id="create_title" />,
            id: 'title' + index,
            onChange: this.handleOnChangeTechDataTile,
            value: techData[index].spec,
            autoFocus: techData.length - 1 === index ? true : false,
          })}
        </div>
        <div className="col-7 product-bar pr-3 postal-input-width">
          {this.renderInputElements({
            name: 'description' + index,
            label: <FormattedMessage id="common_description" />,
            id: 'description' + index,
            onChange: this.handleOnChangeTechDataTile,
            value: techData[index].description,
          })}{' '}
        </div>
        <div className="col-1 text-center pl-0 pr-5 pt-1">
          <label className="close align-items-center mt-3" aria-label="Close" onClick={() => this.handleTdelete(index)}>
            ×
          </label>
        </div>
      </div>
    );
  }
  updateParentState = async () => {
    const { addDataState } = this.props;
    try {
      await addDataState(this.state);
    } catch (err) {
      console.log(err);
    }
  };
  getAppendedComponents = () => {
    const { techData } = this.state;
    return techData.map((obj, index) => this.handleForm(techData, index));
  };

  renderInputElements = control => {
    return control.type === 'select' ? (
      <Select
        options={control}
        className={' cursor-pointer'}
        placeholder={control.label}
        key={control.name}
        value={control.value}
        onChange={control.onChange}
        ref={input => {
          control.ref = input;
        }}
      />
    ) : (
      <Input
        key={control.name}
        options={control}
        value={control.value}
        onChange={control.onChange}
        ref={input => {
          control.ref = input;
        }}
      />
    );
  };

  renderSelectedMedia = (type, data) => {
    let typesKey = groupByKey(data, 'type');
    let icon = type === Constants.FileTypes.IMAGE ? Constants.icons.image : Constants.icons.document;
    if (typesKey[type]) {
      return typesKey[type].map(obj => {
        return (
          <div className="w-100 border-bottom-light pl-1 pb-2 add-products-media-preview" key={obj.id}>
            <img src={icon} width="18" height="18" alt="" />
            <span> {obj.name}</span>
            <span className="pb-1">
              <button
                className="close"
                aria-label="Close"
                className="close"
                onClick={this.props.handleRemoveMedia.bind(this, obj)}
              >
                &times;
              </button>
            </span>
          </div>
        );
      });
    }
  };

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };
  onBlur = event => {
    this.setState({ enterReseller: true });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = async ({ value }) => {
    if (value.length > 3 && !this.state.fetchVendor) {
      this.setState({ fetchVendor: true });
      const response = await this.props.productService.getAllUsers([['filter', { name: [value] }]]);
      const { data } = response;
      if (data && data.length > 0) {
        this.setState({ notFound: false });
      } else {
        this.setState({ notFound: true });
      }
      this.setState({ vendors: data, fetchVendor: false });
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({ vendors: [] });
  };

  handleSuggestionSelected = (event, obj) => {
    this.setState({ selectedVendor: obj.suggestion });

    const { addDataState } = this.props;
    this.state.selectedVendor = obj.suggestion;
    addDataState(this.state);
  };

  onRemoveSelection = () => {
    const { addDataState } = this.props;
    this.state.selectedVendor = '';
    this.setState({ value: '', selectedVendor: '' });
    addDataState(this.state);
  };
  handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDropImage = event => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      //this.setState({ file: event });
      this.props.handleimage(event);
    }
  };
  handleDropDocument = event => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      //this.setState({ file: event });
      this.props.handleDocuments(event);
    }
  };
  render() {
    const {
      controls,
      isPrivatePClicked,
      isPublicClicked,
      isResellerClicked3,
      Dropship1,
      handleOnSelectedCategory,
      Dropship0,
      show,
      dontshow,
      privatePrice,
      companyPrice,
      resellerPrice,
      DropshipYes,
      DropshipNo,
      ShowNumber,
      DontShowNumber,
      mediaArray,
      handleimage,
      itemClassOptions,
      quillModules,
      newProductDescription,
      newProductEditorChange,
      ProductModalClose,
      handleOnCreateNewProduct,
      handleDocuments,
      mediaId,
      isValid,
      handleOnSelectedStatus,
      quillblur,
      isSubmitted,
      formValid,
    } = this.props;

    let item = {};
    item.media = mediaArray;
    const { techData, value, vendors, selectedVendor } = this.state;
    const inputProps = {
      placeholder: Constants.language.create_select_vendor,
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
    };
    const options = vendors.slice(0, 20).map(vendor => {
      return { id: vendor.number, name: vendor.name };
    });
    return (
      <>
        <div className="row justify-content-between mb-4 pb-2">
          <div className="col-12 fs-16">
            <label className="product-heading mb-0 fs-16">
              <FormattedMessage id="create_product" />
            </label>
            <div className="row mt-0 w-100 ">
              <div className="col-7 product-bar pr-3 postal-input-width">
                {controls.addProduct.map(control => this.renderInputElements(control))}
              </div>
              <div className=" col-3 mt-2 pt-1 pl-0 cursor-pointer">
                {controls.addStatus.map(control => this.renderInputElements(control))}
              </div>
            </div>
            <div className="row w-100 ">
              <div className="col-7 product-bar pr-3 postal-input-width">
                {controls.addItem.map(control => this.renderInputElements(control))}
              </div>
              <div className=" postal-input-width status">
                {controls.addEl.map(control => this.renderInputElements(control))}
              </div>
            </div>
            <label className="product-heading mb-0">
              <FormattedMessage id="create_default_price" />
            </label>
            <div className="row  status ml-0 p-0">
              {controls.addPrice.map(control => this.renderInputElements(control))}
            </div>
            <label className="product-heading mb-0">
              <FormattedMessage id="create_installation_time" />
            </label>
            <div className="row status ml-0 p-0">
              {controls.installationTime.map(control => this.renderInputElements(control))}
            </div>
            <label className="product-heading mb-2">
              <FormattedMessage id="create_customer_class" />
            </label>
            <label className="d-flex align-items-center mb-2 pb-1">
              <CheckBox
                isSelected={isPrivatePClicked}
                onClick={this.props.privatePrice}
                onChange={this.props.privatePrice}
                label={<FormattedMessage id="create_consumer" />}
                className={'space-remove'}
              />
            </label>
            {isPrivatePClicked && (
              <div className="row col-3 pb-1 space-remove">
                {controls.addPrivatePrice.map(control => this.renderInputElements(control))}
                <p className="col optional  pl-2 space-remove">
                  <FormattedMessage id="create_optional" />
                </p>
              </div>
            )}
            <label className="d-flex align-items-center mb-2 pb-1">
              <CheckBox
                isSelected={isPublicClicked}
                onClick={companyPrice}
                onChange={companyPrice}
                label={<FormattedMessage id="create_company" />}
              />
            </label>
            {isPublicClicked && (
              <div className="row col-3 space-remove">
                {controls.addCompanyPrice.map(control => this.renderInputElements(control))}
                <div>
                  <p className="col optional pl-2 space-remove">
                    <FormattedMessage id="create_optional" />
                  </p>
                </div>
              </div>
            )}
            <label className="d-flex align-items-center mb-0">
              <CheckBox
                isSelected={isResellerClicked3}
                onClick={resellerPrice}
                onChange={resellerPrice}
                label={<FormattedMessage id="all_contracts_reseller" />}
              />
            </label>
            {isResellerClicked3 && (
              <div className="row col-3 space-remove">
                {controls.addResellerPrice.map(control => this.renderInputElements(control))}
                <div>
                  <p className="col optional pl-2 space-remove">
                    <FormattedMessage id="create_optional" />
                  </p>
                </div>
              </div>
            )}
            {isSubmitted && !isPrivatePClicked && !isPublicClicked && !isResellerClicked3 && (
              <div className="text-danger error-message small mt-2">
                <FormattedMessage id="create_product_customer_class_error" />
              </div>
            )}
          </div>
        </div>
        <div className="row mb-5 pb-2">
          <div className="col">
            <label className="product-heading">
              <FormattedMessage id="all_products_product_snapshot" />
            </label>
            <ReactQuill
              className="product-quill w-634-max"
              theme="snow"
              value={newProductDescription}
              modules={quillModules}
              onChange={value => newProductEditorChange(value)}
            />
          </div>
        </div>
        <label className="product-heading mb-2">
          <FormattedMessage id="create_technical_data" />
        </label>
        <div className="mb-4 pb-2 cursor-pointer fs-16">
          {this.getAppendedComponents()}
          <a
            href="#"
            onClick={this.handleAddTechData}
            className={techData.length > 0 ? 'col-10 d-flex justify-content-end pr-2' : ''}
          >
            <u>
              + <FormattedMessage id="create_add_new_table" />
            </u>
          </a>
        </div>

        <label className="product-heading">
          <FormattedMessage id="create_images" />
        </label>
        <div className="row ml-0">
          <label className="col-3 document-upload">
            <p
              className="fs-14 my-4 pcolor mb-0 text-center line-height-normal ls-normal"
              onDrop={e => this.handleDropImage(e)}
              onDragOver={e => this.handleDragOver(e)}
              onDragEnter={e => this.handleDragEnter(e)}
              onDragLeave={e => this.handleDragLeave(e)}
            >
              <span className="plus-upload1">+</span>
              <br /> <FormattedMessage id="create_drag_and_drop_image" />
            </p>
            <input
              accept={
                Constants.inputAcceptImageTypes // single={"true"}
              }
              onClick={this.openFileDialog}
              onChange={handleimage}
              className="FileInput"
              type="file"
            />
          </label>
          <div className="col-8 ml-1 mb-4 pr-5">{this.renderSelectedMedia(Constants.FileTypes.IMAGE, mediaId)}</div>
        </div>
        <label className="product-heading">
          <FormattedMessage id="create_documents" />
        </label>
        <div className="row ml-0 pb-2">
          <label
            className="col-3 document-upload mb-4 pl-0 pr-0"
            onDrop={e => this.handleDropDocument(e)}
            onDragOver={e => this.handleDragOver(e)}
            onDragEnter={e => this.handleDragEnter(e)}
            onDragLeave={e => this.handleDragLeave(e)}
          >
            <p className=" my-4 mb-0 pcolor text-center line-height-normal ls-normal ">
              <span className="plus-upload1">+</span>
              <br />
              <FormattedMessage id="create_drag_and_drop_document1" />
              <br />
              <FormattedMessage id="create_drag_and_drop_document2" />
            </p>
            <input
              accept={
                Constants.inputAcceptDocumentTypes // single={"true"}
              }
              onClick={this.openFileDialog}
              onChange={handleDocuments}
              className="FileInput"
              type="file"
            />
          </label>
          <div className="col-8 ml-1 mb-4 pr-5">{this.renderSelectedMedia(Constants.FileTypes.DOCUMENT, mediaId)}</div>
        </div>
        <label className="product-heading">
          <FormattedMessage id="create_category" />
        </label>
        <div className="mb-4 row">
          <div className="col-6 ml-3 pl-0 pr-0 w-378-max">
            {controls.itemClass && controls.itemClass.map(control => this.renderInputElements(control))}
          </div>
        </div>
        <label className="product-heading mb-0">
          <FormattedMessage id="create_dropship" />
        </label>
        <div className="row col-6 mt-2 fs-16">
          <RadioButtonComponent
            checked={show}
            value={show}
            onClick={DropshipYes}
            onChange={DropshipYes}
            label={<FormattedMessage id="create_yes" />}
          />
        </div>
        <div className="row col-6 mb-0 pb-2 mt-2 pt-1 fs-16">
          <RadioButtonComponent
            checked={dontshow}
            value={dontshow}
            onClick={DropshipNo}
            onChange={DropshipNo}
            label={<FormattedMessage id="create_no" />}
          />
        </div>
        {isSubmitted && !formValid.dropship && (
          <div className="text-danger error-message small mt-2">
            <FormattedMessage id="create_product_dropship_error" />
          </div>
        )}

        <label className="product-heading mb-2 mt-4">
          <FormattedMessage id="create_vendor" />
        </label>
        <div className="mb-4 row">
          <div className="col-6 ml-3 pl-0 pr-0 searchvendor fs-16">
            {!selectedVendor && (
              <div id="search-box" className="input-group border-vendor vendor-color bg-green pl-4 w-378-max">
                <div className="form-control border-0 pt-2 pl-0">
                  <Autosuggest
                    suggestions={options}
                    onSuggestionSelected={this.handleSuggestionSelected}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={option => option.name}
                    renderSuggestion={suggestion => (
                      <div className="w-378-max">
                        {suggestion.name} - #{suggestion.id}
                      </div>
                    )}
                    inputProps={inputProps}
                  />
                </div>
                <div className="search-icon ">
                  <button className="btn py-2 mr-1 z-index-4 bg-transparent bg-color-inherit h-100" type="button">
                    {this.state.fetchVendor && (
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">
                          <FormattedMessage id="common_loading_overlay" />
                        </span>
                      </div>
                    )}
                    {!this.state.fetchVendor ? (
                      value.length > 0 ? (
                        <span className="float-right cursor-pointer" onClick={this.onRemoveSelection}>
                          <img
                            className="mr-2 cursor-pointer"
                            src={Constants.icons.crossNew1}
                            width="14"
                            height="14"
                            alt="trash-icon"
                          />
                        </span>
                      ) : (
                        <img src={Constants.icons.search} width="18" height="18" alt="search-icon" />
                      )
                    ) : (
                      ''
                    )}
                  </button>
                </div>
              </div>
            )}
            {this.state.enterReseller && !this.props.notFound && (
              <div className="text-danger error-message small customer-suggest-error mt-1">
                <FormattedMessage id="warning_vendor" />
              </div>
            )}
            {this.props.notFound && (
              <div className="text-danger error-message small customer-suggest-error mt-1">
                <FormattedMessage id="vendor_search_error" />
              </div>
            )}
            {selectedVendor && (
              <div id="selectedVendor" className="input-group mb-0 w-378-max">
                <div
                  className="pl-0 pr-0 border-vendor vendor-color bg-green pl-3"
                  title={selectedVendor.name + ', ' + selectedVendor.id}
                >
                  {[charLimit.charLimit(selectedVendor.name, 12), selectedVendor.id].join(',  ')}
                  <span className="float-right cursor-pointer px-1" onClick={this.onRemoveSelection}>
                    <img
                      className="mr-2 cursor-pointer"
                      src={Constants.icons.crossNew1}
                      width="14"
                      height="14"
                      alt="trash-icon"
                    />
                  </span>
                </div>
              </div>
            )}
            {!options && (
              <div className="text-danger error-message small customer-suggest-error ml-2">
                <span>
                  <FormattedMessage id="vendor_search_error" />
                </span>
              </div>
            )}
          </div>
        </div>

        <label className="product-heading mb-2">
          <FormattedMessage id="create_show_on_rackit_no" />
        </label>
        <div className="row col-6 mb-2 pb-1 fs-16">
          <RadioButtonComponent
            checked={Dropship1}
            onClick={ShowNumber}
            onChange={ShowNumber}
            label={<FormattedMessage id="create_yes" />}
          />
        </div>
        <div className="row col-6 mb-4 pb-2 fs-16">
          <RadioButtonComponent
            checked={Dropship0}
            onClick={DontShowNumber}
            onChange={DontShowNumber}
            label={<FormattedMessage id="create_no" />}
          />
        </div>
        <div className="">
          <div className="d-inline-block mr-1">
            <button className={isValid ? 'btn-primary btn-pro' : 'btn-pro'} onClick={handleOnCreateNewProduct}>
              {' '}
              <FormattedMessage id="create_add_new" />
            </button>
          </div>
          <div className="d-inline-block ml-5 pl-5">
            <a href="#table" className="font-weight-bold black" onClick={ProductModalClose} data-dismiss="modal">
              <u>
                <FormattedMessage id="create_cancel" />
              </u>
            </a>
          </div>
        </div>
      </>
    );
  }
}
