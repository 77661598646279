import React from 'react';

export default class PriceEditable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: this.props.text || 0,
    };
  }

  handleRef = ref => {
    this.textInput = ref;
    if (this.props.ref) {
      this.props.ref(ref);
    }
  };

  handleChange = text => {
    this.setState({ text });
  };

  handleBlur = () => {
    const { onChange } = this.props;
    const price = Number(this.textInput.value).toFixed(2);
    this.setState({ text: price });
    if (onChange) {
      onChange(price);
    }
  };

  render() {
    const { text } = this.state;
    const {
      inputClassName,
      inputMaxLength,
      inputPlaceHolder,
      inputTabIndex,
      inputWidth,
      inputHeight,
      inputFontSize,
      inputFontWeight,
    } = this.props;
    return (
      <div className="d-inline-block">
        <input
          type="number"
          step=".01"
          className={inputClassName}
          ref={this.handleRef}
          defaultValue={text}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          style={{
            width: inputWidth,
            height: inputHeight,
            fontSize: inputFontSize,
            fontWeight: inputFontWeight,
            borderWidth: 0,
            borderColor: '#ccc',
            cursor: 'text',
          }}
          maxLength={inputMaxLength}
          placeholder={inputPlaceHolder}
          tabIndex={inputTabIndex}
          onKeyPress={e => {
            if ((e.charCode > 47 && e.charCode < 58) || e.charCode === 46) {
              return true;
            }

            e.preventDefault();
          }}
        />
      </div>
    );
  }
}
