const Routes = {
  home: { url: '/' },
  dashboard: { url: '/dashboard' },
  newReseller: '/new-reseller',
  createNewReseller: '/new-reseller/create-new-reseller',
  connectExistingReseller: '/new-reseller/connect-existing-reseller',
  signin: '/',
  products: '/products',
  services: '/services',
  resellers: '/resellers',
  customers: '/customers',
  contracts: '/contracts',
  orders: '/orders',
  proposals: '/proposals',
  myOrders: '/my-orders',
  myProposals: '/my-proposals',
  newOrderChooseCustomerService: '/new-order/choose-customer/service',
  newOrderChooseCustomerProduct: '/new-order/choose-customer/product',
  newProposalChooseCustomerProduct: '/new-proposal/choose-customer/product',
  newProposalChooseCustomerService: '/new-proposal/choose-customer/service',
  newOrderPrepareOrder: '/new-order/prepare-order',
  newServicePrepareOrder: '/new-order/select-service',
  newProductPrepareProposal: '/new-proposal/select-product',
  newServicePrepareProposal: '/new-proposal/select-service',
  newProductPrepareOrder: '/new-order/select-product',
  newOrderThankyouOrder: '/new-order/thankyou',
  newProposalThankyou: '/new-proposal/thankyou',
  newOrderService: '/new-order/service',
  newOrderConfirmOrderProducts: '/new-order/products/confirm-order',
  newOrderConfirmOrderServices: '/new-order/services/confirm-order',
  newProposalConfirmProposalProducts: '/new-proposal/products/confirm-proposal',
  newProposalConfirmProposalServices: '/new-proposal/services/confirm-proposal',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  newPassword: '/new-password',
  newOrderProduct: '/new-order/select-product',
  thankYouOrder: '/new-order/thank-you-order',
  service: '/services',
  users: '/users',
  documents: 'documents',
};
export default Routes;
