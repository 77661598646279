import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomerService from '../services/CustomerService';
import Config from '../config';
import Constants from '../shared/constants';
import CustomerListView from '../components/Customers/Components/ListView/CustomerListView';
import SearchInput from '../components/Search/';
import Pagination from '../components/Pagination/';
import BaseComponent from '../components/BaseComponent';
import LoadingOverlay from 'react-loading-overlay';
import AuthService from '../services/AuthService';
import BounceLoader from 'react-spinners/BounceLoader';
import { FilterButton } from '../components/FilterData/Components/FilterButton';
import { FilterPopOver } from '../components/FilterData/FilterPopOver';
import CustomerDetailView from '../components/Customers/Components/DetailView/CustomerDetailView';
import CustomerModal from '../components/Customers/CustomerModal';
import Toast from '../components/Toast/index';

class Customers extends BaseComponent {
  customerService = new CustomerService(Config.apiBase);
  authService = new AuthService(Config.apiBase);
  isResellerLoggedIn = this.authService.isResellerUser();

  constructor(props) {
    super(props);
    this.state = {
      loggedResellerId: JSON.parse(localStorage.userData).visma_id,
      customers: [],
      meta: { cursor: { pageNumber: 1 } },
      searchQuery: '',
      showNext: true,
      message: false,
      sortedBy: 'date',
      sortAscending: false,
      isFilterOpen: false,
      ismodalopen: false,
      showToast: false,
      searchData: { id: 'name', value: '', component: 'search' },
      toastData: {},
      customerFilterData: [
        {
          id: 'startsWith',
          component: 'letterSelect',
          label: Constants.language.customer_head_1,
          values: [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
          ],
          defaultValues: [],
        },
        {
          id: 'date',
          component: 'datePicker',
          label: Constants.language.filter_registered_date,
          fromDate: { defaultDate: this.props.maxDate },
          toDate: { defaultDate: this.props.maxDate },
        },
        {
          id: 'type',
          component: 'checkBox',
          groups: [
            {
              label: Constants.language.customer_type,
              id: 'type',
              values: [1, 4, 5],
              defaultValues: [],
            },
          ],
        },
        {
          id: 'status',
          component: 'checkBox',
          groups: [
            {
              label: Constants.language.all_orders_order_status,
              id: 'customerStatus',
              values: ['Active', 'Inactive'],
              defaultValues: ['Active'],
            },
          ],
        },
      ],
      toBeFilteredData: {},
    };
    this.searchCustomers.bind(this);
  }

  async componentDidMount() {
    const { toBeFilteredData } = this.state;
    toBeFilteredData.status= ['Active'];
    this.setState({ toBeFilteredData: {...toBeFilteredData, status: ['Active']} })
    await this.fetchCustomers(toBeFilteredData);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      searchQuery,
      meta: {
        cursor: { pageNumber },
      },
      sortAscending,
      sortedBy,
    } = this.state;
    const {
      meta: {
        cursor: { pageNumber: prevPageNumber },
      },
      sortAscending: prevSortAscending,
      sortedBy: prevSortedBy,
    } = prevState;
    if (prevPageNumber !== pageNumber || prevSortedBy !== sortedBy || prevSortAscending !== sortAscending) {
      this.fetchCustomers();
    }
  }

  searchCustomers(value) {
    const { toBeFilteredData, searchQuery } = this.state;
    if (value && value.length > 0) {
      if (toBeFilteredData.name && toBeFilteredData.name.length > 0) {
        toBeFilteredData.name = toBeFilteredData.name.filter(obj => obj !== searchQuery);
        toBeFilteredData.name.push(value);
      } else {
        toBeFilteredData.name = [value];
      }
    } else if (toBeFilteredData.name) {
      delete toBeFilteredData.name;
    }
    let searchData = {
      id: 'name',
      value,
      component: 'search',
    };
    this.fetchCustomers(toBeFilteredData, value);
    this.setState({ searchQuery: value, toBeFilteredData, searchData });
  }
  handleGotoPage(pageNumber) {
    this.setState({
      meta: {
        cursor: {
          pageNumber: pageNumber,
        },
      },
    });
  }

  async fetchCustomers(toBeFilteredData, searchInput) {
    const { sortedBy, sortAscending, toBeFilteredData: filterData, loggedResellerId, loading } = this.state;
    const queryParams = !this.isResellerLoggedIn
      ? [
          ['pageNumber', searchInput ? 1 : this.state.meta.cursor.pageNumber],
          ['sortedBy', sortedBy],
          ['sortAscending', sortAscending],
          ['filter', toBeFilteredData || filterData],
        ]
      : [
          ['pageNumber', searchInput ? 1 : this.state.meta.cursor.pageNumber],
          ['sortedBy', sortedBy],
          ['sortAscending', sortAscending],
          ['filter', { reseller: [loggedResellerId] }],
        ];

    if (!loading) {
      this.setState({ loading: true });
    }

    let response;
    try {
      response = await this.customerService.getAllUsers(queryParams);
      if (response && response.status === 200) {
        let { status, customers, meta } = response;
        if (customers.length > 0) {
          if (this.state.searchQuery) {
            this.setState({ message: false });
          }
          this.setState({
            customers: customers,
            meta,
          });
        } else {
          if (this.state.searchQuery) {
            this.setState({
              message: true,
              unknown: true,
              customers: [],
              meta,
            });
          } else {
            this.setState({ customers: [], meta });
          }
        }
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.error(e);
    }
    this.setState({ loading: false });
  }

  handleSorting = name => {
    if (this.state.sortedBy === name) {
      this.setState({ sortAscending: !this.state.sortAscending });
    } else {
      this.setState({ sortedBy: name, sortAscending: true });
    }
  };

  // Filter Data Handle
  handleFitlerButton() {
    const { isFilterOpen } = this.state;
    this.setState({ isFilterOpen: !isFilterOpen });
  }

  handleCancelFilter() {
    this.setState({ isFilterOpen: false });
  }

  handleFilterApply(data) {
    let toBeFilteredData = {};
    data.map(obj => {
      if (toBeFilteredData[obj.id]) {
        toBeFilteredData[obj.id].push(obj.value);
      } else {
        toBeFilteredData[obj.id] = [];
        toBeFilteredData[obj.id].push(obj.value);
      }
      return obj;
    });
    this.setState({ toBeFilteredData, isFilterOpen: false });
    this.fetchCustomers(toBeFilteredData, data);
  }

  handleAddCustomer = () => {
    this.setState({ ismodalopen: true }, () => {
      this.openElement.click();
    });
  };

  handleCustomerModalClose = () => {
    this.setState({ ismodalopen: false });
  };

  handleToast = data => {
    this.setState({ showToast: true, toastData: data });
    setTimeout(() => {
      this.setState({
        showToast: false,
      });
    }, data.delay);
    if (data.type === Constants.toast.SUCCESS) {
      this.fetchCustomers();
    }
  };

  handleCreateCustomer = () => {
    this.setState({ loading: true });
  };

  render() {
    const {
      customers,
      meta,
      loading,
      searchQuery,
      isFilterOpen,
      customerFilterData,
      toBeFilteredData,
      ismodalopen,
      toastData,
      showToast,
      searchData,
      sortedBy,
      sortAscending,
    } = this.state;
    const isSuperAdmin = this.authService.isSuperAdminUser();
    const isAdminSales = this.authService.isAdminSalesUser();
    const isAdminBackOffice = this.authService.isAdminBackOfficeUser();
    return (
      <React.Fragment>
        {showToast && (
          <Toast type={toastData.type} message={toastData.message} showToast={showToast} delay={toastData.delay} />
        )}
        <section id="page-header">
          <div className="container-fluid px-0">
            <div className=" mt-5">
              <div className="row mb-3">
                <div className="col">
                  <h1 className="mb-1 ls-0-25 line-height-normal">
                    <FormattedMessage id="sidemenu_all_customers" />
                  </h1>
                  <p className="mb-0 line-height-22 w-600-max">
                    <FormattedMessage id="customer_paragraph" />
                  </p>
                </div>
              </div>
              {(isSuperAdmin || isAdminBackOffice) && (
                <div className="row my-4">
                  <div className="col">
                    <button
                      className="new-product-btn  btn btn-primary fs-14  w-180-min text-align-center line-height-16 py-1 line-height-16 ls-1-27"
                      onClick={this.handleAddCustomer}
                    >
                      <img src={Constants.icons.iconAddWhite} width="24" height="24" className="mr-1" alt="icon" />
                      <span className="va-middle font-weight-normal">
                        <FormattedMessage id="customer_add_customer" />
                      </span>
                    </button>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#customerModal"
                      ref={openModal => (this.openElement = openModal)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <section id="products" className="bg-green pb-3 loading-bg-height mb-5">
          <React.Fragment>
            <div id="products-content" className="collapse show">
              <div className="container-fluid mt-4 px-0">
                <div className=" ">
                  <div className="card card-rounded">
                    <div className="card-body list-view-card-body">
                      <div className="row">
                        <h4 className="mb-3 pl-3 font-weight-semibold line-height-normal ls-0-25">
                          <FormattedMessage id="all_customer_overview" />
                        </h4>
                      </div>
                      <div
                        className={
                          'row ' + (toBeFilteredData && Object.keys(toBeFilteredData).length > 0 ? 'mb-4' : 'mb-4 pb-3')
                        }
                      >
                        <div className="col-5 mr-4 w-378-max p-0 ml-3">
                          <SearchInput
                            onSearch={this.searchCustomers.bind(this)}
                            containerStyle="h-44 border-2-ededed br-4"
                            iconStyle="h-100"
                          />
                        </div>
                        <div className="col-2 w-107-max p-0 ml-2 h-44">
                          <FilterButton
                            className="btn-grey-light btn mr-3 h-44"
                            onClick={this.handleFitlerButton.bind(this)}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          'filter-pop-over-container-resellers ' + (toBeFilteredData ? 'filter-data-selected' : '')
                        }
                      >
                        <FilterPopOver
                          isOpen={isFilterOpen}
                          onApply={this.handleFilterApply.bind(this)}
                          onCancel={this.handleCancelFilter.bind(this)}
                          options={customerFilterData}
                          searchData={searchData}
                          searchQuery={searchQuery}
                          onClearSearch={this.searchCustomers.bind(this, '')}
                        />
                      </div>

                      <LoadingOverlay
                        active={loading}
                        spinner={<BounceLoader />}
                        className="loading__overlay_white_wrapper loader-list-view-common"
                        text={<FormattedMessage id="common_loading_overlay" />}
                      >
                        <div className="ri-table mb-3 px-2 fs-16 ls-0-44 pr-4">
                          {customers.length > 0 ? (
                            <CustomerListView
                              users={customers}
                              sortAscending={sortAscending}
                              onSort={this.handleSorting}
                              sortedBy={sortedBy}
                            />
                          ) : (
                            <>
                              <div className=" pt-3 ri-table pb-5">
                                <React.Fragment>
                                  {!loading && customers.length === 0 && (
                                    <div className="row justify-content-center mb-4">
                                      <p className="text-center">
                                        ! &nbsp; <FormattedMessage id="customers_no_customers_yet" />
                                      </p>
                                    </div>
                                  )}
                                </React.Fragment>
                              </div>
                            </>
                          )}
                        </div>
                      </LoadingOverlay>

                      {!loading && customers.length > 0 && (
                        <Pagination cursor={meta.cursor} gotoPage={this.handleGotoPage.bind(this)} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </section>
        {ismodalopen && (
          <CustomerModal
            ismodalopen={ismodalopen}
            customerModalClose={this.handleCustomerModalClose}
            handleToast={this.handleToast}
            customerCreate={this.handleCreateCustomer}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Customers;
