import ApiService from "./ApiService";

class LocationService extends ApiService {
  endPoint = "locations";

  async getAll(businessId) {
    try {
      let response = await this.get(
        this.baseURL + this.endPoint + "/" + businessId
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async createLocation(accountId, locationName, payload, addressSameAsMain) {
    let { mainContact, mainAddress } = payload;
    let data = {};

    data["baccountId"] = { value: accountId };
    data["locationName"] = { value: locationName };

    const contact = this.formatLoad(mainContact, "contact");
    contact["name"] = contact["attention"];
    data["contact"] = {
      value: contact
    };

    if (addressSameAsMain) {
      data["addressIsSameAsMain"] = {
        value: addressSameAsMain
      };
    } else {
      data["address"] = {
        value: this.formatLoad(mainAddress, "address")
      };
    }

    try {
      let response = await this.post(this.baseURL + this.endPoint, data);
      return response;
    } catch (e) {
      console.error(e);
    }
    return [];
  }

  formatLoad(controls, group) {
    let elements = controls
      .filter(obj => obj.group === group)
      .filter(obj => obj.value);

    let payload = {};
    for (var i = 0; i < elements.length; i++) {
      const obj = elements[i];
      payload[obj.name] = { value: obj.value };
    }
    return payload;
  }
}

export default LocationService;
