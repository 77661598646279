import axios from "axios";
import Constants from "../../shared/constants";
export function signIn(url, loginCredentials) {
  return axios.post(url, loginCredentials, {
    headers: Constants.config.apiHeaderJSON
  });
}

export function getUserDetails(url, token) {
  return axios.get(url, {
    headers: {
      ...Constants.config.apiHeaderJSON,
      Authorization: `Bearer ${token}`
    }
  });
}
