import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';

const ListViewAccordionHeader = ({
  handleToggle,
  isExpanded,
  item,
  orderType,
  invoiceType,
  isActivityLog,
  isDetails,
}) => {
  const renderOrderHeaders = () => (
    <div className={'row px-2 py-2 align-items-center table-row text-truncate '}>
      <div className="col-3 font-weight-bold rounded-color">
        <a
          href={'allorder-' + item.orderNo}
          data-toggle="collapse"
          onClick={handleToggle}
          className={
            'thumbnail d-inline-block align-middle rounded-circle mr-3 collapsed ' +
            (isExpanded ? ' rotate-180deg' : 'rotate-0deg')
          }
        >
          <span className="" />
        </a>

        <span>{item.orderNo}</span>
      </div>
      <div className="col-3">
        {item.type ? (
          item.type.slice(13) === 'New' ? (
            <FormattedMessage id="all_orders_type_new_sub" />
          ) : item.type.slice(13) === 'Upgrade' ? (
            <FormattedMessage id="all_orders_type_upgrade_sub" />
          ) : item.type.slice(13) === 'Termination' ? (
            <FormattedMessage id="all_orders_type_termination_sub" />
          ) : item.type.slice(13) === 'Renewal' ? (
            <FormattedMessage id="all_orders_type_renewal_sub" />
          ) : (
            ''
          )
        ) : item.orderType && item.orderType === 'SO' ? (
          <FormattedMessage id="filter_all_products" />
        ) : (
          ''
        )}
      </div>
      <div className="col-3">
        {moment(item.registeredDate && item.registeredDate.slice(0, 10)).format('DD/MM/YYYY')}
      </div>
      <div className="col-3">
        {item.status === 'Open' ? (
          <FormattedMessage id="all_orders_status_open" />
        ) : item.status === 'Completed' ? (
          <FormattedMessage id="all_orders_status_completed" />
        ) : item.status === 'Cancelled' ? (
          <FormattedMessage id="all_orders_status_cancelled" />
        ) : item.status === 'Hold' ? (
          <FormattedMessage id="all_order_status_hold" />
        ) : item.status === 'Closed' ? (
          <FormattedMessage id="all_order_status_closed" />
        ) : item.status === 'Migrated' ? (
          <FormattedMessage id="all_order_status_migrated" />
        ) : (
          ''
        )}
      </div>
    </div>
  );
  const renderInvoiceHeaders = () => (
    <div className={'row px-2 py-2 align-items-center table-row text-truncate'}>
      <div className="col-3 font-weight-bold rounded-color">
        <a
          href={'allorder-' + item.invoiceNumber}
          data-toggle="collapse"
          onClick={handleToggle}
          className={
            'thumbnail d-inline-block align-middle rounded-circle mr-3 collapsed ' +
            (isExpanded ? ' rotate-180deg' : 'rotate-0deg')
          }
        >
          <span className="" />
        </a>

        <span>
          {item.vismaDetails ? item.vismaDetails.referenceNumber : <FormattedMessage id="all_orders_invoice_draft" />}
        </span>
      </div>
      <div className="col-2">
        {item.vismaDetails
          ? moment(item.vismaDetails.documentDate && item.vismaDetails.documentDate.slice(0, 10)).format('DD/MM/YYYY')
          : 'N.A.'}
      </div>
      <div className="col-3">
        {item.vismaDetails
          ? moment(item.vismaDetails.documentDueDate && item.vismaDetails.documentDueDate.slice(0, 10)).format(
              'DD/MM/YYYY'
            )
          : 'N.A.'}
      </div>
      <div className="col-2">
        {!item.vismaDetails
          ? (item.status === 'Draft' && <FormattedMessage id="all_orders_invoice_draft" />) ||
            (item.status === 'Cancelled' && <FormattedMessage id="all_orders_status_cancelled" />)
          : (item.vismaDetails.status === 'Balanced' && <FormattedMessage id="all_contracts_status_registered" />) ||
            (item.vismaDetails.status === 'Open' && <FormattedMessage id="all_orders_invoice_sent" />) ||
            (item.vismaDetails.status === 'Closed' && <FormattedMessage id="all_order_status_closed" />)}
      </div>
      <div className="col-2">
        {item.vismaDetails ? (
          <>
            <span className="mr-1">{item.vismaDetails.amount}</span>
            ,- /<FormattedMessage id="common_per_month" />
          </>
        ) : (
          'N.A.'
        )}
      </div>
    </div>
  );
  const renderAcitivityLogHeaders = () => (
    <div className={'row px-2 py-2 align-items-center table-row text-truncate ' + (isExpanded ? 'bg-green' : '')}>
      <div className="col-2 font-weight-bold">
        <span className="text-capitalize">{item.changeType}</span>
      </div>
      <div className="col-2">{item.customerName || item.userName}</div>
      <div className="col-2">
        <span
          title={moment
            .utc(item.logTime, 'YYYY-MM-DD HH:mm')
            .local()
            .format('HH:mm:ss')}
        >
          {moment(item.logTime && item.logTime.slice(0, 10)).format('DD/MM/YYYY')}
        </span>
      </div>
      <div className="col-6  text-truncate" title={item.description}>
        {item.description}
      </div>
    </div>
  );

  const renderDetailsHeaders = () => (
    <div
      className={
        'row px-2 py-2 align-items-center table-row text-truncate ' +
        (isExpanded ? 'bg-green' : '') +
        (!item.lastBilled && ' disabled-state')
      }
    >
      <div className="col-2 font-weight-bold rounded-color">
        <span>{item.service}</span>
      </div>
      <div className="col-2">{item.quantity}</div>
      <div className="col-2">{item.unitPrice}</div>
      <div className="col-2">{item.amount}</div>
      <div className="col-2">
        {item.lastBilled && (
          <>
            <i className="fa fa-check serives-active-tick mr-2" aria-hidden="true" />
            {item.lastBilled}
          </>
        )}
      </div>
      <div className="col-2">{item.nextBill}</div>
    </div>
  );
  return (
    <React.Fragment>
      {orderType && renderOrderHeaders()}
      {invoiceType && renderInvoiceHeaders()}
      {isActivityLog && renderAcitivityLogHeaders()}
      {isDetails && renderDetailsHeaders()}
    </React.Fragment>
  );
};

ListViewAccordionHeader.propTypes = {
  orderID: PropTypes.number,
  handleToggle: PropTypes.func,
  isExpanded: PropTypes.bool,
  pathName: PropTypes.string,
  item: PropTypes.bool,
  orderType: PropTypes.string,
  invoiceType: PropTypes.string,
  isActivityLog: PropTypes.bool,
  isDetails: PropTypes.bool,
};
export default ListViewAccordionHeader;
