import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Control } from '../../shared/Control';
import validator from 'validator';
import Constants from '../../shared/constants';

class Input extends Component {
  state = {
    classes: ['form-control', 'px-3', 'input-common', 'py-4'],
    isValid: false,
    showError: false,
  };

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { value, rule } = this.props.options;
    const { previousValue } = prevProps.options;
    if (value !== previousValue) {
      if (rule) {
        this.props.options.isValid = this.validate(value, rule);
      }
    }
  }

  handleChange = event => {
    const { name, rule, type: optionType } = this.props.options;
    const { classes } = this.state;
    const { type, onChange, options } = this.props;

    let {
      target: { value: eventValue },
    } = event;
    if (eventValue.length === 0) {
      this.setState({ classes: classes.filter(str => str !== 'not-empty') });
    } else {
      this.setState({ classes: classes.concat('not-empty') });
    }
    if (rule) {
      this.props.options.isValid = this.validate(eventValue, rule);
    }
    if (type === 'number' || optionType === 'phone') {
      if (
        (eventValue.charCodeAt(eventValue.length - 1) >= 48 && eventValue.charCodeAt(eventValue.length - 1) <= 57) ||
        eventValue === ''
      ) {
        this.props.options.value = eventValue;
        // Trigger on change event only when the value is valid
        if (onChange) {
          onChange(name, options);
        }
      } else {
        eventValue = '';
        event.preventDefault();
      }
    } else {
      options.value = eventValue;
      // Trigger on change event only when the value is valid
      if (onChange) {
        onChange(name, options);
      }
    }
  };

  handleBlur = event => {
    const { classes } = this.state;
    let { name, onBlur } = this.props.options;
    const { onChange, options } = this.props;
    this.props.options.showError = true;
    if (onChange) {
      onChange(name, options);
    }
    this.setState({ classes: classes.filter(str => str !== 'focus') });
    if (onBlur) {
      onBlur(event, options);
    }
  };

  handleFocus = event => {
    const { onFocus } = this.props.options;
    const { classes } = this.state;
    this.setState({ classes: classes.concat('focus') });
    if (onFocus) {
      onFocus(event);
    }
  };

  focus = () => {
    this.textInput.current.focus();
  };

  render() {
    const {
      name,
      label,
      type,
      isValid,
      rule,
      id,
      showError,
      minLength,
      maxLength,
      disabled,
      value,
      autoFocus,
      classProps,
    } = this.props.options;
    const { classes } = this.state;
    if (value && value.length !== 0 && !classes.includes('not-empty')) {
      classes.push('not-empty');
    }
    return (
      <div className={'form-group animate-label common-input-container ' + (classProps ? classProps : '')}>
        <input
          type={type}
          className={classes.join(' ')}
          name={name}
          id={id}
          aria-describedby={name}
          onChange={this.handleChange}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur}
          ref={this.textInput}
          value={value}
          disabled={disabled}
          minLength={minLength}
          maxLength={maxLength}
          autoFocus={autoFocus || false}
        />
        <label htmlFor={id} className="form-control-placeholder ml-3 bg-white px-1">
          {label}
        </label>
        <div className="text-danger error-message small line-height-22">
          {showError && !isValid && rule ? rule.message : ' '}
        </div>
        {showError && !isValid && rule && <img className="error-icon" src={Constants.icons.triangle} alt="" />}
      </div>
    );
  }

  validate(value, rule) {
    const args = rule.args || [];
    return rule.presence || !validator.isEmpty(value) || !validator.isEmail(value)
      ? rule.method(value, ...args) === rule.validWhen
      : true;
  }
}

Input.propsTypes = {
  options: PropTypes.objectOf(Control),
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  isValid: PropTypes.bool,
  rule: PropTypes.object,
  id: PropTypes.string,
  showError: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};

export default Input;
