import React from 'react';
import Constants from '../shared/constants';

export const DecimalPrice = props => {
  const { number, precision = 2, currency = Constants.currency.norway } = props;
  return (
    <>
      {number === 0 ? 0 : Number(number).toFixed(precision)}
      <span>{currency}</span>
    </>
  );
};

export default DecimalPrice;    