import React from 'react';
import { FormattedMessage } from 'react-intl';

import BaseComponent from '../BaseComponent';
import validator from 'validator';
import Constants from '../../shared/constants';
import { Control } from '../../shared/Control';
import ResellerService from '../../services/ResellerService';
import Config from '../../config';
import { connect } from 'react-redux';
import { chooseCustomer, selectOrder, selectLocation } from '../../state/actions/OrderActions';
import { CustomerType } from '../../shared/CustomerType';
import { OrderType } from '../../shared/OrderType';
import Routes from '../../shared/Routes';
import AuthService from '../../services/AuthService';
import LoadingOverlay from 'react-loading-overlay';
import LocationService from '../../services/LocationService';
import CustomerService from '../../services/CustomerService';
import SelectCustomerCard from './Components/ChooseCustomer/SelectCustomerCard';

class ChooseCustomerComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.authService = new AuthService(Config.apiBase);
    this.resellerService = new ResellerService(Config.apiBase);
    this.customerService = new CustomerService(Config.apiBase);
    this.authService = new AuthService(Config.apiBase);
    this.locationService = new LocationService(Config.apiBase);
    this.state = {
      customerId: '',
      isServiceOrder: false,
      isReseller: true,
      isRackItSelected: false,
      isResellerSelected: false,
      showLocation: false,
      locations: [],
      loading: false,
      handleExistingCustomerReseller: false,
      customerSelected: false,
      existingInstallation: false,
      handleExistingCustomer: false,
      selectedLocation: '',
    };
  }

  async componentDidMount() {
    await this.fetchingDetailsForFurtherProcessing();
  }

  validations = [
    new Control({
      name: 'customerId',
      method: validator.isEmpty,
      validWhen: false,
      message: <FormattedMessage id="common_customer_or_business" />,
    }),
  ];

  async fetchingDetailsForFurtherProcessing() {
    this.setState({ loading: true });
    // Get the order object from Redux state
    const {
      order,
      location: { pathname },
      history,
      chooseCustomer,
    } = this.props;
    const { routes } = Constants;

    let serviceType;
    if (!order || !order.orderType) {
      if (pathname === routes.newOrderChooseCustomerService || pathname === routes.newProposalChooseCustomerService) {
        serviceType = OrderType.SERVICE_ORDER;
        selectOrder(OrderType.SERVICE_ORDER);
      }
      if (pathname === routes.newOrderChooseCustomerProduct || pathname === routes.newProposalChooseCustomerProduct) {
        serviceType = OrderType.PRODUCT_ORDER;
        selectOrder(OrderType.PRODUCT_ORDER);
      }
    } else {
      if (
        (pathname === routes.newOrderChooseCustomerService && order.orderType === OrderType.SERVICE_ORDER) ||
        ((pathname === routes.newOrderChooseCustomerProduct || pathname === routes.newProposalChooseCustomerProduct) &&
          order.orderType === OrderType.PRODUCT_ORDER)
      ) {
        serviceType = order.orderType;
      } else {
        if (pathname === routes.newOrderChooseCustomerService || pathname === routes.newProposalChooseCustomerService) {
          serviceType = OrderType.SERVICE_ORDER;
          selectOrder(OrderType.SERVICE_ORDER);
        }
        if (pathname === routes.newOrderChooseCustomerProduct || pathname === routes.newProposalChooseCustomerProduct) {
          serviceType = OrderType.PRODUCT_ORDER;
          selectOrder(OrderType.PRODUCT_ORDER);
        }
      }
    }

    if (!serviceType) {
      history.push(Routes.orders);
    }
    const isReseller = this.authService.isResellerUser();
    const isServiceOrder = serviceType === OrderType.SERVICE_ORDER;
    const isProductOrder = serviceType === OrderType.PRODUCT_ORDER;
    this.setState({
      isServiceOrder,
      isProductOrder,
      isReseller,
    });

    if (isReseller && isProductOrder && pathname !== routes.newProposalChooseCustomerProduct) {
      const response = await this.authService.usersDetails();
      const { visma_id, name } = response.data;
      const reseller = { internalId: visma_id, name };
      const customerResponse = await this.customerService.getCustomerDetails(visma_id);
      this.setState({ reseller });
      chooseCustomer({
        type: CustomerType.EXISTING_CUSTOMER,
        customer: customerResponse.data,
        reseller: reseller,
      });
      if (response) history.push(Routes.newProductPrepareOrder);
    }

    if (isReseller && (isServiceOrder || pathname === routes.newProposalChooseCustomerProduct)) {
      const response = await this.authService.usersDetails();
      const {
        data: { visma_id, name },
      } = response;
      const selectedReseller = { id: visma_id, name };
      this.setState({ selectedReseller, isResellerSelected: true });
    }

    const { customer } = order;
    if (customer && customer.internalId) {
      const { internalId, name } = order.customer;
      this.setState({ selectedCustomer: { id: internalId, name } });
    }
    const isAdminSalesUser = this.authService.isAdminSalesUser();
    this.setState({ isAdminSalesUser, loading: false });
  }

  async getLocations(id) {
    this.setState({ loading: true });
    try {
      const response = await this.locationService.getAll(id);
      this.setState({ locations: response.data, showLocation: true });
    } catch (error) {
      console.log(error);
    }
    this.setState({ loading: false });
  }

  handleExistingCustomer = async () => {
    const { isServiceOrder, isResellerSelected, selectedReseller, selectedCustomer: customer } = this.state;
    const {
      selectOrder,
      history,
      chooseCustomer,
      location: { pathname },
    } = this.props;
    const customerId = Number(this.customerDetails.customerClass && this.customerDetails.customerClass.id);
    let customerType;
    if (customerId === 1) {
      customerType = CustomerType.PRIVATE_CUSTOMER;
    } else if (customerId === 4) {
      customerType = CustomerType.BUSINESS_CUSTOMER;
    } else if (customerId === 5) {
      customerType = CustomerType.RESELLER;
    }
    if (isServiceOrder) {
      chooseCustomer({
        type: CustomerType.NEW_INSTALLATION,
        customer,
        reseller: isResellerSelected
          ? {
              internalId: selectedReseller.id,
              name: selectedReseller.name,
            }
          : { internalId: Config.vismaId, name: 'Rackit', isCustomer: true },
        customerType,
        customerDetails: this.customerDetails,
      });
      selectOrder(OrderType.SERVICE_ORDER);
      if (pathname === Constants.routes.newProposalChooseCustomerService) {
        history.push({ pathname: Routes.newServicePrepareProposal });
      } else {
        history.push({ pathname: Routes.newServicePrepareOrder });
      }
    } else {
      // For Product order
      // Jump to prepare order
      chooseCustomer({
        type: CustomerType.EXISTING_CUSTOMER,
        customer,
        reseller: {
          internalId: this.customerDetails.reseller.id,
          name: this.customerDetails.reseller.name,
          isCustomer: true,
        }, // Should send RackIT User.
        customerType,
        customerDetails: this.customerDetails,
      });
      if (pathname === Constants.routes.newProposalChooseCustomerProduct) {
        history.push({ pathname: Routes.newProductPrepareProposal });
      } else {
        history.push({ pathname: Routes.newProductPrepareOrder });
      }
    }
  };

  handleOnLocationSelected = location => {
    const { id, name } = this.state.selectedCustomer;
    const { chooseCustomer } = this.props;
    chooseCustomer({
      type: CustomerType.EXISTING_CUSTOMER,
      customer: {
        id: id,
        name,
      },
    });
    this.setState({ selectedLocation: location });
  };

  handleOnDone = () => {
    const { selectedLocation } = this.state;
    const { selectLocation, history } = this.props;
    selectLocation(selectedLocation);
    history.push(Routes.newServicePrepareOrder);
  };

  handleExistingCustomerReseller = () => {
    const { id } = this.state.selectedCustomer;
    this.getLocations(id);
    this.setState({
      handleExistingCustomer: true,
    });
  };

  handleLocationSelected = location => {
    this.setState({ selectedLocation: location });
  };

  handleNewCustomer = ({ customerType }, event) => {
    event.preventDefault();
    const { isResellerSelected, selectedReseller, isServiceOrder } = this.state;
    const {
      history,
      selectOrder,
      chooseCustomer,
      location: { pathname },
    } = this.props;
    const isReseller = this.authService.isResellerUser();
    if (isServiceOrder) {
      selectOrder(OrderType.SERVICE_ORDER);
      chooseCustomer({
        type: CustomerType.NEW_CONSUMER,
        customer: undefined,
        reseller: isResellerSelected
          ? {
              internalId: selectedReseller.id,
              name: selectedReseller.name,
            }
          : { internalId: Config.vismaId, name: 'Rackit', isCustomer: true },
        customerType,
      });
      if (pathname === Constants.routes.newProposalChooseCustomerService) {
        history.push({ pathname: Routes.newServicePrepareProposal })
      } else {
        history.push({ pathname: Routes.newServicePrepareOrder });
      }
    } else if (isReseller && pathname === Constants.routes.newProposalChooseCustomerProduct) {
      selectOrder(OrderType.PRODUCT_ORDER);
      chooseCustomer({
        type: CustomerType.NEW_CONSUMER,
        customer: undefined,
        reseller: isResellerSelected
          ? {
              internalId: selectedReseller.id,
              name: selectedReseller.name,
            }
          : { internalId: Config.vismaId, name: 'Rackit', isCustomer: true },
        customerType,
      });
      history.push({ pathname: Routes.newProductPrepareProposal });
    } else {
      selectOrder(OrderType.PRODUCT_ORDER);
      chooseCustomer({
        type: CustomerType.NEW_CONSUMER,
        customer: undefined,
        reseller: {
          internalId: Config.vismaId,
          name: 'Rackit',
          isCustomer: true,
        },
        customerType,
      });
      if (pathname === Constants.routes.newProposalChooseCustomerProduct) {
        history.push({ pathname: Routes.newProductPrepareProposal });
      } else {
        history.push({ pathname: Routes.newProductPrepareOrder });
      }
    }
  };

  handleNewLocation = event => {
    event.preventDefault();
    const { chooseCustomer, selectOrder, history } = this.props;

    chooseCustomer({
      type: CustomerType.NEW_INSTALLATION,
      customer: this.state.selectedCustomer,
    });
    selectOrder(OrderType.SERVICE_ORDER);
    history.push(Routes.newServicePrepareOrder);
  };

  handleNewReseller = event => {
    const { history } = this.props;
    event.preventDefault();

    chooseCustomer({
      type: CustomerType.NEW_CONSUMER,
      customer: null,
    });
    history.push(Routes.createNewReseller);
  };

  handleOnCustomerSelected = (...e) => {
    if (e[0]) {
      const [selectedValue, { customerDetails }] = e;
      this.customerDetails = customerDetails && customerDetails[0];
      this.setState({ selectedCustomer: selectedValue });
    } else {
      this.customerDetails = {};
      this.setState({ selectedCustomer: null });
    }
  };

  render() {
    const { loading, isServiceOrder, isReseller, selectedReseller } = this.state;
    const { location: { pathname } } = this.props;
    const { routes } = Constants;
    return (
      <>
        <section id="page-header">
          <div className="container-fluid px-0">
            <div className=" mt-0">
              <div className="row justify-content-end pr-0 mx-0">
                <React.Fragment>
                  <div className="col text-right margin-top-neg-11 px-0">
                    <a href="#back" className="font-weight-normal fs-14" onClick={this.goBack}>
                      <img
                        className="mr-2 pr-1"
                        src={Constants.icons.arrowBack}
                        width="24"
                        height="24"
                        alt="back-icon"
                      />

                      <u>
                        <FormattedMessage id="common_back" />
                      </u>
                    </a>
                  </div>
                </React.Fragment>
              </div>
              <div className="row mb-4">
                <div className="col-10">
                  <h1 className="mb-3 pb-0 ls-0-25 line-height-normal">
                    {pathname === routes.newProposalChooseCustomerProduct || pathname === routes.newProposalChooseCustomerService ?
                      <FormattedMessage id="common_new_proposal" /> :
                    isServiceOrder ? (
                      <FormattedMessage id="create_new_service_order" />
                    ) : (
                      <FormattedMessage id="common_new_order_products" />
                    )}
                  </h1>
                  <p className="mb-0 line-height-16 ls-0-47">
                    <FormattedMessage id="choose_customer_description" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <LoadingOverlay
          active={loading}
          spinner
          text={<FormattedMessage id="common_loading_overlay" />}
          classNamePrefix="dark-loading-overlay-"
        >
          <div id="chooseCustomer" className=" container-fluid  pb-3 px-0 pt-0 ml-0">
            <section id="welcome-section" className="ml-0">
              <div className="cardAccordion">
                <SelectCustomerCard
                  customerService={this.customerService}
                  onExistingCustomer={this.handleExistingCustomer}
                  onNewLocation={this.handleNewLocation}
                  onNewCustomer={this.handleNewCustomer}
                  onSuggestionSelected={this.handleOnCustomerSelected}
                  selectedSuggestion={this.state.selectedCustomer}
                  reseller={isReseller ? selectedReseller : null}
                />
              </div>
            </section>
          </div>
        </LoadingOverlay>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    order: state.order,
  };
};

const ChooseCustomer = connect(
  mapStateToProps,
  {
    chooseCustomer,
    selectOrder,
    selectLocation,
  }
)(ChooseCustomerComponent);

export default ChooseCustomer;
