export const CustomerType = {
  EXISTING_CUSTOMER: 'existing-customer',
  NEW_CONSUMER: 'new-consumer',
  NEW_BUSINESS: 'new-business',
  NEW_INSTALLATION: 'new-installation',

  BUSINESS_CUSTOMER: 'business-customer',
  PRIVATE_CUSTOMER: 'private-customer',

  RESELLER: 'reseller',
};
