import ApiService from './ApiService';
import Customer from '../shared/models/Customer';

class CustomerService extends ApiService {
  endPointUser = 'customers';
  endPoint = 'resellers';

  async getAllUsers(queryParam) {
    queryParam.push(['customers', true]);
    let response = await this.get(this.baseURL + this.endPointUser + this.queryParamString(queryParam));
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      const { data, meta } = response.data;

      return {
        status: response.status,
        customers: data,
        data: data,
        meta: meta,
      };
    }
  }
  async getAll(queryParam) {
    let response = await this.get(this.baseURL + this.endPointUser + this.queryParamString(queryParam));
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      const { data, meta } = response.data;

      return {
        status: response.status,
        customers: data,
        data: data,
        meta: meta,
      };
    }
  }
  async getCustomerContracts(customerId, locationId) {
    let response = await this.get(this.baseURL + 'contracts/' + customerId + '/' + locationId);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      const { data, meta } = response.data;
      return {
        status: response.status,
        contracts: data,
        meta: meta,
      };
    }
  }

  async createEndCustomer(payload, billingSameAsMain, shippingSameAsMain) {
    let { customerDetails, installationAddress, billingAddress, shippingAddress } = payload;
    let data = {};

    data['name'] = {
      value: customerDetails.find(obj => obj.name === 'attention').value,
    };
    // data['billingReference'] = {
    //   value: billingReference,
    // };
    data['mainContact'] = {
      value: this.formatLoad(customerDetails, 'mainContact'),
    };
    data['mainAddress'] = {
      value: this.formatLoad(installationAddress, 'mainAddress'),
    };
    data['invoiceContact'] = {
      value: billingSameAsMain ? data['mainContact'] : this.formatLoad(billingAddress, 'invoiceContact'),
    };
    data['invoiceAddress'] = {
      value: billingSameAsMain ? data['mainAddress'] : this.formatLoad(billingAddress, 'invoiceAddress'),
    };

    data['deliveryContact'] = {
      value: shippingSameAsMain ? data['mainContact'] : this.formatLoad(shippingAddress, 'deliveryContact'),
    };

    data['deliveryAddress'] = {
      value: shippingSameAsMain ? data['mainAddress'] : this.formatLoad(shippingAddress, 'deliveryAddress'),
    };

    let response = await this.post(this.baseURL + this.endPointUser + '/end-customer', data);
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async createBusinessCustomer(corporateId, payload, billingSameAsMain, shippingSameAsMain) {
    let { customerDetails, installationAddress, billingAddress, shippingAddress } = payload;
    let data = {};

    data['corporateId'] = {
      value: corporateId,
    };
    // data['billingReference'] = {
    //   value: billingReference,
    // };
    data['name'] = {
      value: customerDetails.find(obj => obj.name === 'attention').value,
    };
    data['mainContact'] = {
      value: this.formatLoad(customerDetails, 'mainContact'),
    };
    data['mainAddress'] = {
      value: this.formatLoad(installationAddress, 'mainAddress'),
    };

    data['invoiceContact'] = {
      value: billingSameAsMain ? data['mainContact'] : this.formatLoad(billingAddress, 'invoiceContact'),
    };
    data['invoiceAddress'] = {
      value: billingSameAsMain ? data['mainAddress'] : this.formatLoad(billingAddress, 'invoiceAddress'),
    };
    // data['billingReference'] = {
    //   value: billingSameAsMain ? data['billingReference'] : 'N.A',
    // };
    data['deliveryContact'] = {
      value: shippingSameAsMain ? data['mainContact'] : this.formatLoad(shippingAddress, 'deliveryContact'),
    };

    data['deliveryAddress'] = {
      value: shippingSameAsMain ? data['mainAddress'] : this.formatLoad(shippingAddress, 'deliveryAddress'),
    };

    let response = await this.post(this.baseURL + this.endPointUser + '/business-customer', data);
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async getCustomerDetails(id) {
    let response = await this.get(this.baseURL + this.endPointUser + '/' + id);
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async getEndCustomerDetails(email, phone) {
    let response = await this.get(
      this.baseURL + this.endPointUser + '/end-customer?email=' + email + '&phone=' + phone
    );

    if (response.status !== 200) {
      return undefined;
    } else {
      return response.data;
    }
  }

  async getBusinessCustomerDetails(corporateId) {
    let response = await this.get(this.baseURL + this.endPointUser + '/business-customer/' + corporateId);

    if (response.status !== 200) {
      return [];
    } else {
      return response.data;
    }
  }

  async getCustomerOrders(id, reseller, queryParam) {
    let response;
    {
      reseller
        ? (response = await this.get(
            this.baseURL +
              this.endPointUser +
              '/' +
              id +
              '/orders' +
              this.queryParamString(queryParam) +
              '&reseller=true'
          ))
        : (response = await this.get(
            this.baseURL + this.endPointUser + '/' + id + '/orders' + this.queryParamString(queryParam)
          ));
    }
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }
  async getCustomerInvoices(id, queryParam) {
    let response = await this.get(
      this.baseURL + this.endPointUser + '/' + id + '/invoices' + this.queryParamString(queryParam)
    );

    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }
  async getCustomerLog(id, queryParam) {
    let response = await this.get(
      this.baseURL + this.endPointUser + '/' + id + '/activities' + this.queryParamString(queryParam)
    );

    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }
  async getCustomerSubscriptions(id, reseller, queryParam) {
    let response;
    {
      reseller
        ? (response = await this.get(
            this.baseURL +
              this.endPointUser +
              '/' +
              id +
              '/contracts' +
              this.queryParamString(queryParam) +
              '&reseller=true'
          ))
        : (response = await this.get(
            this.baseURL + this.endPointUser + '/' + id + '/contracts' + this.queryParamString(queryParam)
          ));
    }

    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }
  async getCustomers(id, queryParam) {
    let response = await this.get(
      this.baseURL + this.endPoint + '/' + id + '/customers' + this.queryParamString(queryParam)
    );

    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }
  async getUsers(id, queryParam) {
    let response = await this.get(
      this.baseURL + this.endPoint + '/' + id + '/users' + this.queryParamString(queryParam)
    );

    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }

  formatLoad(controls, group) {
    let elements = controls.filter(obj => obj.group === group).filter(obj => obj.value);

    let payload = {};
    for (var i = 0; i < elements.length; i++) {
      const obj = elements[i];
      payload[obj.name] = { value: obj.value };
    }
    return payload;
  }
}

export default CustomerService;
