import React, { Component } from 'react';
import Constants from '../shared/constants';
import { withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import AuthService from '../services/AuthService';
import ApiService from '../services/ApiService';
import Config from '../config';
import PersistentStorage from "../shared/PersistentStorage";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';

class SignIn extends Component {
  authService = new AuthService(Config.apiBase);
  apiService = new ApiService(Config.apiBase)
  persistentStorage = new PersistentStorage();

  woocommerceLoginOrRedirect = async () => { 
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const localStorageToken = this.persistentStorage.getToken();

    const redirectUserToWooCommerceLogin = () => {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        searchParams.delete('token');

        url.search = searchParams.toString();

        const modifiedUrl = url.toString();
        window.location.href = Config.wooCommerceLogin + 'my-account/?redirect_to=' + modifiedUrl;
    }
    
    console.log(token);
    
    if (token || localStorageToken || localStorageToken !== null) {
        // Redirect to a logged-in-only part of your app
        this.persistentStorage.setToken(token);

        try {
            const token = await this.authService.signIn(token);
            this.persistentStorage.setToken(token.data.success.token);

            await this.authService.usersDetails();
            const { history } = this.props;
            history.replace('/dashboard');
            return;
        } catch(e) {
            const {response = {}} = e;
            if(response.status !== 401) {
                this.persistentStorage.deleteToken();
                return;
            } 
        }
    }
    
    redirectUserToWooCommerceLogin();
  };

  render() {
    this.woocommerceLoginOrRedirect();

    return (
      <LoadingOverlay
        active={true}
        spinner={
          <BounceLoader
            color={Constants.LOADER_PROPS.COLOR.white}
            size={Constants.LOADER_PROPS.SIZE}
            sizeUnit={Constants.LOADER_PROPS.SIZE_UNIT}
            loading={true}
          />
        }
        classNamePrefix={'login-page_'}
        text={<FormattedMessage id="common_loading_overlay" />}
      />
    );
  }
}

export default withRouter(SignIn);
