import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Constants from '../../shared/constants';
import AuthService from '../../services/AuthService';
import Config from '../../config';
import { render } from 'react-dom';

class AllOrderAccordionHeader extends Component {
  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleOutsideClick = e => {
    // ignore clicks on the component itself
    const {
      onOpenOrderActionsMenu,
      openOrderActionsMenu: { open = false, order: { _id: orderId = '' } } = {},
      item,
    } = this.props;
    if (this.handleOptions && !this.handleOptions.contains(e.target) && open && orderId === item._id) {
      onOpenOrderActionsMenu(item)();
    }
  };

  deleteConfirmationPopUp = () => {
    const {
      item,
      onRetryOrder,
      onDeleteOrder = () => {},
      openOrderActionsMenu: {
        open,
        order: { _id: orderId },
      },
      isProposal,
    } = this.props;
    return (
      <div
        className="modal fade bd-example-modal-sm fade-contracts"
        id="deleteOrderConfirmationModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteOrderConfirmation"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-small" role="document">
          <div className="modal-content contracts-modal bg-green rounded border-0">
            <div className="modal-header pl-5 pb-3">
              <h4 className="modal-title font-weight-semibold" id="myLargeModalLabel">
                <span>
                  {isProposal ? (
                    <FormattedMessage id="common_delete_proposal_title" />
                  ) : (
                    <FormattedMessage id="common_delete_order_modal_title" />
                  )}
                </span>
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body mx-5 rounded p-0 mb-4 mt-2">
              <div className="">
                <div className="row py-4">
                  <div className="col">
                    <h6 className="text-left line-height-28">
                      <FormattedMessage id="common_delete_order_confirmation_message" />
                    </h6>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col text-right">
                    <button
                      className="btn-upg"
                      onClick={isProposal ? onDeleteOrder(item.proposalNo) : onDeleteOrder(orderId)}
                      data-dismiss="modal"
                    >
                      <FormattedMessage id="contract_button_apply" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  acceptConfirmationPopUp = () => {
    const {
      item,
      onRetryOrder = () => {},
      openOrderActionsMenu: {
        open,
        order: { _id: orderId },
      },
    } = this.props;
    return (
      <div
        className="modal fade bd-example-modal-sm fade-contracts"
        id="acceptOrderConfirmationModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="acceptOrderConfirmation"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-small" role="document">
          <div className="modal-content contracts-modal bg-green rounded border-0">
            <div className="modal-header pl-5 pb-3">
              <h4 className="modal-title font-weight-semibold" id="myLargeModalLabel">
                <span>
                  <FormattedMessage id="common_accept_proposal_title" />
                </span>
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body mx-5 rounded p-0 mb-4 mt-2">
              <div className="">
                <div className="row py-4">
                  <div className="col">
                    <h6 className="text-left line-height-28">
                      <FormattedMessage id="common_accept_proposal_message" />
                      {item.reseller.id === Config.vismaId ? (
                        <p className="mt-3">
                          <FormattedMessage id="message_if_rackit_reseller" />
                        </p>
                      ) : (
                        <p className="mt-3">
                          <FormattedMessage id="message_if_other_reseller" />
                          {item.reseller.name}-{item.reseller.id}.
                        </p>
                      )}
                      <p>
                        {!item.orderAvailability && <FormattedMessage id="accept_proposal_for_inactive_products" />}
                      </p>
                    </h6>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col text-right">
                    <button className="btn-upg" onClick={onRetryOrder(item)} data-dismiss="modal">
                      <FormattedMessage id="common_next" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const isSuperAdminUser = new AuthService(Config.apiBase).isSuperAdminUser();
    const isAdminBackOffice = new AuthService(Config.apiBase).isAdminBackOfficeUser();

    const {
      item,
      handleToggle,
      isExpanded,
      isContracts,
      onRetryOrder= () => {},
      onDeleteOrder,
      onOpenOrderActionsMenu,
      openOrderActionsMenu: {
        open,
        order: { _id: orderId },
      },
      isProposal,
      onEditOrder,
    } = this.props;
    return (
      <React.Fragment>
        <div className={'row py-2 align-items-center table-row ls-0-44 ' + (isContracts && ' bg-white ')}>
          <div className="col-2 font-weight-semibold rounded-color">
            {isContracts ? (
              <>
                <Link to={'contracts/' + item.contractNo}>
                  <u className="fs-14">{item.contractNo}</u>
                </Link>
              </>
            ) : (
              <>
                <a
                  href={'allorder-' + item._id}
                  data-toggle="collapse"
                  onClick={handleToggle}
                  className={
                    'thumbnail d-inline-block align-middle collapsed rounded-circle mr-1 ' +
                    (isExpanded ? ' rotate-180deg' : 'rotate-0deg')
                  }
                >
                  <span className="" />
                </a>
                <span className="va-middle">{isProposal ? item.proposalNo : item.orderNo}</span>
              </>
            )}
          </div>
          <div className={isContracts ? 'col-4 fs-14 text-truncate' : 'col-3 text-truncate'} title={item.customer.name}>
            {item.customer && item.customer.name}
          </div>
          <div className={isProposal ? 'col text-truncate' : isContracts ? 'col-2 fs-14' : 'col-2'}>
            {' '}
            {!isContracts ? (
              item.type ? (
                item.type.slice(13) === 'New' ? (
                  <FormattedMessage id="all_orders_type_new_sub" />
                ) : item.type.slice(13) === 'Upgrade' ? (
                  <FormattedMessage id="all_orders_type_upgrade_sub" />
                ) : item.type.slice(13) === 'Termination' ? (
                  <FormattedMessage id="all_orders_type_termination_sub" />
                ) : item.type.slice(13) === 'Renewal' ? (
                  <FormattedMessage id="all_orders_type_renewal_sub" />
                ) : item.type === 'Service' ? (
                  <FormattedMessage id="common_service_header_singular" />
                ) : item.type === 'Product' ? (
                  <FormattedMessage id="filter_all_products" />
                ) : (
                  ''
                )
              ) : item.orderType && item.orderType === 'SO' ? (
                <FormattedMessage id="filter_all_products" />
              ) : (
                ''
              )
            ) : item.registeredDate ? (
              moment(item.registeredDate.slice(0, 10)).format('DD/MM/YYYY')
            ) : (
              ''
            )}
          </div>

          <div className="col-2 ">
            {!isContracts
              ? isProposal || (item.orderType && item.orderType === 'SO')
                ? moment(item.date && item.date.slice(0, 10)).format('DD/MM/YYYY')
                : moment(item.registeredDate && item.registeredDate.slice(0, 10)).format('DD/MM/YYYY')
              : item.activationDate
              ? moment(item.activationDate.slice(0, 10)).format('DD/MM/YYYY')
              : ''}
          </div>
          {!isContracts && (
            <div className={'col text-right pr-0'}>
              {isProposal
                ? item.proposalTotal === '0.00'
                  ? 0
                  : Number(item.proposalTotal).toFixed(2)
                : item.orderTotal === '0.00'
                ? 0
                : Number(item.orderTotal).toFixed(2)}
              ,-
            </div>
          )}
          <div className={'col pl-5 text-left position-static'}>
            {!isContracts && !isProposal ? (
              item.status === 'Open' ? (
                <FormattedMessage id="all_orders_status_open" />
              ) : item.status === 'Completed' ? (
                <FormattedMessage id="all_orders_status_completed" />
              ) : item.status === 'Cancelled' ? (
                <FormattedMessage id="all_orders_status_cancelled" />
              ) : item.status === 'Hold' ? (
                <FormattedMessage id="all_order_status_hold" />
              ) : item.status === 'Closed' ? (
                <FormattedMessage id="all_order_status_closed" />
              ) : item.status === 'Migrated' ? (
                <FormattedMessage id="all_order_status_migrated" />
              ) : item.status === 'Failed' ? (
                <FormattedMessage id="all_order_status_failed" />
              ) : item.status === 'Error' ? (
                <FormattedMessage id="all_order_status_error" />
              ) : (
                item.status
              )
            ) : item.status === 'Activated' ? (
              <FormattedMessage id="all_contracts_status_activated" />
            ) : item.status === 'Accepted' ? (
              <FormattedMessage id="all_proposals_status_accepted" />
            ) : item.status === 'Registered' ? (
              <FormattedMessage id="all_contracts_status_registered" />
            ) : item.status === 'Declined' ? (
              <FormattedMessage id="all_proposals_status_declined" />
            ) : item.status === 'Cancelled' ? (
              <FormattedMessage id="all_orders_status_cancelled" />
            ) : item.status === 'Terminated' ? (
              <FormattedMessage id="all_contracts_status_terminated" />
            ) : (
              item.status
            )}
            {((item.status === Constants.ORDER_STATUS.FAILED && (isSuperAdminUser || isAdminBackOffice)) ||
              (isProposal && item.status === Constants.ORDER_STATUS.REGISTERED)) && (
              <>
                <span
                  className="align-text-bottom fs-18 ml-4 font-weight-bold cursor-pointer"
                  onClick={onOpenOrderActionsMenu(item)}
                >
                  ...
                </span>

                {open && orderId === item._id && (
                  <div
                    ref={optionRef => {
                      this.handleOptions = optionRef;
                    }}
                    className={'mt-0 menuPopup contract-service-card-button right-2'}
                  >
                    {isProposal && (
                      <div
                        className="mx-2 contracts-action-button "
                        onClick={isProposal ? onEditOrder(item) : onEditOrder(orderId)}
                      >
                        <FormattedMessage id="proposal_actions_edit_proposal" />
                      </div>
                    )}
                    {!isProposal ? (
                      <div className="mx-2 contracts-action-button " onClick={onRetryOrder(orderId)}>
                        <FormattedMessage id="order_actions_retry_order" />
                      </div>
                    ) : (
                      <div
                        className="mx-2 contracts-action-button "
                        data-toggle="modal"
                        data-target={'#acceptOrderConfirmationModal'}
                      >
                        <FormattedMessage id="proposal_actions_accept_proposal" />
                      </div>
                    )}
                    <div
                      className="mx-2 contracts-action-button "
                      data-toggle="modal"
                      data-target={'#deleteOrderConfirmationModal'}
                    >
                      {isProposal ? (
                        <FormattedMessage id="proposal_actions_decline_proposal" />
                      ) : (
                        <FormattedMessage id="order_actions_delete_order" />
                      )}
                    </div>
                  </div>
                )}
                {open && orderId === item._id && this.deleteConfirmationPopUp()}
                {open && orderId === item._id && this.acceptConfirmationPopUp()}
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AllOrderAccordionHeader.propTypes = {
  item: PropTypes.object,
  handleToggle: PropTypes.func,
  isExpanded: PropTypes.bool,
  isContracts: PropTypes.bool,
};

export default AllOrderAccordionHeader;
