import React from 'react';
import Constants from '../../shared/constants';

const RadioButtonComponent = ({ checked, className, label, onChange }) => {
  const toggleState = () => {
    if (onChange) {
      onChange(!checked);
    }
  };
  return (
    <span onClick={toggleState} className={[className ? className : 'cursor-pointer font-weight-normal']}>
      {!checked && <img src={Constants.icons.circle} alt="unchecked" width="20" height="20" className="opacity-50" />}
      {checked && <img src={Constants.icons.dot} alt="checked" width="20" height="20" />}
      {label && <span className="ml-2 align-middle">{label}</span>}
    </span>
  );
};

export default RadioButtonComponent;
