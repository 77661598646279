// https://api.bring.com/shippingguide/api/postalCode.json?clientUrl=http://localhost:8080&pnr=4012

import axios from "axios";
import ApiService from "./ApiService";

class PostalCodeService extends ApiService {
  postalCodeEndpoint =
    "https://api.bring.com/shippingguide/api/postalCode.json?clientUrl=http://localhost:3000&pnr=";

  async get(url) {
    let response = await axios.get(url, { headers: this.headers });
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    }
    return response;
  }

  async getCityDetails(postalCode) {
    let response = await this.get(this.postalCodeEndpoint + postalCode);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data
      };
    }
  }
}

export default PostalCodeService;
