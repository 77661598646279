import React, { Component } from 'react';
import Constants from '../../shared/constants';
import { withRouter } from 'react-router-dom';
import { getUserDetails } from '../../state/actions/user';
import Avatar from 'react-avatar';
import { FormattedMessage } from 'react-intl';
import AuthService from '../../services/AuthService';
import Config from '../../config';

import { connect } from 'react-redux';
import { updateSideMenu } from '../../state/actions/UtilsAction';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: '',
      token: '',
      user: null,
      width: window.innerWidth,
    };
    let state = localStorage[Constants.storage.appState];
    if (state) {
      let AppState = JSON.parse(state);
      let userInLocalStorage = localStorage[Constants.storage.userData];
      const { activePage } = this.props;
      this.setState({ isLoggedIn: AppState.isLoggedIn, token: AppState.token });
      if (!userInLocalStorage || userInLocalStorage === 'null') {
        //if user not in login page,then fetch user details
        let publicPages = Constants.publicPages();
        var i = publicPages.indexOf(activePage);
        if (i > -1) {
          //have to add logics if need
        } else {
          this.getUser(AppState.token);
        }
      } else {
        this.setState({ user: JSON.parse(userInLocalStorage) });
      }
    }
  }

  authService = new AuthService(Config.apiBase);

  dashboard = ['/', '/dashboard', '/forgot-password', '/reset-password'];

  getUser = token => {
    getUserDetails(Constants.end_points.v1.userDetails, token).then(response => {
      if (response.status !== 200) {
        throw Error(response.statusText);
      } else if (response.data) {
        localStorage[Constants.storage.userData] = JSON.stringify(response.data);
        this.setState({ user: response.data });
        let { history } = this.props;
        history.push({ pathname: Constants.routes.home.url });
      }
    });
  };

  logOut = () => {
    localStorage.clear();
    window.location.href = `${Config.wooCommerceLogin}?customer-logout=true`;
  };

  setLanguage = event => {
    localStorage.setItem('language', event.target.value);
    window.location.reload();
  };

  dashboardRedirect = () => {
    this.props.history.push({ pathname: Constants.routes.home.url });
  };

  renderLogo() {
    let element =
      this.dashboard.indexOf(this.props.location.pathname) >= 0 ? (
        <img
          src={Constants.rackitHeaderLogo}
          alt="logo"
          onClick={this.dashboardRedirect}
          className="cursor-pointer dashboardLogo"
        />
      ) : (
        <img
          src={Constants.rackitHeaderLogo}
          alt="logo"
          onClick={this.dashboardRedirect}
          className="cursor-pointer dashboardLogo"
        />
      );

    return element;
  }
  // sidemenu open close icon
  handleSideMenuClick = () => {
    let mainWrapper = document.getElementById('main-wrapper');
    mainWrapper.classList.toggle('sidebar-mini');
    this.props.updateSideMenu(!mainWrapper.classList.contains('sidebar-mini'));
  };
  // updateSideMenu() {
  //   let mainWrapper = document.getElementById('main-wrapper');
  //   if (window.innerWidth < 992 && !mainWrapper.classList.contains('sidebar-mini')) {
  //     mainWrapper.classList.add('sidebar-mini');
  //   }
  //   if (window.innerWidth > 992 && mainWrapper.classList.contains('sidebar-mini')) {
  //     mainWrapper.classList.remove('sidebar-mini');
  //   }
  //   this.props.updateSideMenu(!mainWrapper.classList.contains('sidebar-mini'));
  // }
  // componentDidMount() {
  //   //window.addEventListener('resize', this.updateSideMenu.bind(this));
  // }
  // componentDidUnMount() {
  //   window.removeEventListener('resize', this.updateSideMenu.bind(this));
  // }

  render() {
    this.token = localStorage.getItem('token');

    if (localStorage.userData) {
      this.userData = JSON.parse(localStorage.getItem('userData'));
      let name = this.userData.name;
      this.userName = name.split(' ', 1);

      if (!localStorage.language) {
        localStorage.setItem('language', 'no');
      }
    }
    const isSuperAdmin = this.authService.isSuperAdminUser();
    const isAdminSales = this.authService.isAdminSalesUser();
    const isAdminBackOffice = this.authService.isAdminBackOfficeUser();
    const { sideMenuCollapse } = this.props;
    const {
      location: { pathname },
    } = this.props;
    return (
      <React.Fragment>
        <nav
          className={
            '' + pathname !== '/'
              ? 'navbar navbar-expand-sm navbar-dark bg-dark py-3 pl-0'
              : 'navbar navbar-expand-sm navbar-dark bg-dark border-bottom py-3 border-0'
          }
        >
          <div className="container-fluid align-items-start pr-xl-5 px-0">
            <div className="nav navbar-nav w-100 justify-content-start font-weight-bold align-self-center">
              <img
                src={sideMenuCollapse ? Constants.SideMenu.menuClose : Constants.SideMenu.menuOpen}
                alt="logo"
                onClick={this.handleSideMenuClick}
                className="cursor-pointer"
              />
            </div>

            <div className="flex-fill w-100 text-center">
              {/* <span className="">
                <img
                  className="d-inline-block align-middle dashvert-align-sub ri-icon mr-2"
                  src={Constants.icons.phone}
                  width="20"
                  height="20"
                  alt="phone-icon"
                />
                <FormattedMessage id="customer_service" />
              </span> */}
              {this.renderLogo()}
            </div>
            <ul className="nav navbar-nav w-100 justify-content-end align-self-center">
              {this.token &&
                pathname !== '/' &&
                pathname !== '/forgot-password' &&
                (isSuperAdmin || isAdminBackOffice || isAdminSales) && (
                  <li className="navbar-text pt-0 mr-3">
                    <select
                      value={localStorage.getItem('language')}
                      onChange={this.setLanguage}
                      className={'languageSelector'}
                    >
                      <option value="en">EN</option>
                      <option value="no">NO</option>
                    </select>
                  </li>
                )}

              {this.userData && pathname !== '/' && pathname !== '/forgot-password' && (
                <li className="navbar-text mr-3 pt-0">
                  {/* <Avatar
                    name={this.userData.name}
                    round={true}
                    size={20}
                    textSizeRatio={2.2}
                    className="mr-2 mb-1"
                    value={this.userData.name}
                  /> */}
                  <img src={Constants.icons.userLoginIcon} className="mr-1 headerIcon" alt="User" />
                  <span className="text-truncate mw-20 font-weight-normal">{this.userName}</span>
                </li>
              )}

              {this.token && pathname !== '/' && pathname !== '/forgot-password' && (
                <li className="navbar-text mr-3 pt-0">
                  <a className="nav-link py-0 languageSelector" onClick={this.logOut} href="/">
                    <img src={Constants.icons.logoutIcon} className="mr-1 headerIcon" alt="logout" />
                    <FormattedMessage id="common_logout" />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  sideMenuCollapse: state.Utils.sideMenuCollapse,
});
export default connect(mapStateToProps, { updateSideMenu })(withRouter(Nav));
