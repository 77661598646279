import React from 'react';
import PropTypes from 'prop-types';
import ListViewComponent from '../../../ListView/ListView';
import Accordion from '../../../Accordion';
import ListViewAccordionHeader from './ListViewAccordionHeader';
import ListViewAccordionBody from './ListViewAccordionBody';
import ListViewHeader from './ListViewHeader';

class ListViewCard extends ListViewComponent {
  state = { expandedOrder: {} };

  handleToggle = order => {
    const { expandedOrder } = this.state;
    if (order.invoiceNumber) {
      if (order.invoiceNumber === expandedOrder.invoiceNumber) {
        this.setState({ expandedOrder: {} });
      } else {
        this.setState({ expandedOrder: order });
      }
    } else {
      if (order.orderNo === expandedOrder.orderNo) {
        this.setState({ expandedOrder: {} });
      } else {
        this.setState({ expandedOrder: order });
      }
    }
  };

  renderAccordion = ({ ordersData, expandedOrder, invoiceType, orderType, isActivityLog, isDetails }) => {
    return ordersData.map((order, index) => {
      return (
        <Accordion
          key={index}
          item={order}
          isExpanded={expandedOrder.orderNo === order.orderNo}
          onToggle={this.handleToggle}
          invoiceType={invoiceType}
          orderType={orderType}
          isActivityLog={isActivityLog}
          isDetails={isDetails}
        >
          <ListViewAccordionHeader orderID={order.orderNo} item={order} key={Math.random() * 100} />

          <ListViewAccordionBody orderID={order.orderNo} item={order} key={Math.random() * 100} />
        </Accordion>
      );
    });
  };

  render() {
    const { expandedOrder } = this.state;
    const {
      ordersData,
      activityLogsData,
      invoiceData,
      invoiceType,
      orderType,
      isActivityLog,
      isDetails,
      sortAscending,
      sortedBy,
    } = this.props;
    return (
      <React.Fragment>
        <ListViewHeader
          onSort={this.props.onSort}
          isSortAscending={sortAscending}
          invoiceType={invoiceType}
          orderType={orderType}
          isActivityLog={isActivityLog}
          isDetails={isDetails}
          sortedBy={sortedBy}
        />
        <div className="accordion service-list">
          {ordersData &&
            this.renderAccordion({
              ordersData,
              expandedOrder,
              invoiceType,
              orderType,
              isActivityLog,
              isDetails,
            })}
          {invoiceData &&
            invoiceData.map((invoice, index) => {
              return (
                <Accordion
                  key={index}
                  item={invoice}
                  isExpanded={expandedOrder.invoiceNumber === invoice.invoiceNumber}
                  onToggle={this.handleToggle}
                  invoiceType={invoiceType}
                >
                  <ListViewAccordionHeader invoiceID={invoice.invoiceNo} item={invoice} key={Math.random() * 100} />

                  <ListViewAccordionBody invoiceID={invoice.invoiceNumber} item={invoice} key={Math.random() * 100} />
                </Accordion>
              );
            })}
          {activityLogsData &&
            activityLogsData.map((activity, index) => {
              return (
                <Accordion key={index} item={activity} isActivityLog={isActivityLog}>
                  <ListViewAccordionHeader item={activity} />
                </Accordion>
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}

ListViewCard.propTypes = {
  ordersData: PropTypes.array,
  activityLogsData: PropTypes.array,
  invoiceData: PropTypes.array,
  invoiceType: PropTypes.string,
  orderType: PropTypes.string,
  isActivityLog: PropTypes.bool,
  isDetails: PropTypes.bool,
  sortAscending: PropTypes.bool,
  sortedBy: PropTypes.string,
};

export default ListViewCard;
