import markerSDK from "@marker.io/browser";
import Config from "./config";
import Package from "../package.json";

const configureMarkerIO = () => {
  markerSDK.loadWidget({
    destination: Config.markerDestinationId,
    version: Package.version,
  });
};

export default configureMarkerIO;