import { UPDATE_SIDE_MENU } from "./types";

export function updateSideMenu(value) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_SIDE_MENU,
      payload: value
    });
  };
}
