import { UPDATE_SIDE_MENU } from "../actions/types";
const initialState = {
  sideMenuCollapse: true
};
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SIDE_MENU:
      return {
        ...state,
        sideMenuCollapse: action.payload
      };
    default:
      return { ...state };
  }
};
