export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const PRODUCTS_ARE_LOADING = "PRODUCTS_ARE_LOADING";
export const getAllProducts = { type: GET_ALL_PRODUCTS };
export const UPDATE_TOTAL_PRODUCTS = "UPDATE_TOTAL_PRODUCTS";
export const SIGN_IN = "SIGN_IN";
export const GENERATE_RESET_LINK = "GENERATE_RESET_LINK";

export const SELECT_ORDER = "SELECT_ORDER";
export const CREATE_CUSTOMER = "CHOOSE_CUSTOMER";
export const CHOOSE_CUSTOMER = "CHOOSE_CUSTOMER";
export const NEW_END_CUSTOMER = "NEW_END_CUSTOMER";
export const NEW_BUSINESS_CUSTOMER = "NEW_BUSINESS_CUSTOMER";
export const SELECT_LOCATION = "SELECT_LOCATION";
export const CONFIRM_ORDER = "CONFIRM_ORDER";
export const FINISH_ORDER = "FINISH_ORDER";
export const UPDATE_SELECTED_PRODUCTS = "UPDATE_SELECTED_PRODUCTS";

export const CONTRACT_ACTION_LOADING = "CONTRACT_ACTION_LOADING";
export const CONTRACT_ACTION_ERROR = "CONTRACT_ACTION_ERROR";
export const CONTRACT_MODAL_TYPE = "CONTRACT_MODAL_TYPE";
export const CONTRACT_MODAL_TYPE_TERMINATE = "CONTRACT_MODAL_TYPE_TERMINATE";
export const CONTRACT_MODAL_TYPE_TERMINATE_ALL =
  "CONTRACT_MODAL_TYPE_TERMINATE_ALL";
export const CONTRACT_SUBSCRIPTION_DATA = "CONTRACT_SUBSCRIPTION_DATA";
export const CONTRACT_MODAL_DATE_CHANGE = "CONTRACT_MODAL_DATE_CHANGE";
export const CONTRACT_MODAL_DATA_ITEMS = "CONTRACT_MODAL_DATA_ITEMS";
export const CONTRACT_MODAL_SUCCESS_TYPE = "CONTRACT_MODAL_SUCCESS_TYPE";

export const UPDATE_SIDE_MENU = "UPDATE_SIDE_MENU";
