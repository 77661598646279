import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const ListViewAccordionBody = ({ orderID, isExpanded, orderType, invoiceID, invoiceType, item }) => {
  const renderOrderBody = () => (
    <>
      {item.serviceData && (
        <div className="row ri-list-row-details bg-green br-4 text-truncate">
          <div
            id={'allorder-' + orderID}
            className={'col collapse ' + (isExpanded ? 'show' : '')}
            data-parent=".accordion"
            key={orderID}
          >
            <div className="row border-0 justify-content-between py-4 px-4">
              <div className="col p-0 text-truncate line-height-22">
                <ul className="list-clean pl-0">
                  <li className="font-weight-semibold mb-2">
                    <FormattedMessage id="common_order_details" />
                  </li>
                  <li className="font-weight-normal text-truncate" title={item.customer.name}>
                    {item.customer.name} (#{item.customer.number})
                  </li>
                  <li className="font-weight-normal">
                    <FormattedMessage id="common_order_contract_no" />
                    <span className="font-weight-normal">: #{item.contractNo}</span>
                  </li>
                </ul>
              </div>
              <div className="col p-0 text-truncate line-height-22">
                <ul className="list-clean pl-2">
                  <li className="font-weight-semibold mb-2 text-truncate">
                    <FormattedMessage id="common_installation_address" />
                  </li>
                  <li title={item.soInstallationAddress.locationName}>{item.soInstallationAddress.locationName}</li>
                  <li title={item.soInstallationAddress.addressLine1}>{item.soInstallationAddress.addressLine1},</li>
                  <li className="mb-0 text-truncate" title={item.soInstallationAddress.city}>
                    {item.soInstallationAddress.city} {item.soInstallationAddress.postalCode}
                  </li>

                  <li className="text-truncate" title={item.soInstallationContact.email}>
                    {item.soInstallationContact.email}
                  </li>
                  <li className="mb-2">{item.soInstallationContact.phone1}</li>
                </ul>
              </div>
              <div className="col-md-6 mb-2 pr-0">
                <div className="">
                  <p className="font-weight-semibold mb-0">
                    <FormattedMessage id="common_product_order_details" />#{item.orderNo}
                  </p>
                  <div className="border-bottom-dark pb-3">
                    {item.serviceData.map(product => {
                      return (
                        <div className="row align-items-start mx-0 mb-1 line-height-28" key={Math.random() * 100}>
                          <div className="col-auto font-weight-semibold pl-0 pr-1">{product.quantity}</div>
                          <span>X</span>

                          <div className="col-8 pl-1 pr-0 text-nowrap-normal">{product.description}</div>

                          <div className="col text-right pr-0">
                            {Number(product.currentCost).toFixed(2)},- /
                            <FormattedMessage id="common_per_month" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {item.serviceTotal && (
                    <div className="row py-3 no-gutters">
                      <div className="col-12">
                        <div className="row my-1 line-height-28 ls-0-44 body-color">
                          <div className="col ">
                            <FormattedMessage id="common_total_ex_tax" />
                          </div>
                          <div className="col-auto line-height-28 ls-0-44 body-color">
                            {Number(item.serviceTotal.exMoms).toFixed(2)}
                            ,- /
                            <FormattedMessage id="common_per_month" />
                          </div>
                        </div>
                        <div className="row my-1 line-height-24 ls-0-01">
                          <div className="col text-uppercase">
                            <FormattedMessage id="common_tax" />
                          </div>
                          <div className="col-auto line-height-24 ls-0-01">
                            {Number(item.serviceTotal.Moms).toFixed(2)}
                            ,- /
                            <FormattedMessage id="common_per_month" />
                          </div>
                        </div>
                        <div className="row font-weight-bold my-1">
                          <div className="col body-color ls-0-44 line-height-28">
                            <span className="font-weight-bold">
                              <FormattedMessage id="common_total" />
                            </span>
                          </div>
                          <div className="col-auto">
                            <span className="font-weight-bold">
                              {Number(item.serviceTotal.total).toFixed(2)}
                              ,- /
                              <FormattedMessage id="common_per_month" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  const renderInvoiceBody = () => {
    return (
      <div className="row bg-green">
        <div
          id={'allorder-' + invoiceID}
          className={'col collapse ' + (isExpanded ? 'show' : '')}
          data-parent=".accordion"
          key={invoiceID}
        >
          <div className="row border-0 justify-content-between pt-4 mt-2 pb-0 px-4 ls-0-25 line-height-22">
            <div className="col-6 pl-0 pr-7">
              <div className="font-weight-semibold mb-3">
                <FormattedMessage id="contract_detail_invoice_tab" /> <FormattedMessage id="customer_nav_tab_1" />
              </div>
              <div className="details-border row w-100 pl-0">
                <div className="col-5 pl-0">
                  <FormattedMessage id="customer_name" />:
                </div>
                <div className="col-7 text-truncate" title={item.customer.name}>
                  <p className="m-0">{item.customer.name}</p>
                </div>
              </div>
              <div className="details-border row w-100 pl-0">
                <div className="col-5 pl-0">
                  <FormattedMessage id="common_customer_ID" />:
                </div>
                <div className="col-7">
                  <p className="m-0">{item.customer.number}</p>
                </div>
              </div>
              <div className="details-border row w-100 pl-0">
                <div className="col-5 pl-0">
                  <FormattedMessage id="customer_details_billing-email" />:
                </div>
                <div className="col-7 text-truncate" title={item.invoiceContact.email}>
                  <p className="m-0"> {item.invoiceContact.email}</p>
                </div>
              </div>
              <div className="details-border row w-100 pl-0">
                <div className="col-5 pl-0">
                  <FormattedMessage id="all_orders_type" />:
                </div>
                <div className="col-7">
                  <p className="m-0">
                    {' '}
                    <FormattedMessage id="contract_detail_invoice_tab" />
                  </p>
                </div>
              </div>
              <div className="details-border row w-100 pl-0">
                <div className="col-5 pl-0">
                  <FormattedMessage id="ph_billing_reference" />:
                </div>
                <div className="col-7 text-truncate" title={item.customerOrder && item.customerOrder.value}>
                  <p className="m-0"> {item.customerOrder && item.customerOrder.value}</p>
                </div>
              </div>
              <div className="details-border row w-100 pl-0">
                <div className="col-5 pl-0">
                  <FormattedMessage id="common_terms" />:
                </div>
                <div className="col-7">
                  <p className="m-0">
                    {item.vismaDetails && item.vismaDetails.creditTerms && item.vismaDetails.creditTerms.id} days
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 pr-0 pl-6">
              <div className="font-weight-semibold mb-3">
                <FormattedMessage id="contract_location_details" />
              </div>
              <div className="details-border row w-100 pl-0">
                <div className="col-5 pl-0">
                  <FormattedMessage id="contract_details_edit_address" />:
                </div>
                <div className="col-7">
                  <p className="m-0 text-nowrap-normal">
                    {item.installationAddress.locationName && item.installationAddress.locationName}{' '}
                    {item.installationAddress.addressLine1}
                    <br /> {item.installationAddress.postalCode}, {item.installationAddress.city}
                  </p>
                </div>
              </div>
              <div className="details-border row w-100 pl-0">
                <div className="col-5 pl-0">
                  <FormattedMessage id="all_reseller_contact_person" />:
                </div>
                <div className="col-7 text-truncate" title={item.installationContact.attention}>
                  <p className="m-0">{item.installationContact.attention}</p>
                </div>
              </div>
              <div className="details-border row w-100 pl-0">
                <div className="col-5 pl-0">
                  <FormattedMessage id="ph_phone" />:
                </div>
                <div className="col-7">
                  <p className="m-0"> {item.installationContact.phone1}</p>
                </div>
              </div>
              <div className="details-border row w-100 pl-0">
                <div className="col-5 pl-0">
                  <FormattedMessage id="ph_email" />:
                </div>
                <div className="col-7 text-truncate" title={item.installationContact.email}>
                  <p className="m-0"> {item.installationContact.email}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-0 px-2 mx-0">
            <div className="col my-4 bg-white-3 py-3 line-height-28 ls-0-44">
              {item.collectivelyInvoiced ? (
                <div className="d-flex justify-content-center">
                  <FormattedMessage id="collective_invoice_msg" />
                </div>
              ) : (
                <div className="mt-2 border-bottom-dark mx-2">
                  <div className="row justify-content-between font-weight-semibold">
                    <div className="col-md-4">Item</div>
                    <div className="col-md-4">Period</div>
                    <div className="col-md-2 text-right">Amount</div>
                    <div className="col-md-2 text-right">VAT</div>
                  </div>
                  <div className="row justify-content-between mb-2">
                    {item.servicesAndTerms &&
                      (item.status !== 'Cancelled'
                        ? item.servicesAndTerms.map(invoiceItem => {
                            return (
                              <>
                                {item.status !== 'Sent to Visma' ? (
                                  <div className="col-12 " key={Math.random() * 100}>
                                    <div className={'row ' + (invoiceItem.cancelled ? 'strike-through' : '')}>
                                      <div className="col-md-4">
                                        <>
                                          <strong>{invoiceItem.qty}</strong>
                                          <span className="mx-3">X</span>
                                        </>
                                        <>
                                          <span className="text-nowrap-normal">{invoiceItem.name}</span>
                                        </>
                                      </div>
                                      <div className="col-md-4">{invoiceItem.term}</div>
                                      <div className="col-md-2 text-right">{Number(invoiceItem.price).toFixed(2)}</div>
                                      <div className="col-md-2 text-right">
                                        {item.vismaDetails ? item.vismaDetails.taxDetails[0].vatRate + ' %' : 'N.A.'}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  !invoiceItem.cancelled && (
                                    <div className="col-12">
                                      <div className={'row ' + (invoiceItem.cancelled ? 'strike-through' : '')}>
                                        <div className="col-md-4">
                                          <>
                                            <strong>{invoiceItem.qty}</strong>
                                            <span className="mx-3">X</span>
                                          </>
                                          <>
                                            <span className="text-nowrap-normal">{invoiceItem.name}</span>
                                          </>
                                        </div>
                                        <div className="col-md-4">{invoiceItem.term}</div>
                                        <div className="col-md-2 text-right">
                                          {Number(invoiceItem.price).toFixed(2)}
                                        </div>
                                        <div className="col-md-2 text-right">
                                          {item.vismaDetails ? item.vismaDetails.taxDetails[0].vatRate + ' %' : 'N.A.'}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </>
                            );
                          })
                        : item.servicesAndTerms.map(invoiceItem => {
                            return (
                              <>
                                <div className="col-12" key={Math.random() * 100}>
                                  <div className="row strike-through">
                                    <div className="col-md-4">
                                      <>
                                        <strong>{invoiceItem.qty}</strong>
                                        <span className="mx-3">X</span>
                                      </>
                                      <>
                                        <span className="text-nowrap-normal">{invoiceItem.name}</span>
                                      </>
                                    </div>
                                    <div className="col-md-4">{invoiceItem.term}</div>
                                    <div className="col-md-2 text-right">{Number(invoiceItem.price).toFixed(2)}</div>
                                    <div className="col-md-2 text-right">
                                      {item.vismaDetails ? item.vismaDetails.taxDetails[0].vatRate + ' %' : 'N.A.'}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }))}
                  </div>
                </div>
              )}
              <div className={'my-3 mx-2' + (item.status !== 'Cancelled' ? '' : '')}>
                {item.status !== 'Cancelled' && (
                  <div className="row font-weight-bold mb-1">
                    <div className="col-8">
                      <p className="mb-0 font-weight-bold">
                        <FormattedMessage id="common_total_ex_tax" />
                      </p>
                    </div>
                    <div className="col-4 text-right">
                      <p className="mb-0 font-weight-bold">
                        {item.vismaDetails ? (
                          <span>
                            {Number(item.vismaDetails.vatTaxableTotal).toFixed(2)}
                            {',- /'}
                            <FormattedMessage id="common_per_month" />
                          </span>
                        ) : (
                          <span>
                            {Number(item.totalWithoutTax && item.totalWithoutTax).toFixed(2)}
                            {',- /'}
                            <FormattedMessage id="common_per_month" />
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                )}
                {item.vismaDetails && (
                  <div className="row mb-1">
                    <div className="col-8">
                      <small>
                        <FormattedMessage id="common_tax" />
                      </small>
                    </div>
                    <div className="col-4 text-right">
                      <small>
                        {Number(item.vismaDetails.vatTotal).toFixed(2)},- /<FormattedMessage id="common_per_month" />
                      </small>
                    </div>
                  </div>
                )}

                {item.vismaDetails && (
                  <div className="row font-weight-bold">
                    <div className="col-8">
                      <p className="font-weight-bold">
                        <FormattedMessage id="common_total" />
                      </p>
                    </div>
                    <div className="col-4 text-right">
                      <p className="font-weight-bold">
                        {Number(item.vismaDetails.amount).toFixed(2)},- /
                        <FormattedMessage id="common_per_month" />
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <React.Fragment>
      {orderType && renderOrderBody()}
      {invoiceType && renderInvoiceBody()}
    </React.Fragment>
  );
};

ListViewAccordionBody.propTypes = {
  orderID: PropTypes.number,
  isExpanded: PropTypes.bool,
  orderType: PropTypes.string,
  invoiceID: PropTypes.number,
  invoiceType: PropTypes.string,
  item: PropTypes.object,
};

export default ListViewAccordionBody;
