import React from 'react';
import { FormattedMessage } from 'react-intl';
import Constants from '../../shared/constants';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ItemMeta } from 'semantic-ui-react';

const AllOrderAccordionBody = ({
  item,
  isExpanded,
  isSuperAdmin,
  isAdminBackOffice,
  isAdminSales,
  isContracts,
  isProposal = false,
}) => (
  <React.Fragment>
    {!isContracts && (
      <div
        className={'row ri-list-row-details br-2  text-truncate ls-0-44 ' + (isExpanded && ' pt-0')}
        key={Math.random() * 100}
      >
        <div
          id={'allorder-' + item.orderNo}
          className={'col bg-green collapse p-0 ' + (isExpanded ? 'show' : '')}
          data-parent=".accordion"
          key={item.orderNo}
        >
          <div className=" justify-content-between ml-0 px-4 my-2 py-4 row fs-14 line-height-22 ls-0-25 color-1d1d1d">
            <div className="col-3 order-body-1-col pl-0 ls-0-25">
              <ul className="list-clean">
                <li className="font-weight-semibold mb-2 line-height-20">
                  <FormattedMessage id="common_order_details" />
                </li>
                <li className="mb-0 line-height-22 text-truncate" title={item.customer.name}>
                  {item.customer.name} (#
                  <Link to={{ pathname: `/customers/${item.customer.number}`, query: { name: item.customer.name } }}>
                    {item.customer.number}
                  </Link>
                  )
                </li>
                {item.contractNo && (
                  <>
                    <li className="mb-0 line-height-22">
                      <FormattedMessage id="common_order_contract_no" /> :
                      <span className="ml-2">
                        {isSuperAdmin || isAdminBackOffice || isAdminSales ? (
                          <Link to={'contracts/' + item.contractNo}>#{item.contractNo}</Link>
                        ) : (
                          '#' + item.contractNo
                        )}
                      </span>
                    </li>
                  </>
                )}
                {(isSuperAdmin || isAdminBackOffice || isAdminSales) && (
                  <>
                    {item.currentUser && item.currentUser.name && item.currentUser.resellerName && (
                      <li className="mb-4 text-nowrap-normal">
                        <span className="font-weight-bold text-nowrap-normal">
                          <FormattedMessage id="all_orders_registered_by" /> :
                        </span>
                        {' ' + item.currentUser.name + ', ' + item.currentUser.resellerName}
                      </li>
                    )}
                  </>
                )}
                {isProposal && item && item.orderNo && (
                  <p className="mt-3">
                    <span className="font-weight-bold">
                      <FormattedMessage id="common_order_ID" />:
                    </span>{' '}
                    {item.orderNo}
                  </p>
                )}
                {!isProposal && item && item.fromProposal && (
                  <p className="mt-3">
                    <span className="font-weight-bold">
                      <FormattedMessage id="common_proposal_ID" />:
                    </span>{' '}
                    {item.proposalNo && item.proposalNo.value ? item.proposalNo.value : item.proposalNo}
                  </p>
                )}
                {isProposal && item.media && (
                  <li>
                    <span className="font-weight-semibold mb-2">
                      <FormattedMessage id="common_pdf" />
                    </span>
                    {item.media.length > 0 &&
                      item.media.map(o => {
                        return (
                          <li className="text-underline text-truncate" title={o.file_name}>
                            <img src={Constants.icons.pdfIcon} alt="icon" width="24px" height="24px" className="mr-2" />
                            <a href={o.file_url} target="_blank">
                              {o.file_name}
                            </a>
                          </li>
                        );
                      })}
                  </li>
                )}
              </ul>
            </div>
            <div className="col-4 order-body-2-col ls-0-25">
              <ul className="list-clean text-nowrap-normal">
                {isProposal ? (
                  <li className="font-weight-semibold mb-2 line-height-20">
                    <FormattedMessage id="contract_details_edit_address" />
                  </li>
                ) : item.orderType !== 'SO' ? (
                  <li className="font-weight-semibold mb-2 line-height-20">
                    <FormattedMessage id="common_installation_address" />
                  </li>
                ) : (
                  <li className="font-weight-semibold mb-2 line-height-20">
                    <FormattedMessage id="common_shipping_address" />
                  </li>
                )}
                <li className="mb-0 line-height-22 text-truncate">
                  {isProposal
                    ? item.customer
                      ? item.customer.name
                      : ''
                    : item.soInstallationContact
                    ? item.soInstallationContact.name
                    : item.soShippingContact
                    ? item.soShippingContact.name
                    : ''}
                </li>
                <li className="mb-0 line-height-22 text-truncate">
                  {isProposal
                    ? item.mainContact
                      ? item.mainContact.attention
                      : ''
                    : item.soInstallationContact
                    ? item.soInstallationContact.attention
                    : item.soShippingContact
                    ? item.soShippingContact.attention
                    : ''}
                </li>

                <li className="mb-0 line-height-22">
                  {isProposal ? (
                    <>
                      {item.mainAddress ? item.mainAddress.addressLine1 : ''}
                      {item.mainAddress ? item.mainAddress.addressLine2 : ''}
                      {item.mainAddress ? item.mainAddress.addressLine3 : ''}
                    </>
                  ) : (
                    <>
                      {item.soInstallationAddress
                        ? item.soInstallationAddress.addressLine1
                        : item.soShippingContact
                        ? item.soShippingContact.addressLine1
                        : ''}{' '}
                      {item.soInstallationAddress
                        ? item.soInstallationAddress.addressLine2
                        : item.soShippingContact
                        ? item.soShippingContact.addressLine2
                        : ''}{' '}
                      {item.soInstallationAddress
                        ? item.soInstallationAddress.addressLine3
                        : item.soShippingContact
                        ? item.soShippingContact.addressLine3
                        : ''}{' '}
                    </>
                  )}
                </li>
                <li className="mb-0 line-height-22">
                  {isProposal ? (
                    <>
                      {item.mainAddress ? item.mainAddress.city + ', ' : ''}
                      {item.mainAddress ? item.mainAddress.postalCode : ''}
                    </>
                  ) : (
                    <>
                      {item.soShippingAddress
                        ? item.soShippingAddress.city
                        : item.soInstallationAddress
                        ? item.soInstallationAddress.city
                        : ''}{' '}
                      ,{' '}
                      {item.soShippingAddress
                        ? item.soShippingAddress.country && item.soShippingAddress.country.name + ','
                        : item.soInstallationAddress
                        ? item.soInstallationAddress.country && item.soInstallationAddress.country.name + ','
                        : ''}{' '}
                      {item.soShippingAddress
                        ? item.soShippingAddress.postalCode
                        : item.soInstallationAddress
                        ? item.soInstallationAddress.postalCode
                        : ''}{' '}
                    </>
                  )}
                </li>

                <li className="mb-0 line-height-22 text-truncate">
                  {isProposal ? (
                    <>
                      {item.mainContact ? item.mainContact.email + ', ' : ''}
                      <br />
                      {item.mainContact ? item.mainContact.phone1 : ''}
                    </>
                  ) : (
                    <>
                      {item.soShippingContact
                        ? item.soShippingContact.email
                        : item.soInstallationContact
                        ? item.soInstallationContact.email
                        : ''}
                    </>
                  )}
                </li>

                <li className="mb-0 line-height-22">
                  {item.soShippingContact
                    ? item.soShippingContact.phone1
                    : item.soInstallationContact
                    ? item.soInstallationContact.phone1
                    : ''}
                </li>
              </ul>
            </div>
            <div className="col rounded ls-0-44 body-color">
              <p className="font-weight-semibold mb-0 line-height-28 ls-0-44 ">
                {isProposal ? (
                  item.type === 'Product' ? (
                    <>
                      <FormattedMessage id="common_product_proposal_details" />#{item.proposalNo}
                    </>
                  ) : (
                    <>
                      <FormattedMessage id="common_service_proposal_details" />#{item.proposalNo}
                    </>
                  )
                ) : item.orderType === 'SO' ? (
                  <>
                    <FormattedMessage id="common_product_order_details" /> #{item.orderNo}
                  </>
                ) : (
                  <>
                    <FormattedMessage id="common_service_order_details" /> #{item.orderNo}
                  </>
                )}
              </p>
              <div
                className={
                  'border-bottom-dark pb-3 ' +
                  (item.lines && Object.keys(item.lines).length > 10 && ' orders-list-scroll')
                }
              >
                {item.orderType === 'SO' || item.type === 'Service' || isProposal
                  ? item.lines &&
                    item.lines
                      .filter(obj => obj.inventory && obj.inventory.number !== 'FRK01')
                      .map(product => {
                        return (
                          <div
                            className="row align-items-start mb-1 mx-0 line-height-28 ls-0-44"
                            key={Math.random() * 100}
                          >
                            <div className="col-auto pl-0 font-weight-bold pr-1">{product.quantity}</div>
                            <span>X</span>
                            {item.type === 'Service' ? (
                              <div className="col-8 pr-0 text-nowrap-normal  pl-1">
                                {product.description || product.inventory.name}
                              </div>
                            ) : (
                              <div className="col-8 pr-0 text-nowrap-normal pl-1">
                                {product.lineDescription || product.inventory.name}
                              </div>
                            )}
                            <div className="col pr-0 text-right">
                              {product.price
                                ? Number(product.price).toFixed(2)
                                : product.unitPrice
                                ? Number(product.unitPrice).toFixed(2)
                                : 0}
                              {Constants.currency.norway}
                              {item.type === 'Service' && (
                                <>
                                  <span>{' /'}</span>
                                  <FormattedMessage id="common_per_month" />
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })
                  : item.serviceData &&
                    item.serviceData.map(service => {
                      return (
                        <div className="row align-items-start mb-1 mx-0 line-height-28" key={Math.random() * 100}>
                          <div className="col-auto font-weight-semibold pl-0 pr-1">{service.quantity}</div>
                          <span>X</span>
                          <div className="col-8 pl-1 pr-0 text-nowrap-normal">{service.description}</div>

                          <div className="col pr-0 text-right">
                            {service.currentCost ? Number(service.currentCost).toFixed(2) : 0}
                            {Constants.currency.norway}
                            <span>{' /'}</span>
                            <FormattedMessage id="common_per_month" />
                          </div>
                        </div>
                      );
                    })}
                {isProposal && item.type === 'Product' && (
                  <p className="font-weight-semibold mb-0 line-height-28 ls-0-44 ">
                    <FormattedMessage id="common_installation" />
                  </p>
                )}
                {isProposal && item.type === 'Product' ? (
                  item.installation && item.fixedPrice ? (
                    <div className="row align-items-start mb-1 mx-0 line-height-28" key={Math.random() * 100}>
                      <div className="col-auto pl-0 pr-1">
                        <FormattedMessage id="installation_hour_rate" />
                      </div>
                      <div className="col pr-0 text-right">
                        {item.installation && item.installation.price}
                        {Constants.currency.norway}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row align-items-start mb-1 mx-0 line-height-28" key={Math.random() * 100}>
                        <div className="col-auto pl-0 pr-1">
                          <FormattedMessage id="installation_hour_rate" />
                        </div>
                        <div className="col pr-0 text-right">
                          {item.installation && item.installation.hours && item.installation.hours.pricePerHour}
                          {Constants.currency.norway}
                        </div>
                      </div>
                      <div className="row align-items-start mb-1 mx-0 line-height-28" key={Math.random() * 100}>
                        <div className="col-auto pl-0 pr-1">
                          <FormattedMessage id="installation_amount_of_hours" />
                        </div>
                        <div className="col pr-0 text-right">
                          {item.installation && item.installation.hours && item.installation.hours.hours}h
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="pt-2 mt-1">
                {item.lines &&
                  Array(item.lines.find(product => product.inventory && product.inventory.number === 'FRK01')).map(
                    values => (
                      <>
                        {values && values.unitPrice >= 0 && (
                          <div className="row my-1" key={Math.random() * 100}>
                            <div className="col">
                              <small>
                                <FormattedMessage id="common_shipping" />
                              </small>
                            </div>
                            <div className="col-auto">
                              <small>
                                {values && values.unitPrice}
                                {Constants.currency.norway}
                              </small>
                            </div>
                          </div>
                        )}
                      </>
                    )
                  )}
                <div className="row my-1 line-height-28 ls-0-44 body-color">
                  <div className="col">
                    <FormattedMessage id="common_total_ex_tax" />
                  </div>
                  <div className="col-auto line-height-28 ls-0-44 body-color">
                    {item.orderType || isProposal
                      ? item.vatTaxableTotal + item.vatExemptTotal === 0
                        ? 0
                        : Number(item.vatTaxableTotal + item.vatExemptTotal).toFixed(2)
                      : item.totalWithoutTax === '0.00'
                      ? 0
                      : Number(item.totalWithoutTax).toFixed(2)}
                    {Constants.currency.norway}
                    {(isProposal && item.type !== 'Service') || item.orderType ? (
                      ' '
                    ) : (
                      <>
                        <span>{' /'}</span>
                        <FormattedMessage id="common_per_month" />
                      </>
                    )}
                  </div>
                </div>

                <div className="row mb-2 line-height-24 ls-0-01">
                  <div className="col text-uppercase ">
                    <FormattedMessage id="common_tax" />
                  </div>
                  <div className="col-auto line-height-24 ls-0-01">
                    <span>
                      {item.taxTotal === '0.00' ? 0 : Number(item.taxTotal).toFixed(2)}
                      {Constants.currency.norway}
                      {(!isProposal || item.type === 'Service') && item.type && (
                        <>
                          <span>{' /'}</span>
                          <FormattedMessage id="common_per_month" />
                        </>
                      )}
                    </span>
                  </div>
                </div>

                <div className="row font-weight-bold mt-2">
                  <div className="col body-color ls-0-44 line-height-28">
                    <FormattedMessage id="common_total" />
                  </div>
                  <div className="col-auto body-color ls-0-44 line-height-28">
                    {isProposal
                      ? item.proposalTotal === '0.00'
                        ? 0
                        : Number(item.proposalTotal).toFixed(2)
                      : item.orderTotal === '0.00'
                      ? 0
                      : Number(item.orderTotal).toFixed(2)}
                    {Constants.currency.norway}
                    {(!isProposal || item.type === 'Service') && item.type && (
                      <>
                        <span>{' /'}</span>
                        <FormattedMessage id="common_per_month" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </React.Fragment>
);

AllOrderAccordionBody.propTypes = {
  item: PropTypes.object,
  isExpanded: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  isAdminBackOffice: PropTypes.bool,
  isAdminSales: PropTypes.bool,
  isContracts: PropTypes.bool,
};

export default AllOrderAccordionBody;
