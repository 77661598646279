import React from 'react';
import PropTypes from 'prop-types';
import ListViewComponent from '../ListView/ListView';
import ProductService from '../../services/ProductService';
import CustomerService from '../../services/CustomerService';
import OrderService from '../../services/OrderService';
import Config from '../../config';
import Accordion from '../Accordion';
import { ProductAccordionHeader, ProductAccordionBody, ProductListViewHeader } from './';
import AuthService from '../../services/AuthService';
import Constants from '../../shared/constants';
class ProductListView extends ListViewComponent {
  productService = new ProductService(Config.apiBase);
  customerService = new CustomerService(Config.apiBase);
  orderService = new OrderService(Config.apiBase);
  authService = new AuthService(Config.apiBase);

  state = {
    expandedItem: {},
    productBrief: '',
    isTextAreaEnabled: false,
    productDescription: this.authService.isSuperAdminUser(),
    productItems: JSON.parse(JSON.stringify(this.props.items)),
    selectedFile: null,
    items: 1,
  };

  componentWillReceiveProps(nextProps, prevProps) {
    //console.log(nextProps);
    if (nextProps && nextProps.items) {
      this.setState({ productItems: nextProps.items });
    }
  }
  async updateProducts(inventoryNumber) {
    // let result;
    // try {
    //   result = await this.productService.updateProducts(inventoryNumber);
    // } catch (error) {
    //   console.log(error);
    // }
  }
  handleToggle = item => {
    const { expandedItem } = this.state;
    const { getEditData, getimage } = this.props;
    if (getEditData) {
      getEditData(item);
    }
    // if (getImage) {
    //   getImage(image);
    // }
    this.setState({ isTextAreaEnabled: false, productBrief: '' });
    if (item.inventoryId === expandedItem.inventoryId) {
      this.setState({ expandedItem: {} });
    } else {
      this.setState({
        productBrief: item.productBrief ? item.productBrief : '',
        expandedItem: item,
      });
    }
  };
  async componentDidMount() {
    this.setState({ loading: true });

    try {
      let response;
      const inventoryNumber = this.props.match && this.props.match.params.id ? this.props.match.params.id : false;
      if (inventoryNumber) {
        this.setState({ loading: true });
        response = await this.contractService.updateProducts(inventoryNumber);
        if (response) {
          this.contractDetailsStateUpdate(response);
          this.setState({ loading: false });
        }
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ loading: false });
  }
  handleProductImage = async id => {
    let response = await this.productService.getProductImage(id).then(response => response.data);
    return response;
  };
  handleUpdateProductDetails = async inventoryNumber => {
    let response = await this.productService.updateProducts(inventoryNumber).then(response => response.data);
    return response;
  };
  handleFindMediaLength = (media, media_type) => {
    try {
      return media.filter(obj => obj.file_type === media_type).length;
    } catch (err) {
      console.log(err, media, 'ERROR');
    }
  };

  handleFileDelete = async (fileID, inventoryNumber) => {
    let products = this.state.productItems.map(product => {
      if (product.inventoryNumber === inventoryNumber) {
        if (product.media && this.handleFindMediaLength(product.media, Constants.FileTypes.DOCS) > 0) {
          product.media = product.media.filter(obj => obj.file_id !== fileID);
        }
      }
      return product;
    });

    this.setState({ productItems: products });

    await this.productService.removeProductDocument(fileID, inventoryNumber);
  };

  handleOnFilesAdded = async (file, inventoryNumber, documentType) => {
    // Below code is to convert Image into Base64/DataURI form
    let getBase64 = file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    };
    getBase64(file).then(data => {
      if (documentType !== Constants.FileTypes.DOCUMENT) {
        //This code is displaying the Uploaded image in certain time
        //so the user no need to wait till get response from the backend.
        let productsTempImage = this.state.productItems.map(product => {
          if (product.inventoryNumber === inventoryNumber) {
            if (product.media && this.handleFindMediaLength(product.media, Constants.FileTypes.IMAGE) > 0) {
              product.media
                .filter(obj => obj.file_type === Constants.FileTypes.IMAGE)
                .map(obj => (obj.file_url = data));
            } else {
              const obj = {
                file_type: Constants.FileTypes.IMAGE,
                file_url: data,
              };
              if (product.media) {
                product.media.push(obj);
              } else {
                product['media'] = [];
                product.media.push(obj);
              }
            }
          }

          return product;
        });
        this.setState({ productItems: productsTempImage });
      }
    });
    let response;

    response = await this.productService.uploadFile(file, inventoryNumber, documentType);

    if (response && response.data.data.url) {
      if (documentType !== Constants.FileTypes.DOCUMENT) {
        let products = this.state.productItems.map(product => {
          if (product.inventoryNumber === inventoryNumber) {
            if (product.media && this.handleFindMediaLength(product.media, Constants.FileTypes.IMAGE) > 0) {
              product.media
                .filter(obj => obj.file_type === Constants.FileTypes.IMAGE)
                .map(obj => (obj.file_url = response.data.data.url));
            } else {
              const obj = {
                file_url: response.data.data.url,
                file_id: response.data.data.id,
                file_type: Constants.FileTypes.IMAGE,
              };
              if (product.media) {
                product.media.push(obj);
              } else {
                product['media'] = [];
                product.media.push(obj);
              }
            }
          }
          return product;
        });

        this.setState({ productItems: products });
        // });
      } else {
        let products = this.state.productItems.map(product => {
          if (product.inventoryNumber === inventoryNumber) {
            const obj = {
              file_url: response.data.data.url,
              file_id: response.data.data.id,
              file_name: response.data.data.name,
              file_type: Constants.FileTypes.DOCS,
            };
            if (product.media) {
              product.media.push(obj);
            } else {
              product['media'] = [];
              product.media.push(obj);
            }
          }
          return product;
        });

        this.setState({ productItems: products });
      }
    }
  };

  handleTextArea = async item => {
    this.setState({ isTextAreaEnabled: true });
    this.setState({ productBrief: item.productBrief });
  };

  saveTextArea = async (item, productBriefValue) => {
    this.setState({
      isTextAreaEnabled: false,
    });
    item.productBrief = productBriefValue;

    if (
      productBriefValue === '<p><br></p>' ||
      (productBriefValue !== '' && productBriefValue.replace(/\s/g, '') === '<p></p>')
    ) {
      productBriefValue = '';
    }

    try {
      if (item.inventoryNumber && productBriefValue) {
        await this.orderService.createProductDescription(item.inventoryNumber, productBriefValue);
      }
    } catch (error) {
      console.log(error);
    }
    let items = this.state.productItems.map(obj => {
      if (obj.inventoryNumber === item.inventoryNumber) {
        obj.productBrief = productBriefValue;
      }
      return obj;
    });
    this.setState({
      isTextAreaEnabled: false,
      productItems: items,
    });
  };

  closeTextArea = () => {
    this.setState({
      isTextAreaEnabled: false,
    });
  };

  handlePriceEditable = item => {
    this.props.items.map(obj =>
      obj.inventoryId === item.inventoryId ? (obj.defaultPrice = Number(item.defaultPrice)) : ''
    );
  };

  renderAccordionContent = ({
    items,
    expandedItem,
    isReseller,
    forBasket,
    onItemClick,
    onIncreaseItem,
    onDecreaseItem,
    onRemoveItem,
    onQuantityUpdated,
    onQuantityUpdatedBlur,
    onPriceChanged,
    isSuperAdmin,
    isAdminSales,
    isAdminBackOffice,
    onRemoveToolTip,
    isTextAreaEnabled,
    productBrief,
    productDescription,
    featureData,
    editFeature,
    onFeatureChange,
    deleteFeature,
    onFileUploading,
    onFileDelete,
    editProdutsModal,
    imageModal,
    isProposals,
  }) =>
    items.map(item => {
      return (
        <Accordion
          key={item.inventoryId}
          item={item}
          isExpanded={expandedItem.inventoryId === item.inventoryId}
          onToggle={this.handleToggle}
          isReseller={isReseller}
          forBasket={forBasket}
          isAdminBackOffice={isAdminBackOffice}
        >
          <ProductAccordionHeader
            key={item.inventoryId}
            forBasket={forBasket}
            onItemClick={onItemClick}
            onIncreaseItem={onIncreaseItem}
            onDecreaseItem={onDecreaseItem}
            onRemoveItem={onRemoveItem}
            onQuantityUpdated={onQuantityUpdated}
            onQuantityUpdatedBlur={onQuantityUpdatedBlur}
            onPriceChanged={onPriceChanged}
            isSuperAdminUser={isSuperAdmin}
            isAdminSales={isAdminSales}
            isAdminBackOffice={isAdminBackOffice}
            onRemoveToolTip={onRemoveToolTip}
            isResellerUser={isReseller}
            proposalDetails={this.props.proposalDetails}
            isProposals={isProposals}
          />
          <ProductAccordionBody
            key={item.inventoryId}
            isTextAreaEnabled={isTextAreaEnabled}
            productBrief={productBrief}
            handleTextArea={this.handleTextArea}
            handleTextAreaChange={this.handleTextAreaChange}
            saveTextArea={this.saveTextArea}
            closeTextArea={this.closeTextArea}
            productDescription={productDescription}
            productImage={this.handleProductImage}
            onFilesAdded={this.handleOnFilesAdded}
            isSuperAdminUser={isSuperAdmin}
            isAdminBackOffice={isAdminBackOffice}
            isAdminSales={isAdminSales}
            fileDelete={this.handleFileDelete}
            findMediaLength={this.handleFindMediaLength}
            featureData={featureData}
            editFeature={editFeature}
            onFeatureChange={onFeatureChange}
            deleteFeature={deleteFeature}
            onFileUploading={onFileUploading}
            onFileDelete={onFileDelete}
            getAllProduct={this.getAllProduct}
            editProdutsModal={editProdutsModal}
            imageModal={imageModal}
          />
        </Accordion>
      );
    });

  render() {
    const {
      isReseller,
      isSuperAdmin,
      isAdminSales,
      isAdminBackOffice,
      onRemoveToolTip,
      forBasket,
      onItemClick,
      onPriceChanged,
      onIncreaseItem,
      onDecreaseItem,
      onRemoveItem,
      onQuantityUpdated,
      onQuantityUpdatedBlur,
      featureData,
      editFeature,
      onFeatureChange,
      deleteFeature,
      editProdutsModal,
      imageModal,
      onSort,
      onFileUploading,
      onFileDelete,
      sortAscending,
      sortedBy,
      isProposals = false,
    } = this.props;
    const { productBrief, expandedItem, isTextAreaEnabled, productDescription, productItems: items } = this.state;
    return (
      <React.Fragment>
        <ProductListViewHeader
          forBasket={forBasket}
          onSort={onSort}
          sortAscending={sortAscending}
          isReseller={isReseller}
          sortedBy={sortedBy}
          isProposals={isProposals}
        />
        <div className={'accordion ' + (forBasket && 'fs-14')}>
          {this.renderAccordionContent({
            items,
            expandedItem,
            isReseller,
            forBasket,
            onItemClick,
            onIncreaseItem,
            onDecreaseItem,
            onRemoveItem,
            onQuantityUpdated,
            onQuantityUpdatedBlur,
            onPriceChanged,
            isSuperAdmin,
            isAdminSales,
            isAdminBackOffice,
            onRemoveToolTip,
            isTextAreaEnabled,
            productBrief,
            productDescription,
            featureData,
            editFeature,
            onFeatureChange,
            deleteFeature,
            onSort,
            onFileUploading,
            onFileDelete,
            editProdutsModal,
            imageModal,
            isProposals,
          })}
        </div>
      </React.Fragment>
    );
  }
}

ProductListView.propTypes = {
  isReseller: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  isAdminSales: PropTypes.bool,
  isAdminBackOffice: PropTypes.bool,
  onRemoveToolTip: PropTypes.func,
  forBasket: PropTypes.bool,
  onItemClick: PropTypes.func,
  onPriceChanged: PropTypes.func,
  onIncreaseItem: PropTypes.func,
  onDecreaseItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onQuantityUpdated: PropTypes.func,
  onQuantityUpdatedBlur: PropTypes.func,
  featureData: PropTypes.array,
  editFeature: PropTypes.func,
  onFeatureChange: PropTypes.func,
  deleteFeature: PropTypes.func,
  items: PropTypes.array,
  onSort: PropTypes.func,
  onFileUploading: PropTypes.func,
  onFileDelete: PropTypes.func,
  proposalDetails: PropTypes.array,
};

export default ProductListView;
