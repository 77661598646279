import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import AuthService from '../../services/AuthService';
import { charLimit } from '../../shared/stringOperations';
import Constants from '../../shared/constants';
import Config from '../../config';
import PriceEditable from '../Input/PriceEditable';
import CounterChange from '../CounterChange/CounterChange';

const ProductAccordionHeader = ({
  item,
  handleToggle,
  forBasket,
  onIncreaseItem,
  onDecreaseItem,
  onQuantityUpdated,
  onQuantityUpdatedBlur,
  isExpanded,
  onPriceChanged,
  isSuperAdminUser,
  isAdminBackOffice,
  isResellerUser,
  proposalDetails,
  isProposals = false,
}) => {
  const authService = new AuthService(Config.apiBase);
  const isAdminSales = authService.isAdminSalesUser();

  const handlePriceChange = price => {
    item.defaultPrice = price;
    onPriceChanged(item);
  };

  const handleOnDecrease = event => {
    event.stopPropagation();
    onDecreaseItem(item);
  };

  const handleOnIncreaseItem = event => {
    event.stopPropagation();
    onIncreaseItem(item);
  };

  const handleOnQuantityUpdate = event => {
    event.stopPropagation();
    onQuantityUpdated(item, event.target.value);
  };

  const handleOnQuantityUpdatedBlur = event => {
    event.stopPropagation();
    onQuantityUpdatedBlur(item, event.target.value);
  };

  const handleOnKeyPress = e => {
    if (e.charCode > 47 && e.charCode < 58) {
      return true;
    }
    e.preventDefault();
  };

  const setDefaultPriceValue = () => {
    if(proposalDetails){
      const { lines = [] } = proposalDetails;
      const record = lines.find((data) => data.inventory.number === item.inventoryNumber);
      return record ? record.unitPrice : item.defaultPrice;
    }
    return item.defaultPrice;
  }

  return (
    <React.Fragment>
      <div className={'row py-2 table-row text-truncate  align-items-center bg-white ls-0-44 '}>
        <div className={' font-weight-semibold rounded-color  text-truncate ' + (forBasket ? 'col-4' : 'col')}>
          <div className={'row select-icon-holder'}>
            <div className={'col-1  mr-2 ' + (forBasket ? ' mr-1' : '')}>
              <a
                href={'product-' + item.inventoryId}
                data-toggle="collapse"
                onClick={handleToggle}
                className={
                  'thumbnail d-inline-block align-middle collapsed rounded-circle ' +
                  (isExpanded ? ' rotate-180deg' : 'rotate-0deg')
                }
              >
                <span className="" />
              </a>
            </div>

            <div className={(item.selected ? 'col ' : 'col ') + 'pl-1 text-truncate'} title={item.description}>
              {/* <span className={'px-2' + (!forBasket ? ' ml-1' : '')} title={item.description}> */}
              {forBasket && item.description} {!forBasket && !isResellerUser && item.description}{' '}
              {!forBasket && isResellerUser && item.description} {/* </span> */}
            </div>
          </div>
        </div>

        <div
          className={isProposals ? 'col-2' : 'col-3' + ' w-20-per-max'}
          title={item.itemClass && item.itemClass.description}
        >
          <span> {item.itemClass && charLimit.charLimit(item.itemClass.description, 20)} </span>
        </div>
        <div className={isProposals ? 'col-1' : 'col-2'} title={item.inventoryNumber}>
          <span> {item.inventoryNumber && charLimit.charLimit(item.inventoryNumber, 11)} </span>
        </div>
        {isProposals ? (
          <>
            <div className={'col-2 text-right w-14-per-max pl-0 pr-4'}>
              <span>
                {item.resellerPrice || 0}
                <span>{Constants.currency.norway}</span>
              </span>
            </div>
            <div className={'col-2 text-right w-14-per-max pl-0 pr-4'}>
              <span className={item.selected && 'price-edit p-1'}>
                {item.selected && (
                  <PriceEditable
                    text={setDefaultPriceValue()}
                    onChange={handlePriceChange}
                    labelClassName="ItemPriceEditable"
                    inputClassName="ItemPriceEditable bg-transparent text-right"
                    inputWidth="90px"
                    inputHeight="30px"
                    inputMaxLength="50"
                  />
                )}
                {!item.selected && <>{item.defaultPrice}</>}
                <span>{Constants.currency.norway}</span>
              </span>
            </div>
          </>
        ) : (
          <div className={'col-3 text-right w-14-per-max pl-0 pr-4'}>
            <span className={!isResellerUser && item.selected && 'price-edit p-1'}>
              {!isResellerUser && item.selected && (
                <PriceEditable
                  text={item.defaultPrice}
                  onChange={handlePriceChange}
                  labelClassName="ItemPriceEditable"
                  inputClassName="ItemPriceEditable bg-transparent text-right"
                  inputWidth="90px"
                  inputHeight="30px"
                  inputMaxLength="50"
                />
              )}
              {((!isResellerUser && !item.selected) || isResellerUser) && <>{item.defaultPrice}</>}
              <span>{Constants.currency.norway}</span>
            </span>
          </div>
        )}
        {!forBasket && !isResellerUser && (
          <div className={'col-2 text-left w-12-per-max pr-0 pl-4'}>
            {item.status === 'Active' ? (
              <FormattedMessage id="all_products_status_active" />
            ) : item.status === 'NoSales' ? (
              <FormattedMessage id="all_products_status_no_sales" />
            ) : item.status === 'NoPurchases' ? (
              <FormattedMessage id="all_products_status_no_purchases" />
            ) : item.status === 'Inactive' ? (
              <FormattedMessage id="all_products_status_in_active" />
            ) : (
              item.status
            )}{' '}
          </div>
        )}
        {forBasket && (
          <div className={'col-2 item-quantity text-center w-12-per-max align-self-end pl-4'}>
            <React.Fragment>
              <CounterChange
                value={item.quantity || 0}
                onChangeValue={handleOnQuantityUpdate}
                onBlurValue={handleOnQuantityUpdatedBlur}
                onIncrease={handleOnIncreaseItem}
                onDecrease={handleOnDecrease}
                onKeyPressValue={handleOnKeyPress}
              />
            </React.Fragment>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

ProductAccordionHeader.propTypes = {
  item: PropTypes.object,
  handleToggle: PropTypes.func,
  forBasket: PropTypes.bool,
  onIncreaseItem: PropTypes.func,
  onDecreaseItem: PropTypes.func,
  onQuantityUpdated: PropTypes.func,
  onQuantityUpdatedBlur: PropTypes.func,
  isExpanded: PropTypes.bool,
  onPriceChanged: PropTypes.func,
  isSuperAdminUser: PropTypes.bool,
  isAdminSales: PropTypes.bool,
  isAdminBackOffice: PropTypes.bool,
  isResellerUser: PropTypes.bool,
};

export default ProductAccordionHeader;
