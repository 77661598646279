import React, { Component } from 'react';
import Constants from '../../shared/constants';

export default class Toast extends Component {
  state = {
    show: '',
  };
  close() {
    this.setState({ show: false });
  }

  componentDidMount() {
    const { showToast, delay } = this.props;
    this.setState({
      show: showToast,
    });
    setTimeout(() => {
      this.setState({
        show: false,
      });
    }, delay);
    // console.log(showToast, delay);
  }
  componentWillUnmount() {
    this.setState({
      show: false,
    });
  }

  render() {
    const { type, message, title } = this.props;
    const { show } = this.state;
    return (
      <>
        {show && (
          <div className={'toastContainer toast' + type}>
            <div role="alert" aria-live="assertive" aria-atomic="true" className="toast" data-autohide="false">
              <div className="toast-header sidemenu-text">
                <img
                  src={type === 'Success' ? Constants.icons.tickWhite : Constants.icons.cross}
                  width="14"
                  height="14"
                  className="mr-2"
                />
                {title && (
                  <>
                    <span className="mr-auto sidemenu-text">{title}</span>
                    <br />
                  </>
                )}
                <span className="mr-auto sidemenu-text">{message}</span>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
