import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Constants from '../../shared/constants';
import AuthService from '../../services/AuthService';
import Config from '../../config';
import validator from 'validator';
import LoadingOverlay from 'react-loading-overlay';

class ForgotPassword extends Component {
  authService = new AuthService(Config.apiBase);
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isSubmitted: false,
    };
  }
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSubmit = async event => {
    event.preventDefault();
    const { email } = this.state;
    this.setState({ loading: true });
    const response = await this.authService.generateResetLink({ email });
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else if (response.data.success !== 'ok') {
      this.setState({
        email: '',
        isSubmitted: true,
        loading: false,
      });
    } else if (response.data.success === 'ok') {
      this.setState({
        email: '',
        isSubmitted: true,
        loading: false,
      });
    }
  };
  validateForm = () => {
    const { email } = this.state;
    return email.length > 0 && validator.isEmail(email);
  };

  render() {
    const { email, isSubmitted, loading } = this.state;
    return (
      <LoadingOverlay
        className="h-100"
        active={loading}
        spinner
        text={<FormattedMessage id="common_loading_overlay" />}
      >
        <div className="container-fluid h-100 p-0 bg-light-dark">
          <div className="container h-100">
            {!isSubmitted ? (
              <div className="pt-8">
                <div className="row mb-3">
                  <div className="col text-center">
                    <h1 className="text-white font-weight-light fs-59">
                      <FormattedMessage id="forgot_password_header" />
                    </h1>
                  </div>
                </div>
                <div className="row">
                  <div className="col fs-15 text-white font-weight-normal   text-center">
                    <p>
                      <FormattedMessage id="forgot_password_message" />
                    </p>
                  </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="row justify-content-center">
                    <div className="col-5 w-378-max px-0">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          defaultValue={email}
                          onChange={this.handleChange}
                          placeholder={Constants.language.ph_forgot_password_email}
                          required
                          autoFocus
                          className="form-control h-44 px-4 signin-input"
                          id="exampleInputEmail"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-5 w-378-max px-0">
                      <div className="text-center mt-3 h-44">
                        <button type="submit" className="btn btn-primary">
                          <FormattedMessage id="bc_reset_password" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="row mb-3">
                  <div className="col text-center  mt-5">
                    <a href={Constants.routes.home.url} className="font-weight-normal text-white fs-14">
                      <u>
                        <FormattedMessage id="common_back" />
                      </u>
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row h-100">
                <div className="col-12">
                  <div className="row pt-8">
                    <div className="col text-center">
                      <h1 className="text-white font-weight-light fs-59">
                        <FormattedMessage id="forgot_password_check_mail" />
                      </h1>
                      <p className="mt-3 mb-0 fs-16 text-white font-weight-semibold">
                        <FormattedMessage id="forgot_password_check_mail_message" />
                      </p>
                      <p className="mt-1 fs-16 text-white font-weight-normal">
                        <FormattedMessage id="forgot_password_check_mail_message_1" />
                      </p>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-5 w-378-max px-0">
                      <div className="text-center mt-3 h-44">
                        <Link to={Constants.routes.home.url} className="btn btn-primary btn-sm">
                          <FormattedMessage id="common_sign_in_header" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default ForgotPassword;
