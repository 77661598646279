import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ListViewComponent from '../ListView/ListView';
import Accordion from '../Accordion';
import { ResellerListViewHeader } from './';

class ResellerListView extends ListViewComponent {
  render() {
    const { users, onSort, sortAscending, sortedBy } = this.props;

    return (
      <React.Fragment>
        <ResellerListViewHeader onSort={onSort} sortAscending={sortAscending} sortedBy={sortedBy} />
        <div className="fs-14">
          {users.map((user, index) => {
            return (
              <div
                className={
                  'row py-2 align-items-center table-row fs-14' +
                  (index + 1 === users.length && ' border-bottom-979797 ')
                }
                key={Math.random() * 100}
              >
                <div className="col-3 font-weight-semibold rounded-color px-2 text-truncate fs-14" title={user.name}>
                  <>
                    <Link to={{ pathname: `/resellers/${user.number}`, query: { name: user.name } }}>
                      <u>{user.name}</u>
                    </Link>
                  </>
                </div>
                <div className="col-2">{user.number}</div>
                <div className="col-3 text-truncate" title={user.mainContact.name}>
                  {user.mainContact.name}
                </div>
                <div className="col-2">{user.mainContact.phone1}</div>
                <div className="col-2">{moment(user.createdDateTime, 'YYYY-MM-DD').format('DD/MM/YYYY')}</div>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

ResellerListView.propTypes = {
  users: PropTypes.array.isRequired,
  onSort: PropTypes.func,
  sortAscending: PropTypes.bool,
};

export default ResellerListView;
