import ApiService from './ApiService';
// import axios from "axios";

class ProductService extends ApiService {
  endPoint = 'orders';
  search = 's=';
  page = 'p=';

  /* Note: Service calls for allOrders.js */

  async gotoPage(page_number) {
    let response = await this.get(this.baseURL + this.endPoint + this.page + page_number);
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  /* Note: Service calls for ProductService.js */

  endPointProduct = 'product-filters';
  endPointProducts = 'products';

  async getAll(queryParam) {
    let response = await this.get(this.baseURL + this.endPointProduct + this.queryParamString(queryParam));
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        products: response.data.data.map(obj => obj),
        meta: response.data.meta,
      };
    }
  }

  async getAllProducts() {
    let response = await this.get(this.baseURL + this.endPointProduct);

    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        products: response.data.data.map(obj => obj),
        meta: response.data.meta,
      };
    }
  }

  async uploadFile(file, inventoryNumber, documentType) {
    const form = new FormData();
    if (documentType === 'document') {
      form.append('media', file);
      form.append('inventoryNumber', inventoryNumber);
      form.append('type', 'docs');
    } else {
      form.append('media', file);
      form.append('inventoryNumber', inventoryNumber);
      form.append('type', 'image');
    }

    let response;
    if (documentType === 'document') {
      response = await this.postImage(this.baseURL + this.endPointProducts + '/media-upload', form);
    } else {
      response = await this.postImage(this.baseURL + this.endPointProducts + '/media-upload', form);
    }

    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async removeProductDocument(fileID, inventoryNumber) {
    const data = {
      id: fileID,
      inventoryNumber: inventoryNumber,
    };
    let response = await this.post(this.baseURL + this.endPointProducts + '/remove-media', data);
    return response;
  }

  async getServices({ queryParam = '' }) {
    let response = await this.get(this.baseURL + 'services' + (queryParam ? this.queryParamString(queryParam) : ''));

    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        services: response.data.data.map(obj => obj),
        meta: response.data.meta,
      };
    }
  }

  async getProductImage(attachmentId) {
    let response = await this.getImage(this.baseURL + 'attachments/' + attachmentId);

    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async searchProducts(searchText) {
    let response = await this.get(this.baseURL + this.endPoint + this.search + searchText);
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return response;
    }
  }
  async updateProductData(inventoryNumber) {
    let response = await this.get(this.baseURL + this.endPointProducts + '/' + inventoryNumber);
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }
  async getDefaultSupplier(inventoryNumber) {
    let response = await this.get(this.baseURL + this.endPointProducts + '/default-supplier/' + inventoryNumber);
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }
  async getItemClassEntity() {
    let response = await this.get(this.baseURL + this.endPointProducts + '/item-class');
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }
  async getAllUsers(queryParam) {
    let response = await this.get(this.baseURL + 'vendors' + this.queryParamString(queryParam));
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async createProductItem(data) {
    let response = await this.post(this.baseURL + this.endPointProducts + '/create-product', data);
    if (response.status !== 201) {
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }
  async updateProductDetails(data, inventoryNumber) {
    let response = await this.put(
      this.baseURL + this.endPointProducts + '/' + data.inventoryNumber + '/' + 'update',
      data
    );
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: response.data,
        meta: response.data.meta,
      };
    }
  }
  async uploadmediaFile(file, documentType) {
    const form = new FormData();
    if (documentType === 'document') {
      form.append('media', file);
      form.append('type', 'docs');
    } else {
      form.append('media', file);
      form.append('type', 'image');
    }

    let response;
    if (documentType === 'document') {
      response = await this.postImage(this.baseURL + this.endPointProducts + '/media-upload', form);
    } else {
      response = await this.postImage(this.baseURL + this.endPointProducts + '/media-upload', form);
    }

    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response;
    }
  }
  async removemediaFile(fileID, inventoryNumber) {
    let query = 'fileId=' + fileID;
    if (inventoryNumber) {
      query += '&id=' + inventoryNumber;
    }
    // console.log(query);
    return await this.put(this.baseURL + this.endPointProducts + '/remove-media?' + query, {});
  }
}

export default ProductService;
