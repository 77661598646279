import React, { Component } from 'react';
import Constants from '../../../shared/constants';
import ContractsActionButtons from './ContractsActionButtons';
import PriceEditable from '../../Input/PriceEditable';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { render } from 'react-dom';

class RenderServiceCardElements extends Component {
  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleOutsideClick = e => {
    const { data: items, handleOpenButtonMenu, openButtonMenu } = this.props;
    // ignore clicks on the component itself
    if (
      this.handleOptions &&
      !this.handleOptions.contains(e.target) &&
      openButtonMenu.open &&
      openButtonMenu.inventoryNumber === items.inventoryNumber
    ) {
      handleOpenButtonMenu(items.inventoryNumber);
    }
  };
  render() {
    const {
      type,
      data: items,
      parentData,
      chooseService,
      changePriceOfService,
      onPriceChange,
      isSuperAdmin,
      isAdminSales,
      isAdminBackOffice,
      onClickActionButtons,
      onItemClick,
      contractModalTypeSelection,
      contractModalDataItems,
      handleOpenButtonMenu,
      openButtonMenu,
    } = this.props;

    return (
      <div
        className={
          'shadow-none services bg-white' + (type === Constants.contractModalType.parent ? ' ' : 'add-on-services ')
        }
      >
        <div
          className={
            'pb-0 pl-2 mr-3 border-bottom-light ' +
            (type === Constants.contractModalType.parent && 'service-detail-toggle') +
            (chooseService && (type !== Constants.contractModalType.parent ? ' mb-3 ' : ' mb-4 '))
          }
        >
          <div
            className={
              'row justify-content-between pt-2 py-2 ' +
              (chooseService && 'align-items-center border-bottom border-dark-grey') +
              (chooseService && type === Constants.contractModalType.parent && 'pb-4 ') +
              (parentData &&
              parentData.dueStatus !== Constants.orderItemStatus.pendingTermination &&
              (parentData.selected ||
                (parentData.alreadySubscribed
                  ? parentData.itemStatus === Constants.orderItemStatus.cancelled ||
                    parentData.itemStatus === Constants.orderItemStatus.terminated
                    ? false
                    : true
                  : false))
                ? ' parent-selected'
                : type !== Constants.contractModalType.parent && chooseService && ' parent-not-selected')
            }
          >
            <div className="col">
              <div className={'px-2 card-title pt-0 m-0 ' + (!chooseService ? 'pt-1' : 'shadow-none')}>
                {items.status === Constants.orderItemStatus.registered && !items.dueStatus && (
                  <img
                    className="mr-2 mb-1 ml-0"
                    src={Constants.icons.pendingActivation}
                    width="24"
                    height="24"
                    alt="activated-icon"
                  />
                )}
                {items.status === Constants.orderItemStatus.terminated && !items.dueStatus && (
                  <img
                    className="mr-2 mb-1 ml-1"
                    src={Constants.icons.crossFilled}
                    width="16"
                    height="16"
                    alt="terminated-icon"
                  />
                )}
                {items.status === Constants.orderItemStatus.activated && !items.dueStatus && (
                  <img
                    className="mr-2 mb-1 ml-1"
                    src={Constants.icons.tickFilled}
                    width="19"
                    height="19"
                    alt="activated-icon"
                  />
                )}

                {items.status === Constants.orderItemStatus.cancelled && !items.dueStatus && (
                  <img
                    className="mr-2 mb-1 ml-1"
                    src={Constants.icons.crossFilled}
                    width="16"
                    height="16"
                    alt="terminated-icon"
                  />
                )}
                {items.dueStatus === Constants.orderItemStatus.pendingActivation && (
                  <img className="mr-2 mb-1" src={Constants.icons.pendingActivation} alt="pending activation" />
                )}
                {}
                {items.dueStatus === Constants.orderItemStatus.pendingTermination && (
                  <img className="mr-2 mb-1" src={Constants.icons.updatePending} alt="pending termination" />
                )}
                <h3
                  className={
                    'd-inline-block m-0 mr-2 contract-service-title ' +
                    (!chooseService ? 'pb-0' : '') +
                    (type !== Constants.contractModalType.parent ? ' fs-16' : '')
                  }
                >
                  {chooseService ? items.description : items.name}
                </h3>

                <span className="font-weight-normal black fs-16">
                  {type === Constants.contractModalType.parent && (
                    <>
                      {'  | '}
                      <FormattedMessage id="contract_service_base" />
                    </>
                  )}
                </span>
                {chooseService && (
                  <>
                    {items.itemStatus === Constants.orderItemStatus.terminated && (
                      <>
                        {items.dueStatus && (
                          <span className="ml-4 pl-2 font-weight-light">
                            (<FormattedMessage id="contract_activation_due" />{' '}
                            {moment(
                              items.dueStatus === Constants.orderItemStatus.pendingTermination &&
                                String(items.activationDate).slice(0, 10)
                            ).format('DD-MM-YYYY')}
                            )
                          </span>
                        )}
                      </>
                    )}
                    {items.itemStatus === Constants.orderItemStatus.registered && (
                      <>
                        {items.dueStatus && (
                          <span className="ml-4 pl-2 font-weight-light">
                            (<FormattedMessage id="contract_activation_due" />{' '}
                            {moment(
                              items.dueStatus === Constants.orderItemStatus.pendingTermination &&
                                String(items.activationDate).slice(0, 10)
                            ).format('DD-MM-YYYY')}
                            )
                          </span>
                        )}
                      </>
                    )}
                    {items.itemStatus === Constants.orderItemStatus.activated && (
                      <>
                        {items.dueStatus && (
                          <span className="ml-4 pl-2 font-weight-light">
                            (<FormattedMessage id="contract_termination_due" />{' '}
                            {moment(String(items.terminationDate).slice(0, 10)).format('DD-MM-YYYY')})
                          </span>
                        )}
                      </>
                    )}
                    {items.itemStatus === Constants.orderItemStatus.cancelled && (
                      <>
                        {items.dueStatus && (
                          <span className="ml-4 pl-2 font-weight-light">
                            (<FormattedMessage id="contract_activation_due" />{' '}
                            {moment(
                              items.dueStatus === Constants.orderItemStatus.pendingTermination &&
                                String(items.activationDate).slice(0, 10)
                            ).format('DD-MM-YYYY')}
                            )
                          </span>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {chooseService && (
              <div className="col-auto pb-3">
                <div className="d-inline-block price-edit-holder">
                  {items.priceChange ? (
                    <>
                      <PriceEditable
                        text={chooseService ? items.currentCost : items.price}
                        onChange={value => {
                          onPriceChange(value, items.inventoryNumber);
                        }}
                        labelClassName="ItemPriceEditable"
                        inputClassName="ItemPriceEditable"
                        inputWidth="100px"
                        inputHeight="25px"
                        inputMaxLength="50"
                      />
                      <div className="d-inline-block">
                        ,- /
                        <FormattedMessage id="common_per_month" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-inline-block">
                        {chooseService ? items.currentCost : items.price},- /
                        <FormattedMessage id="common_per_month" />
                      </div>
                    </>
                  )}
                </div>
                {(isSuperAdmin || isAdminBackOffice) && items && items.selected ? (
                  <img
                    className={'mx-2 cursor-pointer opacity-50 ' + (items.priceChange && 'pb-1')}
                    alt=""
                    src={items.priceChange ? Constants.icons.tickBlack : Constants.icons.translateBlack}
                    width="16"
                    height="25" //onClick={onClickActionButtons}
                    onClick={changePriceOfService.bind(this, items.inventoryNumber)}
                  />
                ) : (
                  <div className="edit-price-upgrade d-inline-block px-3" />
                )}
              </div>
            )}
            {(isSuperAdmin || isAdminBackOffice) &&
              !chooseService &&
              items.status !== Constants.orderItemStatus.terminated && (
                <>
                  {' '}
                  <div
                    className="mr-4 pr-1 font-weight-bold cursor-pointer"
                    onClick={handleOpenButtonMenu.bind(this, items.inventoryNumber)}
                  >
                    ...
                  </div>
                  {openButtonMenu.open && openButtonMenu.inventoryNumber === items.inventoryNumber && (
                    <div
                      ref={optionRef => {
                        this.handleOptions = optionRef;
                      }}
                      className={
                        'menuPopup contract-service-card-button ' +
                        (type !== Constants.contractModalType.parent && ' col-auto px-0')
                      }
                    >
                      {(isSuperAdmin || isAdminBackOffice) &&
                        items.status !== Constants.orderItemStatus.terminated &&
                        items.status !== Constants.orderItemStatus.cancelled && (
                          <>
                            <div
                              className="mx-2 contracts-action-button "
                              data-toggle="modal"
                              data-target={'#commonForAllModal'}
                              onClick={() => {
                                onClickActionButtons();
                                handleOpenButtonMenu();
                                contractModalTypeSelection({ contractModalType: { editPrice: true } });
                                contractModalDataItems({ contractSubItem: items });
                              }}
                            >
                              <FormattedMessage id="contract_modal_head_edit_price" />
                            </div>
                          </>
                        )}
                      {(isSuperAdmin || isAdminBackOffice) && items.status === Constants.orderItemStatus.activated && (
                        <>
                          <div
                            className="mx-2 contracts-action-button px-1"
                            data-toggle="modal"
                            data-target={'#commonForAllModal'}
                            onClick={() => {
                              onClickActionButtons();
                              handleOpenButtonMenu();
                              contractModalTypeSelection({ contractModalType: { editBillingPeriod: true } });
                              contractModalDataItems({ contractSubItem: items });
                            }}
                          >
                            <FormattedMessage id="contract_modal_head_edit_billing_period" />
                          </div>
                        </>
                      )}
                      {items.status === Constants.orderItemStatus.activated &&
                        items.dueStatus === Constants.orderItemStatus.pendingTermination && (
                          <>
                            {(type === Constants.contractModalType.parent ||
                              (parentData.status === Constants.orderItemStatus.activated &&
                                parentData.dueStatus !== Constants.orderItemStatus.pendingTermination)) && (
                              <>
                                <ContractsActionButtons
                                  type={Constants.contractModalType.editTerminate}
                                  data={items}
                                  onClickActionButtons={onClickActionButtons}
                                  handleOpenButtonMenu={handleOpenButtonMenu}
                                />

                                <ContractsActionButtons
                                  type={Constants.contractModalType.cancel}
                                  data={items}
                                  onClickActionButtons={onClickActionButtons}
                                  handleOpenButtonMenu={handleOpenButtonMenu}
                                />
                              </>
                            )}
                          </>
                        )}
                      {(items.status === Constants.orderItemStatus.terminated ||
                        items.status === Constants.orderItemStatus.registered) &&
                        items.dueStatus === Constants.orderItemStatus.pendingActivation && (
                          <>
                            {(type === Constants.contractModalType.parent ||
                              items.dueStatus === Constants.orderItemStatus.pendingActivation) && (
                              <>
                                <ContractsActionButtons
                                  type={Constants.contractModalType.editActivate}
                                  data={items}
                                  onClickActionButtons={onClickActionButtons}
                                  handleOpenButtonMenu={handleOpenButtonMenu}
                                />

                                <ContractsActionButtons
                                  type={Constants.contractModalType.cancel}
                                  data={items}
                                  onClickActionButtons={onClickActionButtons}
                                  handleOpenButtonMenu={handleOpenButtonMenu}
                                />
                              </>
                            )}
                          </>
                        )}
                      {items.status === Constants.orderItemStatus.activated && !items.dueStatus && (
                        <>
                          <ContractsActionButtons
                            type={Constants.contractModalType.terminate}
                            data={items}
                            onClickActionButtons={onClickActionButtons}
                            handleOpenButtonMenu={handleOpenButtonMenu}
                          />
                        </>
                      )}
                      {items.status === Constants.orderItemStatus.cancelled &&
                        items.dueStatus === Constants.orderItemStatus.pendingTermination && (
                          <>
                            <ContractsActionButtons
                              type={Constants.contractModalType.editActivate}
                              data={items}
                              onClickActionButtons={onClickActionButtons}
                              handleOpenButtonMenu={handleOpenButtonMenu}
                            />
                            <ContractsActionButtons
                              type={Constants.contractModalType.cancel}
                              data={items}
                              onClickActionButtons={onClickActionButtons}
                              handleOpenButtonMenu={handleOpenButtonMenu}
                            />
                          </>
                        )}
                      {items.status === Constants.orderItemStatus.registered && !items.dueStatus && (
                        <>
                          {(type === Constants.contractModalType.parent ||
                            (parentData.status === Constants.orderItemStatus.activated &&
                              parentData.dueStatus !== Constants.orderItemStatus.pendingTermination)) && (
                            <>
                              <ContractsActionButtons
                                type={Constants.contractModalType.activate}
                                data={items}
                                onClickActionButtons={onClickActionButtons}
                                handleOpenButtonMenu={handleOpenButtonMenu}
                              />
                              <ContractsActionButtons
                                type={Constants.contractModalType.cancel}
                                data={items}
                                onClickActionButtons={onClickActionButtons}
                                handleOpenButtonMenu={handleOpenButtonMenu}
                              />
                            </>
                          )}
                          {type === Constants.contractModalType.child &&
                            items.status === Constants.orderItemStatus.registered &&
                            parentData.status === Constants.orderItemStatus.registered && (
                              <ContractsActionButtons
                                type={Constants.contractModalType.cancel}
                                data={items}
                                onClickActionButtons={onClickActionButtons}
                                handleOpenButtonMenu={handleOpenButtonMenu}
                              />
                            )}
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            {chooseService && (
              <div className="col-auto pb-3">
                {(parentData &&
                  (parentData.selected ||
                    (parentData.alreadySubscribed
                      ? parentData.itemStatus === Constants.orderItemStatus.cancelled ||
                        parentData.itemStatus === Constants.orderItemStatus.terminated
                        ? false
                        : true
                      : false))) ||
                type === Constants.contractModalType.parent ? (
                  <>
                    {items.alreadySubscribed ? (
                      items.itemStatus === Constants.orderItemStatus.cancelled ||
                      items.itemStatus === Constants.orderItemStatus.terminated ? (
                        items.selected ? (
                          <ContractsActionButtons
                            selected
                            data={items}
                            onItemClick={onItemClick}
                            handleOpenButtonMenu={handleOpenButtonMenu}
                          />
                        ) : (
                          <ContractsActionButtons
                            select
                            data={items}
                            onItemClick={onItemClick}
                            handleOpenButtonMenu={handleOpenButtonMenu}
                          />
                        )
                      ) : items.itemStatus === Constants.orderItemStatus.activated ? (
                        <div className="contracts-action-button bg-transparent px-0 pointer-none">
                          <FormattedMessage id="all_products_status_active" />
                        </div>
                      ) : (
                        items.itemStatus === Constants.orderItemStatus.registered && (
                          <div className="contracts-action-button bg-transparent px-0 pointer-none">
                            <FormattedMessage id="common_ordered" />
                          </div>
                        )
                      )
                    ) : items.selected ? (
                      <ContractsActionButtons
                        selected
                        data={items}
                        onItemClick={onItemClick}
                        handleOpenButtonMenu={handleOpenButtonMenu}
                      />
                    ) : (
                      <ContractsActionButtons
                        select
                        data={items}
                        onItemClick={onItemClick}
                        handleOpenButtonMenu={handleOpenButtonMenu}
                      />
                    )}
                  </>
                ) : (
                  <ContractsActionButtons select data={items} handleOpenButtonMenu={handleOpenButtonMenu} />
                )}
              </div>
            )}
          </div>
          {!chooseService && (
            <>
              <div className="row justify-content-between pb-2">
                <div className="col">
                  <div
                    className="d-inline-block cursor-pointer collapsed pl-2"
                    data-toggle="collapse"
                    data-target={'#services' + items.inventoryNumber}
                  >
                    <img
                      className={'ml-2 arrow-expand'}
                      src={Constants.icons.accArrow}
                      width="15"
                      height="15"
                      alt="arrow-icon"
                    />
                  </div>
                  <p className="font-weight-normal black d-inline-block mr-3 px-2 fs-16 mb-2 pb-1">
                    {items.status === Constants.orderItemStatus.terminated && (
                      <>
                        {/* <img
                        className="mr-2"
                        src={Constants.icons.terminated}
                        width="18"
                        height="18"
                        alt="terminated-icon"
                      /> */}
                        <FormattedMessage id="contract_details_terminated_since" />{' '}
                        {moment(items.terminationDate && String(items.terminationDate).slice(0, 10)).format(
                          'DD-MM-YYYY'
                        )}
                        {items.dueStatus && (
                          <span className="ml-4 pl-2 font-weight-light">
                            (<FormattedMessage id="contract_activation_due" />{' '}
                            {moment(
                              items.dueStatus === Constants.orderItemStatus.pendingTermination &&
                                String(items.activationDate).slice(0, 10)
                            ).format('DD-MM-YYYY')}
                            )
                          </span>
                        )}
                      </>
                    )}
                    {items.status === Constants.orderItemStatus.registered && (
                      <>
                        {' '}
                        <FormattedMessage id="contract_details_registered_since" />{' '}
                        {moment(items.registeredDate && String(items.registeredDate).slice(0, 10)).format('DD-MM-YYYY')}
                        {items.dueStatus && (
                          <span className="ml-4 pl-2 font-weight-light">
                            (<FormattedMessage id="contract_activation_due" />{' '}
                            {moment(
                              items.dueStatus === Constants.orderItemStatus.pendingActivation &&
                                String(items.activationDate).slice(0, 10)
                            ).format('DD-MM-YYYY')}
                            )
                          </span>
                        )}
                      </>
                    )}

                    {items.status === Constants.orderItemStatus.activated && (
                      <>
                        {/* <img
                        className="mr-2"
                        src={Constants.icons.activated}
                        width="18"
                        height="18"
                        alt="activated-icon"
                      /> */}
                        <FormattedMessage id="contract_details_activated_since" />{' '}
                        {moment(items.activationDate && String(items.activationDate).slice(0, 10)).format('DD-MM-YYYY')}
                        {items.dueStatus && (
                          <span className="ml-4 pl-2 font-weight-light">
                            (<FormattedMessage id="contract_termination_due" />{' '}
                            {moment(String(items.terminationDate).slice(0, 10)).format('DD-MM-YYYY')})
                          </span>
                        )}
                      </>
                    )}
                    {items.status === Constants.orderItemStatus.cancelled && (
                      <>
                        {/* <img
                        className="mr-2"
                        src={Constants.icons.terminated}
                        width="18"
                        height="18"
                        alt="terminated-icon"
                      /> */}
                        <FormattedMessage id="contract_cancelled_since" />{' '}
                        {moment(items.cancellationDate && String(items.cancellationDate).slice(0, 10)).format(
                          'DD-MM-YYYY'
                        )}
                        {items.dueStatus && (
                          <span className="ml-4 pl-2 font-weight-light">
                            (<FormattedMessage id="contract_activation_due" />{' '}
                            {moment(
                              items.dueStatus === Constants.orderItemStatus.pendingTermination &&
                                String(items.activationDate).slice(0, 10)
                            ).format('DD-MM-YYYY')}
                            )
                          </span>
                        )}
                      </>
                    )}
                  </p>
                </div>
                <div className="font-weight-semibold service-price mr-4 pr-1">
                  {items.priceChage ? (
                    <PriceEditable
                      text={items.price}
                      onChange={value => {
                        console.log(value);
                      }}
                      labelClassName="ItemPriceEditable"
                      inputClassName="ItemPriceEditable"
                      inputWidth="80px"
                      inputHeight="25px"
                      inputMaxLength="50"
                    />
                  ) : (
                    <>
                      {items.price},- /
                      <FormattedMessage id="common_per_month" />
                    </>
                  )}
                  {/* {(isSuperAdmin || isAdminBackOffice) &&
                  items.status !== Constants.orderItemStatus.terminated &&
                  items.status !== Constants.orderItemStatus.cancelled && (
                    <>
                      <img
                        className="mx-2 cursor-pointer opacity-50"
                        alt=""
                        src={Constants.icons.translateBlack}
                        width="16"
                        height="14"
                        data-toggle="modal"
                        data-target={'#commonForAllModal'}
                        onClick={() => {
                          onClickActionButtons();
                          contractModalTypeSelection({
                            contractModalType: {
                              editPrice: true,
                            },
                          });
                          contractModalDataItems({ contractSubItem: items });
                        }}
                      />
                    </>
                  )} */}
                </div>
              </div>
            </>
          )}
          <div className="row collapse bg-white-1 pl-0 ml-0 mr-1 mb-2 " id={'services' + items.inventoryNumber}>
            <div className="col-4 px-4 font-weight-light black ml-0">
              {/* {items.activities &&
              items.activities.map(recentActivity => (
                <p className="m-2 font-weight-semibold fs-14">
                  {'activation' === recentActivity.changeType && <FormattedMessage id="contract_details_activated_since" />}
                  {'termination' === recentActivity.changeType && <FormattedMessage id="contract_details_terminated_since" />}
                  {'cancellation' === recentActivity.changeType && <FormattedMessage id="contract_cancelled_since" />}
                  {'registration' === recentActivity.changeType && (
                    <FormattedMessage id="contract_details_registered_since" />
                  )}{' '}
                  {': '}
                  <span className="font-weight-normal fs-14 ml-2">
                    {moment(
                      recentActivity.change &&
                        recentActivity.change[0] &&
                        String(recentActivity.change[0].after).slice(0, 10)
                    ).format('DD-MM-YYYY')}
                  </span>
                </p>
              ))} */}

              {moment(String(items.registeredDate).slice(0, 10)).format('DD/MM/YYYY') !== 'Invalid date' && (
                <p className="row m-2 font-weight-semibold fs-14">
                  <div className="col p-0">
                    <FormattedMessage id="contract_details_registered_since" />:
                  </div>
                  <div className="col p-0">
                    <span className="font-weight-normal">
                      {moment(String(items.registeredDate).slice(0, 10)).format('DD-MM-YYYY')}
                    </span>
                  </div>
                </p>
              )}
              {moment(String(items.activationDate).slice(0, 10)).format('DD/MM/YYYY') !== 'Invalid date' && (
                <p className="row m-2 font-weight-semibold fs-14">
                  <div className="col p-0">
                    <FormattedMessage id="contract_details_activated_since" />:
                  </div>
                  <div className="col p-0">
                    <span className="font-weight-normal">
                      {moment(String(items.activationDate).slice(0, 10)).format('DD-MM-YYYY')}
                    </span>
                  </div>
                </p>
              )}
              {moment(String(items.terminationDate).slice(0, 10)).format('DD/MM/YYYY') !== 'Invalid date' && (
                <p className="row m-2 font-weight-semibold fs-14">
                  <div className="col p-0">
                    <FormattedMessage id="contract_details_terminated_since" />:
                  </div>
                  <div className="col p-0">
                    <span className="font-weight-normal">
                      {moment(String(items.terminationDate).slice(0, 10)).format('DD-MM-YYYY')}
                    </span>
                  </div>
                </p>
              )}
              {moment(String(items.cancellationDate).slice(0, 10)).format('DD/MM/YYYY') !== 'Invalid date' && (
                <p className="row m-2 font-weight-semibold fs-14">
                  <div className="col p-0">
                    <FormattedMessage id="contract_cancelled_since" />:
                  </div>
                  <div className="col p-0">
                    <span className="font-weight-normal">
                      {moment(String(items.cancellationDate).slice(0, 10)).format('DD-MM-YYYY')}
                    </span>
                  </div>
                </p>
              )}
            </div>
            <div className="col-4 pr-5 ">
              <div className="">
                <p className="row m-2 font-weight-semibold fs-14">
                  <div className="col p-0">
                    <FormattedMessage id="contract_last_billed_until" />:
                  </div>
                  <div className="col p-0">
                    <span className="font-weight-normal fs-14 ml-0">
                      {items.lastBilledDate ? items.lastBilledDate.slice(0, 10) : 'N/A'}
                    </span>
                  </div>
                </p>
              </div>
              <div className="">
                <p className="row m-2 font-weight-semibold fs-14">
                  <div className="col p-0">
                    <FormattedMessage id="contract_next_bill" />:
                  </div>
                  <div className="col p-0">
                    <span className="font-weight-normal fs-14 ml-0">
                      {items.nextBillingDate ? items.nextBillingDate.slice(0, 10) : 'N/A'}
                    </span>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RenderServiceCardElements;
