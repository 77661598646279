import React, { Component } from 'react';
import Constants from '../../shared/constants';
import { FormattedMessage } from 'react-intl';
import PopUpClickable from '../PopUpClickable/PopUpClickable';
import ServiceModal from '../Services/ServiceModal';
import { removeHTMLTags } from '../../shared/utils';
import renderHTML from 'react-render-html';
class ServiceCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: 0,
      serviceModalShow: false,
      serviceData: {},
    };
    const {
      parentServiceData,
      onServiceSelect,
      onServiceRemove,
      forProducts,
      onPopUpClick,
      onServiceToggle,
      openCreateservicemodal,
      createServiceModalClose,
      isSuperAdminUser,
      isAdminBackOffice,
    } = this.props;
  }

  isPendingTermination({ service }) {
    return service.dueStatus === Constants.orderItemStatus.pendingTermination;
  }
  isregistered({ service }) {
    return service.itemStatus === Constants.orderItemStatus.registered;
  }
  handleCustomerModalClose = () => {
    this.setState({ serviceModalShow: false });
  };
  render() {
    const { price } = this.state;
    const {
      parentServiceData,
      onServiceSelect,
      onServiceRemove,
      forProducts,
      onPopUpClick,
      onServiceToggle,
      openCreateservicemodal,
      createServiceModalClose,
      editServiceModal,
      isAdminBackOffice,
      isSuperAdminUser,
    } = this.props;

    const { serviceModalShow, serviceData } = this.state;
    return (
      <React.Fragment>
        <div id="service-card" className="row no-gutters">
          {parentServiceData.length > 0 &&
            parentServiceData.map((service, index) => (
              <React.Fragment key={index}>
                <div className={'mb-3 col-12 '} key={service.inventoryId}>
                  <div
                    className={
                      'bg-white card-rounded ' +
                      (service.selected ? ' card-selected border-secondary' : '') +
                      (service.alreadySubscribed
                        ? ' card-selected list-view-upgrade-subscription already-subscribed'
                        : ' list-view-card-body') +
                      (this.isPendingTermination({ service }) ? ' pending-terminated-subscription' : '')
                    }
                  >
                    <div
                      className={
                        'row ' +
                        (service.alreadySubscribed &&
                        !this.isPendingTermination({
                          service,
                        })
                          ? ' px-3 pt-4 mb-2 '
                          : this.isPendingTermination({
                              service,
                            }) && 'px-3 pt-4 mb-2 pending-termination-border')
                      }
                    >
                      <div className={'col-9 pt-1 '}>
                        <h3 className="font-weight-semibold-black mb-3">
                          <PopUpClickable
                            type="info"
                            onClick={onPopUpClick.bind(this, {
                              service,
                            })}
                            infoData={{
                              title: service.description,
                              subTitle: Constants.language.contract_service_base,
                              body: service.postingClass && service.postingClass.description,
                              brief: service.serviceBrief ? renderHTML(service.serviceBrief) : '',
                            }}
                            isOpen={service.isInfoOpened}
                          />
                          <span className="font-weight-semibold color-1d1d1d">{service.description}</span>
                        </h3>
                        <ul
                          className={
                            'service-desc-list mb-3 ml-1 pl-1 ' +
                            (service.alreadySubscribed ? 'font-weight-semibold' : '')
                          }
                        >
                          {service.servicePoints &&
                            service.servicePoints.length > 0 &&
                            service.servicePoints.map((obj, index) => <li key={index}>{obj.value || obj}</li>)}
                        </ul>
                      </div>
                      <div className="col px-0 text-right content-justify-end">
                        <div className="row text-right justify-content-end ">
                          {!forProducts && (
                            <div className="col text-right pr-4 mr-2">
                              <h3 className="mb-2 pb-1 ">
                                <span>
                                  {service && service.totalSubPrice
                                    ? service.totalSubPrice === price
                                      ? price
                                      : Number(service.totalSubPrice).toFixed(2)
                                    : service.defaultPrice === price
                                    ? price
                                    : Number(service.defaultPrice).toFixed(2)}
                                  {Constants.currency.norway}
                                </span>
                                <span className="fs-14 pt-2 pl-1 mt-auto font-weight-normal black">
                                  {' /'}
                                  <FormattedMessage id="common_per_month" />
                                </span>
                              </h3>

                              {service.alreadySubscribed && (
                                <h6 className="mt-4">
                                  <span
                                    className={
                                      this.isPendingTermination({
                                        service,
                                      })
                                        ? 'abonnement-aktiv w-auto color-red'
                                        : this.isregistered({
                                            service,
                                          })
                                        ? ' w-auto'
                                        : 'abonement-aktiv  w-auto'
                                    }
                                  >
                                    <img
                                      src={
                                        this.isPendingTermination({
                                          service,
                                        })
                                          ? Constants.icons.terminateIcon
                                          : this.isregistered({
                                              service,
                                            })
                                          ? Constants.icons.pendingActivation
                                          : Constants.icons.tickIconGreen
                                      }
                                      width={
                                        this.isPendingTermination({ service })
                                          ? '20'
                                          : this.isregistered({
                                              service,
                                            })
                                          ? '22'
                                          : '18'
                                      }
                                      height={
                                        this.isPendingTermination({
                                          service,
                                        })
                                          ? '20'
                                          : this.isregistered({
                                              service,
                                            })
                                          ? '22'
                                          : '18'
                                      }
                                      alt="icon"
                                      color="black"
                                      className={this.isPendingTermination ? 'opacity-80 mr-2 mb-1' : 'mr-2 mb-1'}
                                    />
                                    <span>
                                      {this.isPendingTermination({ service }) ? (
                                        <FormattedMessage id="contract_under_termination" />
                                      ) : this.isregistered({ service }) ? (
                                        <FormattedMessage id="contract_registered_subscription" />
                                      ) : (
                                        <FormattedMessage id="contract_active_subscription" />
                                      )}
                                    </span>
                                  </span>
                                </h6>
                              )}
                              {!forProducts && !service.alreadySubscribed && (
                                <div className="float-right pr-0">
                                  <button
                                    onClick={
                                      !service.selected
                                        ? onServiceSelect.bind(this, service)
                                        : onServiceRemove.bind(this, service)
                                    }
                                    className={
                                      (service.selected ? 'selected btn-primary  ' : 'btn-secondary ') +
                                      ' btn  py-1 px-5 btn-subscription-parent'
                                    }
                                  >
                                    <span className="fs-13.8">
                                      {service.selected ? (
                                        <FormattedMessage id="new_service_selected" />
                                      ) : (
                                        <FormattedMessage id="contract_button_select_service" />
                                      )}
                                    </span>
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                          {forProducts && (
                            <div className=" text-right pr-4">
                              <h3 className="mb-2 pb-1 ">
                                <span>
                                  {service && service.totalSubPrice
                                    ? service.totalSubPrice === this.state.price
                                      ? 0
                                      : Number(service.totalSubPrice).toFixed(1)
                                    : service.defaultPrice === this.state.price
                                    ? 0
                                    : Number(service.defaultPrice).toFixed(2)}
                                  {Constants.currency.norway}
                                </span>
                                <span className="fs-14 pt-2 pl-1 mt-auto font-weight-normal black">
                                  {' /'}
                                  <FormattedMessage id="common_per_month" />
                                </span>
                              </h3>

                              {service.alreadySubscribed && (
                                <h6 className="mt-4">
                                  <img
                                    src={
                                      this.isPendingTermination({
                                        service,
                                      })
                                        ? Constants.icons.pendingIcon
                                        : Constants.icons.tickBlack
                                    }
                                    width={this.isPendingTermination({ service }) ? '24' : '15'}
                                    height={
                                      this.isPendingTermination({
                                        service,
                                      })
                                        ? '24'
                                        : '15'
                                    }
                                    alt="icon"
                                    className={this.isPendingTermination ? ' opacity-80 mr-2 mb-1' : 'mr-2 mb-1'}
                                  />
                                  <span>
                                    {this.isPendingTermination({ service }) ? (
                                      <FormattedMessage id="contract_under_termination" />
                                    ) : (
                                      <FormattedMessage id="contract_active_subscription" />
                                    )}
                                  </span>
                                </h6>
                              )}
                            </div>
                          )}
                          {forProducts && (isSuperAdminUser || isAdminBackOffice) && (
                            <div className=" p-0 text-right">
                              <a
                                href="#"
                                role="button"
                                data-toggle="modal"
                                data-target="#servicemodal"
                                ref={openModal => (this.openElement = openModal)}
                                onClick={editServiceModal.bind(this, service)}
                                className="fs-14 text-align-center col-3 pl-0 pr-4 mr-1"
                              >
                                <span className="redigere">
                                  <u>
                                    <FormattedMessage id="contract_button_edit_service" />
                                  </u>
                                </span>
                                <img
                                  alt=""
                                  src={Constants.icons.edit}
                                  width="14"
                                  height="14"
                                  className="ml-2 opacity-70"
                                />
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={service.alreadySubscribed ? 'px-4 pb-0' : 'pb-0 pl-0 pr-3'}>
                      {service.children.length > 0 ? (
                        <div
                          data-toggle="collapse"
                          className={
                            'row pb-2 pl-0 ml-0 pr-0 text-left cursor-pointer ' +
                            (service.alreadySubscribed ? 'pl-0' : 'pl-0 ml-0')
                          }
                          href={'#collapseExample' + service.inventoryId}
                          aria-expanded="false"
                          onClick={onServiceToggle.bind(this, {
                            serviceData: service,
                          })}
                        >
                          <img
                            src={Constants.icons.accArrow}
                            width="20"
                            height="18"
                            className={
                              'm-1 p-1 arrow-expand ' +
                              ((service.selected || service.alreadySubscribed) &&
                              !this.isPendingTermination({ service })
                                ? ''
                                : !forProducts && 'opacity-50')
                            }
                            alt="icon"
                          />
                          <span
                            className={
                              'fs-14 font-weight-semibold ml-2 ' +
                              ((service.selected || service.alreadySubscribed) &&
                              !this.isPendingTermination({ service })
                                ? ''
                                : !forProducts && 'grey')
                            }
                          >
                            <FormattedMessage
                              id="new_service_card_extra_service"
                              values={{ service: service.description }}
                            />
                          </span>
                        </div>
                      ) : (
                        <div className="row pb-1">
                          <div className="ml-0 pr-0 text-left pl-4">
                            <span className={'fs-14 font-weight-semibold'}>
                              <FormattedMessage id="contract_no_addon_now" />
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="collapse row show" id={'collapseExample' + service.inventoryId}>
                        <div className="w-100 service-addons">
                          {service.children.map((child, index) => (
                            <React.Fragment key={index}>
                              <div
                                className={
                                  'row no-gutters pb-1 border-bottom  py-2 ' +
                                  (service.children.length - 1 === index ? ' mb-0 border-0 ' : '') +
                                  (service.alreadySubscribed ? ' pl-2 ml-1' : ' ml-3')
                                }
                                key={child.inventoryId}
                              >
                                {
                                  <>
                                    <div
                                      className="col-8 text-left px-2 align-self-center collapsed font-weight-semibold"
                                      data-toggle="collapse"
                                      data-target={'#child' + child.inventoryId}
                                    >
                                      <PopUpClickable
                                        type="info"
                                        isChildService
                                        onClick={onPopUpClick.bind(this, {
                                          service: child,
                                        })}
                                        infoData={{
                                          title: child.description,
                                          subTitle: Constants.language.contract_service_add_on,
                                          body: child.postingClass && child.postingClass.description,
                                          brief: child.serviceBrief ? renderHTML(child.serviceBrief) : '',
                                        }}
                                        isOpen={child.isInfoOpened}
                                      />
                                      <p
                                        className={
                                          'font-weight-normal d-inline-block mb-0 line-height-20 ls-0-25 ' +
                                          ((service.selected || service.alreadySubscribed) &&
                                          !this.isPendingTermination({ service })
                                            ? ''
                                            : !forProducts && 'grey')
                                        }
                                      >
                                        {child.description}
                                      </p>
                                    </div>
                                    <div className={forProducts ? 'col px-0 text-right' : 'col px-4 text-right'}>
                                      <span
                                        className={
                                          'font-weight-normal fs-14 ' +
                                          ((service.selected || service.alreadySubscribed) &&
                                          !this.isPendingTermination({ service })
                                            ? ''
                                            : !forProducts && 'grey')
                                        }
                                      >
                                        {child.defaultPrice === this.state.price
                                          ? this.state.price
                                          : Number(child.defaultPrice).toFixed(2)}
                                        {Constants.currency.norway}
                                        {' /'}
                                        <FormattedMessage id="common_per_month" />
                                      </span>
                                      {forProducts && (isSuperAdminUser || isAdminBackOffice) && (
                                        <a
                                          href="#"
                                          role="button"
                                          data-toggle="modal"
                                          data-target="#servicemodal"
                                          ref={openModal => (this.openElement = openModal)}
                                          onClick={forProducts && editServiceModal.bind(this, child)}
                                          className="fs-14 text-align-center col-3 pl-4 pr-0"
                                        >
                                          <span className="redigere">
                                            <u>
                                              <FormattedMessage id="contract_button_edit_service" />
                                            </u>
                                          </span>

                                          <img
                                            alt=""
                                            src={Constants.icons.edit}
                                            width="14"
                                            height="14"
                                            className="ml-2 opacity-70"
                                          />
                                        </a>
                                      )}
                                    </div>
                                    {/* </div> */}
                                    {!forProducts && (
                                      <div className="col text-center subscription-child-btn-col font-weight-semibold">
                                        <div className="">
                                          {child.alreadySubscribed ? (
                                            <>
                                              {child.itemStatus === Constants.orderItemStatus.registered && (
                                                <span className="text-black">
                                                  <FormattedMessage id="common_ordered" />
                                                </span>
                                              )}
                                              {child.itemStatus === Constants.orderItemStatus.activated && (
                                                <span className="green">
                                                  <FormattedMessage id="all_products_status_active" />
                                                </span>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <button
                                                className={
                                                  (child.selected ? 'btn-primary ' : 'btn-secondary ') +
                                                  ' btn  py-1 b-width-1 '
                                                }
                                                onClick={
                                                  child.selected
                                                    ? onServiceRemove.bind(this, child)
                                                    : onServiceSelect.bind(this, child)
                                                }
                                                disabled={
                                                  !(
                                                    (service.selected || service.alreadySubscribed) &&
                                                    !this.isPendingTermination({
                                                      service,
                                                    })
                                                  )
                                                }
                                              >
                                                <div className="fs-14 font-weight-semibold">
                                                  {child.selected ? (
                                                    <FormattedMessage id="new_service_selected" />
                                                  ) : (
                                                    <FormattedMessage id="contract_button_select_service" />
                                                  )}
                                                </div>
                                              </button>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                }
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
        </div>
        {serviceModalShow && (
          <ServiceModal serviceModalShow={serviceModalShow} customerModalClose={this.handleCustomerModalClose} />
        )}
      </React.Fragment>
    );
  }
}

export default ServiceCards;
