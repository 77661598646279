import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import CustomerService from '../../../../services/CustomerService';
import AuthService from '../../../../services/AuthService';
import Config from '../../../../config';
import Constants from '../../../../shared/constants';
import Pagination from '../../../Pagination/index';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import ListViewCard from '../../../ContractDetails/Components/ListView/ListViewCard';
import BaseComponent from '../../../BaseComponent';
import { Link } from 'react-router-dom';
import { AllOrderListView } from '../../../AllOrders';
import OrderService from '../../../../services/OrderService';

class CustomerDetailViewTabContent extends BaseComponent {
  customerService = new CustomerService(Config.apiBase);
  authService = new AuthService(Config.apiBase);
  orderService = new OrderService(Config.apiBase);
  isResellerLoggedIn = this.authService.isResellerUser();
  constructor(props) {
    super(props);
    this.state = {
      meta: {
        cursor: {
          previous: null,
          current: 0,
          next: 1,
          count: null,
        },
      },

      searchQuery: '',
      showNext: true,
      message: false,
      sortedBy: this.props.sortedBy,

      sortAscending: false,
      ordersData: [],
      invoiceData: [],
      serviceData: [],
      userData: [],
      customersData: [],
      details: [],
      activityLogsData: [],
      loading: true,
    };
  }
  componentDidMount() {
    const { order, invoice, log, subscriptions, details, customer, user, sortedBy } = this.props;
    if (order) {
      this.setState({ loading: true });
      this.fetchOrders();
    } else if (invoice) {
      this.fetchInvoices();
    } else if (subscriptions) {
      this.fetchSubscriptions();
    } else if (customer) {
      this.fetchCustomers();
    } else if (user) {
      this.fetchUsers();
    } else if (log) {
      this.fetchLog();
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { order, invoice, log, subscriptions, details, customer, user } = this.props;
    if (
      prevState.searchQuery !== this.state.searchQuery ||
      prevState.meta.cursor.pageNumber !== this.state.meta.cursor.pageNumber ||
      prevState.sortedBy !== this.state.sortedBy ||
      prevState.sortAscending !== this.state.sortAscending
    ) {
      if (order) {
        this.fetchOrders();
      } else if (invoice) {
        this.fetchInvoices();
      } else if (subscriptions) {
        this.fetchSubscriptions();
      } else if (customer) {
        this.fetchCustomers();
      } else if (user) {
        this.fetchUsers();
      } else if (log) {
        this.fetchLog();
      }
    }
  }

  gotoPage = pageNumber => {
    this.setState({
      meta: {
        cursor: {
          pageNumber: pageNumber,
        },
      },
    });
  };
  async fetchOrders() {
    const { customerID } = this.props;
    const { sortedBy, sortAscending, searchQuery } = this.state;
    let response;
    this.setState({ loading: true });
    const queryParams = [
      ['name', searchQuery],
      ['pageNumber', this.state.meta.cursor.pageNumber],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
    ];
    try {
      response = await this.customerService.getCustomerOrders(customerID, this.props.reseller, queryParams);

      if (response && response.status === 200) {
        let { meta, status } = response;
        let ordersData;
        ordersData = response.data.data;
        const showNext = this.canShowNext(this.state.ordersData, ordersData, meta.cursor);

        if (ordersData.length > 0) {
          this.setState({
            ordersData,
            meta,
            showNext,
          });
        }
      }
      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  async fetchLog() {
    const { customerID } = this.props;
    const { sortedBy, sortAscending, searchQuery } = this.state;
    let response;
    this.setState({ loading: true });
    const queryParams = [
      ['name', searchQuery],
      ['pageNumber', this.state.meta.cursor.pageNumber],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
    ];
    try {
      response = await this.customerService.getCustomerLog(customerID, queryParams);
      if (response && response.status === 200) {
        let { meta, status } = response;
        let activityLogsData = response.data.data;
        const showNext = this.canShowNext(this.state.activityLogsData, activityLogsData, meta.cursor);
        if (activityLogsData.length > 0) {
          this.setState({ activityLogsData, meta, showNext });
        }
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.error(e);
    }
    this.setState({ loading: false });
  }
  async fetchInvoices() {
    const { customerID } = this.props;
    const { sortedBy, sortAscending, searchQuery } = this.state;
    let response;
    this.setState({ loading: true });
    const queryParams = [
      ['name', searchQuery],
      ['pageNumber', this.state.meta.cursor.pageNumber],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
    ];
    try {
      response = await this.customerService.getCustomerInvoices(customerID, queryParams);
      if (response && response.status === 200) {
        let { meta, status } = response;
        let invoiceData = response.data.data;
        const showNext = this.canShowNext(this.state.invoiceData, invoiceData, meta.cursor);
        if (invoiceData.length > 0) {
          this.setState({ invoiceData, meta, showNext });
        }
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.error(e);
    }

    this.setState({ loading: false });
  }
  async fetchSubscriptions() {
    const { customerID } = this.props;
    const { sortedBy, sortAscending, searchQuery } = this.state;
    let response;
    this.setState({ loading: true });
    const queryParams = [
      ['name', searchQuery],
      ['pageNumber', this.state.meta.cursor.pageNumber],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
    ];
    try {
      response = await this.customerService.getCustomerSubscriptions(customerID, this.props.reseller, queryParams);
      if (response && response.status === 200) {
        let { meta, status } = response;
        let serviceData = response.data.data;
        const showNext = this.canShowNext(this.state.serviceData, serviceData, meta.cursor);
        if (serviceData.length > 0) {
          this.setState({ serviceData, meta, showNext });
        }
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.error(e);
    }

    this.setState({ loading: false });
  }
  async fetchUsers() {
    const { customerID } = this.props;
    const { sortedBy, sortAscending, searchQuery } = this.state;
    let response;
    this.setState({ loading: true });
    const queryParams = [
      ['name', searchQuery],
      ['pageNumber', this.state.meta.cursor.pageNumber],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
    ];
    try {
      response = await this.customerService.getUsers(customerID, queryParams);
      if (response && response.status === 200) {
        let { meta, status } = response;
        let userData;
        userData = response.data.data;
        const showNext = this.canShowNext(this.state.userData, userData, meta.cursor);
        if (userData.length > 0) {
          this.setState({ userData, meta, showNext });
        }
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.error(e);
    }

    this.setState({ loading: false });
  }
  async fetchCustomers() {
    const { customerID } = this.props;
    const { sortedBy, sortAscending, searchQuery } = this.state;
    let response;
    this.setState({ loading: true });
    const queryParams = [
      ['name', searchQuery],
      ['pageNumber', this.state.meta.cursor.pageNumber],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
    ];
    try {
      response = await this.customerService.getCustomers(customerID, queryParams);
      if (response && response.status === 200) {
        let { meta, status } = response;
        let customersData = response.data.data;
        const showNext = this.canShowNext(this.state.customersData, customersData, meta.cursor);
        if (customersData.length > 0) {
          this.setState({ customersData, meta, showNext });
        }
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.error(e);
    }

    this.setState({ loading: false });
  }
  handleSorting = name => {
    if (this.state.sortedBy === name) {
      this.setState({ sortAscending: !this.state.sortAscending });
    } else {
      this.setState({ sortedBy: name, sortAscending: true });
    }
  };

  handleRetryOrder = (orderId) => async () => {
    const { handleToast = () => {} } = this.props;
    this.setState({loading : true });
    try {
      const response = await this.orderService.retryOrder(orderId);
      if(response) {
        this.setState({loading: false});
        handleToast({
          type: Constants.toast.SUCCESS,
          message: <FormattedMessage id="order_action_retry_order_success_message" />,
          delay: Constants.toast.DELAY
        });
        this.fetchOrders();
      }
    } catch (e) {
      this.setState({loading: false});
      handleToast({
        type: Constants.toast.ERROR,
        message: <FormattedMessage id="order_action_retry_order_failed_message" />,
        delay: Constants.toast.DELAY
      });
    }
  } 

  handleDeleteOrder = (orderId) => async () => {
    const { handleToast = () => {} } = this.props;
    this.setState({loading : true });
    try {
      const response = await this.orderService.deleteOrder(orderId);
      if(response) {
        this.setState({loading: false});
        handleToast({
          type: Constants.toast.SUCCESS,
          message: <FormattedMessage id="order_action_delete_order_success_message" />,
          delay: Constants.toast.DELAY
        });
        this.fetchOrders();
      }
    } catch (e) {
      this.setState({loading: false});
      handleToast({
        type: Constants.toast.ERROR,
        message: <FormattedMessage id="order_action_delete_order_failed_message" />,
        delay: Constants.toast.DELAY
      });
    }
  }

  renderOrders = () => {
    const { loading, meta, ordersData, sortAscending, sortedBy } = this.state;
    return (
      <>
        <div className=" ">
          {!loading && ordersData.length > 0 ? (
            <div className="ri-table mb-5">
              <div className="row mt-3 pb-2 fs-14 ls-0-44 mx-0">
                <div
                  className={'col-2 cursor-pointer pr-0'}
                  onClick={this.handleSorting.bind(this, Constants.sortingOptions.orderNo)}
                >
                  <u className={sortedBy === 'orderNo' ? 'font-weight-semibold' : ''}>
                    <FormattedMessage id="common_order_ID" />
                  </u>
                  <img
                    src={Constants.icons.arrowBack}
                    width="18px"
                    height="18px"
                    alt="icon"
                    className={
                      sortedBy === 'orderNo' || sortedBy === 'contractNo'
                        ? sortAscending
                          ? 'arrow-up-sort'
                          : 'arrow-down-sort'
                        : 'd-none'
                    }
                  />
                </div>
                <div
                  className={' col-3 cursor-pointer'}
                  onClick={this.handleSorting.bind(this, Constants.sortingOptions.customerName)}
                >
                  <u className={sortedBy === 'customer.name' ? 'font-weight-semibold' : ''}>
                    <FormattedMessage id="customer_name" />
                  </u>
                  <img
                    src={Constants.icons.arrowBack}
                    width="18px"
                    height="18px"
                    alt="icon"
                    className={
                      sortedBy === 'customer.name' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'
                    }
                  />
                </div>
                <div
                  className={'col-2 cursor-pointer'}
                  onClick={this.handleSorting.bind(this, Constants.sortingOptions.type)}
                >
                  {' '}
                  <u className={sortedBy === 'type' ? 'font-weight-semibold' : ''}>
                    <FormattedMessage id="all_orders_type" />
                  </u>
                  <img
                    src={Constants.icons.arrowBack}
                    width="18px"
                    height="18px"
                    alt="icon"
                    className={
                      sortedBy === 'registeredDate' || sortedBy === 'type'
                        ? sortAscending
                          ? 'arrow-up-sort '
                          : 'arrow-down-sort '
                        : 'd-none'
                    }
                  />
                </div>
                <div
                  className={'col-2 cursor-pointer pr-0'}
                  onClick={this.handleSorting.bind(this, Constants.sortingOptions.date)}
                >
                  {' '}
                  <u className={sortedBy === 'date' ? 'font-weight-semibold' : ''}>
                    <FormattedMessage id="all_orders_order_date" />
                  </u>
                  <img
                    src={Constants.icons.arrowBack}
                    width="18px"
                    height="18px"
                    alt="icon"
                    className={
                      sortedBy === 'activationDate' || sortedBy === 'date'
                        ? sortAscending
                          ? 'arrow-up-sort '
                          : 'arrow-down-sort '
                        : 'd-none'
                    }
                  />
                </div>
                <div
                  className={'col cursor-pointer text-right px-0'}
                  onClick={this.handleSorting.bind(this, Constants.sortingOptions.amount)}
                >
                  <u className={sortedBy === 'orderTotal' ? 'font-weight-semibold' : ''}>
                    <FormattedMessage id="order_amount" />
                  </u>
                  <img
                    src={Constants.icons.arrowBack}
                    width="18px"
                    height="18px"
                    alt="icon"
                    className={
                      sortedBy === 'orderTotal' ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'
                    }
                  />
                </div>
                <div
                  className={'col cursor-pointer text-left pl-5 pr-0'}
                  onClick={this.handleSorting.bind(this, Constants.sortingOptions.status)}
                >
                  <u>
                    <FormattedMessage id="all_orders_order_status" />
                  </u>
                  <img
                    src={Constants.icons.arrowBack}
                    width="18px"
                    height="18px"
                    alt="icon"
                    className={
                      sortedBy === 'status' ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'
                    }
                  />
                </div>
              </div>
              <div className="fs-14">
                <AllOrderListView
                  orders={ordersData}
                  sorting={true}
                  onSort={this.handleSorting}
                  sortAscending={sortAscending}
                  sortedBy={sortedBy}
                  onRetryOrder={this.handleRetryOrder}
                  onDeleteOrder={this.handleDeleteOrder}
                />
              </div>
            </div>
          ) : (
            <div className=" pt-6 mt-3">
              <React.Fragment>
                {!loading && (
                  <div className="container pt-0 ri-table ">
                    <div className="row justify-content-center mb-4">
                      <p className="text-center">
                        ! &nbsp;
                        <FormattedMessage id="my_orders_no_orders_yet" />
                      </p>
                    </div>
                  </div>
                )}
              </React.Fragment>
            </div>
          )}
        </div>
        {!loading && ordersData.length > 0 && <Pagination cursor={meta.cursor} gotoPage={this.gotoPage} />}
      </>
    );
  };
  renderInvoices = () => {
    const { loading } = this.state;
    const { invoiceData, meta, sortedBy, sortAscending } = this.state;

    return (
      <>
        <div className="col-12">
          {!loading && invoiceData.length > 0 ? (
            <div className="ri-table mb-5 fs-14">
              <ListViewCard
                invoiceData={invoiceData}
                invoiceType
                onSort={this.handleSorting}
                sortAscending={sortAscending}
                sortedBy={sortedBy}
              />
            </div>
          ) : (
            <div className=" pt-6 mt-3">
              <React.Fragment>
                {!loading && (
                  <div className="justify-content-center ">
                    <p className="text-center m-0">
                      ! &nbsp;
                      <FormattedMessage id="common_no_invoice_found" />
                    </p>
                  </div>
                )}
              </React.Fragment>
            </div>
          )}
        </div>

        {!loading && invoiceData.length > 0 && <Pagination cursor={meta.cursor} gotoPage={this.gotoPage} />}
      </>
    );
  };
  renderDetails = () => {
    const { orderdetails, customerID, handleEdit } = this.props;
    const isSuperAdmin = this.authService.isSuperAdminUser();
    const isAdminSales = this.authService.isAdminSalesUser();
    const isAdminBackOffice = this.authService.isAdminBackOfficeUser();
    const roles = {
      '1': Constants.language.role_privat,
      '4': Constants.language.role_bedrift,
      '5': Constants.language.all_contracts_reseller,
    };
    return (
      <div className="col-12">
        {orderdetails && (
          <div className="row line-height-22 ls-0-25">
            <div className="col-6 pl-0">
              <div>
                <h5>
                  <FormattedMessage id="common_customer_details" />
                </h5>

                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    {' '}
                    <FormattedMessage id="customer_head_1" />:
                  </div>
                  <div className="col-7 px-0 text-truncate" title={orderdetails.name}>
                    {' '}
                    <span>{orderdetails.name}</span>
                  </div>
                </div>
                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="common_customer_ID" />:
                  </div>
                  <div className="col-7 px-0">
                    {' '}
                    <span>#{orderdetails.number}</span>
                  </div>
                </div>
                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="all_orders_order_status" />:
                  </div>
                  <div className="col-7 px-0">
                    {' '}
                    <span>{orderdetails.status}</span>
                  </div>
                </div>
                {orderdetails.customerClass &&
                (orderdetails.customerClass.id === '4' || orderdetails.customerClass.id === '5') ? (
                  <div className="details-border row pl-0">
                    <div className="col-5 p-0 font-weight-semibold">
                      {' '}
                      <FormattedMessage id="customer_details_corporateID" />:
                    </div>

                    <div className="col-7 px-0">
                      {' '}
                      <span>{orderdetails.corporateId}</span>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {orderdetails.customerClass && orderdetails.customerClass.id === '5' ? (
                  <div className="details-border row pl-0">
                    <div className="col-5 p-0 font-weight-semibold">
                      {' '}
                      <FormattedMessage id="customer_details_subDealerID" />:
                    </div>

                    <div className="col-7 px-0">
                      {' '}
                      <span>{orderdetails.subDealerId}</span>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    {' '}
                    <FormattedMessage id="customer_type" />:
                  </div>
                  <div className="col-7 px-0">
                    {' '}
                    <span>{orderdetails.customerClass && roles[orderdetails.customerClass.id]}</span>
                  </div>
                </div>
                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="all_contracts_reseller" />:
                  </div>
                  <div className="col-7 text-truncate px-0" title={orderdetails.reseller && orderdetails.reseller.name}>
                    {' '}
                    <span>{orderdetails.reseller && orderdetails.reseller.name}</span>
                  </div>
                </div>
                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="common_reseller_ID" />:
                  </div>
                  <div className="col-7 px-0">
                    {' '}
                    <span>#{orderdetails.reseller && orderdetails.reseller.id}</span>
                  </div>
                </div>
                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="old_customer_id" />:
                  </div>
                  <div className="col-7 px-0 text-capitalize">
                    {' '}
                    <span>{orderdetails.hkold_customer_id && orderdetails.hkold_customer_id}</span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <h5 className="pt-4">
                    <FormattedMessage id="common_main_contact" />{' '}
                  </h5>

                  <div className="details-border row pl-0">
                    <div className="col-5 p-0 font-weight-semibold">
                      <FormattedMessage id="customer_details_contact_person" />:
                    </div>
                    <div
                      className="col-7 text-truncate px-0"
                      title={
                        orderdetails.mainContact &&
                        (orderdetails.mainContact.attention || orderdetails.mainContact.name)
                      }
                    >
                      {' '}
                      <span>
                        {orderdetails.mainContact &&
                          (orderdetails.mainContact.attention || orderdetails.mainContact.name)}
                      </span>
                    </div>
                  </div>
                  <div className="details-border row pl-0">
                    <div className="col-5 p-0 font-weight-semibold">
                      <FormattedMessage id="ph_phone" />:
                    </div>
                    <div className="col-7 px-0">
                      {' '}
                      <span>{orderdetails.mainContact && orderdetails.mainContact.phone1}</span>
                    </div>
                  </div>
                  <div className="details-border row pl-0">
                    <div className="col-5 p-0 font-weight-semibold">
                      <FormattedMessage id="ph_email" />:
                    </div>
                    <div
                      className="col-7 text-truncate px-0"
                      title={orderdetails.mainContact && orderdetails.mainContact.email}
                    >
                      {' '}
                      <span>{orderdetails.mainContact && orderdetails.mainContact.email}</span>
                    </div>
                  </div>
                  <div className="details-border row pl-0">
                    <div className="col-5 p-0 font-weight-semibold">
                      <FormattedMessage id="contract_details_edit_address" />:
                    </div>
                    <div className="col-7 ">
                      {' '}
                      <span>
                        <div className="row">
                          {orderdetails.mainAddress && orderdetails.mainAddress.addressLine1}{' '}
                          {orderdetails.deliveryAddress && orderdetails.deliveryAddress.addressLine2}
                        </div>
                        <div className="row">
                          {orderdetails.mainAddress && orderdetails.mainAddress.postalCode}{' '}
                          {orderdetails.mainAddress && orderdetails.mainAddress.city}
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 pr-0">
              <div>
                <h5 className="">
                  <FormattedMessage id="common_billing" />
                  {(isSuperAdmin || isAdminBackOffice) && (
                    <span className="p-0 cursor-pointer float-right d-inline-block" onClick={handleEdit}>
                      <u className="mr-2 color-1d1d1d fs-14 font-weight-normal">
                        <FormattedMessage id="contract_button_edit_service" />
                      </u>
                      <img className="" src={Constants.icons.materialEdit} width="16" height="16" alt="back-icon" />
                    </span>
                  )}
                </h5>

                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="customer_details_billing-email" />:
                  </div>
                  <div
                    className="col-7 text-truncate px-0"
                    title={orderdetails.invoiceContact && orderdetails.invoiceContact.email}
                  >
                    {' '}
                    <span>{orderdetails.invoiceContact && orderdetails.invoiceContact.email}</span>
                  </div>
                </div>
                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="all_reseller_contact_person" />:
                  </div>
                  <div
                    className="col-7 text-truncate px-0"
                    title={
                      orderdetails.invoiceContact &&
                      (orderdetails.invoiceContact.attention || orderdetails.invoiceContact.name)
                    }
                  >
                    {' '}
                    <span>
                      {orderdetails.invoiceContact &&
                        (orderdetails.invoiceContact.attention || orderdetails.invoiceContact.name)}
                    </span>
                  </div>
                </div>
                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="ph_phone" />:
                  </div>
                  <div className="col-7 px-0">
                    {' '}
                    <span>{orderdetails.invoiceContact && orderdetails.invoiceContact.phone1}</span>
                  </div>
                </div>
                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="invoice_frequency" />:
                  </div>
                  <div className="col-7 px-0 text-capitalize">
                    {' '}
                    <span>{orderdetails.invoiceFrequency ? orderdetails.invoiceFrequency : Constants.language.monthly}</span>
                  </div>
                </div>
                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="ph_billing_reference" />:
                  </div>
                  <div
                    className="col-7 px-0 text-truncate"
                    title={orderdetails.customerOrder && orderdetails.customerOrder.value}
                  >
                    <span>{orderdetails.customerOrder && orderdetails.customerOrder.value}</span>
                  </div>
                </div>
                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="common_collective_invoices" />:
                  </div>
                  <div className="col-7 px-0">
                    <span>
                      {orderdetails.collectiveInvoice ? (
                        <FormattedMessage id="create_yes" />
                      ) : (
                        <FormattedMessage id="create_no" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <h5 className="pt-4">
                  <FormattedMessage id="common_shipping" />
                </h5>
                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="all_reseller_contact_person" />:
                  </div>
                  <div
                    className="col-7 px-0 text-truncate"
                    title={
                      orderdetails.deliveryContact &&
                      (orderdetails.deliveryContact.attention || orderdetails.deliveryContact.name)
                    }
                  >
                    {' '}
                    <span>
                      {orderdetails.deliveryContact &&
                        (orderdetails.deliveryContact.attention || orderdetails.deliveryContact.name)}
                    </span>
                  </div>
                </div>
                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="ph_phone" />:
                  </div>
                  <div className="col-7 px-0">
                    {' '}
                    <span>{orderdetails.deliveryContact && orderdetails.deliveryContact.phone1}</span>
                  </div>
                </div>
                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="ph_email" />:
                  </div>
                  <div
                    className="col-7 px-0 text-truncate"
                    title={orderdetails.deliveryContact && orderdetails.deliveryContact.email}
                  >
                    {' '}
                    <span>{orderdetails.deliveryContact && orderdetails.deliveryContact.email}</span>
                  </div>
                </div>
                <div className="details-border row pl-0">
                  <div className="col-5 p-0 font-weight-semibold">
                    <FormattedMessage id="contract_details_edit_address" />:
                  </div>
                  <div className="col-7">
                    {' '}
                    <span>
                      <div className="row">
                        {' '}
                        {orderdetails.deliveryAddress && orderdetails.deliveryAddress.addressLine1}{' '}
                        {orderdetails.deliveryAddress && orderdetails.deliveryAddress.addressLine2}
                      </div>
                      {/* {orderdetails.deliveryAddress && orderdetails.deliveryAddress.addressLine3} , */}
                      <div className="row">
                        {' '}
                        {orderdetails.deliveryAddress && orderdetails.deliveryAddress.postalCode}{' '}
                        {orderdetails.deliveryAddress && orderdetails.deliveryAddress.city}
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  renderSubscriptions = () => {
    const { loading, meta, serviceData, sortAscending, sortedBy } = this.state;

    return (
      <>
        <div className="col-12">
          {!loading &&
            (serviceData.length > 0 ? (
              <div className="ri-table mb-5">
                <div className="row mt-3 pb-2 px-3 fs-14">
                  <div
                    className={'col-2 pl-2 cursor-pointer'}
                    onClick={this.handleSorting.bind(this, Constants.sortingOptions.contractNo)}
                  >
                    <u className={sortedBy === 'contractNo' ? 'font-weight-semibold' : ''}>
                      <FormattedMessage id="all_contracts_contract_id" />
                    </u>
                    <img
                      src={Constants.icons.arrowBack}
                      width="18px"
                      height="18px"
                      alt="icon"
                      className={
                        sortedBy === 'contractNo' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'
                      }
                    />
                  </div>
                  <div
                    className={'col-4 cursor-pointer'}
                    onClick={this.handleSorting.bind(this, Constants.sortingOptions.customerName)}
                  >
                    {' '}
                    <u className={sortedBy === 'customer.name' ? 'font-weight-semibold' : ''}>
                      <FormattedMessage id="customer_name" />
                    </u>
                    <img
                      src={Constants.icons.arrowBack}
                      width="18px"
                      height="18px"
                      alt="icon"
                      className={
                        sortedBy === 'customer.name' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'
                      }
                    />
                  </div>
                  <div
                    className={'col-2 cursor-pointer'}
                    onClick={this.handleSorting.bind(this, Constants.sortingOptions.registeredDate)}
                  >
                    {' '}
                    <u className={sortedBy === 'registeredDate' ? 'font-weight-semibold' : ''}>
                      <FormattedMessage id="customer_head_5" />
                    </u>
                    <img
                      src={Constants.icons.arrowBack}
                      width="18px"
                      height="18px"
                      alt="icon"
                      className={
                        sortedBy === 'registeredDate' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'
                      }
                    />
                  </div>
                  <div
                    className={'col-2 cursor-pointer'}
                    onClick={this.handleSorting.bind(this, Constants.sortingOptions.activationDate)}
                  >
                    <u className={sortedBy === 'activationDate' ? 'font-weight-semibold' : ''}>
                      <FormattedMessage id="all_contracts_contract_active_date" />
                    </u>
                    <img
                      src={Constants.icons.arrowBack}
                      width="18px"
                      height="18px"
                      alt="icon"
                      className={
                        sortedBy === 'activationDate' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'
                      }
                    />
                  </div>
                  <div
                    className={'col-2 cursor-pointer'}
                    onClick={this.handleSorting.bind(this, Constants.sortingOptions.status)}
                  >
                    <u>
                      <FormattedMessage id="all_orders_order_status" />
                    </u>
                    <img
                      src={Constants.icons.arrowBack}
                      width="18px"
                      height="18px"
                      alt="icon"
                      className={
                        sortedBy === 'status' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'
                      }
                    />
                  </div>
                </div>
                {serviceData.map((data, index) => {
                  return (
                    <div
                      className="row py-2 align-items-center table-row text-truncate fs-14 px-3"
                      key={index}
                      title={data.contractNo}
                    >
                      <div className="col-2 pl-2">
                        <Link to={'/contracts/' + data.contractNo}>
                          <u className="fs-14 font-weight-semibold">{data.contractNo}</u>
                        </Link>
                      </div>
                      <div className="col-4 text-truncate" title={data.customer.name}>
                        {data.customer.name}
                      </div>
                      <div className="col-2 ">{moment(data.registeredDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</div>

                      <div className="col-2">
                        {data.activationDate === null
                          ? ''
                          : moment(data.activationDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                      </div>
                      <div className="col-2">
                        {data.status &&
                          (data.status === 'Activated' ? (
                            <FormattedMessage id="all_contracts_status_activated" />
                          ) : data.status === 'Registered' ? (
                            <FormattedMessage id="all_contracts_status_registered" />
                          ) : data.status === 'Cancelled' ? (
                            <FormattedMessage id="all_orders_status_cancelled" />
                          ) : data.status === 'Terminated' ? (
                            <FormattedMessage id="all_contracts_status_terminated" />
                          ) : (
                            ''
                          ))}
                      </div>
                    </div>
                  );
                })}
                <div className="row py-2 align-items-center table-row text-truncate fs-14 px-4" />
              </div>
            ) : (
              <div className=" pt-6 mt-3">
                <React.Fragment>
                  <div className="justify-content-center ">
                    <p className="text-center m-0">
                      ! &nbsp;
                      <FormattedMessage id="customers_no_subscriptions_yet" />
                    </p>
                  </div>
                </React.Fragment>
              </div>
            ))}
        </div>

        {!loading && serviceData.length > 0 && <Pagination cursor={meta.cursor} gotoPage={this.gotoPage} />}
      </>
    );
  };
  renderCustomers = () => {
    const { loading, sortAscending, sortedBy } = this.state;
    const { customersData, meta } = this.state;
    return (
      <>
        <div className="col-12 ">
          {!loading &&
            (customersData.length > 0 ? (
              <div className="ri-table mb-5">
                <div className="row mt-3 pb-2 px-3 fs-14">
                  <div className={'col-4 pl-2 cursor-pointer'} onClick={this.handleSorting.bind(this, 'name')}>
                    <u className={sortedBy === 'name' ? 'font-weight-semibold' : ''}>
                      <FormattedMessage id="customer_head_1" />
                    </u>
                    <img
                      src={Constants.icons.arrowBack}
                      width="18px"
                      height="18px"
                      alt="icon"
                      className={
                        sortedBy === 'name' ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'
                      }
                    />
                  </div>
                  <div className="col-2 cursor-pointer" onClick={this.handleSorting.bind(this, 'id')}>
                    {' '}
                    <u className={sortedBy === 'id' ? 'font-weight-semibold' : ''}>
                      <FormattedMessage id="all_contracts_contract_id" />
                    </u>
                    <img
                      src={Constants.icons.arrowBack}
                      width="18px"
                      height="18px"
                      alt="icon"
                      className={sortedBy === 'id' ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'}
                    />
                  </div>
                  <div className="col-3 cursor-pointer" onClick={this.handleSorting.bind(this, 'type')}>
                    {' '}
                    <u className={sortedBy === 'type' ? 'font-weight-semibold' : ''}>
                      <FormattedMessage id="all_orders_type" />
                    </u>
                    <img
                      src={Constants.icons.arrowBack}
                      width="18px"
                      height="18px"
                      alt="icon"
                      className={
                        sortedBy === 'type' ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'
                      }
                    />
                  </div>
                  <div className="col-3 cursor-pointer" onClick={this.handleSorting.bind(this, 'date')}>
                    {' '}
                    <u className={sortedBy === 'date' ? 'font-weight-semibold' : ''}>
                      <FormattedMessage id="filter_registered_date" />
                    </u>
                    <img
                      src={Constants.icons.arrowBack}
                      width="18px"
                      height="18px"
                      alt="icon"
                      className={
                        sortedBy === 'date' ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'
                      }
                    />
                  </div>
                </div>
                {customersData.map((data, index) => {
                  return (
                    <div className="row py-2 align-items-center table-row text-truncate fs-14 px-3" key={index}>
                      <div className="col-4 pl-2 text-truncate" title={data.name}>
                        {data.name}
                      </div>
                      <div className="col-2">{data.number}</div>
                      <div className="col-3 text-truncate" title={data.customerClass.description}>
                        {data.customerClass.description}
                      </div>
                      <div className="col-3">{moment(data.createdDateTime, 'YYYY-MM-DD').format('DD/MM/YYYY')}</div>
                    </div>
                  );
                })}
                <div className="row py-2 align-items-center table-row text-truncate fs-14 px-4" />
              </div>
            ) : (
              <div className=" pt-6 mt-3">
                <React.Fragment>
                  <div className="justify-content-center ">
                    <p className="text-center m-0">
                      ! &nbsp;
                      <FormattedMessage id="customers_no_customers_yet" />
                    </p>
                  </div>
                </React.Fragment>
              </div>
            ))}
        </div>
        {!loading && customersData.length > 0 && <Pagination cursor={meta.cursor} gotoPage={this.gotoPage} />}
      </>
    );
  };
  renderUsers = () => {
    const roles = {
      'super-admin': Constants.language.user_options_superadmin,
      'admin-backoffice': Constants.language.user_options_backoffice,
      'admin-sales': Constants.language.user_options_adminsales,
      reseller: Constants.language.user_options_reseller,
    };
    const { loading, userData, meta, sortAscending, sortedBy } = this.state;

    return (
      <>
        <div className="col-12 ">
          {!loading && userData.length > 0 ? (
            <div className="ri-table table-header mb-5">
              <div className="row mt-3 pb-2 px-3 fs-14">
                <div className={'col-3 pl-2 cursor-pointer'} onClick={this.handleSorting.bind(this, 'name')}>
                  <u className={sortedBy === 'name' ? 'font-weight-semibold' : ''}>
                    <FormattedMessage id="common_name" />
                  </u>
                  <img
                    src={Constants.icons.arrowBack}
                    alt="sort"
                    className={sortedBy === 'name' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'}
                  />
                </div>
                <div className={'col-3 cursor-pointer'} onClick={this.handleSorting.bind(this, 'email')}>
                  {' '}
                  <u className={sortedBy === 'email' ? 'font-weight-semibold' : ''}>
                    <FormattedMessage id="ph_email" />
                  </u>
                  <img
                    src={Constants.icons.arrowBack}
                    alt="sort"
                    className={sortedBy === 'email' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'}
                  />
                </div>
                <div className={'col-3 cursor-pointer'} onClick={this.handleSorting.bind(this, 'role')}>
                  {' '}
                  <u className={sortedBy === 'role' ? 'font-weight-semibold' : ''}>
                    <FormattedMessage id="common_user_type" />
                  </u>
                  <img
                    src={Constants.icons.arrowBack}
                    alt="sort"
                    className={sortedBy === 'role' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'}
                  />
                </div>
                <div className={'col-3 cursor-pointer'} onClick={this.handleSorting.bind(this, 'date')}>
                  <u className={sortedBy === 'date' ? 'font-weight-semibold' : ''}>
                    <FormattedMessage id="customer_head_5" />
                  </u>
                  <img
                    src={Constants.icons.arrowBack}
                    alt="sort"
                    className={sortedBy === 'date' ? (sortAscending ? 'arrow-up-sort' : 'arrow-down-sort') : 'd-none'}
                  />
                </div>
              </div>

              {userData.map((data, index) => {
                return (
                  <div className="row py-2 align-items-center table-row text-truncate fs-14 px-3" key={index}>
                    <div className="col-3 pl-2 text-truncate" title={data.name}>
                      {data.name}
                    </div>
                    <div className="col-3 text-truncate" title={data.email}>
                      {data.email}
                    </div>
                    <div className="col-3">{roles[data.role_name]}</div>
                    <div className="col-3">{moment(data.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY')}</div>
                  </div>
                );
              })}
              <div className="row py-2 align-items-center table-row text-truncate fs-14 px-4" />
            </div>
          ) : (
            <div className=" pt-6 mt-3">
              <React.Fragment>
                <div className="justify-content-center ">
                  <p className="text-center m-0">
                    ! &nbsp;
                    <FormattedMessage id="customers_no_users_yet" />
                  </p>{' '}
                </div>
              </React.Fragment>
            </div>
          )}
        </div>
        {!loading && userData.length > 0 && <Pagination cursor={meta.cursor} gotoPage={this.gotoPage} />}
      </>
    );
  };
  renderlog = () => {
    const { loading, activityLogsData, meta, sortedBy, sortAscending } = this.state;
    return (
      <>
        <div className="col-12 p-0">
          {!loading &&
            (activityLogsData.length > 0 ? (
              <div className="ri-table mb-5 fs-14">
                <ListViewCard
                  activityLogsData={activityLogsData}
                  isActivityLog
                  onSort={this.handleSorting}
                  sortAscending={sortAscending}
                  sortedBy={sortedBy}
                />
              </div>
            ) : (
              <div className=" pt-6 mt-3">
                <React.Fragment>
                  <div className="justify-content-center ">
                    <p className="text-center m-0">
                      ! &nbsp;
                      <FormattedMessage id="common_no_log_found" />
                    </p>
                  </div>
                </React.Fragment>
              </div>
            ))}
        </div>
        {!loading && activityLogsData.length > 0 && <Pagination cursor={meta.cursor} gotoPage={this.gotoPage} />}
      </>
    );
  };

  render() {
    const { order, invoice, log, subscriptions, details, customer, user, orderdetails } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <div className="w-100 pb-5 mb-5">
          <LoadingOverlay
            active={
              details
                ? orderdetails && Object.keys(orderdetails).length === 0 && orderdetails.constructor === Object
                : loading
            }
            spinner={<BounceLoader />}
            text={<FormattedMessage id="common_loading_overlay" />}
          >
            <div className="card card-rounded mt-4">
              <div className="card-body  list-view-card-body pr-4">
                <div className="">
                  {order && this.renderOrders()}
                  {invoice && this.renderInvoices()}
                  {log && this.renderlog()}
                  {subscriptions && this.renderSubscriptions()}
                  {details && this.renderDetails()}
                  {customer && this.renderCustomers()}
                  {user && this.renderUsers()}
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      </React.Fragment>
    );
  }
}

export default CustomerDetailViewTabContent;
