import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import Constants from '../../shared/constants';
import {charLimit} from '../../shared/stringOperations';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {hightlight: false};
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    const {disabled} = this.props;
    if (disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    const {disabled, onFilesAdded} = this.props;
    if (disabled) return;
    const files = evt.target.files;
    if (onFilesAdded) {
      const array = this.fileListToArray(files);
      array.length === 1 && onFilesAdded(array[0]);
    }
  }

  onDocumentsAdded = (evt, item) => {
    const {disabled, onFilesAdded} = this.props;
    if (disabled) return;
    if (
      (item.media &&
        item.media.filter(obj => obj.file_type === Constants.FileTypes.DOCS)
          .length < 2) ||
      !item.media
    ) {
      const files = evt.target.files;
      if (onFilesAdded) {
        const array = this.fileListToArray(files);
        array.length === 1 && onFilesAdded(array[0]);
      }
    }
  };

  onDragOver(evt) {
    const {disabled} = this.props;
    evt.preventDefault();
    if (disabled) return;
    this.setState({hightlight: true});
  }

  onDragLeave() {
    this.setState({hightlight: false});
  }

  onDrop(event) {
    const {disabled, onFilesAdded} = this.props;
    event.preventDefault();
    if (disabled) return;
    const files = event.dataTransfer.files;
    if (onFilesAdded) {
      const array = this.fileListToArray(files);
      array.length === 1 &&
        Constants.imageTypes.includes(array[0].type) &&
        onFilesAdded(array[0]);
    }
    this.setState({hightlight: false});
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  findMediaLength = (media, media_type) => {
    try {
      return media.filter(obj => obj.file_type === media_type).length;
    } catch (err) {
      console.log(err, media, 'ERROR');
    }
  };

  render() {
    let {
      item,
      disabled,
      fileType,
      isSuperAdminUser,
      isAdminSales,
      isAdminBackOffice,
      showRemoveIcon,
      fileDelete,
      rackitLogoBlackBG,
    } = this.props;
    return (
      <>
        {fileType === Constants.FileTypes.IMAGE && (
          <div className="col-4 mb-1 pb-2">
            <div
              onDragOver={this.onDragOver}
              onDragLeave={this.onDragLeave}
              onDrop={this.onDrop}
              onClick={this.openFileDialog}
              style={{cursor: disabled ? 'default' : 'pointer'}}
            >
              <div className="Dropzone imageClass">
                <input
                  ref={this.fileInputRef}
                  className="FileInput"
                  accept={Constants.inputAcceptImageTypes}
                  type="file"
                  single={'true'}
                  onChange={this.onFilesAdded}
                />
                {item.media &&
                  this.findMediaLength(item.media, Constants.FileTypes.IMAGE) >
                    0 && (
                    <div
                      className="imageCropped"
                      style={{
                        backgroundImage: `url("${item.media
                          .filter(
                            obj => obj.file_type === Constants.FileTypes.IMAGE
                          )
                          .map(obj => obj.file_url)}")`,
                      }}
                    />
                  )}
                {(!item.media ||
                  (item.media &&
                    this.findMediaLength(
                      item.media,
                      Constants.FileTypes.IMAGE
                    ) === 0)) && (
                  <div
                    className="image-crop-no-image"
                    style={{
                      backgroundImage: `url("${rackitLogoBlackBG}")`,
                    }}
                  />
                )}
                {/* <div
                  className={`overlayProductImage ${
                    this.state.hightlight ? "Highlight" : ""
                  }`}
                /> */}

                {/* {!this.state.hightlight && (
                  <span
                    className={'imageUploadOverlayText'}
                    onDragOver={this.onDragOver}
                    onDrop={this.onDrop}
                  >
                    <FormattedMessage id="common_products_picture_upload" />
                  </span>
                )}
                {this.state.hightlight && (
                  <span
                    className={'imageUploadOverlayTextDrag'}
                    onDragOver={this.onDragOver}
                    onDrop={this.onDrop}
                  >
                    <FormattedMessage id="common_products_drop_box" />
                  </span>
                )} */}
              </div>
            </div>
          </div>
        )}
        {fileType === Constants.FileTypes.DOCUMENT && (
          <>
            <div className="col-7 pt-3">
              {item.media &&
                this.findMediaLength(item.media, Constants.FileTypes.DOCS) >
                  0 &&
                item.media
                  .filter(obj => obj.file_type === Constants.FileTypes.DOCS)
                  .map(obj => (
                    <div className="row mb-2" key={Math.random() * 100}>
                      <div className="col">
                        <i
                          className="fa fa-file-text mt-1"
                          aria-hidden="true"
                        />
                        <u className="pl-2 pr-2">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={obj.file_url}
                            title={obj.file_name}
                          >
                            {charLimit.charLimit(obj.file_name, 30)}
                          </a>
                        </u>

                        {(isSuperAdminUser ||
                          isAdminSales ||
                          isAdminBackOffice) &&
                          showRemoveIcon && (
                            <i
                              className="fa fa-trash mt-1 cursor-pointer"
                              onClick={fileDelete.bind(
                                this,
                                obj.file_id,
                                item.inventoryNumber
                              )}
                              aria-hidden="true"
                            />
                          )}
                      </div>
                    </div>
                  ))}
            </div>
            <div className="col-4 pt-1">
              {(isSuperAdminUser || isAdminSales || isAdminBackOffice) &&
                ((item.media &&
                  this.findMediaLength(item.media, Constants.FileTypes.DOCS) <
                    2) ||
                  !item.media) && (
                  <div className="float-right">
                    <label className="btn bg-dark-aquamarine btn-sm text-white upload-file-btn">
                      {' '}
                      <FormattedMessage id="upload_file_products" />
                      <input
                        ref={this.fileInputRef}
                        single={'true'}
                        accept={Constants.inputAcceptDocumentTypes}
                        onClick={this.openFileDialog}
                        className="FileInput"
                        onChange={event => this.onDocumentsAdded(event, item)}
                        type="file"
                      />
                    </label>
                  </div>
                )}
            </div>
          </>
        )}
      </>
    );
  }
}

export default FileUpload;
