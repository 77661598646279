import {
  SELECT_ORDER,
  CHOOSE_CUSTOMER,
  NEW_END_CUSTOMER,
  NEW_BUSINESS_CUSTOMER,
  SELECT_LOCATION,
  CONFIRM_ORDER,
  FINISH_ORDER,
  UPDATE_SELECTED_PRODUCTS
} from "../actions/types";

const initialState = {
  products: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SELECT_ORDER:
      return {
        ...state,
        ...action.payload
      };
    case CHOOSE_CUSTOMER:
      return {
        ...state,
        ...action.payload
      };
    case NEW_END_CUSTOMER:
      return {
        ...state,
        customer: action.payload
      };
    case NEW_BUSINESS_CUSTOMER:
      return {
        ...state,
        customer: action.payload
      };
    case SELECT_LOCATION:
      return {
        ...state,
        location: action.payload
      };
    case CONFIRM_ORDER:
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_SELECTED_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
    case FINISH_ORDER:
      // Reset the state
      return {};
    default:
      return state;
  }
}
