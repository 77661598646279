import React from 'react';
import Chart from 'react-apexcharts';

function LineChart({ series, xaxis, height, colors, strokes, tooltip = { enabled: false } }) {
  const marker = {
    size: 4,
    fillColor: '#fff',
    fillOpacity: 0,
  };

  const discrete = series
    .filter(obj => obj.discrete)
    .map((obj, index) =>
      [0, 6, 11].map(dataPointIndex => ({
        ...marker,
        strokeColor: colors[index],
        seriesIndex: index,
        dataPointIndex,
      }))
    )
    .flat();

  const options = {
    chart: {
      height: height,
      type: 'line',
      zoom: {
        enabled: false,
      },
      foreColor: 'rgba(0, 0, 0, 0.6)',
    },
    legend: {
      horizontalAlign: 'center',
      labels: {
        colors: 'rgba(0, 0, 0, 0.6)',
      },
      show: true,
      markers: {
        fillColors: colors,
      },
      fontSize: 14,
      fontFamily: 'OpanSans, Helvetica, Arial, sans-serif',
    },
    markers: {
      colors: colors,
      size: 1,
      fillOpacity: 0,
      strokeWidth: 1,
      discrete: discrete,
      hover: {
        size: 4,
      },
    },
    tooltip: tooltip,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: strokes,
      colors: colors,
    },
    title: {
      text: '',
      align: 'left',
    },
    grid: {
      borderColor: '#f3f3f3',
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        bottom: 0,
      },
    },
    xaxis: {
      categories: xaxis,
      labels: {
        show: true,
        style: {
          colors: 'rgba(0, 0, 0, 0.6)',
          fontSize: 14,
          fontFamily: 'OpanSans, Helvetica, Arial, sans-serif',
        },
      },
      crosshairs: {
        opacity: 1,
        position: 'front',
        stroke: {
          width: 1,
          color: '#000000',
          dashArray: 0,
        },
      },
      tooltip: { enabled: false },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: 'rgba(0, 0, 0, 0.6)',
          fontSize: 14,
          fontFamily: 'OpanSans, Helvetica, Arial, sans-serif',
        },
      },
    },
    fill: {
      opacity: 1,
      colors: colors,
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
  };

  return <Chart options={options} series={series} type="line" height={height} />;
}

export default LineChart;
