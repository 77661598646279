import AddServiceForm from './AddServiceForm';
import { FormattedMessage } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import React, { Component } from 'react';
import { Control, ValidationRule } from '../../shared/Control';
import validator from 'validator';
import ContractService from '../../services/ContractService';
import Config from '../../config';
import Constants from '../../shared/constants';

export default class CreateServiceModal extends Component {
  contractService = new ContractService(Config.apiBase);

  state = {
    addServices: {
      isValid: false,
      isFocus: false,
    },
    isAddOnClicked: false,
    newServiceDescription: '',
  };
  constructor(props) {
    super(props);

    this.controls = {
      addServices: [
        new Control({
          name: 'service_name',
          type: 'string',
          label: <FormattedMessage id="common_service_name" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="create_service_name_error"></FormattedMessage>,
          }),
          group: 'soShippingContact',
          id: 'shippingAttention',
          formGroup: 0,
          onBlur: this.handleAddServiceBlur,
          onFocus: this.handleAddServiceFocus,
          onChange: this.handleAddServiceChange,
        }),
        new Control({
          name: 'default_price',
          type: 'number',
          label: <FormattedMessage id="create_contract_default_price" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="create_service_price_error" />,
          }),
          group: 'soShippingContact',
          id: 'defaultPrice',
          formGroup: 0,
          onBlur: this.handleAddServiceBlur,
          onFocus: this.handleAddServiceFocus,
          onChange: this.handleAddServiceChange,
        }),
      ],
    };
  }

  quillModules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['clean'],
        ['custom'],
      ],
      handlers: {
        custom: () => {
          alert('Hello');
        },
      },
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  async componentWillMount() {
    let response = await this.contractService.getParentServicesEntity();
    let parentServicesOptions = [];
    response.data.map(obj => {
      parentServicesOptions.push({
        label: obj.description,
        value: obj.inventoryId,
      });
      return obj;
    });
    this.setState({
      parentServicesOptions,
      newServiceSelectedParent: parentServicesOptions[0],
    });
  }

  validate(controls) {
    return controls.find(obj => obj.rule && !obj.isValid && !obj.donotCheck) === undefined;
  }

  handleAddServiceBlur = () => {
    let { addServices } = this.state;
    addServices.isFocus = false;
    this.setState({ addServices });
  };

  handleAddServiceFocus = () => {
    let { addServices } = this.state;
    addServices.isFocus = true;
    addServices.isValid = this.validate(this.controls.addServices);
  };

  handleAddServiceChange = async (name, value) => {
    let { addServices } = this.state;
    addServices.isValid = this.validate(this.controls.addServices);
    this.setState({ addServices });
  };

  handlOnAddOn = () => {
    if (this.state.isAddOnClicked) {
      this.setState({ isAddOnClicked: false });
    } else {
      this.setState({ isAddOnClicked: true });
    }
  };
  handleOnCreateNewService = async () => {
    const { isAddOnClicked, newServiceDescription, newServiceSelectedParent, newServiceFile, fileItem } = this.state;
    const { addServices } = this.controls;
    let form = new FormData();
    form.append('description', addServices[0].value);
    form.append('defaultPrice', Number(addServices[1].value));

    if (isAddOnClicked) {
      form.append('isChild', true);
      form.append('parent', Number(newServiceSelectedParent.value));
    } else {
      form.append('isChild', false);
    }
    if (newServiceDescription !== '<p><br></p>') {
      form.append('serviceBrief', newServiceDescription);
    }
    if (newServiceFile && newServiceFile[0]) {
      form.append('media', fileItem);
    }
    try {
      this.setState({ loading: true });
      let response = await this.contractService.createSubscriptionItem(form);
      this.setState({ loading: false });
      if (response.status === 202) {
        this.setState({ newServiceSubmitted: true });
      } else {
        this.setState({ serviceFailed: true });
      }
      response = await this.contractService.getParentServicesEntity();
      let parentServicesOptions = [];
      response.data.map(obj => {
        parentServicesOptions.push({
          label: obj.description,
          value: obj.inventoryId,
        });
        return obj;
      });
      this.setState({
        parentServicesOptions,
        newServiceSelectedParent: parentServicesOptions[0],
      });
    } catch (error) {
      console.log(error);
    }
    this.props.toUpdateProductService();
  };

  handleNewSubscriptionFileChange = item => {
    let getBase64 = file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    };
    getBase64(item).then(data => {
      let newItem = {},
        newServiceFile = [];
      newItem.file_type = Constants.FileTypes.IMAGE;
      newItem.file_url = data;
      newServiceFile.push(newItem);
      this.setState({ newServiceFile, fileItem: item });
    });
  };

  handleNewSubscriptionEditorChange = value => {
    this.setState({ newServiceDescription: value });
  };

  handleOnSelectParentService = value => {
    this.setState({ newServiceSelectedParent: value });
  };

  handleOnClickServiceModalClose = async () => {
    this.controls = {
      addServices: [
        new Control({
          name: 'service_name',
          type: 'string',
          label: 'Service Name',
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: 'Please Enter Service Name',
          }),
          group: 'soShippingContact',
          id: 'shippingAttention',
          formGroup: 0,
          onBlur: this.handleAddServiceBlur,
          onFocus: this.handleAddServiceFocus,
          onChange: this.handleAddServiceChange,
        }),
        new Control({
          name: 'default_price',
          type: 'number',
          label: 'Default Price',
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: 'Please Enter Default Price',
          }),
          group: 'soShippingContact',
          id: 'shippingAttention',
          formGroup: 0,
          onBlur: this.handleAddServiceBlur,
          onFocus: this.handleAddServiceFocus,
          onChange: this.handleAddServiceChange,
        }),
      ],
    };
    const addServices = {
      isValid: false,
      isFocus: false,
    };
    const isAddOnClicked = false;
    const newServiceDescription = '';
    this.setState({
      addServices,
      isAddOnClicked,
      newServiceDescription,
      newServiceSubmitted: false,
      newServiceFile: [],
    });
  };
  render() {
    const {
      isAddOnClicked,
      addServices,
      newServiceDescription,
      newServiceSubmitted,
      serviceFailed,
      newServiceFile,
      parentServicesOptions,
      loading,
    } = this.state;
    return (
      <div
        className="modal fade bd-example-modal-lg"
        tabIndex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="createServiceModal"
      >
        <div className="modal-dialog modal-lg create-service-modal">
          <div className="modal-content border-0 rounded-small bg-white">
            <div className="modal-header">
              <h5 className="modal-title pl-4 font" id="myLargeModalLabel">
                <FormattedMessage id="contract_button_create_service" />
              </h5>
              <button
                type="button"
                className="close outline-0"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.handleOnClickServiceModalClose}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-5  my-2 line-height-normal ls-normal">
              <LoadingOverlay
                active={loading}
                spinner={<BounceLoader />}
                text={<FormattedMessage id="common_loading_overlay" />}
                classNamePrefix={'light_modal_ '}
                className={'loader-list-view-common'}
              >
                <div className="row">
                  <div className="col">
                    <AddServiceForm
                      controls={this.controls}
                      isAddOnClicked={isAddOnClicked}
                      onAddOn={this.handlOnAddOn}
                      onCreateNewService={this.handleOnCreateNewService}
                      newSubscriptionFileChange={this.handleNewSubscriptionFileChange}
                      newSubscriptionEditorChange={this.handleNewSubscriptionEditorChange}
                      onSelectParentService={this.handleOnSelectParentService}
                      newServiceDescription={newServiceDescription}
                      newServiceSubmitted={newServiceSubmitted}
                      isValid={addServices.isValid}
                      quillModules={this.quillModules}
                      serviceFailed={serviceFailed}
                      mediaArray={newServiceFile}
                      parentServicesOptions={parentServicesOptions}
                    />
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
