import React, { Component } from 'react';
import { Control, ValidationRule } from '../../shared/Control';
import Config from '../../config';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';
import ProductService from '../../services/ProductService';
import AuthService from '../../services/AuthService';
import { charLimit } from '../../shared/stringOperations';
import Constants from '../../shared/constants';

import EditProductForm from './EditProductForm';

let productService = new ProductService(Config.apiBase);

let authService = new AuthService(Config.apiBase);
export default class EditProductModal extends Component {
  state = {
    addProduct: { isValid: false, isFocus: false },
    addPrice: { isValid: false, isFocus: false },
    addStatus: { isValid: false, isFocus: false },
    addItem: { isValid: false, isFocus: false },
    editItem: {},
    activeOptions: [
      { value: 'Active', label: Constants.language.all_products_status_active },
      { value: 'Inactive', label: Constants.language.create_inactive },
      { value: 'NoSales', label: Constants.language.all_products_status_no_sales },
    ],
    itemClassOptions: [],
  };
  quillModules = {
    toolbar: {
      container: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean'], ['custom']],
      handlers: {
        custom: () => {
          alert('Hello');
        },
      },
    },
    clipboard: { matchVisual: false },
  };
  constructor(props) {
    super(props);
    this.controls = {
      addProduct: [
        new Control({
          name: 'description',
          type: 'string',
          label: <FormattedMessage id="create_product_name" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="create_product_name_error" />,
          }),
          id: 'addproduct1',
          onFocus: this.handleAddProductFocus,
        }),
      ],
      addStatus: [
        new Control({
          name: 'status',
          type: 'select',
          label: charLimit.charLimit(Constants.language.all_orders_order_status, 10) + '*',
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_status" />,
          }),

          id: 'customerType',
          classProps: 'w-auto mt-0',
          options: this.state.activeOptions,
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
      addItem: [
        new Control({
          name: 'inventoryNumber',
          type: 'alphanumeric',
          label: <FormattedMessage id="create_item_nr" />,
          disabled: true,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_status" />,
          }),
          id: 'additem1',
          onFocus: this.handleAddProductFocus,
        }),
      ],
      addEl: [
        new Control({
          name: 'elNumber',
          type: 'text',
          label: <FormattedMessage id="create_El_nr" />,
          id: 'addEl1',
          onFocus: this.handleAddProductFocus,
        }),
      ],
      addPrice: [
        new Control({
          name: 'defaultPrice',
          type: 'number',
          label: <FormattedMessage id="create_Price" />,
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="create_service_price_error" />,
          }),
          id: 'addPrice1',
          onFocus: this.handleAddProductFocus,
        }),
      ],
      addCompanyPrice: [
        new Control({
          name: 'priceClass',
          type: 'number',
          label: <FormattedMessage id="create_price_company" />,
          customerClass: '4',
          id: 'addCompanyPrice1',
          onFocus: this.handleAddProductFocus,
        }),
      ],
      addResellerPrice: [
        new Control({
          name: 'priceClass',
          type: 'number',
          label: <FormattedMessage id="create_price_reseller" />,
          id: 'addResellerPrice1',
          customerClass: '5',
          onFocus: this.handleAddProductFocus,
        }),
      ],
      addPrivatePrice: [
        new Control({
          name: 'priceClass',
          type: 'number',
          label: <FormattedMessage id="create_price_private" />,
          customerClass: '1',
          id: 'addPrivatePrice1',
          onFocus: this.handleAddProductFocus,
        }),
      ],
      addItemClass: [
        new Control({
          name: 'itemClass',
          type: 'select',
          rule: new ValidationRule({
            method: validator.isEmpty,
            validWhen: false,
            message: <FormattedMessage id="warning_item_class" />,
          }),

          id: 'itemClass',
          classProps: 'w-auto mt-0',
          options: this.state.itemClassOptions,
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        }),
      ],
      installationTime: [
        new Control({
          name: 'installationTime',
          type: 'number',
          label: <FormattedMessage id="installation_time" />,
          id: 'addInstallationTime',
          onFocus: this.handleAddProductFocus,
          onChange: this.handleInputChange,
        })
      ],
    };
  }
  validation(controls) {
    return controls.find(obj => obj.rule && !obj.isValid) === undefined;
  }
  async componentWillMount() {
    const { editItem } = this.props;
    this.setState({ editItem });

    this.quillModules.value = editItem.productBrief;
    let response = await productService.getItemClassEntity();
    const itemClassOptions = [];
    response.data.map(obj => {
      itemClassOptions.push({ label: obj.description, value: obj.id });
      return obj;
    });
    this.controls.addItemClass[0].options = itemClassOptions;

    this.setState({ itemClassOptions });
    this.getProductDetail(editItem.inventoryNumber);
  }
  getProductDetail = async inNumber => {
    let result;
    try {
      result = await productService.updateProductData(inNumber);
      let {
        addProduct,
        addEl,
        addItem,
        addPrice,
        addCompanyPrice,
        addPrivatePrice,
        addResellerPrice,
        addItemClass,
        addStatus,
        installationTime
      } = this.controls;
      const {
        data,
        data: { priceClass, description, inventoryNumber, elNumber, defaultPrice, itemClass, status, installationTime: installationTimeData },
      } = result;
      if (data) {
        addProduct[0].setValue(description);
        addItem[0].setValue(inventoryNumber);
        addPrice[0].setValue(String(defaultPrice) || '');
        (elNumber && elNumber !== null) ? addEl[0].setValue(String(elNumber)) : addEl[0].setValue('');
        installationTimeData ? installationTime[0].setValue(String(installationTimeData)) : installationTime[0].setValue(String('0'));
        addItemClass[0].setValue(itemClass.id);
        addStatus[0].setValue(status);
        let privatePrice =
          priceClass &&
          priceClass.find(obj => {
            if (obj.customerClass === '1') {
              const { price = 0 }= obj;
              return JSON.stringify(price);
            }
            return '';
          });
        let publicPrice =
          priceClass &&
          priceClass.find(obj => {
            if (obj.customerClass === '4') {
              const { price = 0 }= obj;
              return JSON.stringify(price);
            }
            return '';
          });
        let resellerPrice =
          priceClass &&
          priceClass.find(obj => {
            if (obj.customerClass === '5') {
              const { price = 0 }= obj;
              return JSON.stringify(price);
            }
            return '';
          });
          const { price: privateConsumerPrice = 0 } = privatePrice||{};
          const { price: publicConsumerPrice = 0 } = publicPrice||{};
          const { price: resellerConsumerPrice = 0 } = resellerPrice||{};
        addPrivatePrice[0].setValue(privateConsumerPrice === null ? '' : String(privateConsumerPrice));
        addCompanyPrice[0].setValue(publicConsumerPrice === null ? '' : String(publicConsumerPrice));
        addResellerPrice[0].setValue(resellerConsumerPrice === null ? '' : String(resellerConsumerPrice));
        this.setState({ editItem: data });
        this.openElement.click();
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { editItem } = this.state;
    const { editProdutsModalClose, productResponse } = this.props;
    return (
      <>
        <a
          href="#"
          data-toggle="modal"
          data-target="#editproductmodal"
          className="fs-14 text-align-center col-3 pl-5"
          ref={openModal => (this.openElement = openModal)}
        />
        {editItem && (
          <EditProductForm
            validation={this.validation}
            controls={this.controls}
            editItem={editItem}
            quillModules={this.quillModules}
            editProdutsModalClose={editProdutsModalClose}
            productResponse={productResponse}
          />
        )}
      </>
    );
  }
}
