import Constants from "./constants";

export const addDays = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getWeekends = () => {
  let currentDate = new Date();
  let weekends = [];

  for (let i = 0; i <= 365; i++) {
    const temp = new Date(currentDate.getTime());
    temp.setDate(temp.getDate() + i);
    const tempDay = temp.getDay();
    if (tempDay === 0 || tempDay === 6) {
      weekends.push(temp);
    }
  }
  return weekends;
};

export const groupByKey = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

export const removeHTMLTags = str => {
  if (str === null || str === "") return false;
  else {
    str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  }
};

export const getCustomerType = ({customerClass = {} }) => {
  const customerClassId = Number(customerClass.id);
  if (customerClassId && Constants.customerClass[customerClassId]) {
    return Constants.customerClass[customerClassId]
  } 
  return null
};