import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Constants from '../../shared/constants';
import AuthService from '../../services/AuthService';
import Config from '../../config';
import queryString from 'query-string';
import LoadingOverlay from 'react-loading-overlay';
import Routes from '../../shared/Routes';

class NewPassword extends Component {
  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      cpassword: '',
      isSubmitted: false,
      isDisabled: true,
      token: null,
      doesntMatch: false,
      expired: false,
    };
    const token = localStorage.getItem(Constants.storage.token);
    if (token) {
      localStorage.clear();
      this.props.history.push({ pathname: Constants.routes.home.url });
    }
  }

  componentWillMount() {
    const params = queryString.parse(this.props.location.search);
    this.setState({ params });
  }

  handleChange = event => {
    const eventTarget = event.target;
    this.setState({ [eventTarget.name]: eventTarget.value });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { password, cpassword, params } = this.state;

    const isValid = this.validateForm();

    this.setState({
      doesntMatch: isValid.doesntMatch,
      doesntMeetRequirements: isValid.doesntMeetRequirements,
    });

    if (isValid.doesntMatch || isValid.doesntMeetRequirements) {
      return false;
    }

    this.setState({ loading: true });
    const response = await this.authService.resetPassword({
      email: params.email,
      password,
      password_confirmation: cpassword,
      token: params.token,
    });
    if (response.status === 202) {
      this.setState({
        isSubmitted: true,
        error: undefined,
        loading: false,
      });
    } else if (response.status === 401) {
      this.setState({
        expired: true,
        loading: false,
      });
    } else {
      this.setState({ error: response.data.message, loading: false });
    }
  };
  validateForm = () => {
    const { password, cpassword } = this.state;
    const taiPasswordStrength = require('tai-password-strength');
    const passwordStrength = new taiPasswordStrength.PasswordStrength();
    const validation = passwordStrength.check(password);

    const doesntMatch = password !== cpassword;
    const doesntMeetRequirements = !(
      validation &&
      validation.charsets &&
      validation.charsets.upper &&
      validation.charsets.number &&
      validation.passwordLength >= 8
    );

    return { doesntMatch, doesntMeetRequirements };
  };

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    const token = values.token;
    const type = values.type;
    if (token) {
      this.setState({ token, type });
    } else {
      this.props.history.push(Constants.routes.signin);
    }
    if (this.props.location.pathname === Routes.newPassword) {
      localStorage.clear();
    }
  }

  render() {
    const {
      password,
      cpassword,
      isSubmitted,
      doesntMatch,
      params,
      loading,
      doesntMeetRequirements,
      expired,
    } = this.state;
    return (
      <LoadingOverlay
        className={!isSubmitted ? '' : 'h-100'}
        active={loading}
        spinner
        text={<FormattedMessage id="common_loading_overlay" />}
        classNamePrefix="dark-loading-overlay-"
      >
        <div className="container-fluid h-100  p-0 bg-light-dark pb-5">
          <div className="container">
            {!isSubmitted ? (
              <div>
                <div className="row pt-8">
                  <div className="col text-center">
                    <h1 className="font-weight-light fs-59 text-white">
                      <FormattedMessage id="verify_profile_welcome_message" />
                    </h1>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col">
                    <div className="text-center mt-3">
                      <p className="text-white fs-16 font-weight-light">
                        <FormattedMessage id="password_enter_new_password" />
                      </p>
                    </div>
                  </div>
                </div>
                <form onSubmit={this.handleSubmit} className="min-height-500 bg-light-dark">
                  <div className="row justify-content-center">
                    <div className="col-5">
                      <div className="form-group text-white text-center mt-2  ">
                        <label htmlFor="Email" className="font-weight-semibold fs-16 mb-0">
                          <FormattedMessage id="password_your_username" />
                        </label>
                        <div className="font-weight-light fs-16">{params.email}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-5 w-378-max px-0">
                      <div className="form-group mt-3">
                        <label className="fs-16 text-white font-weight-light" htmlFor="Password">
                          <FormattedMessage id="password_choose_password" />
                        </label>

                        <div className="">
                          <input
                            type="password"
                            name="password"
                            defaultValue={password}
                            onChange={this.handleChange}
                            required
                            className="form-control  border-0 h-44 mb-1 signin-input px-4"
                            id="exampleInputPassword"
                            aria-describedby="passwordHelp"
                            placeholder={Constants.language.ph_new_password}
                          />
                          <small
                            id="passwordHelpBlock"
                            className="mb-2 mt-3 form-text fs-14 text-white font-weight-normal"
                          >
                            <FormattedMessage id="password_rules" />
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-5 w-378-max px-0">
                      <div className="form-group mt-2">
                        <label className="fs-16 text-white font-weight-normal" htmlFor="Password">
                          *<FormattedMessage id="password_confirm_password" />
                        </label>
                        <div className="">
                          <input
                            type="password"
                            name="cpassword"
                            defaultValue={cpassword}
                            onChange={this.handleChange}
                            required
                            className="form-control h-44 signin-input mb-1 border-0 px-4"
                            id="exampleInputPassword"
                            aria-describedby="passwordHelp"
                            placeholder={Constants.language.ph_confirm_password}
                          />
                        </div>
                        <div className="text-center">
                          <div className="text-danger  error-message small">
                            {doesntMatch && <FormattedMessage id="password_doesnt_match" />}
                          </div>
                          <div className="text-danger  error-message small">
                            {doesntMeetRequirements && <FormattedMessage id="password_doesnt_meet_requirement" />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center pb-8">
                    <div className="col-5 w-378-max px-0">
                      <div className="text-center">
                        {expired && (
                          <span className="text-danger error-message small">
                            <FormattedMessage id="password_link_expired" />
                          </span>
                        )}
                        <button type="submit" className="btn btn-primary fs-14 h-44 py-1">
                          <FormattedMessage id="password_submit" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <div>
                <div className="row pt-8">
                  <div className="col text-center">
                    <h1 className="font-weight-light text-white fs-59">
                      <FormattedMessage id="password_updated_header" />
                    </h1>
                    <p className="font-weight-normal text-white fs-16 mt-3 pb-1">
                      <FormattedMessage id="password_updated" />
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-5 w-378-max px-0">
                    <div className="text-center mt-5">
                      <Link to={Constants.routes.home.url} className="btn btn-primary fs-14 h-44 py-1">
                        <div className="mt-1">
                          <FormattedMessage id="common_sign_in_header" />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default NewPassword;
