import React from 'react';
import { FormattedMessage } from 'react-intl';
import ListViewComponent from '../ListView/ListView';
import Accordion from '../Accordion';
import { AllOrderAccordionHeader, AllOrderAccordionBody, AllOrderListViewHeader } from './';
import PropTypes from 'prop-types';
import Constants from '../../shared/constants';

class AllOrderListView extends ListViewComponent {
  state = {
    expandedOrder: {},
    openOrderActionsMenu: {
      open: false,
      order: {},
    },
  };

  handleToggle = order => {
    const { expandedOrder, openOrderActionsMenu } = this.state;
    if (order._id === expandedOrder._id) {
      this.setState({ expandedOrder: {}, openOrderActionsMenu: { ...openOrderActionsMenu, open: false } });
    } else {
      this.setState({ expandedOrder: order });
    }
  };

  /**
   * Handler to open order actions for the selected order
   *
   * @param {object} order
   */
  handleOpenOrderActionsMenu = order => () => {
    const {
      openOrderActionsMenu: {
        open,
        order: { _id: orderId },
      },
    } = this.state;

    if (orderId === order._id) {
      this.setState({
        openOrderActionsMenu: {
          open: !open,
          order,
        },
      });
    } else {
      this.setState({
        openOrderActionsMenu: {
          open: true,
          order,
        },
      });
    }
  };

  renderAccordions = ({
    orders,
    expandedOrder,
    isSuperAdmin,
    isAdminBackOffice,
    isAdminSales,
    isContracts,
    pathName,
    onRetryOrder,
    onDeleteOrder,
    openOrderActionsMenu,
    isProposal,
    onEditOrder,
  }) => {
    return orders.map((order, index) => {
      if (order.status === Constants.ORDER_STATUS.FAILED || order.status === Constants.ORDER_STATUS.ERROR) {
        order.orderNo = Constants.language.all_orders_invoice_draft;
      }
      return (
        <Accordion
          key={Math.random() * 100}
          item={order}
          isExpanded={expandedOrder._id === order._id}
          onToggle={this.handleToggle}
          isSuperAdmin={isSuperAdmin}
          isAdminSales={isAdminSales}
          isAdminBackOffice={isAdminBackOffice}
          isContracts={isContracts}
          isProposal={isProposal}
        >
          <AllOrderAccordionHeader
            pathName={pathName}
            key={Math.random() * 100}
            isProposal={isProposal}
            isExpanded={expandedOrder._id === order._id}
            openOrderActionsMenu={openOrderActionsMenu}
            onOpenOrderActionsMenu={this.handleOpenOrderActionsMenu}
            onRetryOrder={onRetryOrder}
            onDeleteOrder={onDeleteOrder}
            onEditOrder={onEditOrder}
          />
          <AllOrderAccordionBody pathName={pathName} key={Math.random() * 100} />
        </Accordion>
      );
    });
  };

  render() {
    const { expandedOrder, openOrderActionsMenu } = this.state;
    const {
      isContracts,
      orders,
      pathName,
      isSuperAdmin,
      isAdminSales,
      isAdminBackOffice,
      onRetryOrder,
      onDeleteOrder,
      isProposal,
      onEditOrder,
    } = this.props;
    return (
      <React.Fragment>
        <div className={'accordion' + (isContracts ? ' contracts-accordion' : '')}>
          {this.renderAccordions({
            orders,
            isSuperAdmin,
            isAdminSales,
            isAdminBackOffice,
            isContracts,
            pathName,
            expandedOrder,
            openOrderActionsMenu,
            onRetryOrder,
            onDeleteOrder,
            isProposal,
            onEditOrder,
          })}
        </div>
      </React.Fragment>
    );
  }
}

AllOrderListView.propTypes = {
  isContracts: PropTypes.bool,
  sortAscending: PropTypes.bool,
  sortedBy: PropTypes.string,
  orders: PropTypes.array,
  pathName: PropTypes.string,
  isSuperAdmin: PropTypes.bool,
  isAdminSales: PropTypes.bool,
  isAdminBackOffice: PropTypes.bool,
};

export default AllOrderListView;
