import React from 'react';
import { FormattedMessage } from 'react-intl';
import Constants from '../../../../shared/constants';
import CounterChange from '../../../CounterChange/CounterChange';
import PropTypes from 'prop-types';
import { charLimit } from '../../../../shared/stringOperations';
import { store } from '../../../../state/store';
import { CustomerType } from '../../../../shared/CustomerType';
import AuthService from '../../../../services/AuthService';
import Config from '../../../../config';
import PriceEditable from '../../../Input/PriceEditable';
import DecimalPrice from '../../../DecimalPrice';

export const SelectedProductsListView = ({
  productsData,
  onQtyIncrease,
  onQtyDecrease,
  isServices,
  isProposals = false,
  onRemoveItem,
  onServicePriceChange,
  needInstallation,
  isPriceBasedOnHours,
  installationFields,
  onRemoveInstallation,
  handleIncreaseOrDecreaseHours,
  isServiceProposal = false,
  proposalDetails,
}) => {
  const authService = new AuthService(Config.apiBase);
  const isSuperAdminUser = authService.isSuperAdminUser();
  const isAdminBackOffice = authService.isAdminBackOfficeUser();
  const { installation } = Constants;
  const order = store.getState().order;
  let customerClassId;
  let customer;
  if (order.type == CustomerType.NEW_CONSUMER) {
    if (order.customerType) {
      if (order.customerType === CustomerType.PRIVATE_CUSTOMER) {
        customerClassId = 1;
      } else if (order.customerType === CustomerType.BUSINESS_CUSTOMER) {
        customerClassId = 4;
      }
    }
  }
  if (order.type == CustomerType.EXISTING_CUSTOMER) {
    if (order.customerDetails) {
      customer = order.customerDetails;
      customerClassId = Number(customer && customer.customerClass && customer.customerClass.id);
    }
  }
  const setDefaultPriceValue = (value) => {
    if(proposalDetails){
      const { lines = [] } = proposalDetails;
      const record = lines.find((data) => data.inventory.number === value.inventoryNumber);
      return record ? record.unitPrice : value.defaultPrice;
    }
    return value.defaultPrice;
  }
  return (
    <div
      className={'row' + (isServices ? ' selected-products-list' : ' selected-products-list')}
      id="selected-products-list"
    >
      <div className="col p-0 mx-3">
        <div className={'row mb-3 fs-14 ' + (isServices ? 'mx-0' : 'ml-0')}>
          <div className={'col pl-0'}>
            <div className="row pb-1 border-bottom mb-2 color-1d1d1d ml-0 mr-1" id="selected-products-header">
              <div className="col pl-1">
                <FormattedMessage id="common_name" />
              </div>
              {!isServices && (
                <div className={'col-3 w-20-per-max pl-2'}>
                  <FormattedMessage id="all_products_category" />
                </div>
              )}
              {isProposals ? (
                <div className={'text-right col-2 w-20-per-max'}>
                  <FormattedMessage id="order_customer_price" />
                </div>
              ) : (
                <div className={'text-right ' + (isServices ? 'col-2' : 'col-2 w-20-per-max')}>
                  {!isServiceProposal && (
                    <>
                      {!isServices ? (
                        <FormattedMessage id="common_price_excluding_tax" />
                      ) : (
                        <FormattedMessage id="common_price" />
                      )}
                      {isServices && (
                        <>
                          {' /'}
                          <FormattedMessage id="common_per_month" />
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
              {isServiceProposal && (
                <>
                  {/* <div className={'text-right col-2 w-20-per-max'}>
                    <FormattedMessage id="order_reseller_price" />
                    {' /'}
                    <FormattedMessage id="common_per_month" />
                  </div> */}
                  <div className={'text-right col-2 w-20-per-max'}>
                    <FormattedMessage id="order_customer_price" />
                    {' /'}
                    <FormattedMessage id="common_per_month" />
                  </div>
                </>
              )}
              {!isServices && (
                <div className={'col-2 text-center'}>
                  <FormattedMessage id="common_quantity" />
                </div>
              )}

              {((productsData && productsData.length > 0) || needInstallation) && (
                <div className="col-1 text-center p-0">
                  <FormattedMessage id="common_remove" />
                </div>
              )}
            </div>

            <div className={'border-bottom min-height-44 '}>
              {productsData.map((obj, index) => (
                <div
                  className={
                    'row selected-product-list-elements fs-14 font-weight-semibold mx-0 mb-2 ' +
                    (obj.children && obj.children.length > 0 ? index > 0 && 'mt-2' : '')
                  }
                  key={Math.random() * 100}
                >
                  <div
                    className={'col pl-1 text-truncate' + ' ' + (obj.parent ? 'font-weight-normal' : '')}
                    title={obj.description}
                  >
                    {obj.description}
                  </div>
                  {!isServices && (
                    <div className={'col-3 w-20-per-max pl-2'} title={obj.itemClass && obj.itemClass.description}>
                      {obj.itemClass && obj.itemClass.description}
                    </div>
                  )}
                  {isProposals ? (
                    <>
                      <div className={'text-right col-2 w-20-per-max'}>
                        {isServices ? (
                          isSuperAdminUser || isAdminBackOffice ? (
                            <span className={'price-edit p-1'}>
                              <PriceEditable
                                text={obj.defaultPrice}
                                onChange={onServicePriceChange(obj)}
                                labelClassName="ItemPriceEditable"
                                inputClassName="ItemPriceEditable bg-transparent text-right"
                                inputWidth="90px"
                                inputHeight="30px"
                                inputMaxLength="50"
                              />
                              {Constants.currency.norway}
                            </span>
                          ) : (
                            <DecimalPrice number={setDefaultPriceValue(obj)} />
                          )
                        ) : (
                          <DecimalPrice number={setDefaultPriceValue(obj) * obj.quantity} />
                        )}
                      </div>
                    </>
                  ) : isServiceProposal ? (
                    <>
                      {/* <div className={'col-2 text-right '}>
                        <DecimalPrice number={obj && obj.resellerPrice || ''} />
                      </div> */}
                      <div className={'col-2 text-right '}>
                        <span className={'price-edit p-1'}>
                          <PriceEditable
                            text={obj.defaultPrice}
                            onChange={onServicePriceChange(obj)}
                            labelClassName="ItemPriceEditable"
                            inputClassName="ItemPriceEditable bg-transparent text-right"
                            inputWidth="90px"
                            inputHeight="30px"
                            inputMaxLength="50"
                          />
                          {Constants.currency.norway}
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className={isServices ? 'col-2 text-right ' : 'col-2 text-right pr-3 w-20-per-max'}>
                      {isServices ? (
                        isSuperAdminUser || isAdminBackOffice ? (
                          <span className={'price-edit p-1'}>
                            <PriceEditable
                              text={obj.defaultPrice}
                              onChange={onServicePriceChange(obj)}
                              labelClassName="ItemPriceEditable"
                              inputClassName="ItemPriceEditable bg-transparent text-right"
                              inputWidth="90px"
                              inputHeight="30px"
                              inputMaxLength="50"
                            />
                            {Constants.currency.norway}
                          </span>
                        ) : (
                          <DecimalPrice number={obj.defaultPrice} />
                        )
                      ) : (
                        <DecimalPrice number={obj.defaultPrice * obj.quantity} />
                      )}
                    </div>
                  )}
                  {!isServices && (
                    <div className="col-2 item-quantity text-center align-self-center">
                      <CounterChange
                        value={obj.quantity || 0}
                        onIncrease={onQtyIncrease.bind(this, obj)}
                        onDecrease={onQtyDecrease.bind(this, obj)}
                      />
                    </div>
                  )}
                  {productsData && productsData.length > 0 && (
                    <div className="col-1 text-center ">
                      <img
                        src={Constants.icons.crossNew1}
                        width="15"
                        height="13"
                        alt="icon"
                        className="cursor-pointer"
                        onClick={onRemoveItem.bind(this, { itemData: obj })}
                      />
                    </div>
                  )}
                </div>
              ))}
              {isProposals && needInstallation && (
                <div className="fs-14">
                  <div className="row m-0 color-1d1d1d py-2 font-weight-semibold mb-4">
                    <div className="col p-0">
                      <FormattedMessage id="common_installation" />
                    </div>
                    {isPriceBasedOnHours ? (
                      <>
                        <div className="col-2 text-right">
                          {Number(installationFields[0].value) * Number(installationFields[1].value)}
                          {Constants.currency.norway}
                        </div>
                        <div className="col-2 item-quantity text-center align-self-center">
                          {/* <CounterChange
                             value={installationFields[1].value || 0}
                             onIncrease={() => handleIncreaseOrDecreaseHours(1)}
                             onDecrease={() => handleIncreaseOrDecreaseHours(-1)}
                          /> */}
                        </div>
                        <div className="col-1 text-center ">
                          <img
                            src={Constants.icons.crossNew1}
                            width="15"
                            height="13"
                            alt="icon"
                            className="cursor-pointer"
                            onClick={() => onRemoveInstallation('needInstallation', installation.no)}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-2 text-right">
                          {installationFields[2].value}
                          {Constants.currency.norway}
                        </div>
                        <div className="col-2 item-quantity text-center align-self-center disable-card">
                          <CounterChange value={1} />
                        </div>
                        <div className="col-1 text-center ">
                          <img
                            src={Constants.icons.crossNew1}
                            width="15"
                            height="13"
                            alt="icon"
                            className="cursor-pointer"
                            onClick={() => onRemoveInstallation('needInstallation', installation.no)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SelectedProductsListView.propTypes = {
  productsData: PropTypes.array,
  onQtyIncrease: PropTypes.func,
  onQtyDecrease: PropTypes.func,
  isServices: PropTypes.bool,
  onRemoveItem: PropTypes.func,
  proposalDetails: PropTypes.array,
};
